/* eslint-disable no-octal */
import {TabState} from '../interfaces/TabActions';
import {
    AddQuarantObjArrayField,
    AddQuarantProd,
    AddQuquarantObjArrayField,
    AddTag,
    AddTndevArrayField,
    DeleteRequirementBlock,
    DeleteTag,
    GetRequirList,
    ResetReqFom,
    SetArrayFieldValue,
    SetReqFormToggleCheker,
    SetReqFormValue
} from '../interfaces/requirementsFormActions'
import {IRequirementsTab, ISpecificRequirementsTab, ITab} from '../interfaces/TabInterface';


export const addTags = (state: TabState, action: AddTag): ITab[] => {
    const newTabs: ITab[] = state.tabs.map((tab, idx) => {
        console.log('Save')
        if (idx === +action.payload.idTab) {
            const newTags = [...tab[action.payload.nameForm][action.payload.nameTags], action.payload.tag]
            const newForm = Object.assign({},
                tab[action.payload.nameForm],
                {
                    [action.payload.nameTags]: newTags
                })
            return Object.assign({},
                tab,
                {
                    [action.payload.nameForm]: newForm
                })
        }
        return tab
    })
    return newTabs
}

export const deleteTag = (state: TabState, action: DeleteTag): ITab[] => {
    return state.tabs.map((tab, idx) => {
        console.log('dasdasd')
        if (idx === action.payload.idTab) {
            let newTags = []
            //Условие нужно для того чтобы удалять пустые поля, которые были доавблены, но не сохранены (их айди поумолчанию 0)
            if (+action.payload.idTag === 0) {
                const indexOfDeleted = tab[action.payload.nameForm][action.payload.nameTags].findIndex((tag: { [key: string]: any }) => {
                    let fl = false
                    Object.keys(tag).forEach(key => {
                        //Split сделан, чтобы проверять только основное id объекта
                        const splitKey = key.split('_')
                        if (splitKey[splitKey.length - 1] === 'id' && splitKey.length < 3) {
                            if (+tag[key] === +action.payload.idTag) {
                                fl = true
                            }
                        }
                    })
                    return fl
                })
                console.log('Index of deleted: ', indexOfDeleted)
                if (indexOfDeleted === -1) {
                    newTags = tab[action.payload.nameForm][action.payload.nameTags]
                } else {
                    newTags = [...tab[action.payload.nameForm][action.payload.nameTags].slice(0, indexOfDeleted), ...tab[action.payload.nameForm][action.payload.nameTags].slice(indexOfDeleted + 1)]
                }
            } else {
                newTags = tab[action.payload.nameForm][action.payload.nameTags].filter((tag: { [key: string]: any }) => {
                    let fl = true
                    Object.keys(tag).forEach(key => {
                        if (key.endsWith('id')) {
                            if (tag[key] === action.payload.idTag) {
                                fl = false
                            }
                        }
                    })
                    return fl
                })
            }
            const newForm = Object.assign({},
                tab[action.payload.nameForm],
                {
                    [action.payload.nameTags]: newTags
                })
            return Object.assign({},
                tab,
                {
                    [action.payload.nameForm]: newForm
                })
        }
        return tab
    })
}


export const deleteRequirementBlock = (state: TabState, action: DeleteRequirementBlock): ITab[] => {
    const newTabs: ITab[] = state.tabs.map((tab, idx) => {
        if (idx === action.payload.idTab) {
            const newRequirements = (tab.content as IRequirementsTab).requirements.filter(item => !action.payload.idRequirements.includes(+item.irq_id))
            const newContent = Object.assign({},
                tab.content,
                {
                    requirements: newRequirements
                })
            return Object.assign({},
                tab,
                {
                    content: newContent
                })
        }
        return tab
    })
    return newTabs
}

export const setReqFormValue = (state: TabState, action: SetReqFormValue): ITab[] => {
    const newTabs: ITab[] = state.tabs.map((tab, idx) => {
        if (idx === action.payload.idTab) {

            const newForm: { [key: string]: any } = Object.assign({},
                tab[action.payload.nameForm])

            if (action.payload.dopField) {
                if (action.payload.nameField.split('_')[action.payload.nameField.split('_').length - 1] === 'id') {
                    let arr = action.payload.dopField.split('__')
                    let newId = arr.pop()
                    const newNameField = arr.join('__')
                    Object.keys(tab[action.payload.nameForm]).forEach(key => {
                        if (key.split('_')[key.split('_').length - 1] === 'id') {
                            arr = key.split('__')
                            arr.pop()
                            console.log('newNameField', newNameField)
                            console.log('arr.join', arr.join('__'))
                            if (arr.join('__') === newNameField) {
                                newForm[[newNameField, newId].join('__')] = action.payload.value
                            }
                        }
                    })
                }
            } else {
                Object.keys(tab[action.payload.nameForm]).forEach(key => {
                    if (key.includes('__')) {
                        const splitKey = key.split('__')
                        if (splitKey[1] === action.payload.nameField) {
                            newForm[key] = action.payload.value
                        } else if (key === action.payload.nameField) {
                            newForm[key] = action.payload.value
                        } else {
                            newForm[key] = tab[action.payload.nameForm][key]
                        }
                    } else {
                        if (key === action.payload.nameField) {
                            newForm[key] = action.payload.value
                        } else {
                            newForm[key] = tab[action.payload.nameForm][key]
                        }
                    }
                })
            }

            return Object.assign({},
                tab,
                {
                    [action.payload.nameForm]: newForm
                })
        }
        return tab
    })
    return newTabs
}

export const setReqFormToggleChecker = (state: TabState, action: SetReqFormToggleCheker): ITab[] => {
    const newTabs: ITab[] = state.tabs.map((tab, idx) => {
        if (idx === action.payload.idTab) {
            const newForm = Object.assign({}, (tab as ISpecificRequirementsTab)[action.payload.nameForm],
                {
                    [action.payload.nameField]: !(tab as ISpecificRequirementsTab)[action.payload.nameForm][action.payload.nameField]
                })
            return Object.assign({},
                tab,
                {
                    [action.payload.nameForm]: newForm
                })
        }
        return tab
    })
    return newTabs
}

export const setReqList = (state: TabState, action: GetRequirList): ITab[] => {
    const newTabs: ITab[] = state.tabs.map((tab, idx) => {
        if (idx === action.payload.idTab) {
            const newContent = Object.assign({},
                tab.content,
                {
                    requirements: action.payload.requirements
                })
            return Object.assign({},
                tab,
                {
                    content: newContent
                })
        }
        return tab
    })
    return newTabs
}

export const resetReqForm = (state: TabState, action: ResetReqFom): ITab[] => {
    const newTabs: ITab[] = state.tabs.map((tab, idx) => {
        if (idx === action.payload.idTab) {
            const newForm = {
                pp: '',
                period_beg: '',
                period_end: '',
                permission: 'Разрешить',
                permonths: '',
                relevance: true,
                reqdate: '',
                reqnumb: '',
                scn_code: '',
                sdt_code: '',
                icn_id: '',
                idt_id: ''
            }
            return Object.assign({},
                tab,
                {
                    addReqForm: newForm
                })
        }
        return tab
    })
    return newTabs
}

export const setArrayFieldValue = (state: TabState, action: SetArrayFieldValue): ITab[] => {
    const newTabs: ITab[] = state.tabs.map((tab, idx) => {
        if (idx === action.payload.idTab) {
            let newTags = [...tab[action.payload.nameForm][action.payload.nameTag]]
            newTags = newTags.map((tag, idx) => {
                if (action.payload.idTag === idx) {
                    tag[action.payload.nameField] = action.payload.value
                    return tag
                }
                return tag
            })
            const newForm = Object.assign({},
                tab[action.payload.nameForm],
                {
                    [action.payload.nameTag]: newTags
                })
            return Object.assign({},
                tab,
                {
                    [action.payload.nameForm]: newForm
                })
        }
        return tab
    })
    return newTabs
}

export const addQuarantProd = (state: TabState, action: AddQuarantProd): ITab[] => {
    const newTabs: ITab[] = state.tabs.map((tab, idx) => {
        if (idx === action.payload.idTab) {
            return {
                ...tab,
                mainForm: {
                    ...tab.mainForm,
                    rquqprodsById: {
                        ...tab.mainForm.rquqprodsById,
                        0: {
                            irp_id: 0,
                            sup_rusname: '',
                            sgr_code: '',
                            skp_rusname: null,
                            srp_permission: 0,
                            srp_note: null,
                            v_rquqprodtnved: [0],
                            v_rqpquarantobj: [0],
                            v_rqaddqualprm: [0]
                        }
                    },
                    rquqprodsAll: [...tab.mainForm.rquqprodsAll, 0]
                }
            }
        }
        return tab
    })
    return newTabs
}

export const addTnvedArrayField = (state: TabState, action: AddTndevArrayField): ITab[] => {
    const newTabs: ITab[] = state.tabs.map((tab, idx) => {
        if (idx === action.payload.idTab) {
            return {
                ...tab,
                mainForm: {
                    ...tab.mainForm,
                    rquqprodsById: {
                        ...tab.mainForm.rquqprodsById,
                        [action.payload.idQuarantProd]: {
                            ...tab.mainForm.rquqprodsById[action.payload.idQuarantProd],
                            v_rquqprodtnved: [...tab.mainForm.rquqprodsById[action.payload.idQuarantProd].v_rquqprodtnved, 0]
                        }
                    },
                }
            }
        }
        return tab
    })
    return newTabs
}

export const addQuarantObjectArrayField = (state: TabState, action: AddQuarantObjArrayField): ITab[] => {
    const newTabs: ITab[] = state.tabs.map((tab, idx) => {
        if (idx === action.payload.idTab) {
            return {
                ...tab,
                mainForm: {
                    ...tab.mainForm,
                    rquqprodsById: {
                        ...tab.mainForm.rquqprodsById,
                        [action.payload.idQuarantProd]: {
                            ...tab.mainForm.rquqprodsById[action.payload.idQuarantProd],
                            v_rqpquarantobj: [...tab.mainForm.rquqprodsById[action.payload.idQuarantProd].v_rqpquarantobj, 0]
                        }
                    },
                }
            }
        }
        return tab
    })
    return newTabs
}

export const addQuquarantObjArrayField = (state: TabState, action: AddQuquarantObjArrayField) => {
    const newTabs: ITab[] = state.tabs.map((tab, idx) => {
        if (idx === action.payload.idTab) {
            return {
                ...tab,
                mainForm: {
                    ...tab.mainForm,
                    v_rqpquarantobj: {
                        ...tab.mainForm.v_rqpquarantobj,
                        [action.payload.idQuarantObj]: {
                            ...tab.mainForm.v_rqpquarantobj[action.payload.idQuarantObj],
                            v_rqpququarantobj: tab.mainForm.v_rqpquarantobj[action.payload.idQuarantObj].v_rqpququarantobj ? [...tab.mainForm.v_rqpquarantobj[action.payload.idQuarantObj].v_rqpququarantobj, 0] : [0]
                        }
                    }
                }
            }
        }
        return tab
    })
    return newTabs
}