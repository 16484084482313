import {formState, reqFormAction, reqFormActionsType} from '../../interfaces/requirementsFormActions';

export const initialState: formState = {
    error: '',
    status: ''
}

export const reqFormReducer = (state = initialState, action: reqFormAction): formState => {
    switch(action.type) {
        case reqFormActionsType.SUCCES_FETCH_REQUIREMENT:
            return {
                error: '',
                status: action.payload.message
            }
        case reqFormActionsType.ERROR_FETCH_REQUIREMENT:
            return {
                error: action.payload.message,
                status: 'error'
            }
        case reqFormActionsType.START_FETCH_REQUIREMENT:
            return {
                error: '',
                status: 'pending'
            }
        case reqFormActionsType.RESET_STATUS_FORM:
            return {
                error: '',
                status: ''
            }
        default:
            return state
    }

}