import React, { useEffect } from 'react';
import Menu from '../components/Menu/Menu'
import MainTabs from '../components/MainTabs/MainTabs';
import registerInterceptor from '../interceptors/fetch.interceptor'

const MainPage = () => {

    useEffect(() => {
        registerInterceptor()
    }, [])

    return (
        <>
            <Menu />
            <MainTabs />
        </>
    );
};

export default MainPage;