import React, { FC, useEffect } from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { StyledEngineProvider } from '@mui/material/styles';
import CountriesPoint from '../../NormatDocPoints/CountriesPoint/CountriesNDPoint';
import PpPoint from '../../NormatDocPoints/PpPoint/PpNDPoint';
import './NormatDocSpecificTab.scss';
import { store } from 'react-notifications-component';
import { INormatDocForm } from '../../../interfaces/TabInterface';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { ResetStatusForm } from '../../../store/action-creators/requirementsFormActionCreator';
import { statuses } from '../../../interfaces/requirementsFormActions';
import MainNDPoint from '../../NormatDocPoints/MainPoint/MainNDPoint';
import {ERROR_NOTIFY, GET_DATA_SUCCESS_NOTIFY, SUCCESS_NOTIFY} from "../../../shared/notification";

interface NormatDocSpecificTabProps {
    idTab: number
    normatDocForm: INormatDocForm
}


const NormatDocSpecificTab : FC<NormatDocSpecificTabProps> = ({ idTab, normatDocForm }) => {

    const [tabValue, setTabValue] = React.useState<string>('Основное');

    const { control } = useForm<INormatDocForm>();

    const dispatch = useDispatch()

    const { error, status } = useTypedSelector(state => state.reqForm)

    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        event.preventDefault()
        event.stopPropagation()
        setTabValue(newValue)
    }

    useEffect(() => {
        if (error) {
            alert(error)
            store.addNotification(ERROR_NOTIFY)
            dispatch(ResetStatusForm())
        }
        if (status === statuses.DATA_GET) {
            store.addNotification(GET_DATA_SUCCESS_NOTIFY)
            dispatch(ResetStatusForm())
        }
        if (status === statuses.DATA_SAVE) {
            store.addNotification(SUCCESS_NOTIFY)
            dispatch(ResetStatusForm())
        }
    }, [dispatch, error, status])

    return (
        <div className="requirement-tab-wrap">
            <StyledEngineProvider injectFirst>

                <TabContext value={tabValue}>
                    <TabList onChange={handleChangeTab}>
                        <Tab label="Основное" value="Основное" />
                        <Tab label="Страны" value="Страны" />
                        <Tab label="Подкарантинная продукция" value="Подкарантинная продукция" />
                    </TabList>
                    <TabPanel value="Основное"><MainNDPoint
                        idTab={idTab}
                        normatDocForm={normatDocForm}
                        control={control}
                    /></TabPanel>
                    <TabPanel value="Страны"><CountriesPoint /></TabPanel>
                    <TabPanel value="Подкарантинная продукция"><PpPoint /></TabPanel>
                </TabContext>

            </StyledEngineProvider >
        </div>
    );
};

export default NormatDocSpecificTab;