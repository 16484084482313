import React, {useEffect} from 'react';

const LogoutPage = () => {

    useEffect(() => {
        window.location.href = '/Authorization/logout'
    }, [])

    return (
        <></>
    );

  /*  useEffect(() => {
        const foo = async () => {await Auth.logout()}
        foo()
    })

    return (
        <></>
    );*/

};

export default LogoutPage;