import {ReactNotificationOptions} from "react-notifications-component";

export const SUCCESS_NOTIFY: ReactNotificationOptions = {
    title: "Сохранение",
    message: "Данные успешно сохранены",
    type: "success",
    insert: "bottom",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 2000,
        onScreen: true
    }
}

export const ERROR_NOTIFY: ReactNotificationOptions = {
    title: "Произошла ошибка",
    message: "Произошла ошибка",
    type: "danger",
    insert: "bottom",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 5000,
        onScreen: true
    }
}

export const GET_DATA_SUCCESS_NOTIFY: ReactNotificationOptions = {
    title: "Данные загружены",
    message: "Данные успешно загружены",
    type: "success",
    insert: "bottom",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 2000,
        onScreen: true
    }
}

export const EXPORT_SUCCESS_NOTIFY: ReactNotificationOptions = {
    title: "Экспорт",
    message: "Документ успешно экспортирован",
    type: "success",
    insert: "bottom",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 2000,
        onScreen: true
    }
}

export const EXPORT_ERROR_NOTIFY: ReactNotificationOptions = {
    title: "Экспорт",
    message: "Произошла ошибка при экспорте документа",
    type: "danger",
    insert: "bottom",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 2000,
        onScreen: true
    }
}

export const getErrorNotification = (title?: string, message?: string): ReactNotificationOptions => {
    return {
        title: title || "Произошла ошибка",
        message: message || "Произошла ошибка",
        type: "danger",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 2000,
            onScreen: true
        }
    }
}