import React, {ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {SortType} from 'rsuite-table';
import {useDispatch} from 'react-redux';
import {useActions} from '../../hooks/useAction';
import {useTypedSelector} from '../../hooks/useTypedSelector';

import './CustomTable.scss'
import {TabTypes} from '../../interfaces/TabInterface';
import MyTagPicker from "../MyTagPicker/MyTagPicker";
import MyPagination from "../MyPagination/MyPagination";
import {GetTableContent} from "../../store/action-creators/tableActionCreator";
import {store} from "react-notifications-component";
import {ERROR_NOTIFY, SUCCESS_NOTIFY} from "../../shared/notification";
import {TableInstance} from "rsuite/Table";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import MyTable from "../MyTable/MyTable";
import {IconDefinition} from "@fortawesome/free-solid-svg-icons";

export const enum AutocompleteCellTypes {
    DEFAULT = 'default',
    MAIN = 'main',
    PERMISSION = 'permission',
    TYPE = 'type',
    INFECTION_TYPE = 'infection_type'
}

interface CustomTableProps {
    idTab: number,
    page: number,
    limit: number,
    sortColumn: string,
    sortType: SortType,
    totalItems: number,
    filters: { field: string, value: string }[]
    api: {
        put?: string,
        post: string
    }
    items: { [key: string]: any }[],
    setting: {
        key: string,
        label: string,
        sortable?: boolean,
        flexGrow?: number,
        width?: number,
        flex?: number,
        filtered?: boolean,
        readonly?: boolean | ((data: Record<string, any>, color: string) => void),
        readonlyColor?: string
        autocomplete?: AutocompleteCellTypes,
        api?: string
        link?: boolean
        icon?: IconDefinition
        titleNameKey?: string
        tabType?: TabTypes
        fieldNameNestedExist?: string
        validateFunc?: (event: ChangeEvent<HTMLInputElement>) => string
        computedValue?: (data: {[key: string]: any}) => string
    }[],
    //  onGetContent: (idx: number, page: number, limit: number, sortColumn?: string, sortType?: string) => void,
    //  onChangeInput: (idTab: number, idContent: number, dataKey: string, value: string | number) => void,
    //  loading: boolean,
    headerHeight: number,
    idName: string,
    postForm?: { [key: string]: any }
}

const CustomTable: FC<CustomTableProps> = ({
                                               idTab,
                                               page,
                                               limit,
                                               sortColumn,
                                               api,
                                               sortType,
                                               items,
                                               setting,
                                               headerHeight,
                                               totalItems,
                                               filters,
                                               idName,
                                               postForm,
                                           }) => {

    const {
        UpdTableContent,
        PutTableRow,
        ToggleCheckbox,
        ToggleAllCheckbox,
        SetSortType,
        SetSortColumn,
        SetPage,
        SetLimit,
        SetFilters
    } = useActions()
    const dispatch = useDispatch()
    const {error, status} = useTypedSelector(state => state.table)
    const [data, setData] = useState(items)
    const [checkedAll, setCheckedAll] = useState<boolean>(false)
    const [timerId, setTimerId] = useState<NodeJS.Timeout>()
    const [columnKeys, setColumnKeys] = React.useState(setting.map(column => column.key));
    const [modalOpen, setModalOpen] = useState(false)
    const refTable = useRef<TableInstance>(null)
    const refModal = useRef<HTMLDivElement>(null)

    const columns = useMemo(() => setting.filter(column => columnKeys.some((key: any) => key === column.key)), [setting, columnKeys])

    useEffect(() => {
        const handleClickTable = (event: any) => {
            if (!refTable.current) return

            //если кликнули на тулбар
            if (event.composedPath().some((element: HTMLElement) => element.classList?.value === 'toolbar')) return

            //если кликнули по опции в автокомплите таблицы
            if (event.target.getAttribute('role') === 'option') return

            //если кликнули по скроллбару
            if (event.target.getAttribute('role') === 'scrollbar') return
            if (event.target.classList.value === 'rs-table-scrollbar-handle') return

            //если кликнули по кнопке сохранить
            if (event.target.getAttribute('id') === 'save-btn') return

            //если кликнули по диалоговому окну
            if (refModal.current && refModal.current.contains(event.target)) return

            const body: HTMLElement = (refTable.current as any).body
            if (!body.contains(event.target)) {

                const dataStringify = JSON.stringify(data)
                const itemsStringify = JSON.stringify(items)
                //если не было изменений
                if (dataStringify === itemsStringify) return
                event.stopPropagation()
                setModalOpen(true)
            }
        };
        document.addEventListener('mousedown', handleClickTable, true)
        return () => {
            document.removeEventListener('mousedown', handleClickTable, true)
        }
    })

    useEffect(() => {
        setData(items)
    }, [items])

    useEffect(() => {
        dispatch(GetTableContent(idTab, page, limit, api.post, {sortColumn, sortType, filters}))
    }, [dispatch, idTab, page, limit, filters, sortColumn, sortType, api.post])

    useEffect(() => {
        if (status === 'error') {
            /*alert(error)*/
            store.addNotification(ERROR_NOTIFY)
        }
        if (status === 'success_save') {
            store.addNotification(SUCCESS_NOTIFY)
        }
    }, [status, error])

    const handleRowChange = useCallback((idRow: number, dataKey: string, value: string, updateStore: boolean = false) => {

        setData(prev => {
            let newDataKey = dataKey

            if (dataKey.startsWith('i')) {
                const threeLetters = dataKey.slice(0, 3)
                Object.keys(prev[idRow]).forEach(key => {
                    if (key.startsWith(threeLetters)) {
                        newDataKey = key
                    }
                })
            }

            return [...prev.slice(0, idRow), {
                ...prev[idRow],
                [newDataKey]: value
            }, ...prev.slice(idRow + 1, prev.length)]
        })

    }, [])

    const handleToggleCheckbox = useCallback((id: number, idName: string) => {
        ToggleCheckbox(idTab, id, idName)
    }, [])

    const handleSortColumn = useCallback((sortColumn: string, sortType: SortType | undefined) => {
        SetSortType(idTab, sortType as string)
        SetSortColumn(idTab, sortColumn as string)
        dispatch(GetTableContent(idTab, page, limit, api.post, {sortColumn, sortType, filters}))
    }, [dispatch, idTab, page, limit, api.post, filters])

    const handleInputFilterChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (timerId) {
            clearTimeout(timerId)
        }
        setTimerId(setTimeout(() => {
            SetFilters(idTab, {field: event.target.name, value: event.target.value})
        }, 800))
    }, [idTab, timerId])

    const SaveChanges = () => {
        items.forEach((row, idx) => {
            if (!api.put) return;
            const rowStringify = JSON.stringify(row)
            const dataRowStringify = JSON.stringify(data[idx])
            if (rowStringify !== dataRowStringify) {
                UpdTableContent(idTab, data[idx], idx)
                PutTableRow(idTab, data[idx], api.put, postForm)
            }
        })
    }

    const SaveChangeMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
        SaveChanges()
    }

    const handleChangeCheckbox = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        ToggleAllCheckbox(idTab, checkedAll)
        setCheckedAll(!checkedAll)
    }, [setCheckedAll, idTab, checkedAll])

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    const handleConfirmModal = () => {
        SaveChanges()
        setModalOpen(false)
    }

    const handleNotConfirmModal = () => {
        dispatch(GetTableContent(idTab, page, limit, api.post, {sortColumn, sortType, filters}))
        setModalOpen(false)
    }

    return (
        <>
            <MyTagPicker
                data={setting}
                value={columnKeys}
                onChange={setColumnKeys}
            />
            <MyTable
                ref={refTable}
                idTab={idTab}
                idName={idName}
                items={data}
                status={status}
                sortType={sortType}
                sortColumn={sortColumn}
                columns={columns}
                onRowChange={handleRowChange}
                onSortColumn={handleSortColumn}
                onChangeCheckbox={handleChangeCheckbox}
                onInputFilterChange={handleInputFilterChange}
                onToggleCheckbox={handleToggleCheckbox}
                checkedAll={checkedAll}
                filters={filters}
            />
            <div className='pagination-button_container'>
                <MyPagination
                    idTab={idTab}
                    totalItems={totalItems}
                    limit={limit}
                    page={page}
                    SetPage={SetPage}
                    SetLimit={SetLimit}
                />
                <button id="save-btn" className="save" onClick={SaveChangeMouseLeave}>Сохранить внесенные изменения
                </button>
            </div>
            <ConfirmModal
                ref={refModal}
                open={modalOpen}
                onClose={handleCloseModal}
                onConfirm={handleConfirmModal}
                onNotConfirm={handleNotConfirmModal}
            />
        </>
    );
};

export default CustomTable;
