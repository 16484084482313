import React, { FC, useState } from 'react';
import { SortType } from 'rsuite-table';
import './MunitsTab.scss';
import ToolBar from '../../ToolBar/ToolBar';
import CustomTable from '../../CustomTable/CustomTable';
import {IContentTable, TabPanel} from '../../../interfaces/TabInterface';
import {MunitsForm} from "../../../shared/form.table.enviroment";
import FormBottomTable from "../../Forms/FormBottomTable/FormBottomTable";

interface MunitsTabProps extends TabPanel {

}

const MunitsTab: FC<MunitsTabProps> = ({ content, idTab, limit, page, sortType, sortColumn, filters, form }) => {

    const [toggleForm, setToggleForm] = useState<boolean>(false)

    return (
        <div className="tab-wrap">
            <div className="table-container">
                <CustomTable
                    api={{
                        post: '/munitss/all',
                        put: '/munitss'
                    }}
                    idName={'id'}
                    headerHeight={70}
                    idTab={idTab}
                    items={content?.items ? content.items : []}
                    limit={limit}
                    page={page}
                    sortType={sortType as SortType}
                    sortColumn={sortColumn}
                    totalItems={content?.totalItems}
                    filters={filters}
                    setting={[{
                        key: 'name',
                        label: 'Наименование',
                        width: 150,
                        sortable: true,
                        filtered: true
                    },
                    {   
                        key: 'okei',
                        label: 'ОКЕИ',
                        width: 100,
                        sortable: true,
                        filtered: true
                    },
                    {   
                        key: 'condnatdes',
                        label: 'Условное национальное обозначение',
                        width: 300,
                        filtered: true
                    },
                    {   
                        key: 'condintdes',
                        label: 'Условное международное обозначение',
                        width: 300,
                    },
                    {   
                        key: 'clnatdes',
                        label: 'Кодовое буквенное национальное обозначение',
                        width: 309,
                    },
                    {   
                        key: 'clintdes',
                        label: 'Кодовое международное национальное обозначение',
                        width: 309,
                    }]}
                />
                <FormBottomTable
                    open={toggleForm} onToggleForm={() => setToggleForm(prev => !prev)}
                    idName={'id'}
                    form={form}
                    fields={MunitsForm}
                    api="/munitss"
                    idTab={idTab}
                />
            </div >
            <ToolBar idTab={idTab} toggleForm={() => setToggleForm(state => !state)} api="/munitss/" idName={'id'}  tableNameExport={'munits'}/>
        </div>
    );
};

export default MunitsTab;