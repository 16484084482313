import React, {FC, PropsWithChildren, useEffect} from 'react';

import './ConfirmModal.scss'

interface ConfirmModalProps {
    onClose: () => void
    onConfirm: () => void
    onNotConfirm: () => void
    open: boolean
    header?: string
}

const ConfirmModal = React.forwardRef<HTMLDivElement, PropsWithChildren<ConfirmModalProps>>(({
                                                                                                 onConfirm,
                                                                                                 onNotConfirm,
                                                                                                 onClose,
                                                                                                 open,
                                                                                                 header = 'Были внесены изменения',
                                                                                                 children
                                                                                             }, ref) => {

    useEffect(() => {
        if (open) {
            document.body.classList.add('lock')
        } else {
            document.body.classList.remove('lock')
        }
    }, [open])

    if (!open) {
        return null
    }

    return (
        <div ref={ref} className="confirm-modal-wrap">
            <div className="confirm-modal-body">
                <h3>{header}</h3>
                {children ? children : <p>Желаете сохранить внесенные изменения?</p>}
                <span className="confirm-modal-close" onClick={() => onClose()}>✖</span>
                <div className="confirm-modal-btn-wrap">
                    <button className="btn primary" onClick={() => onConfirm()}>Да</button>
                    <button className="btn default" onClick={() => onNotConfirm()}>Нет</button>
                </div>
            </div>
        </div>
    );
})

export default ConfirmModal;