import React, { FC } from 'react';
import InputWithLabel from '../../Inputs/InputWithLabel/InputWithLabel';
import AutocompleteWithLabel from '../../Inputs/AutocompleteWithLabel/AutocompleteWithLabel';
import { IMainForm } from '../../../../interfaces/TabInterface';
import { formsName, tagsName } from '../../../../shared/shared';
import { useArrayFieldChangeAutocomplete, useArrayFieldChangeInput, useInputChange, useOtherChange, usePlusClick } from '../../../../hooks/useChange';
import { useSaveNonStandartField, useSaveDefaultField, useSaveArrayFieldDopDoc, useSaveFieldArrayInput, useDeleteArrayFields } from '../../../../hooks/useSave';
import TextAreaWithLabel from '../../../TextAreaWithLabel/TextAreaWithLabel';

interface AccompDocPointProps {
    idTab: number
    mainForm: IMainForm
    control: any
}

const AccompDocPoint: FC<AccompDocPointProps> = ({ idTab, mainForm, control }) => {

    //#region Хуки
    const handleChange = useInputChange(idTab, formsName.mainForm)
    const handleOtherChange = useOtherChange(idTab, formsName.mainForm)
    const handeBlurSaveString = useSaveDefaultField(mainForm.requirements__irq_id, '0')

    // Первая строка - Основные
    const handleAutocompleteDoc_tpmainaccomp = useSaveNonStandartField(mainForm.requirements__irq_id, 'requirements', 'tpmainaccomp_id', mainForm.doc__mainCode__requirements__idt_id)
    const handleAutocompleteDoc_smpmainaccomp = useSaveNonStandartField(mainForm.requirements__irq_id, 'requirements', 'smpmainaccomp_id', mainForm.doc__mainCode__requirements__ids__id)

    // Вторая строка - Сопутствующий
    const handleAutocompleteDoc_tpescaccomp = useSaveNonStandartField(mainForm.requirements__irq_id, 'requirements', 'tpescaccomp_id', mainForm.doc__concomitantCode__requirements__idt_id)
    const handleAutocompleteDoc_smpescaccomp = useSaveNonStandartField(mainForm.requirements__irq_id, 'requirements', 'smpescaccomp_id', mainForm.doc__concomitantSample__requirements__ids_id)

    // Третья строка - Прочая
    const handleAutocompleteDoc_tpothaccomp = useSaveNonStandartField(mainForm.requirements__irq_id, 'requirements', 'tpothaccomp_id', mainForm.doc__otherCode__requirements__idt_id)
    const handleAutocompleteDoc_smpothaccomp = useSaveNonStandartField(mainForm.requirements__irq_id, 'requirements', 'smpothaccomp_id', mainForm.doc__otherSample__requirements__ids_id)

    // При клике на плюс
    const onPlusChange = usePlusClick(idTab, formsName.mainForm, tagsName.dopDoc, {
        idt_id: '',
        idt_doctype_id: '',
        sdt_code: '',
        sdt_name: '',
        srd_note: ''
    })

    //Для дополнительных документов
    const handleArrayFieldChangeAutocomplete = useArrayFieldChangeAutocomplete(idTab, formsName.mainForm, tagsName.dopDoc)
    const handleArrayFieldChangeInput = useArrayFieldChangeInput(idTab, formsName.mainForm, tagsName.dopDoc)
    const handleArrayFieldSaveDopDoc = useSaveArrayFieldDopDoc(idTab, mainForm.requirements__irq_id, formsName.mainForm, tagsName.dopDoc, 'rqadddocs', 'doctype_id', 'ird_id')
    const handleArrauFieldSaveInput = useSaveFieldArrayInput('rqadddoc', 'note')
    const handleDeleteArrayField = useDeleteArrayFields(idTab, 'rqadddocs', formsName.mainForm, tagsName.dopDoc)

    //#endregion

    return (
        <div>
            <p
                style={{
                    color: '#05396B',
                    fontSize: '16px',
                    fontFamily: 'MontserratBold',
                    marginBottom: '10px'
                }}>Сопроводительные документы:</p>
            <div style={{
                display: 'grid',
                gridTemplateRows: '0.1fr 1fr 0.1fr 1fr 0.1fr 1fr 0.1fr',
                gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',

                gridGap: '0 1vw'
            }}>

                <div style={{
                    gridRowStart: 1,
                    gridRowEnd: 2,
                    gridColumnStart: 1,
                    gridColumnEnd: 4
                }}>
                    <AutocompleteWithLabel widthInput={'100%'} widthInputBlock={'70%'} widthLabel={'30%'} label={"Основной"}
                        control={control}
                        nameField="doc__mainCode__requirements__sdt_code"
                        onChangeInput={handleOtherChange}
                        value={mainForm.doc__mainCode__requirements__sdt_code}
                        api={'/v_doctypess/join'}
                        onBlurInput={handleAutocompleteDoc_tpmainaccomp}
                        dopField='doc__mainCode__requirements__idt_id'
                    />
                </div>


                <div style={{
                    gridRowStart: 1,
                    gridRowEnd: 2,
                    gridColumnStart: 4,
                    gridColumnEnd: 7
                }}>
                    <AutocompleteWithLabel widthInput={'100%'} widthInputBlock={'70%'} widthLabel={'30%'} label={"Образец"}
                        control={control}
                        nameField="doc__mainSample__requirements__sds_code"
                        onChangeInput={handleOtherChange}
                        value={mainForm.doc__mainSample__requirements__sds_code}
                        api={'/v_docsampls/join'}
                        onBlurInput={handleAutocompleteDoc_smpmainaccomp}
                        readonly={!mainForm.doc__mainCode__requirements__sdt_code}
                        dopField='doc__mainCode__requirements__ids__id'
                        filters={{
                            nameField: 'sdt_code',
                            value: mainForm.doc__mainCode__requirements__sdt_code
                        }}
                    />
                </div>

                <div style={{
                    gridRowStart: 1,
                    gridRowEnd: 2,
                    gridColumnStart: 8,
                    gridColumnEnd: 10
                }}>
                    <InputWithLabel widthInput={'100%'} widthInputBlock={'60%'} widthLabel={'40%'} label={"Период в днях"}
                        control={control}
                        nameField="doc__mainNote__requirements__permainaccomp"
                        onChangeInput={handleChange}
                        value={mainForm.doc__mainNote__requirements__permainaccomp}
                        onBlurInput={handeBlurSaveString}
                    />
                </div>

                <div style={{
                    gridRowStart: 2,
                    gridRowEnd: 3,
                    gridColumnStart: 1,
                    gridColumnEnd: 10
                }}>
                    <TextAreaWithLabel widthInput={'100%'} widthInputBlock={'90%'} widthLabel={'10%'} label={"Примечание"}
                        height={300}
                        control={control}
                        nameField="doc__mainPeriod__requirements__notemainaccomp"
                        onChangeInput={handleChange}
                        value={mainForm.doc__mainPeriod__requirements__notemainaccomp}
                        onBlurInput={handeBlurSaveString}
                    />
                </div>

                <div style={{
                    gridRowStart: 3,
                    gridRowEnd: 4,
                    gridColumnStart: 1,
                    gridColumnEnd: 4
                }}>
                    <AutocompleteWithLabel widthInput={'100%'} widthInputBlock={'70%'} widthLabel={'30%'} label={"Сопутствующий"}
                        control={control}
                        nameField="doc__concomitantCode__requirements__sdt_code"
                        onChangeInput={handleOtherChange}
                        value={mainForm.doc__concomitantCode__requirements__sdt_code}
                        api={'/v_doctypess/join'}
                        onBlurInput={handleAutocompleteDoc_tpescaccomp}
                        dopField='doc__concomitantCode__requirements__idt_id'
                    />
                </div>


                <div style={{
                    gridRowStart: 3,
                    gridRowEnd: 4,
                    gridColumnStart: 4,
                    gridColumnEnd: 7
                }}>
                    <AutocompleteWithLabel widthInput={'100%'} widthInputBlock={'70%'} widthLabel={'30%'} label={"Образец"}
                        control={control}
                        nameField="doc__concomitantSample__requirements__sds_code"
                        onChangeInput={handleOtherChange}
                        value={mainForm.doc__concomitantSample__requirements__sds_code}
                        api={'/v_docsampls/join'}
                        onBlurInput={handleAutocompleteDoc_smpescaccomp}
                        readonly={!mainForm.doc__concomitantCode__requirements__sdt_code}
                        filters={{
                            nameField: 'sdt_code',
                            value: mainForm.doc__concomitantCode__requirements__sdt_code
                        }}
                        dopField='doc__concomitantSample__requirements__ids_id'
                    />
                </div>

                <div style={{
                    gridRowStart: 3,
                    gridRowEnd: 4,
                    gridColumnStart: 8,
                    gridColumnEnd: 10
                }}>
                    <InputWithLabel widthInput={'100%'} widthInputBlock={'60%'} widthLabel={'40%'} label={"Период в днях"}
                        control={control}
                        nameField="doc__concomitantNote__requirements__perescaccomp"
                        onChangeInput={handleChange}
                        value={mainForm.doc__concomitantNote__requirements__perescaccomp}
                        onBlurInput={handeBlurSaveString}
                    />
                </div>

                <div style={{
                    gridRowStart: 4,
                    gridRowEnd: 5,
                    gridColumnStart: 1,
                    gridColumnEnd: 10
                }}>
                    <TextAreaWithLabel widthInput={'100%'} widthInputBlock={'90%'} widthLabel={'10%'} label={"Примечание"}
                        height={300}
                        control={control}
                        nameField="doc__concomitantPeriod__requirements__noteescaccomp"
                        onChangeInput={handleChange}
                        value={mainForm.doc__concomitantPeriod__requirements__noteescaccomp}
                        onBlurInput={handeBlurSaveString}
                    />
                </div>

                <div style={{
                    gridRowStart: 5,
                    gridRowEnd: 6,
                    gridColumnStart: 1,
                    gridColumnEnd: 4
                }}>
                    <AutocompleteWithLabel widthInput={'100%'} widthInputBlock={'70%'} widthLabel={'30%'} label={"Прочий"}
                        control={control}
                        nameField="doc__otherCode__requirements__sdt_code"
                        onChangeInput={handleOtherChange}
                        value={mainForm.doc__otherCode__requirements__sdt_code}
                        api={'/v_doctypess/join'}
                        onBlurInput={handleAutocompleteDoc_tpothaccomp}
                        dopField='doc__otherCode__requirements__idt_id'
                    />
                </div>


                <div style={{
                    gridRowStart: 5,
                    gridRowEnd: 6,
                    gridColumnStart: 4,
                    gridColumnEnd: 7
                }}>
                    <AutocompleteWithLabel widthInput={'100%'} widthInputBlock={'70%'} widthLabel={'30%'} label={"Образец"}
                        control={control}
                        nameField="doc__otherSample__requirements__sds_code"
                        onChangeInput={handleOtherChange}
                        value={mainForm.doc__otherSample__requirements__sds_code}
                        api={'/v_docsampls/join'}
                        onBlurInput={handleAutocompleteDoc_smpothaccomp}
                        readonly={!mainForm.doc__otherCode__requirements__sdt_code}
                        filters={{
                            nameField: 'sdt_code',
                            value: mainForm.doc__otherCode__requirements__sdt_code
                        }}
                        dopField='doc__otherSample__requirements__ids_id'
                    />
                </div>

                <div style={{
                    gridRowStart: 5,
                    gridRowEnd: 6,
                    gridColumnStart: 8,
                    gridColumnEnd: 10
                }}>
                    <InputWithLabel widthInput={'100%'} widthInputBlock={'60%'} widthLabel={'40%'} label={"Период в днях"}
                        control={control}
                        nameField="doc__otherNote__requirements__perothaccomp"
                        onChangeInput={handleChange}
                        value={mainForm.doc__otherNote__requirements__perothaccomp}
                        onBlurInput={handeBlurSaveString}
                    />
                </div>

                <div style={{
                    gridRowStart: 6,
                    gridRowEnd: 7,
                    gridColumnStart: 1,
                    gridColumnEnd: 10
                }}>
                    <TextAreaWithLabel widthInput={'100%'} widthInputBlock={'90%'} widthLabel={'10%'} label={"Примечание"}
                        height={300}
                        control={control}
                        nameField="doc__otherPeriod__requirements__noteothaccomp"
                        onChangeInput={handleChange}
                        value={mainForm.doc__otherPeriod__requirements__noteothaccomp}
                        onBlurInput={handeBlurSaveString}
                    />
                </div>



                <div style={{
                    gridRowStart: 7,
                    gridRowEnd: 8,
                    gridColumnStart: 1,
                    gridColumnEnd: 10,
                    display: 'grid',
                    alignContent: 'end'
                }}>
                    <p
                        style={{
                            color: '#05396B',
                            fontSize: '16px',
                            fontFamily: 'MontserratBold',
                            marginBottom: '10px'
                        }}>Перечень допольнительных сопроводительных документов:</p>
                </div>


                <div style={{
                    gridRowStart: 8,
                    gridRowEnd: 9,
                    gridColumnStart: 1,
                    gridColumnEnd: 4
                }}>{mainForm.dopDoc.map((doc, idx) => 
                <AutocompleteWithLabel key={idx} widthInput={'100%'} widthInputBlock={'70%'} widthLabel={'30%'} label={"Основной"}
                    control={control}
                    nameField="sdt_code"
                    onChangeInput={handleArrayFieldChangeAutocomplete(idx)}
                    value={doc.sdt_code}
                    api={'/v_doctypess/join'}
                    onBlurInput={handleArrayFieldSaveDopDoc(idx, doc)}
                    plus={idx === mainForm.dopDoc.length - 1}
                    minus={idx !== mainForm.dopDoc.length - 1}
                    onPlusClick={onPlusChange}
                    onMinusClick={handleDeleteArrayField(doc.ird_id)}
                />)}

                </div>

                <div style={{
                    gridRowStart: 8,
                    gridRowEnd: 9,
                    gridColumnStart: 4,
                    gridColumnEnd: 10
                }}>
                    {mainForm.dopDoc.map((doc, idx) =>
                        <InputWithLabel key={idx} widthInput={'100%'} widthInputBlock={'85%'} widthLabel={'15%'} label={"Примечание"}
                            control={control}
                            nameField="srd_note"
                            onChangeInput={handleArrayFieldChangeInput(idx)}
                            value={doc.srd_note}
                            onBlurInput={handleArrauFieldSaveInput(doc.ird_id)}
                            readonly={!doc.sdt_code}
                        />
                    )}

                </div>
            </div>
        </div>
    );
};

export default AccompDocPoint;