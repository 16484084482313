import React from 'react';
import { useForm } from 'react-hook-form';
import InputWithLabel from '../../../RequiredForm/Inputs/InputWithLabel/InputWithLabel';

const TnvedPoint = () => {

    const { control } = useForm()

    return (
        <>
            <div style={{
                display: 'grid',
                gridTemplateRows: '1fr',
                gridTemplateColumns: '1fr 3fr',
                height: '100%',
                gridGap: '0 5vw'
            }}>
                <div style={{
                    gridRowStart: 1,
                    gridRowEnd: 2,
                    gridColumnStart: 1,
                    gridColumnEnd: 2
                }}>
                    <InputWithLabel label={'ТНВЭД'} widthInput={'100%'} widthLabel={'20%'} widthInputBlock={'80%'} control={control} />
                </div>

                <div style={{
                    gridRowStart: 1,
                    gridRowEnd: 2,
                    gridColumnStart: 2,
                    gridColumnEnd: 3
                }}>
                    <InputWithLabel label={'Наименование'} widthInput={'100%'} widthLabel={'15%'} widthInputBlock={'85%'} control={control} />
                </div>

            </div>
            
        </>
    );
};

export default TnvedPoint;