import React from 'react';
import { useForm } from 'react-hook-form';
import InputWithLabel from '../../../RequiredForm/Inputs/InputWithLabel/InputWithLabel';

const QuarantObjPoint = () => {

    const {control} = useForm()

    return (
        <div style={{
            display: 'grid',
            gridTemplateRows: '1fr',
            gridTemplateColumns: '1fr',
            height: '100%',
            gridGap: '0 5vw'
        }}>
            <div style={{
                    gridRowStart: 1,
                    gridRowEnd: 2,
                    gridColumnStart: 1,
                    gridColumnEnd: 2
                }}>
                    <InputWithLabel label={'Объект'} widthInput={'100%'} widthLabel={'10%'} widthInputBlock={'90%'} control={control} />
                </div>
        </div>
    );
};

export default QuarantObjPoint;