import React, {FC, useEffect, useState} from 'react';
import {IContentTable, IMainForm, TabTypes} from "../../../../interfaces/TabInterface";
import {useSpecificReqTabContext, useTabQuarantObjByRequirementContext} from "../../../../contexts/TabsContext";
import {SortType} from "rsuite-table";
import CustomTable from "../../../CustomTable/CustomTable";
import ToolBar from "../../../ToolBar/ToolBar";
import FormBottomTable from "../../../Forms/FormBottomTable/FormBottomTable";
import {QuarantObjPointForm} from "../../../../shared/form.table.enviroment";

interface QuarantObjByRequirementPointProps {
    idTab: number
}

const QuarantObjByRequirementPoint: FC<QuarantObjByRequirementPointProps> = ({idTab}) => {

    const [toggleForm, setToggleForm] = useState<boolean>(false)
    const {content, limit, page, sortType, sortColumn, filters, form} = useTabQuarantObjByRequirementContext()

    useEffect(() => {
        console.log(idTab)
    }, [idTab])

    return (
        <div className="tab-wrap">
            <div className="table-container">
                <CustomTable
                    api={{
                        post: '/v_rqquarantobjs/all',
                        put: '/rqquarantobjs'
                    }}
                    idName={'iqq_id'}
                    headerHeight={80}
                    idTab={idTab}
                    items={(content as IContentTable)?.items ? (content as IContentTable)?.items : []}
                    limit={limit as number}
                    page={page as number}
                    sortType={sortType as SortType}
                    sortColumn={sortColumn as string}
                    totalItems={(content as IContentTable)?.totalItems}
                    filters={filters as { field: string; value: string; }[]}
                    postForm={
                        {
                            irp_pid: '',
                            iqo_quarantobj_id: '',
                            iro_id: ''
                        }
                    }
                    setting={[
                        {
                            key: 'sqo_latname',
                            label: 'Латинское наименование',
                            flex: 1,
                            sortable: true,
                            filtered: true,
                            readonly: true,
                        },
                        {
                            key: 'sqo_rusname',
                            label: 'Русское наименование',
                            flex: 1,
                            sortable: true,
                            readonly: true
                        }
                    ]}
                />
                <FormBottomTable
                    open={toggleForm}
                    onToggleForm={() => setToggleForm(prev => !prev)}
                    fields={QuarantObjPointForm} api='/rqquarantobjs'
                    idTab={idTab}
                    form={form as {[key: string]: any;}}
                    postForm={{
                        irp_pid: '',
                        iqo_quarantobj_id: ''
                    }}
                    idName='iqq_id'
                />
            </div>
            <ToolBar idTab={idTab} toggleForm={() => setToggleForm(state => !state)} api="/rqquarantobjs/" idName={'iqq_id'}></ToolBar>
        </div>
    );
};

export default QuarantObjByRequirementPoint;