import React from 'react';
import { useForm } from 'react-hook-form';
import InputWithLabel from '../../RequiredForm/Inputs/InputWithLabel/InputWithLabel';

const CountriesPoint = () => {

    const {control} = useForm()

    return (
        <div style={{
            display: 'grid',
            gridTemplateRows: '1fr',
            gridTemplateColumns: '1fr 1fr 1fr',
            height: '100%',
            gridGap: '0 5vw'
        }}>
            
            <div style={{
                gridRowStart: 1,
                gridRowEnd: 2,
                gridColumnStart: 1,
                gridColumnEnd: 4
            }}>
                <InputWithLabel label={'Страны, входящие в состав ЭЕК'} widthInput={'100%'} widthLabel={'20%'} widthInputBlock={'80%'} control={control} />
            </div>
        </div>
    );
};

export default CountriesPoint;