import moment from 'moment';
import React, {FC} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {host} from '../../../environments/environments';
import {useActions} from '../../../hooks/useAction';
import {IAddReqForm} from '../../../interfaces/TabInterface';
import AutocompleteWithLabel from '../Inputs/AutocompleteWithLabel/AutocompleteWithLabel';
import CheckBoxWithLabel from '../Inputs/CheckBoxWithLabel/CheckBoxWithLabel';
import DataPickersWithLabel from '../Inputs/DataPickersWithLabel/DataPickersWithLabel';
import InputWithLabel from '../Inputs/InputWithLabel/InputWithLabel';
import {store} from 'react-notifications-component';
import {formsName} from '../../../shared/shared';
import {useCheckboxChange, useInputChange, useOtherChange} from '../../../hooks/useChange';
import './FormAddReq.scss';
import {SUCCESS_NOTIFY} from "../../../shared/notification";

interface FormAddReqProps {
    idTab: number
    form: IAddReqForm
    onToggleForm: () => void
    open: boolean
}

const FormAddReq: FC<FormAddReqProps> = ({idTab, form, onToggleForm, open}) => {

    const {control, handleSubmit, reset} = useForm<IAddReqForm>();

    const {GetRequirementsList, ResetReqForm} = useActions()

    const {SetReqFormValue} = useActions()

    const handleChange = useInputChange(idTab, formsName.addReqForm)
    const handleCheckerChange = useCheckboxChange(idTab, formsName.addReqForm)
    const handleChangeDate = useOtherChange(idTab, formsName.addReqForm)

    const onSubmit: SubmitHandler<IAddReqForm> = async (data) => {

        const object = {
            pp: form.pp,
            importer_id: form.icn_id,
            reqnumb: form.reqnumb,
            reqdate: form.reqdate + 'Z',
            permonths: form.permonths ? form.permonths : null,
            period_beg: form.period_beg ? form.period_beg + 'Z' : null,
            period_end: form.period_end ? form.period_end + 'Z' : null,
            relevance: form.relevance ? 1 : 0,
            permission: form.permission === 'Разрешить' ? 0 : 1,
            reqtype_id: form.idt_id
        }

        const response = await fetch(`${host}/requirementss`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(object)
        })

        if (response.status === 200) {
            store.addNotification(SUCCESS_NOTIFY)
            GetRequirementsList(idTab)
            ResetReqForm(idTab)
            reset()
        }

    }

    const handleDiffDate = (date: string, field: string) => {
        switch (field) {
            case 'period_beg':
                if (form.period_end) {
                    const permonth = Math.abs(Math.floor(moment(form.period_end).diff(moment(date), 'months')))
                    SetReqFormValue(idTab, formsName.addReqForm, 'permonths', permonth.toString())
                }
                break
            case 'period_end':
                if (form.period_beg) {
                    const permonth = Math.abs(Math.floor(moment(date).diff(moment(form.period_beg), 'months')))
                    SetReqFormValue(idTab, formsName.addReqForm, 'permonths', permonth.toString())
                }
                break
            case 'permonths':
                if (form.period_beg) {
                    const period_end = moment(form.period_beg, 'YYYY-MM-DDTHH:mm:ss').add(date, 'month')
                    SetReqFormValue(idTab, formsName.addReqForm, 'period_end', period_end.format('YYYY-MM-DDTHH:mm:ss'))
                }
                break
            default:
                break
        }
    }


    return (
        <div className={`wrap-form ${open ? 'open' : ''}`}>
            <span className="toggle-form" onClick={() => onToggleForm()}> </span>
            <form className={`form-bottom-table ${open ? 'open' : ''}`}
                  style={{maxWidth: '1300px', width: '1300px'}}
                  onSubmit={handleSubmit(onSubmit)}
            >
                <div style={{
                    display: 'grid',
                    gridTemplateRows: '0.8fr 0.2fr',
                    gridTemplateColumns: '1.2fr 1.2fr 1fr 1fr 1fr 1fr',
                    height: '100%',
                    gridGap: '0 1vw'
                }}>

                    <div style={{
                        gridRowStart: 1,
                        gridRowEnd: 2,
                        gridColumnStart: 1,
                        gridColumnEnd: 3
                    }}>
                        <InputWithLabel widthInput={80} widthInputBlock={'60%'} widthLabel={'40%'} label={"№ п.п"}
                                        control={control}
                                        nameField="pp"
                                        onChangeInput={handleChange}
                                        value={form.pp}
                                        rules={{
                                            required: 'Поле не может быть пустым'
                                        }}
                        />
                        <AutocompleteWithLabel widthInput={'100%'} widthInputBlock={'60%'} widthLabel={'40%'}
                                               label={"Документ"}
                                               control={control}
                                               nameField="sdt_code"
                                               onChangeInput={handleChangeDate}
                                               value={form.sdt_code}
                                               rules={{
                                                   required: 'Поле не может быть пустым'
                                               }}
                                               api={'/v_doctypess/join'}
                        />
                        <DataPickersWithLabel label="Период действия с" widthInput={'100%'} widthInputBlock={'60%'}
                                              widthLabel={'40%'}
                                              control={control}
                                              nameField="period_beg"
                                              onChangeInput={handleChangeDate}
                                              value={form.period_beg}
                                              onDiffDate={handleDiffDate}
                        />
                    </div>

                    <div style={{
                        gridRowStart: 1,
                        gridRowEnd: 2,
                        gridColumnStart: 3,
                        gridColumnEnd: 5
                    }}>
                        <CheckBoxWithLabel label={"Актуальность"}
                                           nameField="relevance"
                                           onChangeInput={handleCheckerChange}
                                           value={form.relevance}
                                           control={control}
                        />
                        <InputWithLabel widthInput={'100%'} widthLabel={'10%'} widthInputBlock={'90%'} label={"№"}
                                        control={control}
                                        onChangeInput={handleChange}
                                        nameField="reqnumb"
                                        value={form.reqnumb}
                                        rules={{
                                            required: 'Поле не может быть пустым'
                                        }}
                        />
                        <DataPickersWithLabel label="по" widthInput={'100%'} widthLabel={'10%'} widthInputBlock={'90%'}
                                              control={control}
                                              nameField="period_end"
                                              onChangeInput={handleChangeDate}
                                              value={form.period_end}
                                              onDiffDate={handleDiffDate}
                        />
                    </div>

                    <div style={{
                        gridRowStart: 1,
                        gridRowEnd: 2,
                        gridColumnStart: 5,
                        gridColumnEnd: 7
                    }}>
                        <InputWithLabel widthInput={'100%'} widthLabel={'30%'} widthInputBlock={'70%'} label={""}
                                        control={control}
                                        nameField="permission"
                                        onChangeInput={handleChange}
                                        value={form.permission}/>
                        <DataPickersWithLabel label="Дата" widthLabel={'30%'} widthInputBlock={'70%'}
                                              widthInput={'100%'}
                                              control={control}
                                              nameField="reqdate"
                                              onChangeInput={handleChangeDate}
                                              value={form.reqdate}
                                              rules={{
                                                  required: 'Поле не может быть пустым'
                                              }}
                        />
                        <InputWithLabel label="Месяцев" widthLabel={'30%'} widthInputBlock={'70%'} widthInput={'100%'}
                                        control={control}
                                        nameField="permonths"
                                        onChangeInput={handleChange}
                                        value={form.permonths}
                                        onDiffDate={handleDiffDate}
                        />
                    </div>

                    <div style={{
                        gridRowStart: 2,
                        gridRowEnd: 3,
                        gridColumnStart: 1,
                        gridColumnEnd: 7
                    }}>
                        <AutocompleteWithLabel widthInput={'100%'} widthLabel={'15%'} widthInputBlock={'85%'}
                                               label={"Импортёр"}
                                               control={control}
                                               nameField="scn_code"
                                               onChangeInput={handleChangeDate}
                                               value={form.scn_code}
                                               rules={{
                                                   required: 'Поле не может быть пустым'
                                               }}
                                               api={'/v_clwrldcntrs/join'}
                        />
                    </div>

                </div>
                <div className="form-bottom-table-btn-wrap">
                    <input type="submit" className="form-bottom-table_button submit"  value="Сохранить"/>
                </div>
            </form>
        </div>
    );
};

export default FormAddReq;