import React, {FC, useEffect, useState} from 'react';
import {Requirement} from "../../../interfaces/TabInterface";
import {Controller, useForm} from "react-hook-form";
import './FormCopyQuarantObjects.scss'
import Autocomplete from "@mui/material/Autocomplete";
import closeIcon from '../../../img/icons/close.png'
import {CircularProgress, createFilterOptions} from "@mui/material";
import {host} from "../../../environments/environments";
import {store} from 'react-notifications-component';
import {ERROR_NOTIFY, SUCCESS_NOTIFY} from "../../../shared/notification";

interface InputValues {
    requirement: Requirement | null
}

interface FormCopyQuarantObjectsProps {
    idTab: number
    onClose: () => void
    requirementId: string
    updateContent: () => void
}

const filterOptions = createFilterOptions<Requirement>({
    matchFrom: 'start',
    stringify: (option) => option.country_name,
});

const FormCopyQuarantObjects: FC<FormCopyQuarantObjectsProps> = ({idTab, onClose, requirementId, updateContent}) => {

    const [open, setOpen] = React.useState(false);
    const [requirements, setRequirements] = useState<Array<Requirement>>([]);
    const {control, handleSubmit} = useForm<InputValues>({
        defaultValues: {
            requirement: null
        }
    })
    const [loadingFetch, setLoadingFetch] = useState(false)
    const loading = open && requirements.length === 0;

    useEffect(() => {

        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            if (active) {
                const response = await fetch(host + '/v_requirements_lists/all', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "range": 10000,
                        "batch": 1,
                        "orderSort": {
                            "property": "irq_id",
                            "asc": false
                        }
                    })
                })
                if (response.ok) {
                    const obj = await response.json()
                    const requirements = obj.items

                    setRequirements(requirements)
                }
            }
        })()

        return () => {
            active = false;
        };
    }, [loading])

    React.useEffect(() => {
        if (!open) {
            setRequirements([]);
        }
    }, [open]);

    const submitForm = async (data: InputValues) => {
        setLoadingFetch(true)
        const response = await fetch(host + '/procedure/copy', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                fromId: data.requirement?.irq_id,
                toId: requirementId
            })
        })
        if (response.ok) {
            store.addNotification({
                ...SUCCESS_NOTIFY,
                title: 'Копирование прошло успешно',
                message: 'Данные успешно скопированы'
            })
            setLoadingFetch(false)
            onClose()
            updateContent()
        } else {
            const error = await response.json()
            store.addNotification({
                ...ERROR_NOTIFY,
                message: error.message
            })
            setLoadingFetch(false)
            onClose()
        }
    }

    return (
        <form className='modal-form' onSubmit={handleSubmit(submitForm)}>
            <img src={closeIcon} alt='закрыть' className='close-icon' onClick={onClose}/>
            <label className="form-bottom-table_label">Выберите требование для копирования: </label>
            <div className="form-bottom-table_input_error_wrap">
                <Controller
                    name='requirement'
                    control={control}
                    rules={{required: 'Поле обязательно для заполнения!'}}
                    render={({field: {onChange, value, name}, fieldState: {error}}) =>
                        <>
                            <Autocomplete
                                filterOptions={filterOptions}
                                open={open}
                                onOpen={() => {
                                    setOpen(true);
                                }}
                                onClose={() => {
                                    setOpen(false);
                                }}
                                loading={loading}
                                sx={{width: '100%'}}
                                getOptionLabel={option => option.srq_str}
                                options={requirements.filter(requirement => requirement.irq_id !== requirementId)}
                                value={value}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref} style={{position: 'relative'}}>
                                        <input type="text" {...params.inputProps} placeholder="Поиск по стране..."
                                               style={{width: '100%'}}
                                               className={`form-bottom-table_input ${error ? 'error' : ''}`}/>
                                        {loading ? <CircularProgress color="inherit" size={20} style={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '5px'
                                        }}/> : null}
                                    </div>
                                )}
                                renderOption={(props, option, {selected}) => (
                                    <li {...props} key={option.irq_id}>
                                        {option && option?.srq_str}
                                    </li>
                                )}
                                isOptionEqualToValue={(option, value) => option.irq_id === value.irq_id}
                                onChange={(event, value) => {
                                    onChange(value)
                                }}
                                noOptionsText="Ничего не найдено"
                                loadingText="Загрузка..."
                            />
                            {error && <span className='error-message'>{error.message}</span>}
                        </>}
                />
            </div>
            <div className="form-bottom-table-btn-wrap">
                {loadingFetch ?
                    <button type="button" className="form-bottom-table_button clear" style={{display: 'flex', alignItems: 'center', gap: '5px'}} onClick={onClose}>Копирование... <CircularProgress color="inherit" size={15}/></button> :
                    <input type="submit" className="form-bottom-table_button submit" value="Добавить" />}
                <button type="button" className="form-bottom-table_button clear" onClick={onClose}>Отмена</button>
            </div>
        </form>
    );
};

export default FormCopyQuarantObjects;