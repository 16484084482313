import React, {FC, HTMLProps, PropsWithChildren} from 'react';

import './PrimaryButton.scss'

interface PrimaryButtonProps extends HTMLProps<HTMLButtonElement> {
    isValid?: boolean
    type?: "submit" | "button" | "reset"
    children: React.ReactNode
}

const PrimaryButton = ({isValid= true, type = 'submit', children, ...props}: PrimaryButtonProps) => {
    return (
        <button className={`btn btn-submit ${!isValid ? 'disable' : ''}`} type={type} disabled={!isValid} {...props}>{
            children ? children : 'Сохранить'
        }</button>
    );
};

export default PrimaryButton;