import React, { FC } from 'react';
import ExpandLink from '../ExpandLink/ExpandLink'
import DefaulLink from '../DefaulLink/DefaulLink'

import logo from '../../img/logo.png'
import requirementIcon from '../../img/icons/req-icon.png';
import documentsIcon from '../../img/icons/doc-icon.png';
import { TabTypes } from '../../interfaces/TabInterface';

import './Menu.scss'

const Menu: FC = () => {

    return (
        <nav className="menu">
            <div className="menu-header">
                <img className="menu-header-logo" src={logo} alt="Логотип" />
                <p className="menu-header-text">ФГБУ «Всероссийский центр карантина растений»</p>
            </div>

            <div className="menu-nav">
                <p className='menu-nav_header'>Меню</p>
                <DefaulLink text={'Требования'} typeTab={TabTypes.REQUIREMENT_TAB} icon={requirementIcon}/>
                {/* <DefaulLink text={'Нормативные документы'} typeTab={TabTypes.NORMAT_DOC_TAB} /> */}
                <ExpandLink title="Справочники" >
                    <ExpandLink title="Продукция" >
                        <DefaulLink text={'Подкарантинная продукция'} typeTab={TabTypes.UQUARANTPROD_TAB} />
                        <DefaulLink text={'Коды ТН ВЭД'} typeTab={TabTypes.CODETNVEDS_TAB} />
                        <DefaulLink text={'Карантинные объекты'} typeTab={TabTypes.QUARANTOBJ_TAB} />
                        <DefaulLink text={'Карантинные фитосанитарные зоны'} typeTab={TabTypes.UQUARANTOBJ_TAB} />
                        <DefaulLink text={'Виды карантинных объектов'} typeTab={TabTypes.TPQUARANTOBJ_TAB} />
                        <DefaulLink text={'Род подкарантинной продукции'} typeTab={TabTypes.KINDUQPROD_TAB} />
                        <DefaulLink text={'Группа подкарантинной продукции'} typeTab={TabTypes.GRUQPROD_TAB} />
                    </ExpandLink>
                    <ExpandLink title="Территории" >
                        <DefaulLink text={'Таможенный классификатор стран мира'} typeTab={TabTypes.CLWRLDCNTR_TAB} />
                        <DefaulLink text={'Контрагенты/Экспортеры'} typeTab={TabTypes.EXPORTERS_TAB} />
                        <DefaulLink text={'Регионы'} typeTab={TabTypes.REGIONS_TAB} />
                        <DefaulLink text={'Места ввоза'} typeTab={TabTypes.PLACESIMP_TAB} />
                    </ExpandLink>
                    <ExpandLink title="Прочее" >
                        <DefaulLink text={'Прочие объекты'} typeTab={TabTypes.OTHERSOBJS_TAB} />
                        <DefaulLink text={'Типы документов'} typeTab={TabTypes.DOCTYPES_TAB} />
                        <DefaulLink text={'Типы прочих объектов'} typeTab={TabTypes.TPOTHERSOBJ_TAB} />
                        <DefaulLink text={'Единицы измерения'} typeTab={TabTypes.MUNITS_TAB} />
                        <DefaulLink text={'Методы и нормы отбора образцов подкарантинной продукции'} typeTab={TabTypes.SAMPLREGPR_TAB} />
                    </ExpandLink>
                </ExpandLink>
                <ExpandLink title="Сорные растения">
                    <DefaulLink text={'Культуры'} typeTab={TabTypes.CULTS_TAB} />
                    <DefaulLink text={'Агенты заражения'} typeTab={TabTypes.INFECTIONS_TAB} />
                    <DefaulLink text={'Фенофазы'} typeTab={TabTypes.PHENOCLASSES_TAB} />
                    <DefaulLink text={'Районы'} typeTab={TabTypes.DISTRICTS_TAB} />
                    <DefaulLink text={'Посевы'} typeTab={TabTypes.CROPS_TAB} />
                </ExpandLink>
                <DefaulLink text={'Хранилище документов'} typeTab={TabTypes.COUNTRYREPOSITORY_TAB} icon={documentsIcon}/>
                <div className="menu-link_lite">
                    <a className="logout-button" href="https://e.vniikr.ru"><p>Визуальный редактор</p></a>
                </div>
                <div className="menu-link_lite">
                    <a className={'logout-button'} href="/Authorization/logout"><p>Выйти</p></a>
                </div>
            </div>
        </nav>
    );
};

export default Menu;