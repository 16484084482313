import {FieldsFormButtomTable} from "../interfaces/FormButtomTableInterface";

export const DocTypesForm: FieldsFormButtomTable[] = [
    {
        default: {
            key: 'code',
            label: 'Краткое наименование',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'name',
            label: 'Полное наименование',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    }
]

export const GruQprodForm: FieldsFormButtomTable[] = [
    {
        default: {
            key: 'sgr_code',
            label: 'Краткое наименование',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'sgr_name',
            label: 'Полное наименование',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    }
]

export const TpothersObjForm: FieldsFormButtomTable[] = [
    {
        default: {
            key: 'sto_code',
            label: 'Краткое наименование',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'sto_name',
            label: 'Полное наименование',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    }
]

export const KindUqprodForm: FieldsFormButtomTable[] = [
    {
        default: {
            key: 'skp_latname',
            label: 'Lat наименование',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'skp_rusname',
            label: 'Rus наименование',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    }
]

export const SampleRegPrForm: FieldsFormButtomTable[] = [
    {
        default: {
            key: 'ssr_name',
            label: 'Наименование',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'ssr_samprule',
            label: 'Правила отбора образцов',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    }
]

export const MunitsForm: FieldsFormButtomTable[] = [
    {
        default: {
            key: 'name',
            label: 'Наименование',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'okei',
            label: 'ОКЕИ',
            widthField: 300,
            widthInput: 239,
            marginLeft: 300,
            rules: {
                pattern: {
                    value: /^[0-9]+$/,
                    message: 'Допустимо вводить только числа'
                },
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        wrap: {
            title: 'Условное обозначение:',
            fields: [
                {
                    key: 'condnatdes',
                    label: 'Национальное',
                    widthField: 280,
                    widthInput: 150
                },
                {
                    key: 'condintdes',
                    label: 'Международное',
                    widthField: 280,
                    widthInput: 150,
                    marginLeft: 10,
                }
            ]
        }
    },
    {
        wrap: {
            title: 'Кодовое буквенное обозначение:',
            fields: [
                {
                    key: 'clnatdes',
                    label: 'Национальное',
                    widthField: 280,
                    widthInput: 150,
                },
                {
                    key: 'clintdes',
                    label: 'Международное',
                    widthField: 280,
                    widthInput: 150,
                    marginLeft: 10
                }
            ]
        }
    }
]

export const PlaceSimpForm: FieldsFormButtomTable[] = [
    {
        default: {
            key: 'sps_code',
            label: 'Краткое наименование',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'sps_name',
            label: 'Полное наименование',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'scn_code',
            label: 'Страна',
            widthField: 280,
            widthInput: 200,
            autocomplete: 'default',
            api: '/v_clwrldcntrs/join',
            rules: {
                required: 'Обязательное поле'
            }
        }
    },
    {
        default: {
            key: 'srg_name',
            label: 'Регион',
            widthField: 280,
            widthInput: 200,
            marginLeft: 40,
            autocomplete: 'default',
            api: '/v_regionss/join',
            filters: {
                nameField: 'icn_clwrldcntr_id'
            },
            rules: {
                required: 'Обязательное поле'
            }
        }
    }
]

export const CodeTnvedForm: FieldsFormButtomTable[] = [
    {
        default: {
            key: 'sct_code',
            label: 'Товарный код',
            widthField: 600,
            widthInput: 400,
            rules: {
                pattern: {
                    value: /^[0-9]+$/,
                    message: 'Допустимо вводить только числа'
                }
            }
        }
    },
    {
        default: {
            key: 'sct_name',
            label: 'Наименование',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'sct_note',
            label: 'Расшифровка',
            widthField: 600,
            widthInput: 400
        }
    },
    {
        default: {
            key: 'name',
            label: 'Выберите родительский ТНВЕД',
            widthField: 600,
            widthInput: 300,
            autocomplete: 'default',
            api: '/codetnveds/join'
        }
    },
    {
        wrap: {
            title: 'Таможенная пошлина:',
            fields: [
                {
                    key: 'nct_ratecustomsduty',
                    label: 'Базовая ставка',
                    widthField: 590,
                    widthInput: 400,
                    rules: {
                        required: 'Поле обязательно для заполнения',
                        pattern: {
                            value: /^[0-9]+$/,
                            message: 'Допустимо вводить только числа'
                        }
                    }
                },
                {
                    key: 'sct_dutynote',
                    label: 'Примечание',
                    widthField: 590,
                    widthInput: 400
                }
            ]
        }
    },
    {
        wrap: {
            title: 'Акциз:',
            fields: [
                {
                    key: 'nct_excise',
                    label: 'Ставка',
                    widthField: 590,
                    widthInput: 400,
                    rules: {
                        required: 'Поле обязательно для заполнения',
                        pattern: {
                            value: /^[0-9]+$/,
                            message: 'Допустимо вводить только числа'
                        }
                    }
                },
                {
                    key: 'sct_excisenote',
                    label: 'Примечание',
                    widthField: 590,
                    widthInput: 400
                }
            ]
        }
    },
    {
        wrap: {
            title: 'НДС:',
            fields: [
                {
                    key: 'nct_nds',
                    label: 'Ставка',
                    widthField: 590,
                    widthInput: 400,
                    rules: {
                        required: 'Поле обязательно для заполнения',
                        pattern: {
                            value: /^[0-9]+$/,
                            message: 'Допустимо вводить только числа'
                        }
                    }
                },
                {
                    key: 'sct_ndsnote',
                    label: 'Примечание',
                    widthField: 590,
                    widthInput: 400
                }
            ]
        }
    }
];

export const TpQuarantObjForm: FieldsFormButtomTable[] = [
    {
        default: {
            key: 'stq_code',
            label: 'Краткое наименование',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Полне обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'stq_name',
            label: 'Полное наименование',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Полне обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'stq_absent',
            label: 'Отсутствуют на территории ЕЭС',
            checkbox: true,
            widthField: 280,
            widthInput: 100
        }
    },
    {
        default: {
            key: 'stq_limited',
            label: 'Ограничены на территории ЕЭС',
            checkbox: true,
            marginLeft: 40,
            widthField: 280,
            widthInput: 100
        }
    }
]

export const UquarantProdForm: FieldsFormButtomTable[] = [
    {
        default: {
            key: 'sup_rusname',
            label: 'Наименование (RUS)',
            widthField: 600,
            widthInput: 300,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'sup_latname',
            label: 'Наименование (LAT)',
            widthField: 600,
            widthInput: 300,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'skp_rusname',
            label: 'Род',
            widthField: 290,
            widthInput: 200,
            autocomplete: 'default',
            api: '/v_kinduqprods/join'
        }
    },
    {
        default: {
            key: 'sgr_code',
            label: 'Группа',
            widthField: 290,
            widthInput: 200,
            marginLeft: 20,
            autocomplete: 'default',
            api: '/v_gruqprods/join'
        }
    },
    {
        default: {
            key: 'sup_fsksavail',
            label: 'Наличие ФС/КС',
            widthField: 150,
            widthInput: 50,
            checkbox: true
        }
    },
    {
        default: {
            key: 'sup_ikravail',
            label: 'Наличие ИКР',
            widthField: 130,
            widthInput: 100,
            marginLeft: 75,
            checkbox: true
        }
    },
    {
        default: {
            key: 'sup_kfkavail',
            label: 'Наличие Акт КФК',
            widthField: 170,
            widthInput: 100,
            marginLeft: 75,
            checkbox: true
        }
    },
    {
        default: {
            key: 'nup_wgsmplavg',
            label: 'Масса среднего образца',
            widthField: 600,
            widthInput: 300
        }
    },
    {
        default: {
            key: 'sup_notquar',
            label: 'Неподкарантинная',
            widthField: 200,
            widthInput: 50,
            checkbox: true
        }
    }
]

export const RegionsForm: FieldsFormButtomTable[] = [
    {
        default: {
            key: 'srg_name',
            label: 'Наименование',
            widthField: 600,
            widthInput: 300,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'scn_code',
            label: 'Страна',
            widthField: 600,
            widthInput: 300,
            autocomplete: 'default',
            api: '/v_clwrldcntrs/join',
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    }
]

export const ClwrldCntrForm: FieldsFormButtomTable[] = [
    {
        default: {
            key: 'ncn_numcode',
            label: 'Цифровой код',
            widthField: 400,
            widthInput: 250,
            rules: {
                required: 'Поле обязательно для заполнения',
                pattern: {
                    value: /^[0-9]+$/,
                    message: 'Допустимо вводить только числа'
                }
            }
        }
    },
    {
        default: {
            key: 'scn_base',
            label: 'Основное',
            widthField: 100,
            widthInput: 50,
            checkbox: true,
            marginLeft: 100
        }
    },
    {
        default: {
            key: 'scn_code',
            label: 'Краткое наименование',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'scn_name',
            label: 'Полное наименование',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'scn_letcode2',
            label: 'Буквенный код 2',
            widthField: 290,
            widthInput: 150,
            rules: {
                required: 'Обязательное поле'
            }
        }
    },
    {
        default: {
            key: 'scn_letcode3',
            label: 'Буквенный код 3',
            widthField: 290,
            widthInput: 150,
            marginLeft: 20,
            rules: {
                required: 'Обязательное поле'
            }
        }
    }
]

export const QuarantObjForm: FieldsFormButtomTable[] = [
    {
        default: {
            key: 'sqo_rusname',
            label: 'Наименование (RUS)',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'sqo_latname',
            label: 'Наименование (LAT)',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'sqo_eppo',
            label: 'EPPO',
            widthField: 600,
            widthInput: 400
        }
    },
    {
        default: {
            key: 'sqo_notation',
            label: 'Примечание',
            widthField: 600,
            widthInput: 400
        }
    },
    {
        default: {
            key: 'stq_code',
            label: 'Вид',
            autocomplete: 'default',
            api: '/v_tpquarantobjs/join',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    }
]

export const UquarantObjForm: FieldsFormButtomTable[] = [
    {
        default: {
            key: 'skf_district',
            label: 'Наименование района',
            widthField: 600,
            widthInput: 300,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'srg_name',
            label: 'Регион',
            autocomplete: 'default',
            api: '/v_regionss/join',
            widthField: 290,
            widthInput: 200,
            filters: {
                nameField: 'irg_region_id'
            }
        }
    },
    {
        default: {
            key: 'nkf_area',
            label: 'Площадь',
            widthField: 290,
            widthInput: 190,
            marginLeft: 20,
            rules: {
                pattern: {
                    value: /^[0-9]+$/,
                    message: 'Можно писать только целые числа'
                }
            }
        }
    },
    {
        default: {
            key: 'skf_munits',
            label: 'Единицы измерения',
            widthField: 600,
            widthInput: 300,
        }
    },
    {
        default: {
            key: 'nkf_quant',
            label: 'Кол-во установленных КФЗ',
            widthField: 290,
            widthInput: 60,
            rules: {
                pattern: {
                    value: /^[0-9]+$/,
                    message: 'Можно писать только целые числа'
                }
            }
        }
    },
    {
        default: {
            key: 'sqo_rusname',
            label: 'Карантинный объект',
            autocomplete: 'default',
            api: '/v_quarantobjs/join',
            widthField: 600,
            widthInput: 300,
            filters: {
                nameField: 'iqo_quarantobj_id'
            }
        }

    }
]

export const ExportersForm: FieldsFormButtomTable[] = [
    {
        default: {
            key: 'sea_code',
            label: 'Краткое наименование',
            widthField: 600,
            widthInput: 300,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'sea_name',
            label: 'Полное наименование',
            widthField: 600,
            widthInput: 300,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'nea_uncode',
            label: 'Уникальный код',
            widthField: 290,
            widthInput: 150
        }
    },
    {
        default: {
            key: 'nea_inn',
            label: 'ИНН',
            widthField: 290,
            widthInput: 230,
            marginLeft: 20,
        }
    },
    {
        default: {
            key: 'scn_code',
            label: 'Страна',
            autocomplete: 'default',
            api: '/v_clwrldcntrs/join',
            widthField: 290,
            widthInput: 200,
            rules: {
                required: 'Обязательное поле'
            }
        }
    },
    {
        default: {
            key: 'srg_name',
            label: 'Регион',
            autocomplete: 'default',
            api: '/v_regionss/join',
            filters: {
                nameField: 'icn_clwrldcntr_id'
            },
            widthField: 290,
            widthInput: 230,
            marginLeft: 20
        }
    },
    {
        default: {
            key: 'sea_addrprod',
            label: 'Адрес производства',
            widthField: 600,
            widthInput: 300
        }
    },
    {
        default: {
            key: 'sea_addrjur',
            label: 'Адрес юридический',
            widthField: 600,
            widthInput: 300
        }
    }
]

export const OthersObjForm: FieldsFormButtomTable[] = [
    {
        default: {
            key: 'soo_rusname',
            label: 'Наименование (RUS)',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'soo_latname',
            label: 'Наименование (LAT)',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'sto_code',
            label: 'Тип',
            autocomplete: 'default',
            api: '/v_tpothersobjs/join',
            widthField: 300,
            widthInput: 250,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'smu_name',
            label: 'Единица измерения',
            autocomplete: 'default',
            api: '/v_munitss/join',
            widthField: 290,
            widthInput: 120,
            marginLeft: 10
        }
    },
    {
        default: {
            key: 'soo_type',
            label: 'Тип данных',
            autocomplete: 'type',
            widthField: 300,
            widthInput: 200,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'soo_main',
            label: 'Основная',
            checkbox: true,
            widthField: 100,
            widthInput: 150,
            marginLeft: 200
        }
    },
    {
        default: {
            key: 'soo_label_name',
            label: 'Метка',
            widthField: 600,
            widthInput: 500
        }
    }
]

export const QuarantObjPointForm: FieldsFormButtomTable[] = [
    {
        default: {
            key: 'sqo_rusname',
            label: 'Карантинный объект',
            widthField: 600,
            widthInput: 400,
            api: '/v_quarantobjs/join',
            autocomplete: 'default',
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    }
]

export const UqpqObjForm: FieldsFormButtomTable[] = [
    {
        default: {
            key: 'sqo_rusname',
            label: 'Карантинный объект',
            widthField: 600,
            widthInput: 400,
            api: '/v_quarantobjs/join',
            autocomplete: 'default',
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    }
]

export const CntrqobjForm: FieldsFormButtomTable[] = [
    {
        default: {
            key: 'sqo_rusname',
            label: 'Карантинный объект',
            widthField: 600,
            widthInput: 400,
            api: '/v_quarantobjs/join',
            autocomplete: 'default',
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    }
]

export const CultsForm: FieldsFormButtomTable[] = [
    {
        default: {
            key: 'scu_rusname',
            label: 'Rus наименование',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'scu_latname',
            label: 'Lat наименование',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'spc_phenoclass',
            label: 'Класс фенофазы',
            widthField: 600,
            widthInput: 400,
            api: '/v_phenoclasses/join',
            autocomplete: 'default',
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    }
]

export const PhenoclassesForm: FieldsFormButtomTable[] = [
    {
        default: {
            key: 'spc_phenoclass',
            label: 'Класс фенофазы',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    }
]

export const PhenophasesForm: FieldsFormButtomTable[] = [
    {
        default: {
            key: 'spp_name',
            label: 'Наименование',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    }
]

export const DisctrictsForm: FieldsFormButtomTable[] = [
    {
        default: {
            key: 'sdt_name',
            label: 'Район',
            widthField: 600,
            widthInput: 500,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'srg_name',
            label: 'Регион',
            widthField: 600,
            widthInput: 500,
            api: '/v_regionss/join',
            autocomplete: 'default',
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    }
]

export const CropsForm: FieldsFormButtomTable[] = [
    {
        default: {
            key: 'scu_rusname',
            label: 'Культура',
            widthField: 600,
            widthInput: 400,
            api: '/v_cults/join',
            autocomplete: 'default',
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'sct_name',
            label: 'Тип культуры',
            widthField: 600,
            widthInput: 400,
            api: '/v_cult_types/join',
            autocomplete: 'default',
            filters: {
                nameField: 'icu_cult_id'
            },
        }
    },
    {
        default: {
            key: 'srg_name',
            label: 'Регион',
            widthField: 270,
            widthInput: 200,
            api: '/v_regionss/join',
            autocomplete: 'default',
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'sdt_name',
            label: 'Район',
            widthField: 300,
            widthInput: 200,
            marginLeft: 30,
            api: '/v_districts/join',
            autocomplete: 'default',
            rules: {
                required: 'Поле обязательно для заполнения'
            },
            filters: {
                nameField: 'irg_region_id'
            },
        }
    },
    {
        default: {
            key: 'scr_year',
            label: 'Год',
            widthField: 270,
            widthInput: 200,
            autocomplete: 'year',
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'scr_field',
            label: 'Поле',
            widthField: 300,
            widthInput: 200,
            marginLeft: 30,
            validateFunc: (event) => {
                const value = event.target.value
                if (value.length >= 3) {
                    const slicedValue = value.slice(0, 3)
                    event.target.value = slicedValue
                    return slicedValue
                }
                return event.target.value
            },
            rules: {
                required: 'Поле обязательно для заполнения',
                maxLength: {
                    value: 3,
                    message: 'Максимальная длина 3 символа'
                }
            }
        }
    },
    {
        default: {
            key: 'scr_reseeding',
            label: 'Повторный посев',
            widthField: 220,
            widthInput: 200,
            checkbox: true
        }
    },
]

export const InfectionsForm: FieldsFormButtomTable[] = [
    {
        default: {
            key: 'sin_rusname',
            label: 'Rus наименование',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'sin_latname',
            label: 'Lat наименование',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'sin_type',
            label: 'Тип',
            widthField: 250,
            widthInput: 200,
            autocomplete: 'infection_type'
        }
    },
    {
        default: {
            key: 'spc_phenoclass',
            label: 'Фенофаза',
            widthField: 300,
            widthInput: 200,
            marginLeft: 50,
            api: '/v_phenoclasses/join',
            autocomplete: 'default',
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'sqo_rusname',
            label: 'Карантинный объект',
            widthField: 600,
            widthInput: 400,
            api: '/v_quarantobjs/join',
            autocomplete: 'default'
        }
    },
   /* {
        default: {
            key: 'sqr_latname',
            label: 'Карантинный объект Lat наименование',
            widthField: 600,
            widthInput: 250,
            api: '/v_quarantobjs/join',
            autocomplete: 'default',
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    }*/
]

export const CultTypesForm: FieldsFormButtomTable[] = [
    {
        default: {
            key: 'sct_name',
            label: 'Наименование',
            widthField: 600,
            widthInput: 400,
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    }
]

export const RegQuarantObjPointForm: FieldsFormButtomTable[] = [
    {
        default: {
            key: 'sup_rusname',
            label: 'Подкарантинная продукция',
            widthField: 600,
            widthInput: 350,
            autocomplete: 'default',
            api: '/v_uquarantprods/join',
            rules: {
                required: 'Поле обязательно для заполнения'
            }
        }
    },
    {
        default: {
            key: 'srp_permission',
            label: 'Разрешение',
            widthField: 150,
            widthInput: 50,
            checkbox: true
        }
    },
    {
        default: {
            key: 'srp_note',
            label: 'Примечание',
            widthField: 600,
            widthInput: 500
        }
    }
]