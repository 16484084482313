import {TabAction, TabActionsType, TabState} from '../../interfaces/TabActions'
import {
    SpecificRequirementsTabAction,
    SpecificRequirementsTabActionsType
} from '../../interfaces/SpecificRequirementsTabActions'
import {TableAction, TableActionsType} from '../../interfaces/tableActions';
import {formAction, formActionsType} from '../../interfaces/formActions';
import {reqFormAction, reqFormActionsType} from '../../interfaces/requirementsFormActions';
import {ITab} from '../../interfaces/TabInterface';
import {
    addItemToContent,
    deleteRow,
    setContent,
    setFilters,
    setLimit,
    setPage,
    setSortColumn,
    setSortType,
    toggleAllCheckbox,
    toggleCheckbox,
    updContent
} from '../../services/table.service';
import {createFormOnTab, resetForm, setFormValue} from '../../services/form.service';
import {
    addQuarantObjectArrayField,
    addQuarantProd,
    addQuquarantObjArrayField,
    addTags,
    addTnvedArrayField,
    deleteRequirementBlock,
    deleteTag,
    resetReqForm,
    setArrayFieldValue,
    setReqFormToggleChecker,
    setReqFormValue,
    setReqList
} from '../../services/reqForm.service'
import {FileRepositoryAction, FileRepositoryActionType} from "../../interfaces/fileRepositoryActions";
import {
    addFileInRepository,
    removeFileFromRepository,
    setFilesInRepository
} from "../../services/fileRepository.service";

type Actions = TabAction
    | SpecificRequirementsTabAction
    | TableAction
    | formAction
    | reqFormAction
    | FileRepositoryAction

export const initialState: TabState = {
    tabs: []
}

export const tabReducer = (state = initialState, action: Actions): TabState => {
    switch (action.type) {
        case TabActionsType.ADD_TAB:
            return { tabs: [...state.tabs, action.payload] }
        case TabActionsType.DELETE_TAB:
            return { tabs: [...state.tabs.filter((tab) => {
                    if (tab.t_pid) {
                        return tab.t_pid !== action.payload
                    } else {
                        return tab.id !== action.payload
                    }
            })] }
        case TabActionsType.MOVES_TABS:
            return {
                tabs: [...state.tabs.map((tab) => {
                    if (tab === action.payload.tabDrop) {
                        return { ...action.payload.tabCurrent }
                    }
                    if (tab === action.payload.tabCurrent) {
                        return { ...action.payload.tabDrop }
                    }
                    return tab
                })]
            }
        case reqFormActionsType.ADD_TAGS:
            return {
                tabs: addTags(state, action)
            }
        case reqFormActionsType.DELETE_TAG:
            return {
                tabs: deleteTag(state, action)
            }
        case reqFormActionsType.DELETE_REQUIREMENT_BLOCK:
                return {
                    tabs: deleteRequirementBlock(state, action)
                }
        case reqFormActionsType.SET_REQ_FORM_VALUE:
            return {
                tabs: setReqFormValue(state, action)
            }
        case reqFormActionsType.SET_REQ_FORM_TOGGLE_CHEKER:
            return {
                tabs: setReqFormToggleChecker(state, action)
            }
        case reqFormActionsType.GET_REQUIREMENTS_LIST:
            return {
                tabs: setReqList(state, action)
            }
        case reqFormActionsType.RESET_REQ_FORM:
            return {
                tabs: resetReqForm(state, action)
            }
        case reqFormActionsType.SET_ARRAY_FIELD_VALUE: 
            return {
                tabs: setArrayFieldValue(state, action)
            }
        case SpecificRequirementsTabActionsType.SET_START_DATE:
            return {
                tabs: [...state.tabs.map((tab: ITab, idx: number): ITab => {
                    if (idx === action.payload.idTab) {
                        return Object.assign({},
                            tab,
                            {
                                content: {
                                    ...tab.content,
                                    startDate: action.payload.date as string
                                }

                            })
                    }
                    return tab
                })]
            }
        case SpecificRequirementsTabActionsType.SET_END_DATE:
            return {
                tabs: [...state.tabs.map((tab: ITab, idx: number): ITab => {
                    if (idx === action.payload.idTab) {
                        return Object.assign({},
                            tab,
                            {
                                content: {
                                    ...tab.content,
                                    endDate: action.payload.date as string
                                }
                            })
                    }
                    return tab
                })]
            }
        case TableActionsType.GET_CONTENT:
            return {
                tabs: setContent(state, action)
            }
        case TableActionsType.UPD_CONTENT:
            return {
                tabs: updContent(state, action)
            }
        case TableActionsType.TOGGLE_CHECKBOX:
            return {
                tabs: toggleCheckbox(state, action)
            }
        case TableActionsType.TOGGLE_ALL_CHECKBOX:
            return {
                tabs: toggleAllCheckbox(state, action)
            }
        case TableActionsType.DELETE_ROW:
            return {
                tabs: deleteRow(state, action)
            }
        case TableActionsType.SET_LIMIT:
            return {
                tabs: setLimit(state, action)
            }
        case TableActionsType.SET_PAGE:
            return {
                tabs: setPage(state, action)
            }
        case TableActionsType.SET_SORT_COLUMN:
            return {
                tabs: setSortColumn(state, action)
            }
        case TableActionsType.SET_SORT_TYPE:
            return {
                tabs: setSortType(state, action)
            }
        case TableActionsType.SET_FILTERS:
            return {
                tabs: setFilters(state, action)
            }
        case formActionsType.ADD_ITEMS_TO_CONTENT:
            return {
                tabs: addItemToContent(state, action)
            }
        case formActionsType.CREATE_FORM_ON_TAB:
            return {
                tabs: createFormOnTab(state, action)
            }
        case formActionsType.SET_FORM_VALUE:
            return {
                tabs: setFormValue(state, action)
            }
        case formActionsType.RESET_FORM:
            return {
                tabs: resetForm(state, action)
            }
        case reqFormActionsType.ADD_QUARANT_PROD:
            return {
                tabs: addQuarantProd(state, action)
            }
        case reqFormActionsType.ADD_TNVED_ARRAY_FIELD:
            return {
                tabs: addTnvedArrayField(state, action)
            }
        case reqFormActionsType.ADD_QUARANT_OBJ_ARRAY_FIELD:
            return {
                tabs: addQuarantObjectArrayField(state, action)
            }
        case reqFormActionsType.ADD_QUQUARANT_OBJ_ARRAY_FIELD:
            return {
                tabs: addQuquarantObjArrayField(state, action)
            }
        case FileRepositoryActionType.ADD_FILE:
            return {
                tabs: addFileInRepository(state, action)
            }
        case FileRepositoryActionType.REMOVE_FILE:
            return {
                tabs: removeFileFromRepository(state, action)
            }
        case FileRepositoryActionType.SET_FILES:
            return {
                tabs: setFilesInRepository(state, action)
            }
        default:
            return state
    }
}
