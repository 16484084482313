import React, { FC, useState } from 'react';
import { SortType } from 'rsuite-table';
import ToolBar from '../../ToolBar/ToolBar';
import CustomTable from '../../CustomTable/CustomTable';
import {IContentTable, TabPanel} from '../../../interfaces/TabInterface';
import {KindUqprodForm} from "../../../shared/form.table.enviroment";
import FormBottomTable from "../../Forms/FormBottomTable/FormBottomTable";

interface KinduqprodTabProps extends TabPanel {

}

const KinduqprodTab: FC<KinduqprodTabProps> = ({ idTab, limit, page, sortColumn, sortType, content, filters, form }) => {
    const [toggleForm, setToggleForm] = useState<boolean>(false)

    return (
        <div className="tab-wrap">
            <div className="table-container">
                <CustomTable 
                    api={{
                        post: '/v_kinduqprods/all',
                        put: '/kinduqprods'
                    }}
                    idName={'ikp_id'}
                    headerHeight={80}
                    idTab={idTab}
                    items={content?.items ? content.items : []}
                    limit={limit}
                    page={page}
                    sortType={sortType as SortType}
                    sortColumn={sortColumn}
                    totalItems={content?.totalItems}
                    filters={filters}
                    setting={[
                        {   
                            key: 'skp_latname',
                            label: 'Lat наименование',
                            flex: 1
                        },
                        {   
                            key: 'skp_rusname',
                            label: 'Rus наименование',
                            flex: 1,
                            sortable: true,
                            filtered: true
                        }
                    ]}
                />
                <FormBottomTable
                    open={toggleForm} onToggleForm={() => setToggleForm(prev => !prev)}
                    idName={'ikp_id'}
                    form={form}
                    fields={KindUqprodForm}
                    api="/kinduqprods"
                    idTab={idTab}
                />
            </div>
            <ToolBar idTab={idTab} toggleForm={() => setToggleForm(state => !state)} api="/kinduqprods/" idName={'ikp_id'} tableNameExport={'v_kinduqprod'} />
        </div>
    );
};

export default KinduqprodTab;