import React, {FC, useCallback, useState} from 'react';
import {AuthContext} from "../contexts/AuthContext";
import {User} from "../interfaces/auth";

interface AuthProviderProps {
    children: React.ReactNode
}

const AuthProvider: FC<AuthProviderProps> = ({children}) => {

    const [user, setUser] = useState<User>({
        userName: 'Ivan',
        accessList: []
    })

    const signIn = useCallback((user: User) => {
        setUser(user)
    }, [])

    return (
        <AuthContext.Provider value={{user, signIn}}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;