import React, { FC } from 'react';
import InputWithLabel from '../../Inputs/InputWithLabel/InputWithLabel';
import AutocompleteWithLabel from '../../Inputs/AutocompleteWithLabel/AutocompleteWithLabel';
import { useForm } from 'react-hook-form';
import { ITnvedsUquarantObj } from '../../../../interfaces/TabInterface';
import { useArrayFieldChangeAutocomplete, usePlusClick } from '../../../../hooks/useChange';
import { formsName, tagsName } from '../../../../shared/shared';
import { useDeleteArrayFields, useSAveArrayFieldTnved } from '../../../../hooks/useSave';

interface TnvedPointProps {
    idTab: number,
    tnveds: ITnvedsUquarantObj[],
    pid: string
}

const TnvedPoint: FC<TnvedPointProps> = ({ idTab, tnveds, pid }) => {

    const { control } = useForm()

    //для добавления и сохранения тнвед
    const handleArrayFieldChangeAutocomplete = useArrayFieldChangeAutocomplete(idTab, formsName.mainForm, tagsName.tnveds)
    const handleArrayFieldSaveTnved = useSAveArrayFieldTnved(idTab, pid, formsName.mainForm, tagsName.tnveds, 'rquqprodtnveds', 'codetnved_id', 'irt_id')

    //по клику на плюс
    const onPlusChange = usePlusClick(idTab, formsName.mainForm, tagsName.tnveds, {
        irt_id: '0',
        irp_pid: 0,
        ict_codetnved_id: 0,
        sct_code: '',
        sct_name: '',
        sct_note: '',
        nct_ratecustomsduty: 0,
        sct_dutynote: '',
        nct_excise: 0,
        sct_excisenote: '',
        nct_nds: 0,
        sct_ndsnote: '',
        sct_fullname: ''
    })

    //По клику на минус
    const handleDeleteArrayField = useDeleteArrayFields(idTab, 'rquqprodtnveds', formsName.mainForm, tagsName.tnveds)

    return (<div
        style={{
            display: 'grid',
            gridTemplateRows: '1fr',
            gridTemplateColumns: '1fr 3fr',
            height: '100%',
            gridGap: '10px 1vw'
        }}>

        <div style={{
            gridRowStart: 1,
            gridRowEnd: 2,
            gridColumnStart: 1,
            gridColumnEnd: 2
        }}>
            {tnveds.map((tnved, idx) => <AutocompleteWithLabel
                api={'/v_codetnveds/join'}
                control={control}
                widthInput={'100%'}
                widthInputBlock={'80%'}
                widthLabel={'20%'}
                nameField='sct_code'
                label={"ТНВЭД"}
                value={tnved.sct_code}
                plus={tnveds.length - 1 === idx}
                minus={tnveds.length - 1 !== idx}
                onChangeInput={handleArrayFieldChangeAutocomplete(idx)}
                onBlurInput={handleArrayFieldSaveTnved(idx, tnved)}
                onPlusClick={onPlusChange}
                onMinusClick={handleDeleteArrayField(tnved.irt_id)}
            />)}
        </div>

        <div style={{
            gridRowStart: 1,
            gridRowEnd: 2,
            gridColumnStart: 2,
            gridColumnEnd: 3
        }}>{tnveds.map((tnved, idx) => <InputWithLabel
            control={control}
            widthInput={'100%'}
            widthInputBlock={'85%'}
            widthLabel={'15%'}
            label={"Наименование"}
            value={tnved.sct_fullname}
            readonly
        />)}

        </div>


    </div >);
};

export default TnvedPoint;