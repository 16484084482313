export enum reqFormActionsType {
    ADD_TAGS = 'ADD_TAGS',
    SET_REQ_FORM_VALUE = 'SET_REQ_FORM_VALUE',
    SET_REQ_FORM_TOGGLE_CHEKER = 'SET_REQ_FORM_TOGGLE_CHEKER',
    START_FETCH_REQUIREMENT = 'START_FETCH_REQUIREMENT',
    SUCCES_FETCH_REQUIREMENT = 'SUCCES_FETCH_REQUIREMENT',
    ERROR_FETCH_REQUIREMENT = 'ERROR_FETCH_REQUIREMENT',
    GET_REQUIREMENTS_LIST = 'GET_REQUIREMENTS_LIST',
    RESET_REQ_FORM = 'RESET_REQ_FORM',
    UPD_FIELD_REQ_FORM = 'UPD_FIELD_REQ_FORM',
    RESET_STATUS_FORM = 'RESET_STATUS_FORM',
    DELETE_TAG = 'DELETE_TAG',
    DELETE_REQUIREMENT_BLOCK = 'DELETE_REQUIREMENT_BLOCK',
    SET_ARRAY_FIELD_VALUE = 'SET_ARRAY_FIELD_VALUE',
    ADD_QUARANT_PROD = 'ADD_QUARANT_PROD',
    ADD_TNVED_ARRAY_FIELD = 'ADD_TNVED_ARRAY_FIELD',
    ADD_QUARANT_OBJ_ARRAY_FIELD = 'ADD_QUARANT_OBJ_ARRAY_FIELD',
    ADD_QUQUARANT_OBJ_ARRAY_FIELD = 'ADD_QUQUARANT_OBJ_ARRAY_FIELD'
}

export enum statuses {
    DATA_GET = 'DATA_GET',
    DATA_SAVE = 'DATA_SAVE',
    DATA_DELETE = 'DATA_SAVE'
}

export interface formState {
    error: string,
    status: string
}

export interface startFetch {
    type: reqFormActionsType.START_FETCH_REQUIREMENT
}

export interface getSuccess {
    type: reqFormActionsType.SUCCES_FETCH_REQUIREMENT,
    payload: {
        message: string
    }
}

export interface getError {
    type: reqFormActionsType.ERROR_FETCH_REQUIREMENT,
    payload: {
        message: string
    }
}

export interface AddTag {
    type: reqFormActionsType.ADD_TAGS,
    payload: {
        idTab: number
        nameForm: string
        nameTags: string
        tag: {[key: string]: any}     
    }
}

export interface DeleteTag {
    type: reqFormActionsType.DELETE_TAG,
    payload: {
        idTab: number
        nameForm: string
        nameTags: string,
        idTag: string
    }
}
export interface DeleteRequirementBlock {
    type: reqFormActionsType.DELETE_REQUIREMENT_BLOCK,
    payload: {
        idTab: number
        idRequirements: number[]
    }
}


export interface SetReqFormValue {
    type: reqFormActionsType.SET_REQ_FORM_VALUE,
    payload: {
        idTab: number
        nameForm: string
        nameField: string
        value: string
        dopField: string | undefined
    }
}

export interface SetReqFormToggleCheker {
    type: reqFormActionsType.SET_REQ_FORM_TOGGLE_CHEKER,
    payload: {
        idTab: number
        nameForm: string
        nameField: string
    }
}

export interface GetRequirList {
    type: reqFormActionsType.GET_REQUIREMENTS_LIST,
    payload: {
        idTab: number,
        requirements: {
            irq_id: string,
            str: string,
            permission: number
        }[]
    }
}

export interface ResetReqFom {
    type: reqFormActionsType.RESET_REQ_FORM,
    payload: {
        idTab: number
    }
}

export interface ResetStatusForm {
    type: reqFormActionsType.RESET_STATUS_FORM
}

export interface SetArrayFieldValue {
    type: reqFormActionsType.SET_ARRAY_FIELD_VALUE,
    payload: {
        idTab: number,
        nameForm: string,
        nameTag: string,
        idTag: number
        nameField: string,
        value: string | boolean,    
    }
}

export interface AddQuarantProd {
    type: reqFormActionsType.ADD_QUARANT_PROD,
    payload: {
        idTab: number
    }
}

export interface AddTndevArrayField {
    type: reqFormActionsType.ADD_TNVED_ARRAY_FIELD,
    payload: {
        idTab: number,
        idQuarantProd: number
    }
}

export interface AddQuarantObjArrayField {
    type: reqFormActionsType.ADD_QUARANT_OBJ_ARRAY_FIELD,
    payload: {
        idTab: number,
        idQuarantProd: number
    }
}

export interface AddQuquarantObjArrayField {
    type: reqFormActionsType.ADD_QUQUARANT_OBJ_ARRAY_FIELD,
    payload: {
        idTab: number,
        idQuarantObj: number
    }
}

export type reqFormAction =  AddTag
    | SetReqFormValue
    | SetReqFormToggleCheker
    | startFetch
    | getSuccess
    | getError
    | GetRequirList
    | ResetReqFom
    | ResetStatusForm
    | DeleteTag
    | DeleteRequirementBlock
    | SetArrayFieldValue
    | AddQuarantProd
    | AddTndevArrayField
    | AddQuarantObjArrayField
    | AddQuquarantObjArrayField
