import React, { FC, useState } from 'react';
import { Controller } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import Tag from '../../../Tag/Tag'
import { host } from '../../../../environments/environments';

import './AutocompleteWithTags.scss'

interface AutocompleteWithTagsProps {
    widthInputBlock: number | string
    widthLabel: number | string
    widthInput: number | string
    label: string
    plus?: boolean
    control?: any
    api?: string
    onChangeInput?: (tag: {[key: string]: any}, nameIdTag: string, nameValueTag: string) => void
    onDeleteTag?: (idTag: string) => void
    rules?: any
    type?: string
    onBlurInput?: (idTag: number, value: string, dopField?: string) => void
    tags?: { [key: string]: any }[]
    tagIdName?: string
    tagValueName?: string
    idSearch?: string
    nameSearch?: string
    filters?: {
        nameField: string,
        value: string
    }
    dopName?: string
}

const AutocompleteWithTags: FC<AutocompleteWithTagsProps> = ({ label,
    widthInput,
    widthLabel,
    widthInputBlock,
    plus,
    control,
    api,
    onChangeInput,
    rules,
    type,
    onBlurInput,
    tags,
    tagIdName,
    tagValueName,
    onDeleteTag,
    idSearch,
    nameSearch,
    filters,
    dopName }) => {

    const [options, setOptions] = useState<{ [key: string]: any }[]>([])

    const hanleAutocompleteChange = async (api: string, key: string, value: string) => {
        let searchParams = new URLSearchParams({}) 

        if (filters) {
            searchParams = new URLSearchParams({
                field: key,
                input: value,
                'Filters[0].field': filters.nameField,
                'Filters[0].value': filters.value 
            }) 
        } else {
            searchParams = new URLSearchParams({
                field: key,
                input: value
            }) 
        }

        const response = await fetch(`${host + api}?` + searchParams)


        if (response.status === 200) {
            const opt = await response.json()
            if (opt.length !== 0) {
                setOptions(opt)
            }
        }
    }

    return (
        <div className="input-with-tags-block" style={{height: 'auto', marginBottom: '10px'}}>
            <p className='input-with-tags-block__text' style={{ width: widthLabel }}>{label}</p>
            <div style={{ width: widthInputBlock }}>
                    <Controller
                        name={tagValueName as string}
                        control={control}
                        rules={rules}
                        render={({ field: { onChange, name, value }, fieldState: { error } }) => <Autocomplete
                            disablePortal
                            sx={{ width: '100%' }}
                            
                            getOptionLabel={(option: any) => option[nameSearch as string]}
                            options={options}
                            renderInput={(params) => (
                                <div ref={params.InputProps.ref}>
                                    <input type="text" {...params.inputProps} placeholder="Поиск..."
                                        className="input-with-tags-block_input"
                                        style={{ width: widthInput }}
                                        name={name}
                                    />
                                    <p style={{ color: 'red' }}>{error && error.message}</p>
                                </div>
                            )}
                            onInputChange={(e, val, reas) => {
                                if (reas === 'input') {
                                    if (val) {
                                        hanleAutocompleteChange(api as string, nameSearch as string, val)
                                    } else {
                                        setOptions([])
                                    }
                                }
                                if (reas === 'reset') {
                                    setOptions([])
                                }

                            }}
                            onChange={(e, val: {[key: string]: any}, reas) => {
                                if (reas === 'selectOption') {
                                    onBlurInput && onBlurInput(val[idSearch as string], val[nameSearch as string], val[dopName as string])
                                    setOptions([])
                                }
                            }}
                            noOptionsText="Ничего не найдено"
                        />}
                    />
                <div className="tegs-block" style={{width: widthInput}}>
                    {tags && tags.map(tag =>
                        <Tag key={tag[tagIdName as string]} title={tag[tagValueName as string]} DeleteTag={() => {onDeleteTag && onDeleteTag(tag[tagIdName as string])}} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default AutocompleteWithTags;