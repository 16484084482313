import {TabState} from '../interfaces/TabActions';
import {CreateFormOnTab, ResetForm, SetFormValue} from '../interfaces/formActions';
import {ITab} from '../interfaces/TabInterface';

export const createFormOnTab = (state: TabState, action: CreateFormOnTab): ITab[] => {
    const newTabs: ITab[] = state.tabs.map((tab, idx) => {
        if (idx === action.payload.idTab) {
            return Object.assign({},
                tab,
                {
                    form: action.payload.form
                })
        }
        return tab
    })
    return newTabs
}

export const setFormValue = (state: TabState, action: SetFormValue): ITab[] => {
    const newTabs: ITab[] = state.tabs.map((tab, idx) => {
        if (idx === action.payload.idTab) {
            const obj = Object.assign({}, tab.form)
            if (action.payload.key.startsWith('i')) {
                const threeLetters = action.payload.key.slice(0, 3)
                Object.keys(obj).forEach(key => {
                    if (key.startsWith(threeLetters)) {
                        obj[key] = action.payload.value
                    }
                })
            } else {
                if (obj.hasOwnProperty(action.payload.key)) {
                    obj[action.payload.key] = action.payload.value
                }
            }

            return Object.assign({},
                tab,
                {
                    form: obj
                })
        }
        return tab
    })
    return newTabs
}

export const resetForm = (state: TabState, action: ResetForm): ITab[] => {
    const newTabs: ITab[] = state.tabs.map((tab, idx) => {
        if (idx === action.payload.idTab) {
            const obj = Object.assign({}, tab.form)
            for (let key in obj) {
                if (obj[key] === 'Да') {
                    obj[key] = 'Нет'
                } else if (obj[key] === 'Нет') {
                    obj[key] = 'Нет'
                } else if (key.endsWith('_pid') || key.endsWith('_id')) {
                    //TODO ничего не делать, это было сделано для работы формы добавления и очищения в подкарантинном табе требований
                }
                    else {
                    obj[key] = ''
                }
            }
            return Object.assign({},
                tab,
                {
                    form: obj
                })
        }
        return tab
    })
    return newTabs
}