import React, { ChangeEvent, FC } from 'react';
import { Controller } from 'react-hook-form';
import './CheckBoxWithLabel.scss';

interface CheckBoxWithLabelProps {
    label: string,
    onChangeInput?: (event: ChangeEvent<HTMLInputElement>) => void
    value?: boolean
    nameField?: string
    control?: any
    onBlurInput?: (nameField: string, data: Date | string | boolean) => void
}

const CheckBoxWithLabel: FC<CheckBoxWithLabelProps> = ({ label, onChangeInput, value, nameField, control, onBlurInput }) => {

    return (
        <div className="checkbox-wrap">
            <p className="checkbox-label">{label}</p>
            <Controller
                control={control}
                name={nameField as string}
                defaultValue={value}
                render={({ field: { onChange, onBlur, ref, name } }) => (
                    <input
                        type="checkbox"
                        className="checkbox-input"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            onChange(e);
                            onChangeInput && onChangeInput(e);
                            onBlurInput && onBlurInput(nameField as string, e.target.checked);
                        }}
                        checked={value}
                        name={name}
                    />
                )}
            />

        </div>
    );
};

export default CheckBoxWithLabel;