import React, {FC, useEffect, useRef, useState} from 'react';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import RequirementsTab from '../Tabs/RequirementsTab/RequirementsTab'
import RequirementSpecificTab from '../Tabs/RequirementSpecificTab/RequirementSpecificTab'
import MunitsTab from '../Tabs/MunitsTab/MunitsTab'
import OthersObjsTab from '../Tabs/OthersObjsTab/OthersObjsTab';
import CodeTnvedTab from '../Tabs/CodeTnvedTab/CodeTnvedTab';
import UquarantObjTab from '../Tabs/UquarantObjTab/UquarantObjTab';
import ClwrldcntrTab from '../Tabs/ClwrldcntrTab/ClwrldcntrTab';
import ExportersTab from '../Tabs/ExportersTab/ExportersTab';
import QuarantobjTab from '../Tabs/QuarantobjTab/QuarantobjTab';
import SamplregprTab from '../Tabs/SamplregprTab/SamplregprTab';
import TpquarantobjTab from '../Tabs/TpquarantobjTab/TpquarantobjTab';
import PlacesimpTab from '../Tabs/PlacesimpTab/PlacesimpTab';
import DoctypesTab from '../Tabs/DoctypesTab/DoctypesTab';
import UquarantprodTab from '../Tabs/UquarantprodTab/UquarantprodTab';
import KinduqprodTab from '../Tabs/KinduqprodTab/KinduqprodTab';
import GruqprodTab from '../Tabs/GruqprodTab/GruqprodTab';
import TpothersObjTab from '../Tabs/TpothersObjTab/TpothersObjTab';
import NormatDocTab from '../Tabs/NormatDocTab/NormatDocTab';
import SpecificUquarantTab from '../Tabs/SpecificUquarantTab/SpecificUquarantTab';
import {useTypedSelector} from '../../hooks/useTypedSelector';
import {
    TabQuarantFitoZoneContext,
    TabQuarantObjByRequirement,
    TabSpecificRequirementContext,
    TabSpecificUquarantObjContext
} from '../../contexts/TabsContext';

import './MainTabs.scss'
import {useActions} from '../../hooks/useAction';
import {
    IContentTable,
    INormatDocTab,
    IQuarantFitoZoneTab,
    IQuarantObjByRequirementTab,
    IRequirementsTab,
    ISpecificNormatDocFormTab,
    ISpecificRequirementsTab,
    ISpecificUquarantObjTab,
    ITab,
    TabTypes
} from '../../interfaces/TabInterface';
import RequirementsHistoryTab from '../Tabs/RequirementsHistoryTab/RequirementsHistoryTab';
import DocumentsRepositoryTab from '../Tabs/DocumentsRepositoryTab/DocumentsRepositoryTab';
import CountryRepositoryTab from '../Tabs/CountryRepositoryTab/CountryRepositoryTab';
import ObservationsTab from '../Tabs/ObservationsTab/ObservationsTab';
import CropStagesTab from '../Tabs/CropStagesTab/CropStagesTab';
import CropsTab from '../Tabs/CropsTab/CropsTab';
import DistrictsTab from '../Tabs/DistrictsTab/DistrictsTab';
import PhenophasesTab from '../Tabs/PhenophasesTab/PhenophasesTab';
import PhenoclassesTab from '../Tabs/PhenoclassesTab/PhenoclassesTab';
import InfectionsTab from '../Tabs/InfectionsTab/InfectionsTab';
import CultTypesTab from '../Tabs/CultTypesTab/CultTypesTab';
import CultsTab from '../Tabs/CultsTab/CultsTab';
import CntrqobjTab from '../Tabs/CntrqobjTab/CntrqobjTab';
import UqpqobjTab from '../Tabs/UqpqobjTab/UqpqobjTab';
import RegionsTab from '../Tabs/RegionsTab/RegionsTab';
import NormatDocSpecificTab from '../Tabs/NormatDocSpecificTab/NormatDocSpecificTab';
import QuarantFitoZone from '../RequiredForm/Points/QuarantFitoZone/QuarantFitoZone';


const MainTabs: FC = () => {

    const [currentTab, setCurrentCard] = useState<ITab | null>(null)
    const [tabWidth, setTabWidth] = useState<React.CSSProperties>({})

    const tabContainer = useRef<HTMLDivElement>(null) //needed for optimization tab width

    const {tabs} = useTypedSelector(state => state.tab)
    const {DeleteTab, MovesTabs} = useActions()

    useEffect(() => {
        const optimizedWidthTab = () => {
            const tabContainerWidth = tabContainer.current?.offsetWidth
            let tabWidth = '176'
            if (tabContainerWidth) {
                if (tabContainerWidth / (+tabWidth * tabs.length) < 1) {
                    tabWidth = ((tabContainerWidth / tabs.length) - 26).toString()
                    setTabWidth({minWidth: tabWidth + 'px'})
                    return
                }
            }
            setTabWidth({})
            return
        }
        optimizedWidthTab()
    }, [tabs.length])

    useEffect(() => {
        const lastElement = (document?.querySelector(".react-tabs__tab-list")?.lastChild as HTMLElement)
        lastElement && lastElement.click()
    }, [tabs.length])

    const dragStartHandler = (e: React.DragEvent<HTMLLIElement>, tab: ITab) => {
        (e.target as HTMLLIElement).click();
        setCurrentCard(tab)
    }

    const dragOverhandler = (e: React.DragEvent<HTMLLIElement>) => {
        e.preventDefault();
    }

    const dropHandler = (e: React.DragEvent<HTMLLIElement>, tab: ITab) => {
        e.preventDefault();
        MovesTabs(tab, currentTab as ITab);
        (e.target as HTMLLIElement).click();
    }

    const handleForceRender = (tabType: TabTypes) => {
        switch (tabType) {
            case TabTypes.REQUIREMENT_TAB:
                return true
            case TabTypes.REQUIREMENT_SPECIFIC_TAB:
                return true
            case TabTypes.SPECIFIC_UQUARANTPROD_TAB:
                return true
            default:
                return false
        }
    }

    return (
        <div className="tabs-container" ref={tabContainer}>
            {tabs.length !== 0 &&
                (<Tabs>
                    <TabList>
                        {tabs.sort((a, b) => {
                            return tabs.indexOf(a) - tabs.indexOf(b)
                        }).filter(tab => tab.type !== TabTypes.QUARANT_OBJ_BY_REQUIREMENT).map((tab) => <Tab
                                key={tab.id}
                                draggable={true}
                                onDragStart={(e) => dragStartHandler(e, tab)}
                                onDragOver={(e) => dragOverhandler(e)}
                                onDrop={(e) => dropHandler(e, tab)}
                                style={tabWidth}
                            >
                                <div className="tab-text">{tab.title}</div>
                                <div className="close" onClick={(e) => {
                                    e.stopPropagation();
                                    DeleteTab(tab.id)
                                }}>
                                    <FontAwesomeIcon icon={faTimes}/>
                                </div>
                            </Tab>
                        )}
                    </TabList>
                    {tabs.map((tab, idx) => {
                        if (tab.type === TabTypes.QUARANT_OBJ_BY_REQUIREMENT) {
                            return <React.Fragment key={tab.id} />
                        }
                        return <TabPanel key={tab.id} forceRender={handleForceRender(tab.type)}>
                            {tab.type === TabTypes.REQUIREMENT_TAB &&
                                <RequirementsTab form={(tab as unknown as IRequirementsTab).addReqForm} idTab={idx}
                                                 content={tab.content as IRequirementsTab}/>}
                            {tab.type === TabTypes.NORMAT_DOC_TAB &&
                                <NormatDocTab form={(tab as unknown as INormatDocTab).addNormatdocForm} idTab={idx}
                                              content={tab.content as INormatDocTab}/>}
                            {tab.type === TabTypes.NORMAT_DOC_SPECIFIC_TAB && <NormatDocSpecificTab
                                idTab={idx}
                                normatDocForm={(tab as ISpecificNormatDocFormTab).normatDocForm}
                            />}
                            {tab.type === TabTypes.REQUIREMENT_SPECIFIC_TAB  &&
                                <TabSpecificRequirementContext.Provider value={tab as ISpecificRequirementsTab}>
                                    <TabQuarantObjByRequirement.Provider value={tabs[idx+1] as IQuarantObjByRequirementTab}>
                                        <RequirementSpecificTab
                                            content={tab.content as IContentTable}
                                            idTab={idx}
                                            mainForm={(tab as ISpecificRequirementsTab).mainForm}
                                        />
                                    </TabQuarantObjByRequirement.Provider>
                                </TabSpecificRequirementContext.Provider>
                            }
                            {tab.type === TabTypes.MUNITS_TAB && <MunitsTab
                                idTab={idx}
                                content={tab.content as IContentTable}
                                limit={tab.limit as number}
                                page={tab.page as number}
                                sortColumn={tab.sortColumn as string}
                                sortType={tab.sortType as string}
                                filters={tab.filters as { field: string, value: string }[]}
                                form={tab.form as { [key: string]: any }}
                            />}
                            {tab.type === TabTypes.OTHERSOBJS_TAB && <OthersObjsTab
                                form={tab.form as { [key: string]: any }}
                                idTab={idx}
                                content={tab.content as IContentTable}
                                limit={tab.limit as number}
                                page={tab.page as number}
                                sortColumn={tab.sortColumn as string}
                                sortType={tab.sortType as string}
                                filters={tab.filters as { field: string, value: string }[]}
                            />}
                            {tab.type === TabTypes.CODETNVEDS_TAB && <CodeTnvedTab
                                form={tab.form as { [key: string]: any }}
                                idTab={idx}
                                content={tab.content as IContentTable}
                                limit={tab.limit as number}
                                page={tab.page as number}
                                sortColumn={tab.sortColumn as string}
                                sortType={tab.sortType as string}
                                filters={tab.filters as { field: string, value: string }[]}
                            />}
                            {tab.type === TabTypes.REGIONS_TAB && <RegionsTab
                                form={tab.form as { [key: string]: any }}
                                idTab={idx}
                                content={tab.content as IContentTable}
                                limit={tab.limit as number}
                                page={tab.page as number}
                                sortColumn={tab.sortColumn as string}
                                sortType={tab.sortType as string}
                                filters={tab.filters as { field: string, value: string }[]}
                            />}
                            {tab.type === TabTypes.UQUARANTOBJ_TAB && <UquarantObjTab
                                form={tab.form as { [key: string]: any }}
                                idTab={idx}
                                content={tab.content as IContentTable}
                                limit={tab.limit as number}
                                page={tab.page as number}
                                sortColumn={tab.sortColumn as string}
                                sortType={tab.sortType as string}
                                filters={tab.filters as { field: string, value: string }[]}
                            />}
                            {tab.type === TabTypes.CLWRLDCNTR_TAB && <ClwrldcntrTab
                                form={tab.form as { [key: string]: any }}
                                idTab={idx}
                                content={tab.content as IContentTable}
                                limit={tab.limit as number}
                                page={tab.page as number}
                                sortColumn={tab.sortColumn as string}
                                sortType={tab.sortType as string}
                                filters={tab.filters as { field: string, value: string }[]}
                            />}
                            {tab.type === TabTypes.EXPORTERS_TAB && <ExportersTab
                                form={tab.form as { [key: string]: any }}
                                idTab={idx}
                                content={tab.content as IContentTable}
                                limit={tab.limit as number}
                                page={tab.page as number}
                                sortColumn={tab.sortColumn as string}
                                sortType={tab.sortType as string}
                                filters={tab.filters as { field: string, value: string }[]}
                            />}
                            {tab.type === TabTypes.QUARANTOBJ_TAB && <QuarantobjTab
                                form={tab.form as { [key: string]: any }}
                                idTab={idx}
                                content={tab.content as IContentTable}
                                limit={tab.limit as number}
                                page={tab.page as number}
                                sortColumn={tab.sortColumn as string}
                                sortType={tab.sortType as string}
                                filters={tab.filters as { field: string, value: string }[]}
                            />}
                            {tab.type === TabTypes.SAMPLREGPR_TAB && <SamplregprTab
                                form={tab.form as { [key: string]: any }}
                                idTab={idx}
                                content={tab.content as IContentTable}
                                limit={tab.limit as number}
                                page={tab.page as number}
                                sortColumn={tab.sortColumn as string}
                                sortType={tab.sortType as string}
                                filters={tab.filters as { field: string, value: string }[]}
                            />}
                            {tab.type === TabTypes.TPQUARANTOBJ_TAB && <TpquarantobjTab
                                form={tab.form as { [key: string]: any }}
                                idTab={idx}
                                content={tab.content as IContentTable}
                                limit={tab.limit as number}
                                page={tab.page as number}
                                sortColumn={tab.sortColumn as string}
                                sortType={tab.sortType as string}
                                filters={tab.filters as { field: string, value: string }[]}
                            />}
                            {tab.type === TabTypes.PLACESIMP_TAB && <PlacesimpTab
                                form={tab.form as { [key: string]: any }}
                                idTab={idx}
                                content={tab.content as IContentTable}
                                limit={tab.limit as number}
                                page={tab.page as number}
                                sortColumn={tab.sortColumn as string}
                                sortType={tab.sortType as string}
                                filters={tab.filters as { field: string, value: string }[]}
                            />}
                            {tab.type === TabTypes.UQUARANTPROD_TAB && <UquarantprodTab
                                form={tab.form as { [key: string]: any }}
                                idTab={idx}
                                content={tab.content as IContentTable}
                                limit={tab.limit as number}
                                page={tab.page as number}
                                sortColumn={tab.sortColumn as string}
                                sortType={tab.sortType as string}
                                filters={tab.filters as { field: string, value: string }[]}
                            />}
                            {tab.type === TabTypes.KINDUQPROD_TAB && <KinduqprodTab
                                form={tab.form as { [key: string]: any }}
                                idTab={idx}
                                content={tab.content as IContentTable}
                                limit={tab.limit as number}
                                page={tab.page as number}
                                sortColumn={tab.sortColumn as string}
                                sortType={tab.sortType as string}
                                filters={tab.filters as { field: string, value: string }[]}
                            />}
                            {tab.type === TabTypes.GRUQPROD_TAB && <GruqprodTab
                                form={tab.form as { [key: string]: any }}
                                idTab={idx}
                                content={tab.content as IContentTable}
                                limit={tab.limit as number}
                                page={tab.page as number}
                                sortColumn={tab.sortColumn as string}
                                sortType={tab.sortType as string}
                                filters={tab.filters as { field: string, value: string }[]}
                            />}
                            {tab.type === TabTypes.DOCTYPES_TAB && <DoctypesTab
                                form={tab.form as { [key: string]: any }}
                                idTab={idx}
                                content={tab.content as IContentTable}
                                limit={tab.limit as number}
                                page={tab.page as number}
                                sortColumn={tab.sortColumn as string}
                                sortType={tab.sortType as string}
                                filters={tab.filters as { field: string, value: string }[]}
                            />}
                            {tab.type === TabTypes.TPOTHERSOBJ_TAB && <TpothersObjTab
                                form={tab.form as { [key: string]: any }}
                                idTab={idx}
                                content={tab.content as IContentTable}
                                limit={tab.limit as number}
                                page={tab.page as number}
                                sortColumn={tab.sortColumn as string}
                                sortType={tab.sortType as string}
                                filters={tab.filters as { field: string, value: string }[]}
                            />}
                            {tab.type === TabTypes.SPECIFIC_UQUARANTPROD_TAB &&
                                <TabSpecificUquarantObjContext.Provider value={tab as ISpecificUquarantObjTab}>
                                    <SpecificUquarantTab idTab={idx}/>
                                </TabSpecificUquarantObjContext.Provider>
                            }
                            {tab.type === TabTypes.QUARANT_FITO_ZONE_TAB &&
                                <TabQuarantFitoZoneContext.Provider value={tab as IQuarantFitoZoneTab}>
                                    <QuarantFitoZone idTab={idx}/>
                                </TabQuarantFitoZoneContext.Provider>
                            }
                            {tab.type === TabTypes.UQPQOBJ_TAB && <UqpqobjTab
                                form={tab.form as { [key: string]: any }}
                                idTab={idx}
                                content={tab.content as IContentTable}
                                limit={tab.limit as number}
                                page={tab.page as number}
                                sortColumn={tab.sortColumn as string}
                                sortType={tab.sortType as string}
                                filters={tab.filters as { field: string, value: string }[]}
                            />}
                            {tab.type === TabTypes.CNTRQOBJ_TAB && <CntrqobjTab
                                form={tab.form as { [key: string]: any }}
                                idTab={idx}
                                content={tab.content as IContentTable}
                                limit={tab.limit as number}
                                page={tab.page as number}
                                sortColumn={tab.sortColumn as string}
                                sortType={tab.sortType as string}
                                filters={tab.filters as { field: string, value: string }[]}
                            />}
                            {tab.type === TabTypes.CULTS_TAB && <CultsTab
                                form={tab.form as { [key: string]: any }}
                                idTab={idx}
                                content={tab.content as IContentTable}
                                limit={tab.limit as number}
                                page={tab.page as number}
                                sortColumn={tab.sortColumn as string}
                                sortType={tab.sortType as string}
                                filters={tab.filters as { field: string, value: string }[]}
                            />}
                            {tab.type === TabTypes.CULTTYPES_TAB && <CultTypesTab
                                form={tab.form as { [key: string]: any }}
                                idTab={idx}
                                content={tab.content as IContentTable}
                                limit={tab.limit as number}
                                page={tab.page as number}
                                sortColumn={tab.sortColumn as string}
                                sortType={tab.sortType as string}
                                filters={tab.filters as { field: string, value: string }[]}
                            />}
                            {tab.type === TabTypes.INFECTIONS_TAB && <InfectionsTab
                                form={tab.form as { [key: string]: any }}
                                idTab={idx}
                                content={tab.content as IContentTable}
                                limit={tab.limit as number}
                                page={tab.page as number}
                                sortColumn={tab.sortColumn as string}
                                sortType={tab.sortType as string}
                                filters={tab.filters as { field: string, value: string }[]}
                            />}
                            {tab.type === TabTypes.PHENOCLASSES_TAB && <PhenoclassesTab
                                form={tab.form as { [key: string]: any }}
                                idTab={idx}
                                content={tab.content as IContentTable}
                                limit={tab.limit as number}
                                page={tab.page as number}
                                sortColumn={tab.sortColumn as string}
                                sortType={tab.sortType as string}
                                filters={tab.filters as { field: string, value: string }[]}
                            />}
                            {tab.type === TabTypes.PHENOPHASES_TAB && <PhenophasesTab
                                form={tab.form as { [key: string]: any }}
                                idTab={idx}
                                content={tab.content as IContentTable}
                                limit={tab.limit as number}
                                page={tab.page as number}
                                sortColumn={tab.sortColumn as string}
                                sortType={tab.sortType as string}
                                filters={tab.filters as { field: string, value: string }[]}
                            />}
                            {tab.type === TabTypes.DISTRICTS_TAB && <DistrictsTab
                                form={tab.form as { [key: string]: any }}
                                idTab={idx}
                                content={tab.content as IContentTable}
                                limit={tab.limit as number}
                                page={tab.page as number}
                                sortColumn={tab.sortColumn as string}
                                sortType={tab.sortType as string}
                                filters={tab.filters as { field: string, value: string }[]}
                            />}
                            {tab.type === TabTypes.CROPS_TAB && <CropsTab
                                form={tab.form as { [key: string]: any }}
                                idTab={idx}
                                content={tab.content as IContentTable}
                                limit={tab.limit as number}
                                page={tab.page as number}
                                sortColumn={tab.sortColumn as string}
                                sortType={tab.sortType as string}
                                filters={tab.filters as { field: string, value: string }[]}
                            />}
                            {tab.type === TabTypes.CROPSTAGES_TAB && <CropStagesTab
                                form={tab.form as { [key: string]: any }}
                                idTab={idx}
                                content={tab.content as IContentTable}
                                limit={tab.limit as number}
                                page={tab.page as number}
                                sortColumn={tab.sortColumn as string}
                                sortType={tab.sortType as string}
                                filters={tab.filters as { field: string, value: string }[]}
                            />}
                            {tab.type === TabTypes.OBSERVATIONS_TAB && <ObservationsTab
                                form={tab.form as { [key: string]: any }}
                                idTab={idx}
                                content={tab.content as IContentTable}
                                limit={tab.limit as number}
                                page={tab.page as number}
                                sortColumn={tab.sortColumn as string}
                                sortType={tab.sortType as string}
                                filters={tab.filters as { field: string, value: string }[]}
                            />}
                            {tab.type === TabTypes.COUNTRYREPOSITORY_TAB && <CountryRepositoryTab
                                form={tab.form as { [key: string]: any }}
                                idTab={idx}
                                content={tab.content as IContentTable}
                                limit={tab.limit as number}
                                page={tab.page as number}
                                sortColumn={tab.sortColumn as string}
                                sortType={tab.sortType as string}
                                filters={tab.filters as { field: string, value: string }[]}
                            />}
                            {tab.type === TabTypes.DOCUMENTSREPOSITORY_TAB && <DocumentsRepositoryTab
                                idTab={idx}
                                documents={tab.documents}
                                pid={tab.pid}
                            />}
                            {tab.type === TabTypes.REQUIREMENTSHISTORY_TAB && <RequirementsHistoryTab
                                form={tab.form as { [key: string]: any }}
                                idTab={idx}
                                content={tab.content as IContentTable}
                                limit={tab.limit as number}
                                page={tab.page as number}
                                sortColumn={tab.sortColumn as string}
                                sortType={tab.sortType as string}
                                filters={tab.filters as { field: string, value: string }[]}
                            />}
                        </TabPanel>
                    })}
                </Tabs>)}
        </div>
    );
};

export default MainTabs;

