import React from 'react';

const ErrorPage = () => {
    return (
        <div style={{position: 'relative', width: '100vw', height: '100vh'}}>
            <h1 style={{ textAlign: 'center', 
            margin: '0', 
            position: 'absolute', 
            top: '50%', 
            left: '50%', 
            marginRight: '-50%', 
            transform: 'translate(-50%, -50%)',
            fontSize: '14px' }}>Произошла ошибка, попробуйте позже</h1>
        </div>

    );
};

export default ErrorPage;