import React, {ChangeEvent, FC} from 'react';
import {Controller} from "react-hook-form";

import './InputWithLabel.scss'

interface InputWithLabelProps {
    widthInputBlock: number | string
    widthLabel: number | string
    widthInput: number | string
    label: string
    nameForm?: string
    onChangeInput?: (event: ChangeEvent<HTMLInputElement>) => void
    nameField?: string
    value?: string
    control?: any
    rules?: any
    onDiffDate?: (value: string, fieldName: string) => void
    onBlurInput?: (event: ChangeEvent<HTMLInputElement>) => void
    readonly?: boolean
}

const InputWithLabel: FC<InputWithLabelProps> = ({
                                                     label,
                                                     widthInput,
                                                     widthLabel,
                                                     widthInputBlock,
                                                     nameField,
                                                     onChangeInput,
                                                     value,
                                                     control,
                                                     rules,
                                                     onBlurInput,
                                                     readonly,
                                                     onDiffDate
                                                 }) => {

    return (
        <div className='input-with-label-block'>
            <p className='input-with-label-block__text' style={{width: widthLabel}}>{label}</p>
            <div style={{width: widthInputBlock}}>
                <Controller
                    control={control}
                    name={nameField as string}
                    rules={rules}
                    render={({field: {onChange, onBlur, ref, name, value: inputValue}, fieldState: {error}}) =>
                        <>
                            <input type="text" style={{width: widthInput}}
                                   className={`input-with-label-block_input ${readonly ? 'grey' : ''}`}
                                   onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                       console.log(e.target.value)
                                       onChange(e);
                                       onChangeInput && onChangeInput(e);
                                       onDiffDate && onDiffDate(e.target.value, e.target.name)
                                   }}
                                   onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                                       onBlur()
                                       onBlurInput && onBlurInput(e)
                                   }}
                                   value={value ? value : ''}
                                   name={name}
                                   readOnly={readonly}
                            />
                            <p style={{color: 'red'}}>{error && error.message}</p>
                        </>
                    }
                />
            </div>
        </div>
    );
};

export default InputWithLabel;