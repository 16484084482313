import React from 'react';
import { useForm } from 'react-hook-form';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { StyledEngineProvider } from '@mui/material/styles';
import InputWithLabel from '../../RequiredForm/Inputs/InputWithLabel/InputWithLabel';
import TnvedPoint from './TnvedPoint/TnvedNDPoint';
import QuarantObjPoint from './QuarantObjPoint/QuarantObjNDPoint';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PpPoint = () => {

    const [tabValue, setTabValue] = React.useState<string>('ТНВЭД');

    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        event.preventDefault()
        event.stopPropagation()
        setTabValue(newValue)
    }

    const { control } = useForm()

    return (
        <>
            <div style={{
                display: 'grid',
                gridTemplateRows: '1fr',
                gridTemplateColumns: '1.4fr 1fr 1fr',
                height: '100%',
                gridGap: '0 5vw',
                border: '1px solid #B7BCC9',
                padding: '10px'
            }}>
                <div style={{
                    gridRowStart: 1,
                    gridRowEnd: 2,
                    gridColumnStart: 1,
                    gridColumnEnd: 2
                }}>
                    <InputWithLabel label={'Подкарантинная продукция'} widthInput={'100%'} widthLabel={'45%'} widthInputBlock={'55%'} control={control} />
                </div>

                <div style={{
                    gridRowStart: 1,
                    gridRowEnd: 2,
                    gridColumnStart: 2,
                    gridColumnEnd: 3
                }}>
                    <InputWithLabel label={'Группа'} widthInput={'100%'} widthLabel={'30%'} widthInputBlock={'70%'} control={control} />
                </div>

                <div style={{
                    gridRowStart: 1,
                    gridRowEnd: 2,
                    gridColumnStart: 3,
                    gridColumnEnd: 4
                }}>
                    <InputWithLabel label={'Род'} widthInput={'100%'} widthLabel={'30%'} widthInputBlock={'70%'} control={control} />
                </div>


                <div style={{
                    gridRowStart: 2,
                    gridRowEnd: 3,
                    gridColumnStart: 1,
                    gridColumnEnd: 4
                }}>
                    <StyledEngineProvider injectFirst>

                        <TabContext value={tabValue}>
                            <TabList onChange={handleChangeTab}>
                                <Tab label="ТНВЭД" value="ТНВЭД" />
                                <Tab label="Карантинные объекты" value="Карантинные объекты" />
                            </TabList>
                            <TabPanel value="ТНВЭД"><TnvedPoint /></TabPanel>
                            <TabPanel value="Карантинные объекты"><QuarantObjPoint /></TabPanel>
                        </TabContext>

                    </StyledEngineProvider >

                </div>

            </div>
            <FontAwesomeIcon className="plus-icon" size="2x" icon={faPlus} />
        </>
    );
};

export default PpPoint;