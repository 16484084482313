import React, {ChangeEvent, FC, memo, useEffect, useRef, useState} from 'react';
import { Cell } from 'rsuite-table';
import './EditCell.scss'
import {useDebounce} from "../../hooks/useDebounce";


interface EditCellProps {
    idTab: number
    onChange?: (idRow: number, dataKey: string, val: string) => void
    dataKey: string
    rowData?: any
    rowIndex?: number
    readonly?: boolean | ((data: Record<string, any>) => boolean)
    readonlyColor?: string
    idName: string
    validateFunc?: (event: ChangeEvent<HTMLInputElement>) => string
    computedValue?: (data: {[key: string]: any}) => string
}

const EditCell: FC<EditCellProps> = ({ idTab, rowData, rowIndex, dataKey, onChange, idName, readonly, readonlyColor, computedValue, validateFunc, ...props }) => {

    const [value, setValue] = useState( checkValue())
    const debounced = useDebounce(value)

    useEffect(() => {
        if (!rowData[dataKey])  return
        setValue(rowData[dataKey].toString() ? rowData[dataKey] as string : '')
    }, [rowData, dataKey])

    useEffect(() => {
        onChange && onChange(rowIndex! , dataKey, debounced);
    }, [debounced, dataKey, rowIndex, onChange])

    function checkValue() {
        if (rowData[dataKey]) {
            return rowData[dataKey].toString() ? rowData[dataKey] as string : ''
        } else {
            return ''
        }
    }

    const styles = typeof readonly === 'function' ? readonly(rowData) ? {backgroundColor: readonlyColor} : undefined : undefined


    return (
        <Cell {...props} className="table-content-editing">
            <input
                className={`my-rs-input ${typeof readonly === 'function' ? '' : readonly ? 'readonly' : ''}`}
                value={computedValue ? computedValue(rowData) : value}
                onChange={event => {
                    setValue(validateFunc ? validateFunc(event) : event.target.value)
                }}
                readOnly={typeof readonly === 'function' ? readonly(rowData) : readonly}
                style={styles}
            />
        </Cell>
    );
};

export default EditCell;