import {Dispatch} from "redux"
import {
    SpecificRequirementsTabAction,
    SpecificRequirementsTabActionsType
} from "../../interfaces/SpecificRequirementsTabActions"

export const AddCountry = (idTab: number, country: string) => {
    return (dispatch: Dispatch<SpecificRequirementsTabAction>) => {
        dispatch({
            type: SpecificRequirementsTabActionsType.ADD_COUNTRY, payload: {
                idTab,
                country
            }
        })
    }
}

export const AddCounterpartie = (idTab: number, counterparty: string) => {
    return (dispatch: Dispatch<SpecificRequirementsTabAction>) => {
        dispatch({
            type: SpecificRequirementsTabActionsType.ADD_COUNTERPARTIES, payload: {
                idTab,
                counterparty
            }
        })
    }
}

export const DeleteCountry = (idTab: number, idTag: number) => {
    return (dispatch: Dispatch<SpecificRequirementsTabAction>) => {
        dispatch({
            type: SpecificRequirementsTabActionsType.DELETE_COUNTRY, payload: {
                idTab,
                idTag
            }
        })
    }
}

export const DeleteCounterparties = (idTab: number, idTag: number) => {
    return (dispatch: Dispatch<SpecificRequirementsTabAction>) => {
        dispatch({
            type: SpecificRequirementsTabActionsType.DELETE_COUNTERPARTIES, payload: {
                idTab,
                idTag
            }
        })
    }
}

export const SetNumber = (idTab: number, value: string) => {
    return (dispatch: Dispatch<SpecificRequirementsTabAction>) => {
        dispatch({
            type: SpecificRequirementsTabActionsType.SET_NUMBER, payload: {
                idTab,
                value
            }
        })
    }
}

export const SetTip = (idTab: number, value: string) => {
    return (dispatch: Dispatch<SpecificRequirementsTabAction>) => {
        dispatch({
            type: SpecificRequirementsTabActionsType.SET_TIP, payload: {
                idTab,
                value
            }
        })
    }
}

export const SetStartDate = (idTab: number, date: string) => {
    return (dispatch: Dispatch<SpecificRequirementsTabAction>) => {
        dispatch({
            type: SpecificRequirementsTabActionsType.SET_START_DATE, payload: {
                idTab,
                date
            }
        })
    }
}

export const SetEndDate = (idTab: number, date: string) => {
    return (dispatch: Dispatch<SpecificRequirementsTabAction>) => {
        dispatch({
            type: SpecificRequirementsTabActionsType.SET_END_DATE, payload: {
                idTab,
                date
            }
        })
    }
}
