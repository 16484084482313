import React, {FC, useState} from 'react';
import {SortType} from 'rsuite-table';
import ToolBar from '../../ToolBar/ToolBar';
import CustomTable, {AutocompleteCellTypes} from '../../CustomTable/CustomTable'
import {TabPanel} from '../../../interfaces/TabInterface';
import {UseFormSetError} from 'react-hook-form';
import FormBottomTable from "../../Forms/FormBottomTable/FormBottomTable";
import {ExportersForm} from "../../../shared/form.table.enviroment";

interface ExportersTabProps extends TabPanel {
}

const ClwrldcntrTab: FC<ExportersTabProps> = ({idTab, limit, page, sortColumn, sortType, content, filters, form}) => {
    const [toggleForm, setToggleForm] = useState<boolean>(false)

    const handleValidationInnUnicCodeInputForm = (
        data: { [key: string]: string },
        setError: UseFormSetError<{ [key: string]: any; }>
    ): boolean => {
        if (data['nea_uncode'] === '' && data['nea_inn'] === '') {
            setError('nea_uncode', {
                type: 'required',
                message: 'Поле Уникальный код или ИНН должно быть заполнено'
            })
            setError('nea_inn', {
                type: 'required'
            })
            return false
        }
        return true
    }

    return (
        <div className="tab-wrap">
            <div className="table-container">
                <CustomTable
                    api={{
                        post: '/v_exporterss/all',
                        put: '/exporterss'
                    }}
                    idName={'iea_id'}
                    headerHeight={80}
                    idTab={idTab}
                    items={content?.items ? content.items : []}
                    limit={limit}
                    page={page}
                    sortType={sortType as SortType}
                    sortColumn={sortColumn}
                    totalItems={content?.totalItems}
                    filters={filters}
                    postForm={{
                        sea_code: '',
                        sea_name: '',
                        nea_uncode: '',
                        nea_inn: '',
                        irg_regions_id: '',
                        icn_clwrldcntr_id: '',
                        sea_addrprod: '',
                        sea_addrjur: ''
                    }}
                    setting={[
                        {
                            key: 'sea_code',
                            label: 'Краткое наименование',
                            width: 200,
                            filtered: true
                        },
                        {
                            key: 'sea_name',
                            label: 'Полное наименование',
                            width: 250,
                            sortable: true,
                        },
                        {
                            key: 'nea_uncode',
                            label: 'Уникальный код',
                            width: 250,
                            sortable: true,
                            filtered: true
                        },
                        {
                            key: 'nea_inn',
                            label: 'ИНН',
                            width: 250,
                            filtered: true
                        },
                        {
                            key: 'sea_addrprod',
                            label: 'Адрес производства',
                            width: 250
                        },
                        {
                            key: 'sea_addrjur',
                            label: 'Адрес юридический',
                            width: 250
                        },
                        {
                            key: 'srg_name',
                            label: 'Регион',
                            width: 250,
                            autocomplete: AutocompleteCellTypes.DEFAULT,
                            api: '/v_regionss/join'
                        },
                        {
                            key: 'ncn_numcode',
                            label: 'Цифровой код страны',
                            width: 250,
                            readonly: true
                        },
                        {
                            key: 'scn_code',
                            label: 'Страна',
                            width: 250,
                            autocomplete: AutocompleteCellTypes.DEFAULT,
                            api: '/v_clwrldcntrs/join'
                        },
                        {
                            key: 'scn_name',
                            label: 'Полное наименование страны',
                            width: 250,
                            readonly: true
                        },
                        {
                            key: 'scn_letcode2',
                            label: 'Буквенный код 2',
                            width: 250,
                            readonly: true
                        },
                        {
                            key: 'scn_letcode3',
                            label: 'Буквенный код 3',
                            width: 250,
                            readonly: true
                        }
                    ]}
                />
                <FormBottomTable
                    open={toggleForm} onToggleForm={() => setToggleForm(prev => !prev)}
                    fields={ExportersForm}
                    api='/exporters'
                    idTab={idTab}
                    form={form}
                    idName='iea_id'
                    postForm={{
                        sea_code: '',
                        sea_name: '',
                        nea_uncode: '',
                        nea_inn: '',
                        irg_regions_id: '',
                        icn_clwrldcntr_id: '',
                        sea_addrprod: '',
                        sea_addrjur: ''
                    }}
                    validateFunc={handleValidationInnUnicCodeInputForm}
                />
            </div>
            <ToolBar idTab={idTab} toggleForm={() => setToggleForm(state => !state)} api="/exporters/"
                     idName={'iea_id'} tableNameExport={'v_exporters'}/>
        </div>
    );
};

export default ClwrldcntrTab;