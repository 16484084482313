import { ChangeEvent } from "react"
import { useActions } from './useAction'

export const useInputChange = (idTab: number, form: string, dopField?: string) => {
    const { SetReqFormValue } = useActions()
    return (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        SetReqFormValue(idTab, form, event.target.name, event.target.value, dopField)
    }
}

export const useCheckboxChange = (idTab: number, form: string) => {
    const {SetReqFormToggleCheker} = useActions()
    return (event: ChangeEvent<HTMLInputElement>) => {
        SetReqFormToggleCheker(idTab, form, event.target.name)
    }
}

export const useOtherChange = (idTab: number, form: string) => {
    const { SetReqFormValue } = useActions()
    return (data: string, nameField: string, dopField?: string | number) => {
        SetReqFormValue(idTab, form, nameField, data, dopField as string)
    }
}

export const useTagsChange = (idTab: number, formName: string, tagsName: string) => {
    const {AddTag} = useActions()
    return (tag: {[key: string]: any}, nameIdTag: string, nameValueTag: string) => {
        AddTag(idTab, formName, tag, nameIdTag, tagsName, nameValueTag)
    }
}

export const usePlusClick = (idTab: number, nameForm: string, nameTags: string, tag: {[key: string]: any;}) => {
    const {AddFields} = useActions()
    return () => {
        AddFields(idTab, nameForm, nameTags, tag)
    }
}

export const useArrayFieldChangeAutocomplete = (idTab: number, nameForm: string, nameTag: string) => {
    const {SetArrayFieldValue} = useActions()
    return (idTag: number) => {
        return (value: string, nameField: string) => {
            SetArrayFieldValue(idTab, nameForm, nameTag, nameField, value, idTag as number)
        } 
    }
}

export const useArrayFieldChangeInput = (idTab: number, nameForm: string, nameTag: string) => {
    const {SetArrayFieldValue} = useActions()
    return (idTag: number) => {
        return (event: ChangeEvent<HTMLInputElement>) => {
            SetArrayFieldValue(idTab, nameForm, nameTag, event.target.name, event.target.value, idTag as number)
        } 
    }
}

export const useArrayFieldChangeDatePicker = (idTab: number, nameForm: string, nameTag: string) => {
    const {SetArrayFieldValue} = useActions()
    return (idTag: number) => {
        return (value: string, nameField: string) => {
            SetArrayFieldValue(idTab, nameForm, nameTag, nameField, value, idTag as number)
        }
    }
}

export const useArrayFieldChangeCheckbox = (idTab: number, nameForm: string, nameTag: string) => {
    const {SetArrayFieldValue} = useActions()
    return (idTag: number) => {
        return (event: ChangeEvent<HTMLInputElement>) => {
            SetArrayFieldValue(idTab, nameForm, nameTag, event.target.name, event.target.checked, idTag)
        }
    }
}

