import React from 'react';
import './App.css';
import MainPage from './pages/MainPage'
import ErrorPage from './pages/error'
import LogoutPage from './pages/logout'
import ReactNotification from 'react-notifications-component';
import {Route} from 'react-router-dom';
import AuthProvider from "./hoc/AuthProvider";
import PrivateRoute from "./routes/PrivateRoute";

function App() {
    return (
        <div className="container">
            <AuthProvider>
                <ReactNotification />
                <Route path="/" exact>
                    <PrivateRoute>
                        <MainPage/>
                    </PrivateRoute>
                </Route>
                <Route path="/error" component={ErrorPage}/>
                <Route path="/logout" component={LogoutPage}/>
            </AuthProvider>
        </div>
    );
}

export default App;
