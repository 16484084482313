import React, {FC} from 'react';

import './Modal.scss'

interface ModalProps {
    isOpen: boolean
    onClose: () => void
    children: React.ReactNode
}
const Modal: FC<ModalProps> = ({isOpen, onClose, children}) => {

    return (
        <>
            {isOpen && <div className={'modal-wrapper'}>
                <div className={'modal-body'}>
                    <span className={'modal-close'} onClick={onClose}>✖</span>
                    {children}
                </div>
            </div>}
        </>
    );
};

export default Modal;