import React, { FC, useState } from 'react';
import { SortType } from 'rsuite-table';
import ToolBar from '../../ToolBar/ToolBar';
import CustomTable from '../../CustomTable/CustomTable';
import {IContentTable, TabPanel} from '../../../interfaces/TabInterface';
import {SampleRegPrForm} from "../../../shared/form.table.enviroment";
import FormBottomTable from "../../Forms/FormBottomTable/FormBottomTable";

interface SamplregprTabProps extends TabPanel {

}

const SamplregprTab: FC<SamplregprTabProps> = ({ idTab, limit, page, sortColumn, sortType, content, filters, form }) => {
    const [toggleForm, setToggleForm] = useState<boolean>(false)

    return (
        <div className="tab-wrap">
            <div className="table-container">
                <CustomTable 
                    api={{
                        post: '/v_samplregprs/all',
                        put: '/samplregprs'
                    }}
                    idName={'isr_id'}
                    headerHeight={80}
                    idTab={idTab}
                    items={content?.items ? content.items : []}
                    limit={limit}
                    page={page}
                    sortType={sortType as SortType}
                    sortColumn={sortColumn}
                    totalItems={content?.totalItems}
                    filters={filters}
                    setting={[
                        {   
                            key: 'ssr_name',
                            label: 'Наименование',
                            flex: 1
                        },
                        {   
                            key: 'ssr_samprule',
                            label: 'Правила отбора образцов',
                            flex: 1,
                            sortable: true,
                        }
                    ]}
                />
                <FormBottomTable
                    /*postForm={{
                        isr_pid: '',
                        igr_gruqprod_id: '',
                        sgr_code: '',
                        sgr_name: ''
                    }}*/
                    open={toggleForm} onToggleForm={() => setToggleForm(prev => !prev)}
                    idName={'isr_id'}
                    form={form}
                    fields={SampleRegPrForm}
                    api="/samplregprs"
                    idTab={idTab}
                />
            </div>
            <ToolBar idTab={idTab} toggleForm={() => setToggleForm(state => !state)} api="/samplregprs/" idName={'isr_id'} tableNameExport={'v_samplregpr'} />
        </div>
    );
};

export default SamplregprTab;