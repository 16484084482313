import React, {FC, useState} from 'react';
import {SortType} from 'rsuite-table';
import ToolBar from '../../ToolBar/ToolBar';
import CustomTable, {AutocompleteCellTypes} from '../../CustomTable/CustomTable'
import {IContentTable, TabPanel} from '../../../interfaces/TabInterface';
import FormBottomTable from "../../Forms/FormBottomTable/FormBottomTable";
import {TpQuarantObjForm} from "../../../shared/form.table.enviroment";

interface TpquarantobjTabProps extends TabPanel {

}

const TpquarantobjTab: FC<TpquarantobjTabProps> = ({
                                                       idTab,
                                                       limit,
                                                       page,
                                                       sortColumn,
                                                       sortType,
                                                       content,
                                                       filters,
                                                       form
                                                   }) => {
    const [toggleForm, setToggleForm] = useState<boolean>(false)

    return (
        <div className="tab-wrap">
            <div className="table-container">
                <CustomTable
                    api={{
                        post: '/v_tpquarantobjs/all',
                        put: '/tpquarantobjs'
                    }}
                    idName={'itq_id'}
                    headerHeight={80}
                    idTab={idTab}
                    items={content?.items ? content.items : []}
                    limit={limit}
                    page={page}
                    sortType={sortType as SortType}
                    sortColumn={sortColumn}
                    totalItems={content?.totalItems}
                    filters={filters}
                    postForm={{
                        stq_code: '',
                        stq_name: '',
                        stq_absent: '',
                        stq_limited: '',
                        itq_id: ''
                    }}
                    setting={[
                        {
                            key: 'stq_code',
                            label: 'Краткое наименование',
                            flex: 1,
                            sortable: true,
                            filtered: true
                        },
                        {
                            key: 'stq_name',
                            label: 'Полное наименование',
                            flex: 1,
                            sortable: true,
                        },
                        {
                            key: 'stq_absent',
                            label: 'Отсутствуют на территории ЕАС',
                            flex: 1,
                            sortable: true,
                            autocomplete: AutocompleteCellTypes.MAIN
                        },
                        {
                            key: 'stq_limited',
                            label: 'Ограничены на территории ЕАС',
                            flex: 1,
                            sortable: true,
                            autocomplete: AutocompleteCellTypes.MAIN
                        }
                    ]}
                />
                <FormBottomTable open={toggleForm} onToggleForm={() => setToggleForm(prev => !prev)} fields={TpQuarantObjForm} api='/tpquarantobjs' idTab={idTab} form={form} idName='itq_id'/>
            </div>
            <ToolBar idTab={idTab} toggleForm={() => setToggleForm(state => !state)} api="/tpquarantobjs/"
                     idName={'itq_id'} tableNameExport={'v_tpquarantobj'}/>
        </div>
    );
};

export default TpquarantobjTab;