import React, {FC} from 'react';

import './AnimatedPlus.scss'

interface AnimatedPlusProps {
    expanded: boolean
    onClick?: () => void
}

const AnimatedPlus: FC<AnimatedPlusProps> = ({expanded, onClick}) => {

    return (
        <div className="wrap-expand-icon" onClick={onClick}>
            <span className={`expand-icon ${expanded ? 'expand' : ''}`}> </span>
        </div>
    );
};

export default AnimatedPlus;