import fetchIntercept from "fetch-intercept";

const registerInterceptor = () => {
    /*Auth.getUser()
        .then(user => {
            if (user)
            {
                fetchIntercept.register({
                    request: function (url, config) {
                        let withDefaults = {}
                        if (config)
                        {
                            withDefaults = Object.assign({}, config, {
                                headers: {
                                    ...config.headers,
                                    'Authorization': `Bearer ${user.access_token}`
                                }
                            });
                        } else {
                            withDefaults = {
                                headers: {
                                    'Authorization': `Bearer ${user.access_token}`
                                }
                            }
                        }
                        return [url, withDefaults]
                    },

                    requestError: function (error) {
                        return Promise.reject(error);
                    },

                    response: function (response) {
                        return response;
                    },

                    responseError: function (error) {
                        return Promise.reject(error);
                    }
                })
            }
        })*/

    fetchIntercept.register({
        request: function (url, config) {
            let withDefaults = {}
            if (config) {
                withDefaults = Object.assign({}, config, {
                    headers: {
                        ...config.headers
                    }
                });
            }
            return [url, withDefaults]
        },

        requestError: function (error) {
            return Promise.reject(error);
        },

        response: function (response) {
            if (response.redirected) {
                window.location.href = response.url
            }
            return response;
        },

        responseError: function (error) {
            return Promise.reject(error);
        }
    })
}
export default registerInterceptor
