import React, {FC, memo, RefObject} from 'react';
import {TagPicker} from "rsuite";
import {RowDataType} from "rsuite-table";
import {TabTypes} from "../../interfaces/TabInterface";
import {PickerInstance} from "rsuite/Picker";

interface MyTagPickerProps {
    data: {
        key: string,
        label: string,
        sortable?: boolean,
        flexGrow?: number,
        width?: number,
        filtered?: boolean,
        readonly?: boolean | ((data: Record<string, any>, color: string) => void),
        autocomplete?: string,
        api?: string
        link?: boolean
        titleNameKey?: string
        tabType?: TabTypes
    }[]
    value: string[]
    onChange:  React.Dispatch<React.SetStateAction<string[]>>
}

const MyTagPicker = React.forwardRef<PickerInstance, MyTagPickerProps>(({data, value, onChange}, ref) => (
        <TagPicker
            ref={ref}
            style={{
                width: '100%',
                marginBottom: '10px'
            }}
            data={data}
            labelKey="label"
            valueKey="key"
            value={value}
            onChange={onChange}
            cleanable={false}
        />
))

export default memo(MyTagPicker);