export enum formsName {
    mainForm = 'mainForm',
    addReqForm = 'addReqForm',
    addNormatdocForm = 'addNormatdocForm',
    normatDocForm = 'normatDocForm'
}

export enum tagsName {
    regionsExp = 'regionsExp',
    regionsReexp = 'regionsReexp',
    cntrExp = 'cntrExp',
    cntrReexp = 'cntrReexp',
    dopDoc = 'dopDoc',
    agnReexp = 'agnReexp',
    agnExp = 'agnExp',
    placeSimp = 'placeSimp',
    tnveds = 'tnveds',
    dopQuals = 'dopQuals',
    rqpququarantobjs = 'rqpququarantobjs',
    rquqprodsById = 'rquqprodsById',
    v_rqaddqualprm = 'v_rqaddqualprm',
    v_rquqprodtnved = 'v_rquqprodtnved',
    v_rqpquarantobj = 'v_rqpquarantobj',
    v_rqpququarantobj = 'v_rqpququarantobj',
    rqcntquarantobjs = 'rqcntquarantobjs'
}