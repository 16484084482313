import React, { FC } from 'react';
import { useCheckboxChange, useInputChange, useOtherChange } from '../../../hooks/useChange';
import { useSaveDefaultField, useSaveNonStandartField, useSaveOtherField } from '../../../hooks/useSave';
import { INormatDocForm } from '../../../interfaces/TabInterface';
import { formsName } from '../../../shared/shared';
import AutocompleteWithLabel from '../../RequiredForm/Inputs/AutocompleteWithLabel/AutocompleteWithLabel';
import CheckBoxWithLabel from '../../RequiredForm/Inputs/CheckBoxWithLabel/CheckBoxWithLabel';
import DataPickersWithLabel from '../../RequiredForm/Inputs/DataPickersWithLabel/DataPickersWithLabel';
import InputWithLabel from '../../RequiredForm/Inputs/InputWithLabel/InputWithLabel';


interface MainNDPointProps {
    idTab: number
    normatDocForm: INormatDocForm
    control: any
}


const MainNDPoint: FC<MainNDPointProps> = ({idTab, normatDocForm, control}) =>{

    const handleChange = useInputChange(idTab, formsName.normatDocForm)
    const handeBlurSaveString = useSaveDefaultField(normatDocForm.normatdoc__ind_id, '0')
    const handleOtherChange = useOtherChange(idTab, formsName.normatDocForm)
    const handleAutocompleteDoc = useSaveNonStandartField(normatDocForm.normatdoc__ind_id, 'normatdoc', 'doctype_id', normatDocForm.normatdoc__ind_id)
    const handleBlurSaveDate = useSaveOtherField(normatDocForm.normatdoc__ind_id, '2')
    const handleCheckerChange = useCheckboxChange(idTab, formsName.normatDocForm)
    const handleBlurSaveCheker = useSaveOtherField(normatDocForm.normatdoc__ind_id, '1')


    
    return (
        <div style={{
            display: 'grid',
            gridTemplateRows: '1fr 1fr 1fr 1fr 1fr 1fr',
            gridTemplateColumns: '1fr 1fr 1fr',
            height: '100%',
            gridGap: '0 5vw'
        }}>
            <div style={{
                gridRowStart: 1,
                gridRowEnd: 2,
                gridColumnStart: 1,
                gridColumnEnd: 4
            }}>
                <InputWithLabel widthInput={'100%'} widthInputBlock={'90%'} widthLabel={'10%'} label={"Наименование"}
                    control={control}
                    nameField="normatdoc__name"
                    onChangeInput={handleChange}
                    value={normatDocForm.normatdoc__name}
                    onBlurInput={handeBlurSaveString}
                />
            </div>

            <div style={{
                gridRowStart: 2,
                gridRowEnd: 3,
                gridColumnStart: 1,
                gridColumnEnd: 2
            }}>
                <AutocompleteWithLabel widthInput={'100%'} widthInputBlock={'65%'} widthLabel={'35%'} label={"Документ"}
                    control={control}
                    nameField="sdt_code"
                    onChangeInput={handleOtherChange}
                    value={normatDocForm.normatdoc__sdt_code}
                    api={'/v_doctypess/join'}
                    onBlurInput={handleAutocompleteDoc}
                />
            </div>

            <div style={{
                gridRowStart: 2,
                gridRowEnd: 3,
                gridColumnStart: 2,
                gridColumnEnd: 3
            }}>
                <InputWithLabel widthInput={'80%'} widthInputBlock={'90%'} widthLabel={'10%'} label={"№"}
                    control={control}
                    nameField="normatdoc__docnumb"
                    onChangeInput={handleChange}
                    value={normatDocForm.normatdoc__docnumb}
                    onBlurInput={handeBlurSaveString}
                />
            </div>

            <div style={{
                gridRowStart: 2,
                gridRowEnd: 3,
                gridColumnStart: 3,
                gridColumnEnd: 4
            }}>
                <DataPickersWithLabel label="Дата" widthInput={'80%'} widthLabel={'20%'} widthInputBlock={'80%'}
                    control={control}
                    nameField="normatdoc__docdate"
                    onChangeInput={handleOtherChange}
                    value={normatDocForm.normatdoc__docdate}
                    onBlurInput={handleBlurSaveDate}
                />
            </div>

            <div style={{
                gridRowStart: 3,
                gridRowEnd: 4,
                gridColumnStart: 1,
                gridColumnEnd: 2
            }}>
                <DataPickersWithLabel label="Начало действия" widthInput={'100%'} widthLabel={'35%'} widthInputBlock={'65%'}
                    control={control}
                    nameField="normatdoc__begdate"
                    onChangeInput={handleOtherChange}
                    value={normatDocForm.normatdoc__begdate}
                    onBlurInput={handleBlurSaveDate}
                />
            </div>

            <div style={{
                gridRowStart: 3,
                gridRowEnd: 4,
                gridColumnStart: 2,
                gridColumnEnd: 3
            }}>
                <CheckBoxWithLabel label={"Актуальность"}
                    nameField="normatdoc__relevance"
                    onChangeInput={handleCheckerChange}
                    value={normatDocForm.normatdoc__relevance}
                    control={control}
                    onBlurInput={handleBlurSaveCheker}
                />
            </div>

            <div style={{
                gridRowStart: 4,
                gridRowEnd: 5,
                gridColumnStart: 1,
                gridColumnEnd: 4
            }}>
                <InputWithLabel widthInput={'100%'} widthInputBlock={'90%'} widthLabel={'10%'} label={"Требования РФ"}
                    control={control}
                    nameField="normatdoc__rfrequir"
                    onChangeInput={handleChange}
                    value={normatDocForm.normatdoc__rfrequir}
                    onBlurInput={handeBlurSaveString}
                />
            </div>

            <div style={{
                gridRowStart: 5,
                gridRowEnd: 6,
                gridColumnStart: 1,
                gridColumnEnd: 4
            }}>
                <InputWithLabel widthInput={'100%'} widthInputBlock={'80%'} widthLabel={'20%'} label={"Требования страны импортёра"}
                    control={control}
                    nameField="normatdoc__reqcntrimp"
                    onChangeInput={handleChange}
                    value={normatDocForm.normatdoc__reqcntrimp}
                    onBlurInput={handeBlurSaveString}
                />
            </div>

            <div style={{
                gridRowStart: 6,
                gridRowEnd: 7,
                gridColumnStart: 1,
                gridColumnEnd: 4
            }}>
                <InputWithLabel widthInput={'100%'} widthInputBlock={'80%'} widthLabel={'20%'} label={"Необходимые исследования"}
                    control={control}
                    nameField="normatdoc__necresearch"
                    onChangeInput={handleChange}
                    value={normatDocForm.normatdoc__necresearch}
                    onBlurInput={handeBlurSaveString}
                />
            </div>
        </div>
    );
};

export default MainNDPoint;