import React, {FC, useEffect} from 'react';
import MainPoint from '../../RequiredForm/Points/MainPoint/MainPoint';
import TerritoriesPoint from '../../RequiredForm/Points/TerritoriesPoint/TerritoriesPoint';
import AccompDocPoint from '../../RequiredForm/Points/AccompDocPoint/AccompDocPoint';
import PackPoint from '../../RequiredForm/Points/PackPoint/PackPoint';
import RegQuarantObjPoint from '../../RequiredForm/Points/RegQuarantObjPoint/RegQuarantObj';
import './RequirementSpecificTab.scss';
import {useForm} from 'react-hook-form';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {StyledEngineProvider} from '@mui/material/styles';
import {IContentTable, IMainForm, TabTypes} from '../../../interfaces/TabInterface';
import {useTypedSelector} from '../../../hooks/useTypedSelector';
import {store} from 'react-notifications-component';
import {statuses} from '../../../interfaces/requirementsFormActions';
import {useDispatch} from 'react-redux';
import {ResetStatusForm} from '../../../store/action-creators/requirementsFormActionCreator';
import {ERROR_NOTIFY, GET_DATA_SUCCESS_NOTIFY, SUCCESS_NOTIFY} from "../../../shared/notification";
import QuarantObjByRequirementPoint
    from "../../RequiredForm/Points/QuarantObjByRequirementPoint/QuarantObjByRequirementPoint";
import {v4 as uuid} from 'uuid'
import DocumentsPoint from "../../RequiredForm/Points/DocumentsPoint/DocumentsPoint";


interface RequirementSpecificTabProps {
    content: IContentTable
    idTab: number
    mainForm: IMainForm
}

const RequirementSpecificTab: FC<RequirementSpecificTabProps> = ({idTab, mainForm, content}) => {

    const [tabValue, setTabValue] = React.useState<string>('Основное');
    const {control} = useForm<IMainForm>();

    const dispatch = useDispatch()

    const {error, status} = useTypedSelector(state => state.reqForm)

    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        event.preventDefault()
        event.stopPropagation()
        setTabValue(newValue)
    }

    useEffect(() => {
        if (error) {
            alert(error)
            store.addNotification({
                id: uuid(),
                ...ERROR_NOTIFY
            })
            dispatch(ResetStatusForm())
        }
        if (status === statuses.DATA_GET) {
            store.addNotification({
                id: uuid(),
                ...GET_DATA_SUCCESS_NOTIFY
            })
            dispatch(ResetStatusForm())
        }
        if (status === statuses.DATA_SAVE) {
            store.addNotification({
                id: uuid(),
                ...SUCCESS_NOTIFY
            })
            dispatch(ResetStatusForm())
        }
    }, [dispatch, error, status])

    return (
        <div className="requirement-tab-wrap">
            <StyledEngineProvider injectFirst>
                    <TabContext value={tabValue}>
                        <TabList onChange={handleChangeTab}>
                            <Tab label="Основное" value="Основное"/>
                            <Tab label="Территории" value="Территории"/>
                            <Tab label="Документы" value="Документы"/>
                            <Tab label="Подкарантинная продукция" value="Подкарантинная продукция"/>
                            <Tab label="Упаковка" value="Упаковка"/>
                            <Tab label="Карантинные объекты" value="Карантинные объекты"/>
                            <Tab label="Файл" value="Файл" />
                        </TabList>
                        <TabPanel value="Основное"><MainPoint
                            idTab={idTab}
                            mainForm={mainForm}
                            control={control}
                        /></TabPanel>
                        <TabPanel value="Территории"><TerritoriesPoint
                            mainForm={mainForm}
                            control={control}
                            idTab={idTab}
                        /></TabPanel>
                        <TabPanel value="Документы"><AccompDocPoint
                            idTab={idTab}
                            control={control}
                            mainForm={mainForm}
                        /></TabPanel>
                        <TabPanel value="Подкарантинная продукция">
                            <RegQuarantObjPoint
                                idTab={idTab}
                                mainForm={mainForm}
                            />
                        </TabPanel>
                        <TabPanel value="Упаковка"><PackPoint
                            idTab={idTab}
                            mainForm={mainForm}
                            control={control}
                        /></TabPanel>
                        <TabPanel value="Карантинные объекты">
                            <QuarantObjByRequirementPoint
                                idTab={idTab + 1}
                            />
                        </TabPanel>
                        <TabPanel value="Файл">
                            <DocumentsPoint idTab={idTab} />
                        </TabPanel>
                    </TabContext>
            </StyledEngineProvider>
        </div>
    );
};

export default RequirementSpecificTab;