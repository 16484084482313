import React, {FC, useCallback, useEffect, useState} from 'react';
import {IDocumentRepositoryTab, IFile} from '../../../interfaces/TabInterface';
import {DropEvent, FileRejection, useDropzone} from 'react-dropzone';
import Document from "../../Document/Document";
import ConfirmModal from "../../ConfirmModal/ConfirmModal";
import {useActions} from "../../../hooks/useAction";
import {store} from "react-notifications-component";
import {ERROR_NOTIFY, getErrorNotification} from "../../../shared/notification";
import {
    removeFileFromRepository,
    setFilesInRepository
} from "../../../store/action-creators/fileRepositoryActionCreator";
import { host } from '../../../environments/environments';

import './DocumentsRepository.scss';

interface DocumentsRepositoryTabProps extends IDocumentRepositoryTab {
}

const DocumentsRepositoryTab: FC<DocumentsRepositoryTabProps> = ({idTab, documents, pid}) => {

    const {
        addFileInRepository,
        removeFileFromRepository,
        setFilesInRepository
    } = useActions()
    const [isOpen, setIsOpen] = useState(false)
    const [currentFile, setCurrentFile] = useState<IFile | null>(null)

    const onDrop: <T extends File>(
        acceptedFiles: T[],
        fileRejections: FileRejection[],
        event: DropEvent
    ) => void = useCallback(async (acceptedFiles) => {
        if (acceptedFiles.length !== 0) {
            const formData = new FormData()
            formData.append('countryId', pid)
            formData.append('file', acceptedFiles[0])
            try {
                const response = await fetch(host + '/file', {
                    method: 'POST',
                    body: formData
                })
                if (!response.ok)  {
                    store.addNotification(getErrorNotification('Не удалось загрузить файл', 'При загрузке файла произошла ошибка'))
                    return;
                }
                const newFile: IFile = await response.json()
                addFileInRepository(idTab, newFile)
            } catch(error) {
                store.addNotification(ERROR_NOTIFY)
            }
        }
    }, [idTab, pid])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    useEffect(() => {
        (async () => {
            try {
                const response = await fetch(host + `/file/country/${pid}`)
                if (!response.ok) {
                    store.addNotification(getErrorNotification('Невозможно получить файлы', 'При получении файлов произошла ошибка'))
                    return;
                }
                const files: Array<IFile> = await response.json()
                setFilesInRepository(idTab, files)
            } catch(error) {
                store.addNotification(ERROR_NOTIFY)
            }
        })()
    }, [idTab, pid]);

    const deleteFile = async () => {
        if (!currentFile || !currentFile.id) return;
        try {
            const response = await fetch(host + `/file/${currentFile.id}`, {
                method: 'DELETE'
            })
            if (!response.ok) {
                store.addNotification(getErrorNotification('Невозможно удалить файл', 'При удалении файла произошла ошибка'))
                return;
            }
            removeFileFromRepository(idTab, currentFile.id)
            setCurrentFile(null)
        } catch(error) {
            store.addNotification(ERROR_NOTIFY)
        }
    }

    const handleClickDelete = (file: IFile) => {
        setCurrentFile(file)
        setIsOpen(true)
    }

    return (
        <div className="document-tab-wrap" {...getRootProps({
            onClick: event => event.stopPropagation(),
        })}>
            {isDragActive && <div className='drag-active-wrapper'>
                <p>Отпустите файл для загрузки</p>
            </div>}
            <div className='download-file-button_wrapper'>
                <input id='file' type="file" {...getInputProps({
                    accept: 'application/msword, application/pdf'
                })}/>
                <label htmlFor="file" className='download-file-button'>Загрузить файл</label>
            </div>
            <div className="flex-wrapper" >
                {documents.map(document => <Document key={document.id} file={document} onDelete={handleClickDelete}/>)}
            </div>
            <ConfirmModal
                header={'Вы точно хотите удалить файл?'}
                open={isOpen}
                onClose={() => setIsOpen(false)}
                onConfirm={() => {
                    deleteFile()
                    setIsOpen(false)
                }}
                onNotConfirm={() => {
                    setIsOpen(false)
                    setCurrentFile(null)
                }}
            >
                <p>Отменить данное действие будет невозможно.</p>
            </ConfirmModal>
        </div>
    );
};

export default DocumentsRepositoryTab;