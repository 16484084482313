import React, { FC, useState} from 'react';
import {IContentTable, IMainForm, TabTypes} from '../../../../interfaces/TabInterface';
import CustomTable, {AutocompleteCellTypes} from '../../../CustomTable/CustomTable';
import {useSpecificReqTabContext} from '../../../../contexts/TabsContext'
import {  SortType } from 'rsuite-table';
import ToolBar from '../../../ToolBar/ToolBar';
import FormBottomTable from "../../../Forms/FormBottomTable/FormBottomTable";
import {RegQuarantObjPointForm} from "../../../../shared/form.table.enviroment";
import {faCopy, faArrowAltCircleDown} from "@fortawesome/free-regular-svg-icons";
import ToolBarItem from "../../../ToolBarItem/ToolBarItem";
import {CircularProgress, Modal} from "@mui/material";
import FormCopyQuarantObjects from "../../../Forms/FormCopyQuarantObjects/FormCopyQuarantObjects";
import {GetTableContent} from "../../../../store/action-creators/tableActionCreator";
import {useDispatch} from "react-redux";
import ConfirmModal from "../../../ConfirmModal/ConfirmModal";
import {host} from "../../../../environments/environments";
import {store} from "react-notifications-component";
import {v4 as uuid} from "uuid";
import {ERROR_NOTIFY, SUCCESS_NOTIFY} from "../../../../shared/notification";

interface RegQuarantObjPointProps {
    idTab: number
    mainForm: IMainForm
}

const RegQuarantObjPoint: FC<RegQuarantObjPointProps> = ({ idTab, mainForm }) => {

    const [toggleForm, setToggleForm] = useState<boolean>(false)
    const [toggleCopyForm, setToggleCopyForm] = useState<boolean>(false)
    const [isOpenConfirmationForm, setIsOpenConfirmationForm] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const {content, limit, page, sortType, sortColumn, filters, form} = useSpecificReqTabContext()

    const updateTableContent = () => {
        dispatch(GetTableContent(idTab, page as number, limit as number, '/v_rquqprods/all', {sortColumn, sortType, filters}))
    }

    const handleCopyKO = async () => {
        setIsLoading(true)
        const response = await fetch(host + '/requirement/cp_rqpquarantobj', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: mainForm.requirements__irq_id
            })
        })
        if (response.ok) {
            store.addNotification({
                id: uuid(),
                ...SUCCESS_NOTIFY
            })
            setIsOpenConfirmationForm(false)
            setIsLoading(false)
        } else {
            store.addNotification({
                id: uuid(),
                ...ERROR_NOTIFY
            })
        }
    }

    return (
        <div className="tab-wrap">
            <div className="table-container">
                <CustomTable 
                    api={{
                        post: '/v_rquqprods/all',
                        put: '/rquqprods'
                    }}
                    idName={'irp_id'}
                    headerHeight={80}
                    idTab={idTab}
                    items={(content as IContentTable)?.items ? (content as IContentTable)?.items : []}
                    limit={limit as number}
                    page={page as number}
                    sortType={sortType as SortType}
                    sortColumn={sortColumn as string}
                    totalItems={(content as IContentTable)?.totalItems}
                    filters={filters as { field: string; value: string; }[]}
                    postForm={{
                            srp_note: '',
                            irq_pid: '',
                            iup_uquarantprod_id: '',
                            srp_permission: '',
                            irp_id: ''
                        }}
                    setting={[
                        {
                            key: 'irp_id',
                            label: '',
                            link: true,
                            width: 50,
                            titleNameKey: 'sup_rusname',
                            tabType: TabTypes.SPECIFIC_UQUARANTPROD_TAB
                        },
                        {   
                            key: 'sup_rusname',
                            label: 'Подкарантинная продукция',
                            width: 400,
                            sortable: true,
                            filtered: true,
                            autocomplete: AutocompleteCellTypes.DEFAULT,
                            api: '/v_uquarantprods/join'
                        },
                        {   
                            key: 'sgr_code',
                            label: 'Группа',
                            width: 250,
                            sortable: true,
                            readonly: true
                        },
                        {
                            key: 'skp_rusname',
                            label: 'Род',
                            width: 250,
                            readonly: true
                        },
                        {
                            key: 'srp_permission',
                            label: 'Разрешение',
                            width: 250,
                            autocomplete: AutocompleteCellTypes.PERMISSION
                        },
                        {
                            key: 'srp_note',
                            label: 'Примечание',
                            width: 300,
                        }
                    ]}
                />
                <FormBottomTable open={toggleForm} onToggleForm={() => setToggleForm(prev => !prev)} fields={RegQuarantObjPointForm} api='/rquqprods' idTab={idTab} form={form as {[key: string]: any;}} idName='irp_id' />
                <Modal open={toggleCopyForm} onClose={() => setToggleCopyForm(false)}>
                    <FormCopyQuarantObjects idTab={idTab} onClose={() => setToggleCopyForm(false)} requirementId={mainForm.requirements__irq_id} updateContent={updateTableContent}/>
                </Modal>
                <ConfirmModal
                    open={isOpenConfirmationForm}
                    onClose={() => setIsOpenConfirmationForm(false)}
                    onConfirm={handleCopyKO}
                    onNotConfirm={() => setIsOpenConfirmationForm(false)}
                    header={' '}
                >
                    {!isLoading && <p>Если к некоторым записям подкарантинной продукции из списка уже привязаны карантинные объекты. Выполнение данной функции приведет к очистке заполненного ранее списка карантинных объектов. Продолжить?</p>}
                    {isLoading &&
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '10px', width: '100%', height: '100%'}}>
                            <CircularProgress sx={{color: '#5CDB94'}}/>
                            <p>Загрузка...</p>
                        </div>}
                </ConfirmModal>
            </div>
            <ToolBar idTab={idTab} toggleForm={( ) => setToggleForm(state => !state)} api="/rquqprods/" idName={'irp_id'}>
                <ToolBarItem icon={faCopy} onClick={() => setToggleCopyForm(state => !state)}/>
                <ToolBarItem icon={faArrowAltCircleDown} onClick={() => setIsOpenConfirmationForm(true)} />
            </ToolBar>
        </div>
    );
};

export default RegQuarantObjPoint;