import React, { FC } from 'react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ToolBarItem.scss'

interface ToolBarItemProps {
    icon: IconDefinition
    onClick: () => void
}

const ToolBarItem: FC<ToolBarItemProps> = ({icon, onClick}) => {
    return (
        <div className="toolbar-item" onClick={onClick}>
            <FontAwesomeIcon icon={icon}/>
        </div>
    );
};

export default ToolBarItem;