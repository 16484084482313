import React, { FC, useState } from 'react';
import { SortType } from 'rsuite-table';
import ToolBar from '../../ToolBar/ToolBar';
import CustomTable, {AutocompleteCellTypes} from '../../CustomTable/CustomTable';
import { IContentTable } from '../../../interfaces/TabInterface';
import FormBottomTable from "../../Forms/FormBottomTable/FormBottomTable";
import {OthersObjForm} from "../../../shared/form.table.enviroment";

interface OthersObjsTabProps {
    limit: number,
    page: number,
    sortColumn: string,
    sortType: string,
    content: IContentTable,
    idTab: number,
    filters: {field: string, value: string}[],
    form: {[key: string]: any}
}

const OthersObjsTab: FC<OthersObjsTabProps> = ({ idTab, limit, page, sortColumn, sortType, content, filters, form }) => {

    const [toggleForm, setToggleForm] = useState<boolean>(false)

    return (
        <div className="tab-wrap">
            <div className="table-container">
                <CustomTable 
                    api={{
                        post: '/v_othersobjs/all',
                        put: '/othersobjs'
                    }}
                    idName={'ioo_id'}
                    headerHeight={80}
                    idTab={idTab}
                    items={content?.items ? content.items : []}
                    limit={limit}
                    page={page}
                    sortType={sortType as SortType}
                    sortColumn={sortColumn}
                    totalItems={content?.totalItems}
                    filters={filters}
                    setting={[
                        {   
                            key: 'sto_code',
                            label: 'Тип',
                            width: 200,
                            api: '/v_tpothersobjs/join',
                            autocomplete: AutocompleteCellTypes.DEFAULT
                        },
                        {   
                            key: 'sto_name',
                            label: 'Полное наименование типа',
                            width: 150,
                            sortable: true,
                            readonly: true,
                            filtered: true
                        },
                        {   
                            key: 'soo_latname',
                            label: 'Lat наименование',
                            width: 170,
                            sortable: true,
                        },
                        {   
                            key: 'soo_rusname',
                            label: 'Rus наименование',
                            width: 170,
                        },
                        {   
                            key: 'smu_name',
                            label: 'Единица измерения',
                            width: 100,
                            api: '/v_munitss/join',
                            autocomplete: AutocompleteCellTypes.DEFAULT
                        },
                        {   
                            key: 'nmu_okei',
                            label: 'ОКЕИ',
                            width: 60,
                            readonly: true
                        },
                        {   
                            key: 'smu_condnatdes',
                            label: 'Условное национальное обозначение',
                            width: 200,
                            readonly: true
                        },
                        {   
                            key: 'smu_condintdes',
                            label: 'Условное международное обозначение',
                            width: 200,
                            readonly: true
                        },
                        {   
                            key: 'smu_clnatdes',
                            label: 'Кодовое буквенное национальное обозначение',
                            width: 250,
                            readonly: true
                        },
                        {       
                            key: 'smu_clintdes',
                            label: 'Кодовое буквенное международное обозначение',
                            width: 250,
                            readonly: true
                        },
                        {   
                            key: 'soo_type',
                            label: 'Тип ввода',
                            width: 100,
                            autocomplete: AutocompleteCellTypes.TYPE
                        },
                        {   
                            key: 'soo_main',
                            label: 'Основной',
                            width: 100,
                            autocomplete: AutocompleteCellTypes.MAIN
                        },
                        {   
                            key: 'soo_label_name',
                            label: 'Метка',
                            width: 60,
                        }
                    ]}
                />
                <FormBottomTable open={toggleForm} onToggleForm={() => setToggleForm(prev => !prev)} fields={OthersObjForm} api='/othersobjs' idTab={idTab} form={form} idName='ioo_id' />
            </div>
            <ToolBar idTab={idTab} toggleForm={() => setToggleForm(state => !state)} api="/othersobjs/" idName={'ioo_id'} tableNameExport={'v_othersobj'}/>
        </div>
    );
};

export default OthersObjsTab;