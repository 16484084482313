import React, { FC, useState } from 'react';
import { SortType } from 'rsuite-table';
import ToolBar from '../../ToolBar/ToolBar';
import CustomTable from '../../CustomTable/CustomTable';
import {IContentTable, TabPanel} from '../../../interfaces/TabInterface';
import {TpothersObjForm} from "../../../shared/form.table.enviroment";
import FormBottomTable from "../../Forms/FormBottomTable/FormBottomTable";

interface TpothersObjTabProps extends TabPanel {

}

const TpothersObjTab: FC<TpothersObjTabProps> = ({ idTab, limit, page, sortColumn, sortType, content, filters, form }) => {
    const [toggleForm, setToggleForm] = useState<boolean>(false)


    return (
        <div className="tab-wrap">
            <div className="table-container">
            <CustomTable
                    api={{
                        post: '/v_tpothersobjs/all',
                        put: '/tpothersobjs'
                    }}
                    idName={'ito_id'}
                    headerHeight={80}
                    idTab={idTab}
                    items={content?.items ? content.items : []}
                    limit={limit}
                    page={page}
                    sortType={sortType as SortType}
                    sortColumn={sortColumn}
                    totalItems={content?.totalItems}
                    filters={filters}
                    setting={[
                        {   
                            key: 'sto_code',
                            label: 'Краткое наименование',
                            flex: 1,
                            sortable: true,
                        },
                        {   
                            key: 'sto_name',
                            label: 'Полное наименование',
                            flex: 1,
                            sortable: true,
                            filtered: true
                        }
                    ]}
                />
                <FormBottomTable
                    open={toggleForm} onToggleForm={() => setToggleForm(prev => !prev)}
                    idName={'ito_id'}
                    form={form}
                    fields={TpothersObjForm}
                    api="/tpothersobjs"
                    idTab={idTab}
                />
            </div>
            <ToolBar idTab={idTab} toggleForm={() => setToggleForm(state => !state)} api="/tpothersobjs/" idName={'ito_id'} tableNameExport={'v_tpothersobj'}/>
        </div>
    );
};

export  default  TpothersObjTab;