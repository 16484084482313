import {IFile} from "./TabInterface";

export enum FileRepositoryActionType {
    SET_FILES = 'SET_FILES',
    ADD_FILE = 'ADD_FILE',
    REMOVE_FILE = 'REMOVE_FILE'
}

export interface AddFileAction {
    type: FileRepositoryActionType.ADD_FILE,
    payload: {
        idTab: number,
        file: IFile
    }
}

export interface RemoveFileAction {
    type: FileRepositoryActionType.REMOVE_FILE,
    payload: {
        idTab: number,
        idFile: string
    }
}

export interface SetFilesAction {
    type: FileRepositoryActionType.SET_FILES,
    payload: {
        idTab: number,
        files: Array<IFile>
    }
}

export type FileRepositoryAction = AddFileAction
    | RemoveFileAction
    | SetFilesAction