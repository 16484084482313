import React, {FC, useState} from 'react';
import {SortType} from 'rsuite-table';
import {TabPanel} from '../../../interfaces/TabInterface';
import ToolBar from '../../ToolBar/ToolBar';
import CustomTable from '../../CustomTable/CustomTable';
import FormBottomTable from "../../Forms/FormBottomTable/FormBottomTable";
import {PhenophasesForm} from "../../../shared/form.table.enviroment";

interface PhenophasesTabProps extends TabPanel {
}

const PhenophasesTab: FC<PhenophasesTabProps> = ({idTab, limit, page, sortColumn, sortType, content, filters, form}) => {
    const [toggleForm, setToggleForm] = useState<boolean>(false)

    return (
        <div className="tab-wrap">
            <div className="table-container">
                <CustomTable
                    api={{
                        post: '/v_phenophases/all',
                        put: '/phenophases'
                    }}
                    idName={'ipp_id'}
                    headerHeight={80}
                    idTab={idTab}
                    items={content?.items ? content.items : []}
                    limit={limit}
                    page={page}
                    sortType={sortType as SortType}
                    sortColumn={sortColumn}
                    totalItems={content?.totalItems}
                    filters={filters}
                    setting={[
                        {
                            key: 'spp_name',
                            label: 'Наименование',
                            flex: 1,
                            sortable: true,
                            filtered: true
                        },
                    ]}
                />
                <FormBottomTable
                    open={toggleForm} onToggleForm={() => setToggleForm(prev => !prev)}
                    idName={'ipp_id'}
                    form={form}
                    fields={PhenophasesForm}
                    api="/phenophases"
                    idTab={idTab}
                />
            </div>
            <ToolBar idTab={idTab} toggleForm={() => setToggleForm(state => !state)} api="/phenophases/"
                     idName={'ipp_id'} tableNameExport={'phenophases'} schema_name={'ais_weeds'}/>
        </div>
    );
};

export default PhenophasesTab;