import React, {FC, useEffect, useState} from 'react';
import PrimaryButton from "../../../PrimaryButton/PrimaryButton";
import Modal from '../../../Modal/Modal'
import FormAddFileToRequirement from "../../../Forms/FormAddFileToReqirement/FormAddFileToRequirement";
import {useTypedSelector} from "../../../../hooks/useTypedSelector";
import {IFile} from "../../../../interfaces/TabInterface";
import Document from "../../../Document/Document";

import './DocumentsPoint.scss'
import {store} from "react-notifications-component";
import {host} from "../../../../environments/environments";
import {ERROR_NOTIFY, getErrorNotification} from "../../../../shared/notification";
interface DocumentsPointProps {
    idTab: number
}
const DocumentsPoint: FC<DocumentsPointProps> = ({idTab}) => {

    const {tabs} = useTypedSelector(state => state.tab)

    const [isOpen, setIsOpen] = useState(false)
    const [file, setFile] = useState<IFile | null>(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        (async () => {
            try {
                const response = await fetch(host + `/requirement/${tabs[idTab].mainForm.requirements__irq_id}/file`)
                if (response.ok) {
                    const file: IFile = await response.json()
                    if (file.id) setFile(file)
                } else {
                    store.addNotification(getErrorNotification('Невозможно получить файл', 'При получении файла произошла ошибка'))
                }
            } catch (error) {
                store.addNotification(ERROR_NOTIFY)
            } finally {
                setLoading(false)
            }
        })()
    }, [tabs[idTab].mainForm.requirements__irq_id]);

    const handleClickOpen = () => {
        setIsOpen(true)
    }

    const handleClickCLose = () => {
        setIsOpen(false)
    }

    const handleSubmitForm = async (file: IFile) => {
        try {
            const response = await fetch(host + '/requirement/file', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    fileId: file.id,
                    requirementId: tabs[idTab].mainForm.requirements__irq_id
                })
            })
            if (response.ok) {
                setFile(file)
            } else {
                store.addNotification(getErrorNotification('Невозможно прикрепить файл', 'При прикреплении файла произошла ошибка'))
                return;
            }
        } catch(error) {
            store.addNotification(ERROR_NOTIFY)
        } finally {
            setIsOpen(false)
        }
    }

    const handleDeleteFile = async (file: IFile) => {
        try {
            const response = await fetch(host + `/requirement/${tabs[idTab].mainForm.requirements__irq_id}/file`, {
                method: 'DELETE'
            })
            if (response.ok) {
                setFile(null)
            } else {
                store.addNotification(getErrorNotification('Невозможно удалить файл', 'При удалении файла произошла ошибка'))
                return;
            }
        } catch(error) {
            store.addNotification(ERROR_NOTIFY)
        }
    }

    return (
        <div className={'document-point_wrapper'}>
            <PrimaryButton style={{width: '200px'}} type={'button'} onClick={handleClickOpen}>Добавить файл</PrimaryButton>
            <div className='flex-wrapper'>
                {loading ? <p>Загрузка...</p> : file ? <Document file={file} onDelete={handleDeleteFile}/> : <p>Файл отсутствует</p>}
            </div>
            <Modal isOpen={isOpen} onClose={handleClickCLose}>
                <FormAddFileToRequirement
                    country_id={tabs[idTab].mainForm.requirements__icn_id}
                    onSubmit={handleSubmitForm}
                />
            </Modal>
        </div>
    );
};

export default DocumentsPoint;