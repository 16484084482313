import { ChangeEvent } from "react"
import { IDopQualsUquarantObj, IFSK, ITnvedsUquarantObj } from "../interfaces/TabInterface"
import { useActions } from './useAction'

export const useSaveDefaultField = (id: string, type: string) => {
    //0 - Строка
    //1 - Число
    //2 - Дата
    const { UpdFieldReqForm } = useActions()
    return (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        UpdFieldReqForm(id, event.target.name, event.target.value, type)
    }
}

export const useSaveOtherField = (id: string, type: string) => {
    const { UpdFieldReqForm } = useActions()
    return (nameField: string, value: string | Date | boolean) => {
        UpdFieldReqForm(id, nameField, value, type)
    }
}

export const useSaveNonStandartField = (id: string, nameTable: string, nameField: string, value: string) => {
    const { UpdFieldReqForm } = useActions()
    const nameTablePlusNameField = [nameTable, nameField].join('__')
    return () => {
        UpdFieldReqForm(id, nameTablePlusNameField, value, '1')
    }
}

export const useSaveArrayFieldDopDoc = (idTab: number, pid: string, nameForm: string, nameTags: string, nameTable: string, nameField: string, nameIdField: string) => {
    const { UpdFieldReqForm, PostArrayField } = useActions()
    return (idTag: number, doc: {
        ird_id: string,
        idt_id: string,
        sdt_code: string,
        sdt_name: string,
        srd_note: string
    }) => () => {
        if ((doc.ird_id === '0' || doc.ird_id === '') && doc.idt_id !== '') {
            PostArrayField(idTab, nameForm, nameTags, nameTable, {
                pid: pid,
                doctype_id: doc.idt_id,
                note: doc.srd_note
            }, idTag, nameIdField)
        } else if (doc.ird_id !== '') {
            UpdFieldReqForm(doc.ird_id, [nameTable.slice(0, nameTable.length - 1), nameField].join('__'), doc.idt_id, '1')
        }
    }
}

export const useSaveArrayFieldAgnExp = (idTab: number, pid: string, nameForm: string, nameTags: string, nameTable: string, nameField: string, nameIdField: string) => {
    const { UpdFieldReqForm, PostArrayField } = useActions()
    return (idTag: number, exp: {
        ira_id: string,
        icn_clwrldcntr_id: string,
        iea_id: string,
        sea_code: string,
        sea_addrprod: string
    }) => () => {
        if ((exp.ira_id === '0' || exp.ira_id === '') && exp.iea_id !== '') {
            PostArrayField(idTab, nameForm, nameTags, nameTable, {
                pid: pid,
                clwrldcntr_id: exp.icn_clwrldcntr_id,
                exporter_id: exp.iea_id,
                type: 1
            }, idTag, nameIdField)
        } else if (exp.ira_id !== '') {
            UpdFieldReqForm(exp.ira_id, [nameTable.slice(0, nameTable.length - 1), nameField].join('__'), exp.iea_id, '1')
        }
    }
}

export const useSAveArrayFieldTnved = (idTab: number, pid: string, nameForm: string, nameTags: string, nameTable: string, nameField: string, nameIdField: string) => {
    const { UpdFieldReqForm, PostArrayField } = useActions()
    return (idTag: number, tnved: ITnvedsUquarantObj) => () => {
        if ((tnved.irt_id === '0' || tnved.irt_id === '') && tnved.ict_id !== '') {
            PostArrayField(idTab, nameForm, nameTags, nameTable, {
                pid: pid,
                codetnved_id: tnved.ict_id
            }, idTag, nameIdField)
        } else if (tnved.irt_id !== '') {
            UpdFieldReqForm(tnved.irt_id, [nameTable.slice(0, nameTable.length - 1), nameField].join('__'), tnved.ict_id, '1')
        }
    }
}

export const useSaveArrayFieldQuarantFitoZone = (idTab: number, pid: string, nameForm: string, nameTags: string, nameTable: string, nameField: string, nameIdField: string) => {
    const { UpdFieldReqForm, PostArrayField } = useActions()
    return (idTag: number, fsk: IFSK) => () => {
        if ((fsk.irj_id === '0' || fsk.irj_id === '') && fsk.iuo_id !== '') {
            PostArrayField(idTab, nameForm, nameTags, nameTable, {
                pid: pid,
                uquarantobj_id: fsk.iuo_id,
                pofree: 0,
                pochar: 0
            }, idTag, nameIdField)
        } else if (fsk.irj_id !== '') {
            UpdFieldReqForm(fsk.irj_id, [nameTable.slice(0, nameTable.length - 1), nameField].join('__'), fsk.iuo_id, '1')
        }
    }
}

export const useSaveArrayFieldDopQual = (idTab: number, pid: string, nameForm: string, nameTags: string, nameTable: string, nameField: string, nameIdField: string) => {
    const { UpdFieldReqForm, PostArrayField } = useActions()
    return (idTag: number, doPQual: IDopQualsUquarantObj) => () => {
        if ((doPQual.irm_id === '0' || doPQual.irm_id === '') && doPQual.ioo_id !== '') {
            PostArrayField(idTab, nameForm, nameTags, nameTable, {
                pid: pid,
                othersobj_id: doPQual.ioo_id,
                s_value: '',
                n_value: null,
                d_value: null
            }, idTag, nameIdField)
        } else if (doPQual.irm_id !== '') {
            UpdFieldReqForm(doPQual.irm_id, [nameTable.slice(0, nameTable.length - 1), nameField].join('__'), doPQual.ioo_id, '1')
        }
    }
}

export const useSaveArrayFieldDopQualValue = (idTab: number, pid: string, nameForm: string, nameTags: string, nameTable: string, nameField: string, nameIdField: string) => {
    const { UpdFieldReqForm, PostArrayField } = useActions()
    return (idTag: number, doPQual: IDopQualsUquarantObj) => () => {
        if ((doPQual.irm_id === '0' || doPQual.irm_id === '') && doPQual.ioo_id !== '') {
            PostArrayField(idTab, nameForm, nameTags, nameTable, {
                pid: pid,
                othersobj_id: doPQual.ioo_id,
                s_value: '',
                n_value: null,
                d_value: null
            }, idTag, nameIdField)
        } else if (doPQual.irm_id !== '') {
            UpdFieldReqForm(doPQual.irm_id, [nameTable.slice(0, nameTable.length - 1), nameField].join('__'), doPQual.srm_s_value, '0')
        }
    }
}

export const useSaveArrayFieldPlaceSimp = (idTab: number, pid: string, nameForm: string, nameTags: string, nameTable: string, nameField: string, nameIdField: string) => {
    const { UpdFieldReqForm, PostArrayField } = useActions()
    return (idTag: number, place: {
        irl_id: string,
        ips_id: string,
        sps_code: string,
        drl_begdate: string,
        drl_enddate: string
    }) => () => {
        if ((place.irl_id === '0' || place.irl_id === '') && place.ips_id !== '') {
            PostArrayField(idTab, nameForm, nameTags, nameTable, {
                pid: pid,
                placesimp_id: place.ips_id,
                begdate: place.drl_begdate ? place.drl_begdate : null,
                enddate: place.drl_enddate ? place.drl_enddate : null
            }, idTag, nameIdField)
        } else if (place.irl_id !== '') {
            UpdFieldReqForm(place.irl_id, [nameTable.slice(0, nameTable.length - 1), nameField].join('__'), place.ips_id, '1')
        }
    }
}

export const useSaveFieldArrayInput = (nameTable: string, nameField: string) => {
    const { UpdFieldReqForm } = useActions()
    return (id: string) => (event: ChangeEvent<HTMLInputElement>) => {
        if (id) {
            UpdFieldReqForm(id, [nameTable, nameField].join('__'), event.target.value, '0')
        }
    }
}

export const useSaveFieldArrayDatePicker = (nameTable: string, nameField: string) => {
    const { UpdFieldReqForm } = useActions()
    return (id: string) => (otherField: string, value: string | Date) => {
        if (id) {
            UpdFieldReqForm(id, [nameTable, nameField].join('__'), value, '2')
        }
    }
}

export const useSaveTagsRegions = (pid: number,
    nameTable: string,
    type: number,
    idTab: number,
    nameForm: string,
    nameTags: string) => {
    const { PostTagReqForm } = useActions()
    return (idTag: number, value: string) => {
        PostTagReqForm(idTab, nameForm, nameTags, nameTable, {
            pid: pid,
            region_id: idTag,
            type: type
        }, {
            ire_id: 0,
            nrg_name: value
        })
    }
}

export const useSaveAnyTags = <T>(
                                  nameTable: string,
                                  idTab: number,
                                  nameForm: string,
                                  nameTags: string,
                                  createBody: (tagId: number) => {[key: string]: string | number},
                                  createTagBody: (value: string) => T) => {
    const { PostTagReqForm } = useActions()
    return (idTag: number, value: string) => {
        PostTagReqForm(idTab, nameForm, nameTags, nameTable, createBody(idTag), createTagBody(value))
    }
}

export const useSaveTagsCntr = (pid: number,
    nameTable: string,
    type: number,
    idTab: number,
    nameForm: string,
    nameTags: string) => {
    const { PostTagReqForm } = useActions()
    return (idTag: number, value: string) => {
        PostTagReqForm(idTab, nameForm, nameTags, nameTable, {
            pid: pid,
            clwrldcntr_id: idTag,
            type: type
        }, {
            irc_id: 0,
            scn_name: value
        })
    }
}

export const useSaveTagsAgnReexp = (pid: number,
    nameTable: string,
    type: number,
    idTab: number,
    nameForm: string,
    nameTags: string) => {
    const { PostTagReqForm } = useActions()
    return (idTag: number, value: string, clwrldcntrId?: string) => {
        PostTagReqForm(idTab, nameForm, nameTags, nameTable, {
            pid: pid,
            exporter_id: idTag,
            clwrldcntr_id: clwrldcntrId as string,
            type: type
        }, {
            ira_id: 0,
            sea_code: value
        })
    }
}

export const
    useDeleteTags = (idTab: number,
    nameTable: string,
    nameForm: string,
    nameTags: string) => {
    const { DeleteTagReqForm } = useActions()
    return (idTag: string) => {
        DeleteTagReqForm(idTab, nameForm, nameTags, nameTable, idTag)
    }
}

export const useDeleteArrayFields = (idTab: number,
    nameTable: string,
    nameForm: string,
    nameTags: string) => {
    const { DeleteTagReqForm } = useActions()
    return (idTag: string) => () => {
        DeleteTagReqForm(idTab, nameForm, nameTags, nameTable, idTag)
    }
}

export const useArrayFieldSaveAutocomplete = (pid: number,
    nameTable: string,
    type: number,
    idTab: number,
    nameForm: string,
    nameTags: string) => {
    const { PostTagReqForm } = useActions()
    return (idTag: number, value: string) => {
        PostTagReqForm(idTab, nameForm, nameTags, nameTable, {
            pid: pid,
            clwrldcntr_id: idTag,
            type: type
        }, {
            irc_id: 0,
            scn_name: value
        })
    }
}

export const useArrayFieldSaveCheckbox = (nameField: string, type: string) => {
    const { UpdFieldReqForm } = useActions()
    return (id: string ) => (mock: string, value: string | Date | boolean) => {
        UpdFieldReqForm(id, nameField, value, type)
    }
}