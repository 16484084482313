import React, {FC, useState} from 'react';
import {SortType} from 'rsuite-table';
import ToolBar from '../../ToolBar/ToolBar';
import CustomTable, {AutocompleteCellTypes} from '../../CustomTable/CustomTable'
import FormBottomTable from "../../Forms/FormBottomTable/FormBottomTable";
import {IContentTable, TabPanel} from '../../../interfaces/TabInterface';
import {PlaceSimpForm} from "../../../shared/form.table.enviroment";

interface PlacesimpTabTabProps extends TabPanel {

}

const PlacesimpTabTab: FC<PlacesimpTabTabProps> = ({
                                                       idTab,
                                                       limit,
                                                       page,
                                                       sortColumn,
                                                       sortType,
                                                       content,
                                                       filters,
                                                       form
                                                   }) => {
    const [toggleForm, setToggleForm] = useState<boolean>(false)

    return (
        <div className="tab-wrap">
            <div className="table-container">
                <CustomTable
                    api={{
                        post: '/v_placesimps/all',
                        put: '/placesimps'
                    }}
                    idName={'ips_id'}
                    headerHeight={80}
                    idTab={idTab}
                    items={content?.items ? content.items : []}
                    limit={limit}
                    page={page}
                    sortType={sortType as SortType}
                    sortColumn={sortColumn}
                    totalItems={content?.totalItems}
                    filters={filters}
                    postForm={{
                        sps_code: '',
                        sps_name: '',
                        irg_regions_id: '',
                        icn_clwrldcntr_id: '',
                        ips_id: ''
                    }}
                    setting={[
                        {
                            key: 'sps_code',
                            label: 'Краткое наименование',
                            width: 250,
                            sortable: true,
                            filtered: true
                        },
                        {
                            key: 'sps_name',
                            label: 'Полное наименование',
                            width: 250,
                        },
                        {
                            key: 'srg_name',
                            label: 'Регион',
                            width: 250,
                            api: '/v_regionss/join',
                            autocomplete: AutocompleteCellTypes.DEFAULT
                        },
                        {
                            key: 'ncn_numcode',
                            label: 'Цифровой код',
                            width: 250,
                            readonly: true
                        },
                        {
                            key: 'scn_code',
                            label: 'Страна',
                            width: 250,
                            api: '/v_clwrldcntrs/join',
                            autocomplete: AutocompleteCellTypes.DEFAULT
                        },
                        {
                            key: 'scn_name',
                            label: 'Полное наименование',
                            width: 250,
                            readonly: true
                        },
                        {
                            key: 'scn_letcode2',
                            label: 'Буквенный код 2',
                            width: 250,
                            readonly: true
                        },
                        {
                            key: 'scn_letcode3',
                            label: 'Буквенный код 3',
                            width: 250,
                            readonly: true
                        }
                    ]}
                />
                    <FormBottomTable
                        open={toggleForm} onToggleForm={() => setToggleForm(prev => !prev)}
                        fields={PlaceSimpForm}
                        idName={'ips_id'}
                        form={form}
                        idTab={idTab}
                        api='/placesimps'
                        postForm={{
                            sps_code: '',
                            sps_name: '',
                            irg_regions_id: '',
                            icn_clwrldcntr_id: ''
                        }}/>
            </div>
            <ToolBar idTab={idTab} toggleForm={() => setToggleForm(state => !state)} api="/placesimps/"
                     idName={'ips_id'} tableNameExport={'v_placesimp'}/>
        </div>
    );
};

export default PlacesimpTabTab;