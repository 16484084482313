import { normatdocFormActionsType, normotdocFormAction } from '../../interfaces/normatdocFormActions';
import {formState} from '../../interfaces/requirementsFormActions';

export const initialState: formState = {
    error: '',
    status: ''
}

export const normatdocFormReducer = (state = initialState, action: normotdocFormAction): formState => {
    switch(action.type) {
        case normatdocFormActionsType.SUCCES_FETCH_NORMATDOC:
            return {
                error: '',
                status: action.payload.message
            }
        case normatdocFormActionsType.ERROR_FETCH_NORMATDOC:
            return {
                error: action.payload.message,
                status: 'error'
            }
        case normatdocFormActionsType.START_FETCH_NORMATDOC:
            return {
                error: '',
                status: 'pending'
            }
        case normatdocFormActionsType.RESET_STATUS_FORM_ND:
            return {
                error: '',
                status: ''
            }
        default:
            return state
    }

}