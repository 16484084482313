import React, {ChangeEvent, createRef, FC} from 'react';
import searchIcon from "../../../img/icons/search.png";

import './SearchInput.scss'

interface SerchInputProps {
    name: string
    filters:  {field: string, value: string}[]
    handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const SearchInput: FC<SerchInputProps> = ({name, filters, handleInputChange}) => {

    const ref = createRef<HTMLInputElement>()

    return (
        <div className="field-search-wrap">
            <img src={searchIcon} alt="Поиск" onClick={(e) => {
                e.stopPropagation()
                ref.current?.focus()
            }}/>
            <input
                ref={ref}
                className="filter-input"
                defaultValue={filters?.filter(filter => filter.field === name)[0]?.value}
                name={name}
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                }}
                onChange={handleInputChange}
                style={{display: 'block'}}
            />
        </div>
    );
};

export default SearchInput;