import React, { FC } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Tag.scss'

interface TagProps {
    title: string
    DeleteTag: () => void
}

const Tag: FC<TagProps> = ({ title, DeleteTag }) => {

    return (
        <div className='green-tag'>
            <p className='green-tag_text'>{title}</p>
            <div className="green-tag_close" onClick={(e) => { DeleteTag() }}>
                <FontAwesomeIcon icon={faTimes} />
            </div>
        </div>
    );
};

export default Tag;