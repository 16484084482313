import React, {FC, useState} from 'react';
import {SortType} from 'rsuite-table';
import ToolBar from '../../ToolBar/ToolBar';
import CustomTable, {AutocompleteCellTypes} from '../../CustomTable/CustomTable'
import {TabPanel, TabTypes} from '../../../interfaces/TabInterface';
import FormBottomTable from "../../Forms/FormBottomTable/FormBottomTable";
import {UquarantProdForm} from "../../../shared/form.table.enviroment";

interface UquarantprodTabProps extends TabPanel {

}

const UquarantprodTab: FC<UquarantprodTabProps> = ({
                                                       idTab,
                                                       limit,
                                                       page,
                                                       sortColumn,
                                                       sortType,
                                                       content,
                                                       filters,
                                                       form
                                                   }) => {
    const [toggleForm, setToggleForm] = useState<boolean>(false)

    return (
        <div className="tab-wrap">
            <div className="table-container">
                <CustomTable
                    api={{
                        post: '/v_uquarantprods/all',
                        put: '/uquarantprods'
                    }}
                    idName={'iup_id'}
                    headerHeight={80}
                    idTab={idTab}
                    items={content?.items ? content.items : []}
                    limit={limit}
                    page={page}
                    sortType={sortType as SortType}
                    sortColumn={sortColumn}
                    totalItems={content?.totalItems}
                    filters={filters}
                    postForm={
                        {
                            sup_latname: '',
                            sup_rusname: '',
                            ikp_kinduqprod_id: '',
                            igr_gruqprod_id: '',
                            iup_id: '',
                            sup_fsksavail: '',
                            sup_ikravail: '',
                            sup_kfkavail: '',
                            nup_wgsmplavg: '',
                            sup_notquar: ''
                        }
                    }
                    setting={[
                        {
                            key: 'iup_id',
                            label: '',
                            link: true,
                            width: 50,
                            titleNameKey: 'sup_rusname',
                            tabType: TabTypes.UQPQOBJ_TAB
                        },
                        {
                            key: 'sup_latname',
                            label: 'Lat наименование',
                            width: 250,
                            sortable: true,
                            filtered: true,
                            readonly: (data) => {
                                return data.sup_delmark === 'Да'
                            },
                            readonlyColor: 'rgba(128, 128, 128, 0.4)'
                        },
                        {
                            key: 'sup_rusname',
                            label: 'Rus наименование',
                            width: 250,
                            sortable: true,
                            filtered: true,
                            readonly: (data) => {
                                return data.sup_delmark === 'Да'
                            },
                            readonlyColor: 'rgba(128, 128, 128, 0.4)'
                        },
                        {
                            key: 'sup_fsksavail',
                            label: 'Наличие ФС/КС',
                            width: 250,
                            autocomplete: AutocompleteCellTypes.MAIN,
                            readonly: (data) => {
                                return data.sup_delmark === 'Да'
                            },
                            readonlyColor: 'rgba(128, 128, 128, 0.4)'
                        },
                        {
                            key: 'sup_ikravail',
                            label: 'Наличие ИКР',
                            width: 250,
                            autocomplete: AutocompleteCellTypes.MAIN,
                            readonly: (data) => {
                                return data.sup_delmark === 'Да'
                            },
                            readonlyColor: 'rgba(128, 128, 128, 0.4)'
                        },
                        {
                            key: 'sup_kfkavail',
                            label: 'Наличие АКТ КФК',
                            width: 250,
                            autocomplete: AutocompleteCellTypes.MAIN,
                            readonly: (data) => {
                                return data.sup_delmark === 'Да'
                            },
                            readonlyColor: 'rgba(128, 128, 128, 0.4)'
                        },
                        {
                            key: 'nup_wgsmplavg',
                            label: 'Масса среднего образца (г)',
                            width: 250,
                            sortable: true,
                            readonly: (data) => {
                                return data.sup_delmark === 'Да'
                            },
                            readonlyColor: 'rgba(128, 128, 128, 0.4)'
                        },
                        {
                            key: 'skp_latname',
                            label: 'Lat наименование рода',
                            width: 250,
                            autocomplete: AutocompleteCellTypes.DEFAULT,
                            api: '/v_kinduqprods/join',
                            readonly: (data) => {
                                return data.sup_delmark === 'Да'
                            },
                            readonlyColor: 'rgba(128, 128, 128, 0.4)'
                        },
                        {
                            key: 'skp_rusname',
                            label: 'Rus наименование рода',
                            width: 250,
                            autocomplete: AutocompleteCellTypes.DEFAULT,
                            api: '/v_kinduqprods/join',
                            readonly: (data) => {
                                return data.sup_delmark === 'Да'
                            },
                            readonlyColor: 'rgba(128, 128, 128, 0.4)'
                        },
                        {
                            key: 'sgr_code',
                            label: 'Группа',
                            width: 250,
                            autocomplete: AutocompleteCellTypes.DEFAULT,
                            api: '/v_gruqprods/join',
                            readonly: (data) => {
                                return data.sup_delmark === 'Да'
                            },
                            readonlyColor: 'rgba(128, 128, 128, 0.4)'
                        },
                        {
                            key: 'sgr_name',
                            label: 'Полное наименование группы',
                            width: 250,
                            readonly: true
                        },
                        {
                            key: 'sup_notquar',
                            label: 'Неподкарантинная',
                            width: 250,
                            autocomplete: AutocompleteCellTypes.MAIN,
                            readonly: (data) => {
                                return data.sup_delmark === 'Да'
                            },
                            readonlyColor: 'rgba(128, 128, 128, 0.4)'
                        },
                        {
                            key: 'sup_tnved',
                            label: 'ТН ВЭД (Аргус)',
                            width: 250,
                            readonly: true
                        }
                    ]}
                />
                <FormBottomTable
                    open={toggleForm} onToggleForm={() => setToggleForm(prev => !prev)}
                    fields={UquarantProdForm}
                    api='/uquarantprods'
                    idTab={idTab}
                    form={form}
                    idName='iup_id'
                    postForm={
                        {
                            sup_latname: '',
                            sup_rusname: '',
                            ikp_kinduqprod_id: '',
                            igr_gruqprod_id: '',
                            sup_fsksavail: '',
                            sup_ikravail: '',
                            sup_kfkavail: '',
                            nup_wgsmplavg: '',
                            sup_notquar: ''
                        }
                    }/>
            </div>
            <ToolBar idTab={idTab} toggleForm={() => setToggleForm(state => !state)} api="/uquarantprods/"
                     idName={'iup_id'} tableNameExport={"v_uquarantprod"} />
        </div>
    );
};

export default UquarantprodTab;