import React, {FC, useEffect} from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru'

import "react-datepicker/dist/react-datepicker.css";
import './DataPickersWithLabel.scss'
import { Controller } from 'react-hook-form';


registerLocale('ru', ru)

interface DataPickersWithLabelProps {
    label: string
    widthInputBlock: number | string
    widthLabel: number | string
    widthInput: number | string
    onChangeInput?: (data: string, nameField: string) => void
    onDiffDate?: (date: string, field: string) => void
    nameField?: string
    value?: string
    control?: any
    rules?: any
    onBlurInput?: (nameField: string, data: Date | string) => void
    readonly?: boolean
}

const DataPickersWithLabel: FC<DataPickersWithLabelProps> = ({ label,
    widthInput,
    widthLabel,
    onChangeInput,
    widthInputBlock,
    value,
    control,
    onBlurInput,
    nameField,
    onDiffDate,
    rules,
    readonly }) => {

    return (
        <div className="data-picker-with-label-block">
            <p className="data-picker_text" style={{ width: widthLabel }}>{label}</p>
            <div className="data-pickers-wrap" style={{ width: widthInputBlock }}>
                <Controller
                    control={control}
                    name={nameField as string}
                    rules={rules}
                    defaultValue={value ? new Date(value as string) : null}
                    render={({ field: { onChange, onBlur, ref, value }, fieldState: { error } }) =>
                        <>
                            <DatePicker
                                className={`data-picker ${readonly ? 'grey' : ''}`}
                                selected={value}
                                onChange={(date: Date, event: React.ChangeEvent<HTMLInputElement>) => {
                                    onChange(date);
                                    if (event.type === 'click') {
                                        if (!date) return
                                        const fullDate = date.toLocaleDateString().split('.').reverse().join('-') + 'T' + date.toLocaleTimeString()
                                        onBlurInput && onBlurInput(nameField as string, fullDate);
                                        onChangeInput && onChangeInput(fullDate, nameField as string)
                                        onDiffDate && onDiffDate(fullDate, nameField as string);
                                    }
                                }}
                                onBlur={(event) => {
                                    if (!event.target.value.match(/(0?[1-9]|[12][0-9]|3[01])\.(0?[1-9]|1[012])\.((19|20)\d\d)/)) return
                                    console.log('match', event.target.value)
                                    const splitDate = event.target.value.split('.')
                                    const fullDate = splitDate[2] + '-' + splitDate[1] + '-' + splitDate[0] + 'T00:00:00'
                                    onBlurInput && onBlurInput(nameField as string, fullDate);
                                    onChangeInput && onChangeInput(fullDate, nameField as string)
                                    onDiffDate && onDiffDate(fullDate, nameField as string);
                                }}
                                locale="ru"
                                ref={ref}
                                readOnly={false}
                                showYearDropdown
                                scrollableYearDropdown
                                dateFormat="dd.MM.yyyy"
                            />
                            <p style={{ display: 'block', color: 'red' }}>{error && error.message}</p>
                        </>
                    }
                />
            </div>
        </div>
    );
};

export default DataPickersWithLabel;