import React, { FC, useState } from 'react';
import {IAddNormatDocForm, INormatDocTab, TabPanel, TabTypes} from '../../../interfaces/TabInterface';
import ToolBarItem from '../../ToolBarItem/ToolBarItem';
import FormAddNormatdoc from '../../Forms/FormAddNormatdoc/FormAddNormatdoc';
import { faPlusSquare, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import './NormatDocTab.scss';
import { useActions } from '../../../hooks/useAction';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import NormatdocBlock from '../../NormatdocBlock/NormatdocBlock';

interface NormatDocTabProps {
    idTab: number
    content: INormatDocTab
    form: IAddNormatDocForm
}

const NormatDocTab: FC<NormatDocTabProps> = ({ idTab, content, form }) => {

    const { normatdocs } = content
    const { tabs } = useTypedSelector(state => state.tab)
    const { DeleteNormatdocBlockReqForm } = useActions()
    const [toggleForm, setToggleForm] = useState<boolean>(false)


    const handleDeleteRows = () => {
        tabs.forEach((tab: any, idx: number) => {
            if (idx === idTab) {
                (tab.content as INormatDocTab)?.normatdocs.forEach((item) => {
                    if (item.checked === true)
                    {
                        DeleteNormatdocBlockReqForm(idTab, item.ind_id)
                    }
                })
            }
        })
    }

    return (
        <div className="reqq-wrap">
            <div style={{width: '75vw', height: '100%'}}>
                {toggleForm && <FormAddNormatdoc idTab={idTab} form={form}/>}
                
                {normatdocs.map((normatdoc, idx) => 
                <div>
                    
                    <NormatdocBlock 
                            key={normatdoc.ind_id}
                            id={normatdoc.ind_id}
                            checked = {normatdoc.checked}
                            title={normatdoc.snd_str}
                            typeTab={TabTypes.NORMAT_DOC_SPECIFIC_TAB} idTab={idTab}/>
                </div>)}
            </div>
            <div className="toolbar" style={{ height: 100 }}>
                <ToolBarItem icon={faTrashAlt} onClick={handleDeleteRows}/>
                <ToolBarItem icon={faPlusSquare} onClick={() => {setToggleForm(!toggleForm)}} />
            </div>
        </div>
    );
};

export default NormatDocTab;

