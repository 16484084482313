import React, {FC, useState} from 'react';
import {SortType} from 'rsuite-table';
import {TabPanel, TabTypes} from '../../../interfaces/TabInterface';
import ToolBar from '../../ToolBar/ToolBar';
import CustomTable, {AutocompleteCellTypes} from '../../CustomTable/CustomTable';
import FormBottomTable from "../../Forms/FormBottomTable/FormBottomTable";
import {CropsForm} from "../../../shared/form.table.enviroment";

interface CropsTabProps extends TabPanel {
}

const COMPUTED_CROP_NAME = (data: {[key: string]: any}) => data['srg_name'].slice(0,3).toUpperCase() + '-' + data['sdt_name'].slice(0,4).toUpperCase() + '-'
    + data['scu_rusname'].slice(0,2).toUpperCase() + '-'
    + (data['sct_name'] ? data['sct_name'].slice(0, 2).toUpperCase() : '00') + '-'
    + data['scr_field'] + '-' + data['scr_year']
    + (data['ncr_reseeding'] === 'Да' ? '-П' : '')

const CropsTab: FC<CropsTabProps> = ({idTab, limit, page, sortColumn, sortType, content, filters, form}) => {
    const [toggleForm, setToggleForm] = useState<boolean>(false)

    return (
        <div className="tab-wrap">
            <div className="table-container">
                <CustomTable
                    api={{
                        post: '/v_crops/all',
                        put: '/crops'
                    }}
                    idName={'icr_id'}
                    headerHeight={80}
                    idTab={idTab}
                    items={content?.items ? content.items : []}
                    limit={limit}
                    page={page}
                    sortType={sortType as SortType}
                    sortColumn={sortColumn}
                    totalItems={content?.totalItems}
                    filters={filters}
                    setting={[
                        {
                            key: 'icr_id',
                            label: '',
                            link: true,
                            width: 50,
                            titleNameKey: 'scr_name',
                            tabType: TabTypes.CROPSTAGES_TAB
                        },
                        {
                            key: 'scr_name',
                            label: 'Посев',
                            width: 250,
                            sortable: true,
                            filtered: true,
                            readonly: true,
                            computedValue: COMPUTED_CROP_NAME
                        },
                        {
                            key: 'scu_rusname',
                            label: 'Культура',
                            width: 250,
                            autocomplete: AutocompleteCellTypes.DEFAULT,
                            api: '/v_cults/join'
                        },
                        {
                            key: 'sct_name',
                            label: 'Тип культуры',
                            width: 250,
                            autocomplete: AutocompleteCellTypes.DEFAULT,
                            api: '/v_cult_types/join'
                        },
                        {
                            key: 'ncr_reseeding',
                            label: 'Повторный посев',
                            width: 250,
                            autocomplete: AutocompleteCellTypes.MAIN
                        },
                        {
                            key: 'srg_name',
                            label: 'Регион',
                            width: 250,
                            autocomplete: AutocompleteCellTypes.DEFAULT,
                            api: '/v_districts/join'
                        },
                        {
                            key: 'sdt_name',
                            label: 'Район',
                            width: 250,
                            autocomplete: AutocompleteCellTypes.DEFAULT,
                            api: '/v_districts/join'
                        },
                        {
                            key: 'scr_year',
                            label: 'Год',
                            width: 250
                        },
                        {
                            key: 'scr_field',
                            label: 'Поле',
                            width: 250,
                            sortable: true,
                            filtered: true,
                            validateFunc: (event) => {
                                const value = event.target.value
                                if (value.length >= 3) {
                                    const slicedValue = value.slice(0, 3)
                                    event.target.value = slicedValue
                                    return slicedValue
                                }
                                return event.target.value
                            }
                        }
                    ]}
                    postForm={{
                        scr_name: '',
                        icu_cult_id: '',
                        scr_reseeding: '',
                        ict_cult_type_id: '',
                        irg_region_id: '',
                        idt_district_id: '',
                        scr_year: '',
                        scr_field: '',
                        icr_id: ''
                    }}
                />
                <FormBottomTable
                    open={toggleForm} onToggleForm={() => setToggleForm(prev => !prev)}
                    idName={'icr_id'}
                    form={form}
                    fields={CropsForm}
                    postForm={{
                        scr_name: '',
                        icu_cult_id: '',
                        scr_reseeding: '',
                        ict_cult_type_id: '',
                        irg_region_id: '',
                        idt_district_id: '',
                        scr_year: '',
                        scr_field: '',
                    }}
                    api="/crops"
                    idTab={idTab}
                    additionalValues={(data) => ({
                        scr_name: COMPUTED_CROP_NAME(data)
                    })}
                />
            </div>
            <ToolBar idTab={idTab} toggleForm={() => setToggleForm(state => !state)} api="/crops/"
                     idName={'icr_id'} tableNameExport={'crops'} schema_name={'ais_weeds'}/>
        </div>
    );
};

export default CropsTab;