import React, { FC, useState } from 'react';
import {useEffect } from 'react';
import {authorization} from '../services/auth.service'
import {useAuth} from "../hooks/useAuth";
import {User} from "../interfaces/auth";
import {hostIdentity} from "../environments/environments";

interface PrivateRouteProps {
    children: React.ReactElement
}

const PrivateRoute: FC<PrivateRouteProps> = ({children, ...rest }) => {

    //OLD
    /*const [loaded, setLoaded] = useState<boolean>(false)*/
    const {user, signIn} = useAuth();

    //OLD
    /*useEffect(() => {
        Auth.getUser()
        .then((user) => {
            if (user) {
                setLoaded(true)
            } else {
                Auth.login()
                return <></>
            }
        })
        .catch((err) => console.log(err))
    }, [])

    return (<Route {...rest} render={({ location }) => {
        return loaded ?
            children
            : null
    }} />)*/

useEffect(() => {
       /* fetch(`${hostIdentity}/Authorization/verification`, {
            method: "GET",
            redirect: 'follow'
        })
            .then(authorization)
            .then(res => res.json())
            .then((user: User) => {
                signIn(user)
            })
            .catch(err => console.log(err))*/
    },[signIn])

    if (!user.userName) {
        return null
    }

    return (
        <>
            {children}
        </>
    );




};

export default PrivateRoute;