import React, {ChangeEventHandler, FC, useState} from 'react';
import {SortType} from 'rsuite-table';
import ToolBar from '../../ToolBar/ToolBar';
import CustomTable, {AutocompleteCellTypes} from '../../CustomTable/CustomTable'
import {TabPanel} from '../../../interfaces/TabInterface';
import FormBottomTable from "../../Forms/FormBottomTable/FormBottomTable";
import {UquarantObjForm} from '../../../shared/form.table.enviroment';
import {faFileImport} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {GetTableContent} from "../../../store/action-creators/tableActionCreator";
import {useDispatch} from "react-redux";
import {store} from "react-notifications-component";
import {ERROR_NOTIFY, getErrorNotification} from "../../../shared/notification";
import {CircularProgress} from "@mui/material";
import { host } from '../../../environments/environments';

interface UquarantObjTabProps extends TabPanel {

}

const UquarantObjTab: FC<UquarantObjTabProps> = ({
                                                     idTab,
                                                     limit,
                                                     page,
                                                     sortColumn,
                                                     sortType,
                                                     content,
                                                     filters,
                                                     form
                                                 }) => {

    const dispatch = useDispatch()

    const [loadingExcel, setLoadingExcel] = useState(false)
    const [toggleForm, setToggleForm] = useState<boolean>(false)

    const handleFileChange: ChangeEventHandler<HTMLInputElement> = async (event) => {
        setLoadingExcel(true)
        if (!event.target.files) return;
        const formData = new FormData()
        formData.append('file', event.target.files[0])
        try {
            const response = await fetch(host + '/kfzs/upload', {
                method: 'POST',
                body: formData
            })
            if (!response.ok) {
                store.addNotification(getErrorNotification('Ошибка при загрузке файла', 'Произошал ошибка при загрузке файла'))
                setLoadingExcel(false)
                return;
            }
            setLoadingExcel(false)
            dispatch(GetTableContent(idTab, page, limit, '/v_kfzs/all', {sortColumn, sortType, filters}))
        } catch (error) {
            store.addNotification(ERROR_NOTIFY)
        }

    }

    return (
        <div className="tab-wrap">
            <div className="table-container">
                <CustomTable
                    api={{
                        post: '/v_kfzs/all',
                        put: '/kfzs'
                    }}
                    idName={'ikf_id'}
                    headerHeight={80}
                    idTab={idTab}
                    items={content?.items ? content.items : []}
                    limit={limit}
                    page={page}
                    sortType={sortType as SortType}
                    sortColumn={sortColumn}
                    totalItems={content?.totalItems}
                    filters={filters}
                    postForm={{
                        skf_munits: '',
                        nkf_quant: '',
                        nkf_area: '',
                        skf_district: '',
                        iqo_quarantobj_id: '',
                        irg_region_id: '',
                        ikf_id: ''
                    }}
                    setting={[
                        {
                            key: 'srg_name',
                            label: 'Регион',
                            width: 200,
                            api: '/v_regionss/join',
                            autocomplete: AutocompleteCellTypes.DEFAULT,
                            filtered: true
                        },
                        {
                            key: 'skf_district',
                            label: 'Район',
                            width: 200,
                            filtered: true
                        },
                        {
                            key: 'sqo_latname',
                            label: 'Lat наименование',
                            width: 200,
                            readonly: true
                        },
                        {
                            key: 'sqo_rusname',
                            label: 'Rus наименование',
                            width: 200,
                            readonly: true,
                            api: '/v_quarantobjs/join',
                            autocomplete: AutocompleteCellTypes.DEFAULT
                        },
                        {
                            key: 'nkf_area',
                            label: 'Площадь',
                            width: 200
                        },
                        {
                            key: 'skf_munits',
                            label: 'Единицы измерения',
                            width: 200
                        },
                        {
                            key: 'nkf_quant',
                            label: 'Кол-во установленных карант. ф/с зон штук',
                            width: 450
                        }
                    ]}
                />
                <FormBottomTable
                    open={toggleForm} onToggleForm={() => setToggleForm(prev => !prev)}
                    fields={UquarantObjForm}
                    api='/kfzs'
                    idTab={idTab}
                    form={form}
                    idName='ikf_id'
                    postForm={{
                        nkf_quant: '',
                        nkf_area: '',
                        skf_district: '',
                        iqo_quarantobj_id: '',
                        irg_region_id: '',
                        skf_munits: ''
                    }}/>
            </div>
            <ToolBar idTab={idTab} toggleForm={() => setToggleForm(state => !state)} api="/kfzs/"
                     idName={'ikf_id'} tableNameExport={'v_kfz'}>
                <label htmlFor="loadKFZ">
                    <div className="toolbar-item">
                        {loadingExcel ? <CircularProgress sx={{color: '#05396B'}} size={15} /> : <FontAwesomeIcon icon={faFileImport}/>}
                        <input id="loadKFZ" type='file' accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" style={{display: 'none'}} onChange={handleFileChange}/>
                    </div>
                </label>
            </ToolBar>
        </div>
    );
};

export default UquarantObjTab;
