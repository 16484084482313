import React, { FC } from 'react';
import ContractorsPoint from '../ContractorsPoint/ContractorsPoint';
import PlaceSimpPoint from '../PlaceSimpPoint/PlaceSimpPoint';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { StyledEngineProvider } from '@mui/material/styles';
import { IMainForm } from '../../../../interfaces/TabInterface';
import CountriesRegionsPoint from '../CountriesRegionsPoint/CountriesRegionsPoint';
import AutocompleteWithLabel from '../../Inputs/AutocompleteWithLabel/AutocompleteWithLabel';
import { formsName, tagsName } from '../../../../shared/shared';
import { useCheckboxChange, useInputChange, useOtherChange } from '../../../../hooks/useChange';
import { useDeleteTags, useSaveDefaultField, useSaveNonStandartField, useSaveOtherField, useSaveTagsCntr, useSaveTagsRegions } from '../../../../hooks/useSave';

interface TerritoriesPointProps {
    idTab: number
    control?: any
    mainForm: IMainForm
}

const TerritoriesPoint: FC<TerritoriesPointProps> = ({ idTab, control, mainForm }) => {

    const [tabValue, setTabValue] = React.useState<string>('Страны');

    const handleOtherChange = useOtherChange(idTab, formsName.mainForm)
    const handleSaveAutocompleteImporter = useSaveNonStandartField(mainForm.requirements__irq_id, 'requirements', 'importer_id', mainForm.requirements__icn_id)

    //Для стран
    const handleCheckerChangeCntr = useCheckboxChange(idTab, formsName.mainForm)
    const handleBlurSaveChekerCntr = useSaveOtherField(mainForm.requirements__irq_id, '1')
    const handleSaveCntrTagTypeOne = useSaveTagsCntr(+mainForm.requirements__irq_id, 'rqcntrexports', 1, idTab, formsName.mainForm, tagsName.cntrExp)
    const handleDeleteCntrTagTypeOne = useDeleteTags(idTab, 'rqcntrexports', formsName.mainForm, tagsName.cntrExp)
    const handleSaveCntrTagTypeTwo = useSaveTagsCntr(+mainForm.requirements__irq_id, 'rqcntrexports', 2, idTab, formsName.mainForm, tagsName.cntrReexp)
    const handleDeleteCntrTagTypeTwo = useDeleteTags(idTab, 'rqcntrexports', formsName.mainForm, tagsName.cntrReexp)

    //Для регионов
    const handleCheckerChangeRegions = useCheckboxChange(idTab, formsName.mainForm)
    const handleBlurSaveChekerRegions = useSaveOtherField(mainForm.requirements__irq_id, '1')
    const handleSaveRegionsTagTypeOne = useSaveTagsRegions(+mainForm.requirements__irq_id, 'rqregexports', 1, idTab, formsName.mainForm, tagsName.regionsExp)
    const handleDeleteRegionsTagTypeOne = useDeleteTags(idTab, 'rqregexports', formsName.mainForm, tagsName.regionsExp)
    const handleSaveRegionsTagTypeTwo = useSaveTagsRegions(+mainForm.requirements__irq_id, 'rqregexports', 2, idTab, formsName.mainForm, tagsName.regionsReexp)
    const handleDeleteRegionsTagTypeTwo = useDeleteTags(idTab, 'rqregexports', formsName.mainForm, tagsName.regionsReexp)

    //Для контрагентов
    const handleCheckerChangeContrAgent = useCheckboxChange(idTab, formsName.mainForm)
    const handleBlurSaveChekerContrAgent = useSaveOtherField(mainForm.requirements__irq_id, '1')
    const handleChangeContrAgent = useInputChange(idTab, formsName.mainForm)
    const handeBlurSaveStringContrAgent = useSaveDefaultField(mainForm.requirements__irq_id, '0')

    //  const handleSaveRegionsTagTypeOne = useSaveTagsRegions( +mainForm.requirements__irq_id ,'rqregexports', 1, idTab, formsName.mainForm, tagsName.regionsExp)
    //  const handleDeleteRegionsTagTypeOne = useDeleteTags(idTab, 'rqregexports', formsName.mainForm, tagsName.regionsExp)
    //  const handleSaveRegionsTagTypeTwo = useSaveTagsRegions( +mainForm.requirements__irq_id, 'rqregexports', 2, idTab, formsName.mainForm, tagsName.regionsReexp)
    //  const handleDeleteRegionsTagTypeTwo = useDeleteTags(idTab, 'rqregexports', formsName.mainForm, tagsName.regionsReexp)

    //Для мест ввоза
    // const handleCheckerChangeRegions = useCheckboxChange(idTab, formsName.mainForm)
    // const handleBlurSaveChekerRegions = useSaveOtherField(mainForm.requirements__irq_id, '1')
    // const handleSaveRegionsTagTypeOne = useSaveTagsRegions( +mainForm.requirements__irq_id ,'rqregexports', 1, idTab, formsName.mainForm, tagsName.regionsExp)
    // const handleDeleteRegionsTagTypeOne = useDeleteTags(idTab, 'rqregexports', formsName.mainForm, tagsName.regionsExp)
    // const handleSaveRegionsTagTypeTwo = useSaveTagsRegions( +mainForm.requirements__irq_id, 'rqregexports', 2, idTab, formsName.mainForm, tagsName.regionsReexp)
    // const handleDeleteRegionsTagTypeTwo = useDeleteTags(idTab, 'rqregexports', formsName.mainForm, tagsName.regionsReexp)


    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        event.preventDefault()
        event.stopPropagation()
        setTabValue(newValue)
    }

    return (
        <div style={{
            display: 'grid',
            gridTemplateRows: '1fr',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            gridGap: '10px 1vw'
        }}>

            <div style={{
                gridRowStart: 1,
                gridRowEnd: 2,
                gridColumnStart: 1,
                gridColumnEnd: 3
            }}>
                <AutocompleteWithLabel
                    control={control}
                    widthInput={'100%'}
                    widthInputBlock={'75%'}
                    widthLabel={'25%'}
                    label={"Импортёр"}
                    nameField="scn_code"
                    value={mainForm.requirements__scn_code}
                    api={'/v_clwrldcntrs/join'}
                    onChangeInput={handleOtherChange}
                    onBlurInput={handleSaveAutocompleteImporter}
                />
            </div>

            <div style={{
                gridRowStart: 2,
                gridRowEnd: 3,
                gridColumnStart: 1,
                gridColumnEnd: 5
            }}>
                <StyledEngineProvider injectFirst>

                    <TabContext value={tabValue}>
                        <TabList onChange={handleChangeTab}>
                            <Tab label="Страны" value="Страны" />
                            <Tab label="Регионы" value="Регионы" />
                            <Tab label="Контрагенты" value="Контрагенты" />
                            <Tab label="Места ввоза" value="Места ввоза" />
                        </TabList>
                        <TabPanel value="Страны"><CountriesRegionsPoint
                            handleCheckerChange={handleCheckerChangeCntr}
                            handleBlurSaveCheker={handleBlurSaveChekerCntr}
                            handleSaveTagTypeOne={handleSaveCntrTagTypeOne}
                            handleDeleteTagTypeOne={handleDeleteCntrTagTypeOne}
                            handleSaveTagTypeTwo={handleSaveCntrTagTypeTwo}
                            handleDeleteTagTypeTwo={handleDeleteCntrTagTypeTwo}
                            control={control}
                            api="/v_clwrldcntrs/join"
                            idSearch="icn_id"
                            nameSearch="scn_code"
                            tagIdName="irc_id"
                            tagValueName="scn_name"
                            exporters={mainForm.cntrExp}
                            reexporters={mainForm.cntrReexp}
                            valueCheker={mainForm.requirements__freezonecntr}
                            nameChekerField="requirements__freezonecntr"
                        /></TabPanel>
                        <TabPanel value="Регионы">
                            <CountriesRegionsPoint
                                handleCheckerChange={handleCheckerChangeRegions}
                                handleBlurSaveCheker={handleBlurSaveChekerRegions}
                                handleSaveTagTypeOne={handleSaveRegionsTagTypeOne}
                                handleDeleteTagTypeOne={handleDeleteRegionsTagTypeOne}
                                handleSaveTagTypeTwo={handleSaveRegionsTagTypeTwo}
                                handleDeleteTagTypeTwo={handleDeleteRegionsTagTypeTwo}
                                control={control}
                                api="/v_regionss/join"
                                idSearch="irg_id"
                                nameSearch="srg_name"
                                tagIdName="ire_id"
                                tagValueName="nrg_name"
                                exporters={mainForm.regionsExp}
                                reexporters={mainForm.regionsReexp}
                                valueCheker={mainForm.requirements__freezonereg}
                                nameChekerField="requirements__freezonereg" />
                        </TabPanel>
                        <TabPanel value="Контрагенты">
                            <ContractorsPoint

                                // Общие пропсы
                                control={control}
                                mainForm={mainForm}
                                idTab={idTab}

                                //Свободная зона от КО (экспортеры)
                                handleCheckerChange={handleCheckerChangeContrAgent}
                                handleBlurSaveCheker={handleBlurSaveChekerContrAgent}
                                valueCheker={mainForm.requirements__freezoneagn}
                                nameChekerField="requirements__freezoneagn"

                                // Условия транзита экспортёра
                                handleChangeInputConditionOfTransit={handleChangeContrAgent}
                                handeBlurSaveStringConditionOfTransit={handeBlurSaveStringContrAgent}
                                valueConditionOfTransit={mainForm.condtrans}

                            /></TabPanel>
                        <TabPanel value="Места ввоза">
                            <PlaceSimpPoint
                                control={control}
                                mainForm={mainForm}
                                idTab={idTab}
                            /></TabPanel>
                    </TabContext>

                </StyledEngineProvider >
            </div>


        </div>
    );
};

export default TerritoriesPoint;