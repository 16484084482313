import moment from 'moment';
import React, {FC} from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { host } from '../../../environments/environments';
import { useActions } from '../../../hooks/useAction';
import { IAddNormatDocForm } from '../../../interfaces/TabInterface';
import AutocompleteWithLabel from '../../RequiredForm/Inputs/AutocompleteWithLabel/AutocompleteWithLabel';
import CheckBoxWithLabel from '../../RequiredForm/Inputs/CheckBoxWithLabel/CheckBoxWithLabel';
import DataPickersWithLabel from '../../RequiredForm/Inputs/DataPickersWithLabel/DataPickersWithLabel';
import InputWithLabel from '../../RequiredForm/Inputs/InputWithLabel/InputWithLabel';
import { store } from 'react-notifications-component';
import { formsName } from '../../../shared/shared';
import { useCheckboxChange, useInputChange, useOtherChange } from '../../../hooks/useChange';
import {SUCCESS_NOTIFY} from "../../../shared/notification";

interface FormAddNormatdocProps {
    idTab: number
    form: IAddNormatDocForm
}

const FormAddNormatdoc: FC<FormAddNormatdocProps> = ({ idTab, form }) => {

    const { control, handleSubmit, reset } = useForm<IAddNormatDocForm>();

    const { GetNormatdocsList, ResetNormatdocForm } = useActions()

    /*const [dateEnd, setDateEnd] = useState<string>('')
    const [months, setMonths] = useState<string>('')
    const [dateWith, setDateWith] = useState<string>('')

    const { SetNormatdocFormValue } = useActions()*/

    const handleChange = useInputChange(idTab, formsName.addNormatdocForm)
    const handleCheckerChange = useCheckboxChange(idTab, formsName.addNormatdocForm)
    const handleChangeDate = useOtherChange(idTab, formsName.addNormatdocForm)

    const onSubmit: SubmitHandler<IAddNormatDocForm> = async (data) => {
        const object = {
            name: form.name,
            doctype_id: form.idt_id,
            docnumb: form.docnumb,
            begdate: form.begdate ? new Date(form.begdate) : null,
            docdate: form.docdate ? new Date(form.docdate) : null,
            relevance: form.relevance ? 1 : 0
        }

        const response = await fetch(`${host}/normatdocs`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(object)
        })

        if (response.status === 200) {
            store.addNotification(SUCCESS_NOTIFY)
            GetNormatdocsList(idTab)
            ResetNormatdocForm(idTab)
            reset()
        }

    }

 /*   useEffect(() => {

        if (!dateEnd && form.period_end) {
            setDateEnd(form.period_end)
        }

        if (!dateWith && form.period_beg) {
            setDateWith(form.period_beg)
        }

        if (!months && form.permonths) {
            setMonths(form.period_beg)
        }

        if (form.period_end && form.period_end !== dateEnd && form.period_beg) {
            const dateStart = moment(new Date(form.period_beg))
            const dateEnd = moment(new Date(form.period_end))
            let months = dateEnd.diff(dateStart, 'months')
            SetReqFormValue(idTab, formsName.addReqForm, 'permonths', months.toString())
            setDateEnd(form.period_end)
        }

        if (form.permonths && form.permonths !== months && form.period_beg) {
            const dateStart = new Date(form.period_beg)
            const dateEnd = new Date(dateStart.setMonth(dateStart.getMonth() + Number(form.permonths)))
            SetReqFormValue(idTab, formsName.addReqForm, 'period_end', `${dateEnd.getFullYear()}.${+dateEnd.getMonth() + 1}.${dateEnd.getDate()}`)
            setMonths(form.permonths)
        }

        if (form.period_end && form.period_beg !== dateWith && form.period_end ) {
            console.log('set months 3')
            const dateStart = moment(new Date(form.period_beg))
            const dateEnd = moment(new Date(form.period_end))
            let months = dateEnd.diff(dateStart, 'months')
            SetReqFormValue(idTab, formsName.addReqForm, 'permonths', months.toString())
            setDateEnd(form.period_end)
        }

    }, [form.period_beg, form.period_end, form.permonths])*/

    return (
        <form style={{ border: '1px dotted #B7BCC9', padding: '10px', marginBottom: '10px' }} onSubmit={handleSubmit(onSubmit)}>
            <div style={{
                display: 'grid',
                gridTemplateRows: '1fr 1fr 1fr',
                gridTemplateColumns: '1.2fr 1.2fr 1fr 1fr 1fr 1fr',
                height: '100%',
                gridGap: '0 1vw'
            }}>

                <div style={{
                    gridRowStart: 1,
                    gridRowEnd: 2,
                    gridColumnStart: 1,
                    gridColumnEnd: 5
                }}>
                    <InputWithLabel widthInput={'100%'} widthInputBlock={'90%'} widthLabel={'15%'} label={"Наименование"}
                        control={control}
                        nameField="name"
                        onChangeInput={handleChange}
                        value={form.name}
                        rules={{
                            required: 'Поле не может быть пустым'
                        }}
                    />

                    {/* <DataPickersWithLabel label="Период действия с" widthInput={'100%'} widthInputBlock={'60%'} widthLabel={'40%'}
                        control={control}
                        nameField="period_beg"
                        onChangeInput={handleChangeDate}
                        value={form.period_beg}
                    /> */}
                </div>

                <div style={{
                    gridRowStart: 2,
                    gridRowEnd: 2,
                    gridColumnStart: 1,
                    gridColumnEnd: 7
                }}>
                    <AutocompleteWithLabel widthInput={'100%'} widthInputBlock={'60%'} widthLabel={'40%'} label={"Документ"}
                        control={control}
                        nameField="sdt_code"
                        onChangeInput={handleChangeDate}
                        value={form.sdt_code}
                        rules={{
                            required: 'Поле не может быть пустым'
                        }}
                        api={'/v_doctypess/join'}
                    />
                    <InputWithLabel widthInput={'100%'} widthInputBlock={'90%'} widthLabel={'15%'} label={"№"}
                        control={control}
                        nameField="docnumb"
                        onChangeInput={handleChange}
                        value={form.docnumb}
                        rules={{
                            required: 'Поле не может быть пустым'
                        }}
                    />
                    {/* <CheckBoxWithLabel label={"Актуальность"}
                        nameField="relevance"
                        onChangeInput={handleCheckerChange}
                        value={form.relevance}
                        control={control}
                    />
                    <InputWithLabel widthInput={'100%'} widthLabel={'10%'} widthInputBlock={'90%'} label={"№"}
                        control={control}
                        onChangeInput={handleChange}
                        nameField="reqnumb"
                        value={form.reqnumb}
                        rules={{
                            required: 'Поле не может быть пустым'
                        }}
                    /> */}
                    <DataPickersWithLabel label="Дата" widthInput={'100%'} widthLabel={'10%'} widthInputBlock={'90%'}
                        control={control}
                        nameField="docdate"
                        onChangeInput={handleChangeDate}
                        value={form.docdate}
                    />
                </div>

                <div style={{
                    gridRowStart: 3,
                    gridRowEnd: 3,
                    gridColumnStart: 1,
                    gridColumnEnd: 7
                }}>
                     <DataPickersWithLabel label="Начало действия" widthInput={'100%'} widthLabel={'10%'} widthInputBlock={'90%'}
                        control={control}
                        nameField="begdate"
                        onChangeInput={handleChangeDate}
                        value={form.begdate}
                    />
                    <CheckBoxWithLabel label={"Актуальность"}
                        nameField="relevance"
                        onChangeInput={handleCheckerChange}
                        value={form.relevance}
                        control={control}
                    />
                    {/* <InputWithLabel widthInput={'100%'} widthLabel={'30%'} widthInputBlock={'70%'} label={""}
                        control={control}
                        nameField="permission"
                        onChangeInput={handleChange}
                        value={form.permission} />
                    <DataPickersWithLabel label="Дата" widthLabel={'30%'} widthInputBlock={'70%'} widthInput={'100%'}
                        control={control}
                        nameField="reqdate"
                        onChangeInput={handleChangeDate}
                        value={form.reqdate}
                        rules={{
                            required: 'Поле не может быть пустым'
                        }}
                    />
                    <InputWithLabel label="Месяцев" widthLabel={'30%'} widthInputBlock={'70%'} widthInput={'100%'}
                        control={control}
                        nameField="permonths"
                        onChangeInput={handleChange}
                        value={form.permonths}
                    /> */}
                </div>


            </div>
            <input type="submit" className="form-under-table_button submit" value="Сохранить" />
        </form>
    );
};

export default FormAddNormatdoc;