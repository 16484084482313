export enum MunitsTabActionsType {
    GET_CONTENT = 'GET_CONTENT',
    UPD_CONTENT = 'UPD_CONTENT',
    START_FETCH = 'START_FETCH',
    END_FETCH = 'END_FETCH',
    ERROR_PUT_CONTENT = 'PUT_CONTENT',
    RESET_ERROR = 'RESET_ERROR',
    TOGGLE_CHECKBOX = 'TOOGLE_CHECKBOX',
    TOGGLE_ALL_CHECKBOX = 'TOGGLE_ALL_CHECKBOX',
    DELETE_ROW = 'DELETE_ROW',
    POST_ROW = 'POST_ROW'
}

export interface GetContent {
    type: MunitsTabActionsType.GET_CONTENT,
    payload: {
        idTab: number,
        content: {[key: string]: any}[]
    }
}

export interface UpdContent {
    type: MunitsTabActionsType.UPD_CONTENT,
    payload: {
        idTab: number,
        idContent: number,
        dataKey: string,
        value: string | number
    }
}

export interface StartFetch {
    type: MunitsTabActionsType.START_FETCH,
    payload: {
        idTab: number
    }
}

export interface EndFetch {
    type: MunitsTabActionsType.END_FETCH,
    payload: {
        idTab: number
    }
}

export interface ErrorPutContent {
    type: MunitsTabActionsType.ERROR_PUT_CONTENT,
    payload: {
        idTab: number,
        message: any
    }
}

export interface ResetError {
    type: MunitsTabActionsType.RESET_ERROR,
    payload: {
        idTab: number
    }
}

export interface ToggleCheckbox {
    type: MunitsTabActionsType.TOGGLE_CHECKBOX,
    payload: {
        idTab: number,
        idItem: number
    }
}

export interface ToggleAllCheckbox {
    type: MunitsTabActionsType.TOGGLE_ALL_CHECKBOX,
    payload: {
        idTab: number,
        checked: boolean
    }
}

export interface DeleteRow {
    type: MunitsTabActionsType.DELETE_ROW,
    payload: {
        idTab: number
        idItem: number
    }
}

export interface PostRow {
    type: MunitsTabActionsType.POST_ROW,
    payload: {
        object: { [key: string]: string | number | boolean | null | undefined },
        api: string,
        idTab: number
    }
}

export type MunitsTabAction = GetContent
    | UpdContent
    | StartFetch
    | EndFetch
    | ErrorPutContent
    | ResetError
    | ToggleCheckbox
    | ToggleAllCheckbox
    | DeleteRow
    | PostRow