import React, { ChangeEvent, FC } from 'react';
import InputWithLabel from '../../Inputs/InputWithLabel/InputWithLabel';
import AutocompleteWithLabel from '../../Inputs/AutocompleteWithLabel/AutocompleteWithLabel';
import CheckBoxWithLabel from '../../Inputs/CheckBoxWithLabel/CheckBoxWithLabel';
import TextAreaWithLabel from '../../../TextAreaWithLabel/TextAreaWithLabel';
import AutocompleteWithTags from '../../Inputs/AutocompleteWithTags/AutocompleteWithTags';
import { useDeleteArrayFields, useDeleteTags, useSaveArrayFieldAgnExp, useSaveTagsAgnReexp } from '../../../../hooks/useSave';
import { IMainForm } from '../../../../interfaces/TabInterface';
import { formsName, tagsName } from '../../../../shared/shared';
import { useArrayFieldChangeAutocomplete, usePlusClick } from '../../../../hooks/useChange';

interface ContractorsPointProps {
    // Общие пропсы
    control?: any
    mainForm: IMainForm
    idTab: number

    //Свободная зона от КО (экспортеры)
    handleCheckerChange: (event: ChangeEvent<HTMLInputElement>) => void
    handleBlurSaveCheker: (nameField: string, value: string | Date | boolean) => void
    nameChekerField: string,
    valueCheker: boolean

    // Условия транзита экспортёра
    handleChangeInputConditionOfTransit: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handeBlurSaveStringConditionOfTransit: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    valueConditionOfTransit: string

}


const ContractorsPoint: FC<ContractorsPointProps> = ({
    control,
    mainForm,
    idTab,

    //Свободная зона от КО (экспортеры)
    handleCheckerChange,
    handleBlurSaveCheker,
    nameChekerField,
    valueCheker,

    // Условия транзита экспортёра
    handleChangeInputConditionOfTransit,
    handeBlurSaveStringConditionOfTransit,
    valueConditionOfTransit }) => {

    //Для эскпортёров
    const handleArrayFieldChangeAutocomplete = useArrayFieldChangeAutocomplete(idTab, formsName.mainForm, tagsName.agnExp)
    const handleArrayFieldSaveAgnExp = useSaveArrayFieldAgnExp(idTab, mainForm.requirements__irq_id, formsName.mainForm, tagsName.agnExp, 'rqagnexports', 'exporter_id', 'ira_id')
    
    //По клику на плюс
    const onPlusChange = usePlusClick(idTab, formsName.mainForm, tagsName.agnExp, {
        ira_id: '',
        icn_clwrldcntr_id: '',
        iea_id: '',
        sea_code: '',
        sea_addrprod: ''
    })

    //По клику на минус
    const handleDeleteArrayField = useDeleteArrayFields(idTab, 'rqagnexports', formsName.mainForm, tagsName.agnExp)

    //Для реэкспортёровs
    const handleSaveReexpTagTypeOne = useSaveTagsAgnReexp(+mainForm.requirements__irq_id, 'rqagnexports', 2, idTab, formsName.mainForm, tagsName.agnReexp)
    const handleDeleteReexpTags = useDeleteTags(idTab, 'rqagnexports', formsName.mainForm, tagsName.agnReexp)

    return (
        <div style={{
            display: 'grid',
            gridTemplateRows: '1fr 1fr 3fr 10fr',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            height: '100vh',
            gridGap: '0 1vw'
        }}>

            <div style={{
                gridRowStart: 1,
                gridRowEnd: 2,
                gridColumnStart: 1,
                gridColumnEnd: 3
            }}>
                <CheckBoxWithLabel
                    label={"Свободная зона от КО (экспортеры)"}
                    control={control}
                    value={valueCheker}
                    onChangeInput={handleCheckerChange}
                    onBlurInput={handleBlurSaveCheker}
                    nameField={nameChekerField}
                />
            </div>

            <div style={{
                gridRowStart: 2,
                gridRowEnd: 3,
                gridColumnStart: 1,
                gridColumnEnd: 3
            }}>
                {mainForm.agnExp.map(((exp, idx) => <AutocompleteWithLabel
                    key={idx}
                    control={control}
                    label={'Экспортёры'}
                    widthInput={'100%'}
                    widthInputBlock={'60%'}
                    widthLabel={'40%'}
                    nameField="sea_code"
                    api={'/v_exporterss/join'}
                    plus={idx === mainForm.agnExp.length - 1}
                    minus={idx !== mainForm.agnExp.length - 1}
                    onChangeInput={handleArrayFieldChangeAutocomplete(idx)}
                    onBlurInput={handleArrayFieldSaveAgnExp(idx, exp)}
                    onPlusClick={onPlusChange}
                    onMinusClick={handleDeleteArrayField(exp.ira_id)}
                    value={exp.sea_code}
                />))}
            </div>

            <div style={{
                gridRowStart: 2,
                gridRowEnd: 3,
                gridColumnStart: 3,
                gridColumnEnd: 5
            }}>
                {mainForm.agnExp.map((exp, idx) => <InputWithLabel
                    key={idx}
                    control={control}
                    label={'Адрес производства'}
                    widthInput={'100%'}
                    widthInputBlock={'70%'}
                    widthLabel={'30%'}
                    value={exp.sea_addrprod}
                    readonly
                />)}
                
            </div>

            <div style={{
                gridRowStart: 3,
                gridRowEnd: 4,
                gridColumnStart: 1,
                gridColumnEnd: 5
            }}>
                <TextAreaWithLabel height={200} label="Условия транзита экспортёра" widthInput={'100%'} widthInputBlock={'80%'} widthLabel={'20%'}
                    control={control}
                    nameField="requirements__condtrans"
                    onChangeInput={handleChangeInputConditionOfTransit}
                    value={valueConditionOfTransit}
                    onBlurInput={handeBlurSaveStringConditionOfTransit}
                />
                <AutocompleteWithTags
                    control={control}
                    label={'Реэкспортёры'}
                    widthInput={'400px'}
                    widthInputBlock={'80%'}
                    widthLabel={'20%'}
                    onBlurInput={handleSaveReexpTagTypeOne}
                    onDeleteTag={handleDeleteReexpTags}
                    api={'/v_exporterss/join'}
                    tagIdName='ira_id'
                    tagValueName='sea_code'
                    idSearch='iea_id'
                    nameSearch='sea_code'
                    dopName='icn_clwrldcntr_id'
                    tags={mainForm.agnReexp}
                />
            </div>

        </div>
    );
};

export default ContractorsPoint;