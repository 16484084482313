import React, { FC } from 'react';
import { TabTypes } from '../../interfaces/TabInterface';
import {useActions} from '../../hooks/useAction';

import './DefaulLink.scss'

interface DefaulLinkProps {
    icon?: string
    text: string,
    typeTab: TabTypes
}

const DefaulLink: FC<DefaulLinkProps> = ({text, typeTab, icon}) => {

    const {AddTab} = useActions()

    return (
        <div className="menu-link_lite">
            {icon && <img src={icon} alt={text}/>}
            <p onClick={() => AddTab(text, typeTab)}>{text}</p>
        </div>
    );
};

export default DefaulLink;