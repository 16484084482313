import React, {FC} from 'react';
import {Controller} from 'react-hook-form';
import {Control} from "react-hook-form/dist/types/form";
import {useActions} from "../../../../../hooks/useAction";
import {fieldType} from "../../../../../interfaces/FormButtomTableInterface";

interface DefaultInputProps {
    field: fieldType,
    control: Control<{ [p: string]: any }, object>,
    idTab: number
}

const DefaultInput: FC<DefaultInputProps> = ({
                                                 field,
                                                 control,
                                                 idTab
                                             }) => {

    const {SetFormValue} = useActions()

    const handleBlurInput = (e: React.FocusEvent<HTMLInputElement>) => SetFormValue(idTab, e.target.name, e.target.value)

    return (
        <Controller
            name={field.key}
            control={control}
            rules={field.rules}
            render={({field: {onChange, value, name}, formState: {errors}}) =>
                <div className="form-bottom-table_field" style={{width: field.widthField, marginLeft: field.marginLeft}}>
                    <label className="form-bottom-table_label">{field.label}</label>
                    <div className="form-bottom-table_input_error_wrap">
                        <input className={`form-bottom-table_input ${errors[field.key] ? 'error' : ''}`}
                               style={{width: field.widthInput}}
                               name={name}
                               value={value}
                               onChange={(event) => {
                                   onChange(field.validateFunc ? field.validateFunc(event): event.target.value)
                               }}
                               onBlur={handleBlurInput}
                               readOnly={field.readonly}
                        />
                        {errors[field.key]?.type === 'required' &&
                            <span className='error-message'>{errors[field.key].message}</span>}
                        {errors[field.key]?.type === 'pattern' &&
                            <span className='error-message'>{errors[field.key].message}</span>}
                    </div>
                </div>
            }
        />
    );
};

export default DefaultInput;