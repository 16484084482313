import React, {FC} from 'react';
import {fieldType} from "../../../../../interfaces/FormButtomTableInterface";
import {Control} from "react-hook-form/dist/types/form";
import {Controller} from "react-hook-form";
import {useActions} from "../../../../../hooks/useAction";

interface CheckboxInputProps {
    field: fieldType,
    control: Control<{ [p: string]: any }, object>,
    idTab: number
}

const CheckboxInput: FC<CheckboxInputProps> = ({
                                                   field,
                                                   control,
                                                   idTab,
                                               }) => {

    const {SetFormValue} = useActions()

    return (
        <Controller
            name={field.key}
            control={control}
            rules={field.rules}
            render={({field: {onChange, value, name}, formState: {errors}}) =>
                <div className="form-bottom-table_field"
                     style={{width: field.widthField, marginLeft: field.marginLeft}}>
                    <label className="form-bottom-table_label">{field.label}</label>
                    <div className="form-bottom-table_input_error_wrap">
                        <input
                            className='form-bottom-table_checker'
                            type="checkbox"
                            name={name}
                            checked={value === 'Да'}
                            onChange={(val: React.ChangeEvent<HTMLInputElement>) => {
                                onChange(val.target.checked ? 'Да' : 'Нет')
                                SetFormValue(idTab, name, val.target.checked ? 'Да' : 'Нет')
                            }}
                        />
                    </div>
                </div>
            }
        />
    );
};

export default CheckboxInput;