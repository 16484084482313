export enum normatdocFormActionsType {
    ADD_TAGS_ND = 'ADD_TAGS_ND',
    SET_ND_FORM_VALUE = 'SET_ND_FORM_VALUE',
    SET_ND_FORM_TOGGLE_CHEKER = 'SET_ND_FORM_TOGGLE_CHEKER',
    START_FETCH_NORMATDOC = 'START_FETCH_NORMATDOC',
    SUCCES_FETCH_NORMATDOC = 'SUCCES_FETCH_NORMATDOC',
    ERROR_FETCH_NORMATDOC = 'ERROR_FETCH_NORMATDOC',
    GET_NORMATDOCS_LIST = 'GET_NORMATDOCS_LIST',
    RESET_ND_FORM = 'RESET_ND_FORM',
    UPD_FIELD_ND_FORM = 'UPD_FIELD_ND_FORM',
    RESET_STATUS_FORM_ND = 'RESET_STATUS_FORM_ND',
    DELETE_TAG_ND = 'DELETE_TAG_ND',
    DELETE_NORMATDOC_BLOCK = 'DELETE_NORMATDOC_BLOCK'
}

export enum statusesND {
    DATA_GET = 'DATA_GET',
    DATA_SAVE = 'DATA_SAVE',
    DATA_DELETE = 'DATA_SAVE'
}

export interface formStateND {
    error: string,
    status: string
}

export interface startFetchND {
    type: normatdocFormActionsType.START_FETCH_NORMATDOC
}

export interface getSuccessND {
    type: normatdocFormActionsType.SUCCES_FETCH_NORMATDOC,
    payload: {
        message: string
    }
}

export interface getErrorND {
    type: normatdocFormActionsType.ERROR_FETCH_NORMATDOC,
    payload: {
        message: string
    }
}

export interface AddTagND {
    type: normatdocFormActionsType.ADD_TAGS_ND,
    payload: {
        idTab: number
        nameForm: string
        nameTags: string
        tag: {[key: string]: any}     
    }
}

export interface DeleteNDTag {
    type: normatdocFormActionsType.DELETE_TAG_ND,
    payload: {
        idTab: number
        nameForm: string
        nameTags: string,
        idTag: string
    }
}
export interface DeleteNORMATDOCBlock {
    type: normatdocFormActionsType.DELETE_NORMATDOC_BLOCK,
    payload: {
        idTab: number
        idNormatdoc: string
    }
}


export interface SetNDFormValue {
    type: normatdocFormActionsType.SET_ND_FORM_VALUE,
    payload: {
        idTab: number
        nameForm: string
        nameField: string
        value: string
        dopField: string | undefined
    }
}

export interface SetNDFormToggleCheker {
    type: normatdocFormActionsType.SET_ND_FORM_TOGGLE_CHEKER,
    payload: {
        idTab: number
        nameForm: string
        nameField: string
    }
}

export interface GetNormatDocList {
    type: normatdocFormActionsType.GET_NORMATDOCS_LIST,
    payload: {
        idTab: number,
        NORMATDOCs: {
            irq_id: string,
            str: string,
            permission: number
        }[]
    }
}

export interface ResetNormatDocFom {
    type: normatdocFormActionsType.RESET_ND_FORM,
    payload: {
        idTab: number
    }
}

export interface ResetStatusForm {
    type: normatdocFormActionsType.RESET_STATUS_FORM_ND
}

export type normotdocFormAction =  AddTagND
    | SetNDFormValue
    | SetNDFormToggleCheker
    | startFetchND
    | getSuccessND
    | getErrorND
    | GetNormatDocList
    | ResetNormatDocFom
    | ResetStatusForm
    | DeleteNDTag
    | DeleteNORMATDOCBlock
