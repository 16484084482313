import React, { FC, useState } from 'react';
import { SortType } from 'rsuite-table';
import ToolBar from '../../ToolBar/ToolBar';
import CustomTable, {AutocompleteCellTypes} from '../../CustomTable/CustomTable'
import {IContentTable, TabPanel, TabTypes} from '../../../interfaces/TabInterface';
import FormBottomTable from "../../Forms/FormBottomTable/FormBottomTable";
import {ClwrldCntrForm} from "../../../shared/form.table.enviroment";

interface ClwrldcntrTabProps extends TabPanel {

}

const ClwrldcntrTab: FC<ClwrldcntrTabProps> = ({ idTab, limit, page, sortColumn, sortType, content, filters, form }) => {

    const [toggleForm, setToggleForm] = useState<boolean>(false)

    return (
        <div className="tab-wrap">
            <div className="table-container">
                <CustomTable
                    api={{
                        post: '/v_clwrldcntrs/all',
                        put: '/clwrldcntrs'
                    }}
                    idName={'icn_id'}
                    headerHeight={80}
                    idTab={idTab}
                    items={content?.items ? content.items : []}
                    limit={limit}
                    page={page}
                    sortType={sortType as SortType}
                    sortColumn={sortColumn}
                    totalItems={content?.totalItems}
                    filters={filters}
                    setting={[
                        {
                            key: 'icn_id',
                            label: '',
                            link: true,
                            width: 50,
                            titleNameKey: 'scn_name',
                            tabType: TabTypes.CNTRQOBJ_TAB,
                            fieldNameNestedExist: 'scn_qobj'
                        },
                        {
                            key: 'ncn_numcode',
                            label: 'Цифровой код',
                            width: 200,
                        },
                        {
                            key: 'scn_code',
                            label: 'Краткое наименование',
                            width: 250,
                            sortable: true,
                            filtered: true
                        },
                        {
                            key: 'scn_name',
                            label: 'Полное наименование',
                            width: 250,
                            sortable: true,
                            filtered: true
                        },
                        {
                            key: 'scn_letcode2',
                            label: 'Буквенный код 2',
                            width: 250,
                        },
                        {
                            key: 'scn_letcode3',
                            label: 'Буквенный код 3',
                            width: 250,
                        },
                        {
                            key: 'scn_base',
                            label: 'Основной',
                            width: 250,
                            autocomplete: AutocompleteCellTypes.MAIN
                        }
                    ]}
                />
                <FormBottomTable open={toggleForm} onToggleForm={() => setToggleForm(prev => !prev)} fields={ClwrldCntrForm} api='/clwrldcntrs' idTab={idTab} form={form} idName='icn_id' />
            </div>
            <ToolBar idTab={idTab} toggleForm={() => setToggleForm(state => !state)} api="/clwrldcntrs/" idName={'icn_id'} tableNameExport={'v_clwrldcntr'}/>
        </div>
    );
};

export default ClwrldcntrTab;