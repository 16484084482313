import React, {FC, memo} from 'react';
import {Pagination} from "rsuite";

interface MyPaginationProps {
    totalItems: number
    limit: number
    page: number
    SetPage: (idTab: number,  page: number) => void
    SetLimit: (idTab: number,  limit: number) => void
    idTab: number
}

const MyPagination: FC<MyPaginationProps> = ({totalItems, limit, page, SetPage, SetLimit, idTab}) => {

    return (
        <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="sm"
            layout={['limit', '|', 'pager', 'skip']}
            total={totalItems ? totalItems : 0}
            limitOptions={[10, 30, 50]}
            limit={limit}
            activePage={page}
            onChangePage={(page) => SetPage(idTab, page)}
            onChangeLimit={(limit) => SetLimit(idTab, limit)}
            locale={{
                limit: '{0} строк',
                skip: 'Перейти на{0}',
                prev: 'Предыдущая',
                first: 'Первая',
                next: 'Следующая',
                last: 'Последняя'
            }}
        />
    );
};

export default memo(MyPagination);