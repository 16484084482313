import React, {FC, useState} from 'react';
import {fieldType} from "../../../../../interfaces/FormButtomTableInterface";
import {Control, UseFormSetValue} from "react-hook-form/dist/types/form";
import {host} from "../../../../../environments/environments";
import Autocomplete from "@mui/material/Autocomplete";
import {Controller} from "react-hook-form";
import {useActions} from "../../../../../hooks/useAction";

interface AutocompleteInputProps {
    field: fieldType,
    control: Control<{ [p: string]: any }, object>,
    idTab: number,
    form: { [key: string]: any }
    setValue: UseFormSetValue<any>
}

const AutocompleteInput: FC<AutocompleteInputProps> = ({
                                                           field,
                                                           control,
                                                           idTab,
                                                           form,
                                                           setValue
                                                       }) => {

    const [options, setOptions] = useState<{ [key: string]: any }[]>([])
    const {SetFormValue} = useActions()

    const hanleAutocompleteChange = async (api: string, key: string, value: string, filters?: { nameField: string }) => {

        let searchParams = new URLSearchParams({})

        if (filters && form[filters.nameField]) {
            searchParams = new URLSearchParams({
                field: key,
                input: value,
                'Filters[0].field': filters.nameField,
                'Filters[0].value': form[filters.nameField]
            })
        } else {
            searchParams = new URLSearchParams({
                field: key,
                input: value
            })
        }

        const response = await fetch(`${host + api}?` + searchParams)

        if (response.status === 200) {
            const opt = await response.json()
            if (opt.length !== 0) {
                setOptions(opt)
            }
        }
    }


    return (
        <Controller
            name={field.key}
            control={control}
            rules={field.rules}
            render={({field: {onChange, value, name}, formState: {errors}}) =>
                <div className="form-bottom-table_field"
                     style={{width: field.widthField, marginLeft: field.marginLeft}}>
                    <label className="form-bottom-table_label">{field.label}</label>
                    <div className="form-bottom-table_input_error_wrap">
                        <Autocomplete
                            disablePortal
                            id={field.key}
                            sx={{width: '100%'}}
                            getOptionLabel={(option: any) => option[field.key]}
                            options={options}
                            renderInput={(params) => (
                                <div ref={params.InputProps.ref}>
                                    <input type="text" {...params.inputProps} placeholder="Поиск..." id={field.key}
                                           style={{width: field.widthInput}}
                                           className={`form-bottom-table_input ${errors[field.key] ? 'error' : ''}`}
                                           name={name} value={value}/>
                                </div>
                            )}
                            isOptionEqualToValue={(option, value) => option[field.key] === value[field.key]}
                            onInputChange={(e, val, reas) => {
                                if (reas === 'input') {
                                    onChange(val)
                                    if (val) {
                                        hanleAutocompleteChange(field.api as string, field.key, val, field.filters)
                                    } else {
                                        setOptions([])
                                    }
                                }
                                if (reas === 'reset') {
                                    setOptions([])
                                    onChange('')
                                }
                            }}
                            onChange={(e, val, reas) => {
                                if (reas === 'selectOption') {
                                    onChange((val as { [key: string]: string })[field.key])
                                    setOptions([])
                                    for (let _key in (val as { [key: string]: string })) {
                                        SetFormValue(idTab, _key, (val as { [key: string]: string })[_key])
                                        setValue(_key, (val as { [key: string]: string })[_key])
                                    }
                                }
                            }}
                            noOptionsText="Ничего не найдено"
                        />
                        {errors[field.key]?.type === 'required' &&
                            <span className='error-message'>{errors[field.key].message}</span>}
                        {errors[field.key]?.type === 'pattern' &&
                            <span className='error-message'>{errors[field.key].message}</span>}
                    </div>
                </div>
            }
        />
    );
};

export default AutocompleteInput;