import { createContext, useContext } from "react"
import {
    IQuarantFitoZoneTab,
    IQuarantObjByRequirementTab,
    ISpecificRequirementsTab,
    ISpecificUquarantObjTab
} from '../interfaces/TabInterface'

//для конкретного таба требования
export const TabSpecificRequirementContext = createContext<ISpecificRequirementsTab>({} as ISpecificRequirementsTab)
export const useSpecificReqTabContext = () => useContext(TabSpecificRequirementContext)

//для таба карантинных объектов, который находится внутри таба конкретного требования
//(нужно для фикса бага, связанного с тем, что на одном глобальном табе может быть только одна таблица)
export const TabQuarantObjByRequirement = createContext<IQuarantObjByRequirementTab>({} as IQuarantObjByRequirementTab)
export const useTabQuarantObjByRequirementContext = () => useContext(TabQuarantObjByRequirement)

//для конкретного таба подкарантинного объекта
export const TabSpecificUquarantObjContext = createContext<ISpecificUquarantObjTab>({} as ISpecificUquarantObjTab)
export const useSpecificUquarantObjContext = () => useContext(TabSpecificUquarantObjContext)

//для карантинных фитосанитарных зон
export const TabQuarantFitoZoneContext = createContext<IQuarantFitoZoneTab>({} as IQuarantFitoZoneTab)
export const useQuarantFitoZoneContext = () => useContext(TabQuarantFitoZoneContext)

