import {tableState, TableAction, TableActionsType} from '../../interfaces/tableActions';

export const initialState: tableState = {
    error: '',
    status: ''
}

export const tableReducer = (state = initialState, action: TableAction): tableState => {
    switch(action.type) {
        case TableActionsType.SUCCESS_TABLE_FETCH:
            return {
                error: '',
                status: 'success'
            }
        case TableActionsType.SUCCESS_TABLE_SAVE:
            return {
                error: '',
                status: 'success_save'
            }
        case TableActionsType.ERROR_TABLE_FETCH:
            return {
                error: action.payload.message,
                status: 'error'
            }
        case TableActionsType.START_TABLE_FETCH:
            return {
                error: '',
                status: 'pending'
            }
        default:
            return state
    }

}