type NotOrYesTypes = 'Нет' | 'Да'
type PermissionTypes = 'Запретить' | 'Разрешить'
type TypeTypes = 'строка' | 'число' | 'Дата'
export type FieldTypes = 'main' | 'fsksavail' | 'ikravail' | 'kfkavail' | 'absent' | 'limited' | 'base' | 'notquar' | 'type' | 'permission' | 'reseeding'

const notOrYesToNumber = (value: NotOrYesTypes) => {
    return value === 'Нет' ? '0' : '1'
}

const typesToNumber = (value: TypeTypes) => {
    if (value === 'строка') {
        return '0'
    } else if (value === 'число') {
        return '1'
    } else if (value === 'Дата') {
        return '2'
    }
    return '0'
}

const permissionToNumber = (value: PermissionTypes) => {
    return value === 'Разрешить' ? '0' : '1'
}

export const mappedFieldName: Record<FieldTypes, (value: NotOrYesTypes | PermissionTypes | TypeTypes) => '0' | '1' | '2'> = {
    main: (value) => notOrYesToNumber(value as NotOrYesTypes),
    fsksavail: (value) => notOrYesToNumber(value as NotOrYesTypes),
    ikravail: (value) => notOrYesToNumber(value as NotOrYesTypes),
    kfkavail: (value) => notOrYesToNumber(value as NotOrYesTypes),
    absent: (value) => notOrYesToNumber(value as NotOrYesTypes),
    base: (value) => notOrYesToNumber(value as NotOrYesTypes),
    limited: (value) => notOrYesToNumber(value as NotOrYesTypes),
    notquar: (value) => notOrYesToNumber(value as NotOrYesTypes),
    type: (value) => typesToNumber(value as TypeTypes),
    permission: (value) => permissionToNumber(value as PermissionTypes),
    reseeding: (value) => notOrYesToNumber(value as NotOrYesTypes)
}