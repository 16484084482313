import {RefObject} from "react";

export const useExpandedDiv = (ref: RefObject<HTMLDivElement>) => {
    const expandSection = () => {
        if (!ref.current) return
        const element = ref.current
        const sectionHeight = element.scrollHeight

        element.style.height = sectionHeight + 'px'

        element.addEventListener('transitionend', () => {
            element.style.height = 'auto'
        }, {once: true})
    }

    const collapseSection = () => {
        if (!ref.current) return
        const element = ref.current

        const sectionHeight = element.scrollHeight
        const elementTransition = element.style.transition
        element.style.transition = ''

        requestAnimationFrame(function() {
            element.style.height = sectionHeight + 'px';
            element.style.transition = elementTransition;

            requestAnimationFrame(function() {
                element.style.height = 0 + 'px';
            });
        });
    }

    return [expandSection, collapseSection]
}