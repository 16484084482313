import React, { FC, useState } from 'react';
import { SortType } from 'rsuite-table';
import ToolBar from '../../ToolBar/ToolBar';
import CustomTable from '../../CustomTable/CustomTable'
import {IContentTable, TabPanel} from '../../../interfaces/TabInterface';
import {GruQprodForm} from "../../../shared/form.table.enviroment";
import FormBottomTable from "../../Forms/FormBottomTable/FormBottomTable";

interface GruqprodTabProps extends TabPanel {

}

const GruqprodTab: FC<GruqprodTabProps> = ({ idTab, limit, page, sortColumn, sortType, content, filters, form }) => {
    const [toggleForm, setToggleForm] = useState<boolean>(false)

    return (
        <div className="tab-wrap">
            <div className="table-container">
                <CustomTable 
                    api={{
                        post: '/v_gruqprods/all',
                        put: '/gruqprods'
                    }}
                    idName={'igr_id'}
                    headerHeight={80}
                    idTab={idTab}
                    items={content?.items ? content.items : []}
                    limit={limit}
                    page={page}
                    sortType={sortType as SortType}
                    sortColumn={sortColumn}
                    totalItems={content?.totalItems}
                    filters={filters}
                    setting={[
                        {   
                            key: 'sgr_code',
                            label: 'Краткое наименование',
                            flex: 1,
                            filtered: true
                        },
                        {   
                            key: 'sgr_name',
                            label: 'Полное наименование',
                            flex: 1
                        }
                    ]}
                />
                <FormBottomTable
                    open={toggleForm} onToggleForm={() => setToggleForm(prev => !prev)}
                    idName={'igr_id'}
                    form={form}
                    fields={GruQprodForm}
                    api="/gruqprods"
                    idTab={idTab}
                />
            </div>
            <ToolBar idTab={idTab} toggleForm={() => setToggleForm(state => !state)} api="/gruqprods/" idName={'igr_id'} tableNameExport={'v_gruqprod'}/>
        </div>
    );
};

export default GruqprodTab;