import { ISpecificRequirementsTab, ITab, ISpecificNormatDocFormTab } from "./TabInterface";

export interface TabState {
    tabs: ITab[]
}

export enum TabActionsType {
    ADD_TAB = 'ADD_TAB',
    DELETE_TAB = 'DELETE_TAB',
    MOVES_TABS = 'MOVES_TABS'
}


export interface AddTabAction {
    type: TabActionsType.ADD_TAB
    payload: ITab | ISpecificRequirementsTab | ISpecificNormatDocFormTab
}

export interface DeleteTabAction {
    type: TabActionsType.DELETE_TAB
    payload: string
}

export interface RemoveTabsAction {
    type: TabActionsType.MOVES_TABS
    payload: {
        tabDrop: ITab,
        tabCurrent: ITab
    }
}


export type TabAction = AddTabAction 
    | DeleteTabAction
    | RemoveTabsAction