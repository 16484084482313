import {formState, formAction, formActionsType} from '../../interfaces/formActions';

export const initialState: formState = {
    error: '',
    status: ''
}

export const formReducer = (state = initialState, action: formAction): formState => {
    switch(action.type) {
        case formActionsType.POST_REQUEST_FORM_SUCCESS:
            return {
                error: '',
                status: 'success'
            }
        case formActionsType.POST_REQUEST_FORM_ERROR:
            return {
                error: action.payload.message,
                status: 'error'
            }
        case formActionsType.POST_REQUEST_FORM_START:
            return {
                error: '',
                status: 'pending'
            }
        case formActionsType.SET_EMPTY_STATUS_FORM:
            return {
                error: '',
                status: ''
            }
        default:
            return state
    }

}