import { Dispatch } from "redux"
import { MunitsTabActionsType, MunitsTabAction } from '../../interfaces/MunitsTabActions'
import { host } from '../../environments/environments'
import { formActionsType, formAction } from '../../interfaces/formActions'

export const GetMunitsTabContent = (idx: number, page: number, limit: number, sortColumn?: string, sortType?: string) => {
    return async (dispatch: Dispatch<MunitsTabAction>) => {
        dispatch({
            type: MunitsTabActionsType.START_FETCH,
            payload: {
                idTab: idx
            }
        })
        let body = {
            range: limit,
            batch: page
        }
        if (sortColumn && sortType) {
            body = Object.assign({},
                body,
                {
                    orderSort: {
                        property: sortColumn,
                        asc: sortType === 'asc' ? true : false
                    }
                })
        }
        try {
            const response = await fetch(`${host}/munits/all`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
            const json = await response.json()
            const items = json.items?.map((item: any) => {
                return Object.assign({}, item, {
                    checked: false
                })
            })
            const content = Object.assign({},
                json,
                {
                    items: items
                })
            dispatch({
                type: MunitsTabActionsType.GET_CONTENT,
                payload: {
                    idTab: idx,
                    content: content
                }
            })
            dispatch({
                type: MunitsTabActionsType.END_FETCH,
                payload: {
                    idTab: idx
                }
            })
        } catch (e) {
            console.log(e)
        }

    }
}

export const UpdMunitsTabContent = (idTab: number, idContent: number, dataKey: string, value: string | number) => {
    return (dispatch: Dispatch<MunitsTabAction>) => {
        dispatch({
            type: MunitsTabActionsType.UPD_CONTENT,
            payload: {
                idTab,
                idContent,
                dataKey,
                value
            }
        })
    }
}

export const PutMunitsTabRow = (idTab: number, item: string) => {
    return async (dispatch: Dispatch<MunitsTabAction>) => {
        try {
            const response = await fetch(`${host}/munits/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: item
            })

            if (response.status === 400) {
                const error = await response.json()
                dispatch({
                    type: MunitsTabActionsType.ERROR_PUT_CONTENT,
                    payload: {
                        idTab,
                        message: error.message
                    }
                })
            }
        } catch (err: any) {
            dispatch({
                type: MunitsTabActionsType.ERROR_PUT_CONTENT,
                payload: {
                    idTab,
                    message: err
                }
            })
        }
    }
}

export const ResetError = (idTab: number) => {
    return (dispatch: Dispatch<MunitsTabAction>) => {
        dispatch({
            type: MunitsTabActionsType.RESET_ERROR,
            payload: {
                idTab
            }
        })
    }
}

export const ToggleCheckbox = (idTab: number, idItem: number) => {
    return (dispatch: Dispatch<MunitsTabAction>) => {
        dispatch({
            type: MunitsTabActionsType.TOGGLE_CHECKBOX,
            payload: {
                idTab,
                idItem
            }
        })
    }
}

export const ToggleAllCheckbox = (idTab: number, checked: boolean) => {
    return (dispatch: Dispatch<MunitsTabAction>) => {
        dispatch({
            type: MunitsTabActionsType.TOGGLE_ALL_CHECKBOX,
            payload: {
                idTab,
                checked
            }
        })
    }
}

export const DeleteRows = (idTab: number, idItem: number) => {
    return async (dispatch: Dispatch<MunitsTabAction>) => {
        try {
            const response = await fetch(`${host}/munits/${idItem}`, {
                method: 'DELETE'
            })

            if (response.status === 200) {
                dispatch({
                    type: MunitsTabActionsType.DELETE_ROW,
                    payload: {
                        idTab,
                        idItem
                    }
                })
            }
        } catch (e) {

        }
    }
}

export const PostRow = (object: { [key: string]: string | number | boolean | null | undefined }, api: string, idTab: number) => {
    return async (dispatch: Dispatch<MunitsTabAction | formAction>) => {
        try {

            dispatch({
                type: formActionsType.POST_REQUEST_FORM_START
            })

            const response = await fetch(`${host + api}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(object)
            })

            if (response.status === 200) {
                dispatch({
                    type: formActionsType.POST_REQUEST_FORM_SUCCESS
                })
            }

            if (response.status !== 200) {
                const json = await response.json()
                dispatch({
                    type: formActionsType.POST_REQUEST_FORM_ERROR,
                    payload: {
                        idTab,
                        message: JSON.stringify(json)
                    }
                })
            }
        } catch (e: any) {
            dispatch({
                type: MunitsTabActionsType.ERROR_PUT_CONTENT,
                payload: {
                    idTab,
                    message: e
                }
            })
        }
    }
}