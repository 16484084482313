import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { store } from 'react-notifications-component';
import { useDispatch } from 'react-redux';
import { useQuarantFitoZoneContext } from '../../../../contexts/TabsContext';
import { useArrayFieldChangeAutocomplete, useArrayFieldChangeCheckbox, usePlusClick } from '../../../../hooks/useChange';
import {
    useArrayFieldSaveCheckbox,
    useDeleteArrayFields, useDeleteTags, useSaveAnyTags,
    useSaveArrayFieldQuarantFitoZone, useSaveTagsCntr,
    useSaveTagsRegions
} from '../../../../hooks/useSave';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { statuses } from '../../../../interfaces/requirementsFormActions';
import { formsName, tagsName } from '../../../../shared/shared';
import { ResetStatusForm } from '../../../../store/action-creators/requirementsFormActionCreator';
import AutocompleteWithLabel from '../../Inputs/AutocompleteWithLabel/AutocompleteWithLabel';
import CheckBoxWithLabel from '../../Inputs/CheckBoxWithLabel/CheckBoxWithLabel';
import {ERROR_NOTIFY, GET_DATA_SUCCESS_NOTIFY, SUCCESS_NOTIFY} from "../../../../shared/notification";
import AutocompleteWithTags from "../../Inputs/AutocompleteWithTags/AutocompleteWithTags";

interface QuarantFitoZoneProps {
    idTab: number
}

const QuarantFitoZone: FC<QuarantFitoZoneProps> = ({ idTab }) => {

    const { error, status } = useTypedSelector(state => state.reqForm)

    const { control } = useForm()
    const { mainForm, pid } = useQuarantFitoZoneContext()
    const dispatch = useDispatch()

    const handlePlusClick = usePlusClick(idTab, formsName.mainForm, tagsName.rqpququarantobjs, {
        irj_id: '',
        iro_pid: '',
        srj_pofree: false,
        srj_pocharg: false,
        iuo_uquarantobj_id: '',
        suo_code: '',
        suo_name: '',
        nuo_area: '',
        nuo_number: '',
        icn_clwrldcntr_id: '',
        scn_numcode: '',
        scn_code: '',
        scn_name: '',
        scn_letcode2: '',
        scn_letcode3: '',
        irg_region_id: '',
        srg_name: ''
    })

     //По клику на минус
     const handleDeleteArrayField = useDeleteArrayFields(idTab, 'rqpququarantobjs', formsName.mainForm, tagsName.rqpququarantobjs)


    //сохранение и обновление автокомплита
    const handleArrayFieldChangeAutocomplete = useArrayFieldChangeAutocomplete(idTab, formsName.mainForm, tagsName.rqpququarantobjs)
    const handleQuQuarantobjSaveAutocomplete = useSaveArrayFieldQuarantFitoZone(idTab, pid, formsName.mainForm, tagsName.rqpququarantobjs, 'rqpququarantobjs', 'uquarantobj_id', 'irj_id')

    //для чекбоксов
    const handleChangeCheckbox = useArrayFieldChangeCheckbox(idTab, formsName.mainForm, tagsName.rqpququarantobjs)
    const handleSavePofreeCheckbox = useArrayFieldSaveCheckbox('rqpququarantobj__pofree', '1')
    const handleSavePochargCheckbox = useArrayFieldSaveCheckbox('rqpququarantobj__pocharg', '1')

    //для тегов стран
    const handleSaveCountryTag = useSaveAnyTags<typeof mainForm.rqcntquarantobjs[0]>(
        'rqcntquarantobjs',
        idTab,
        formsName.mainForm,
        tagsName.rqcntquarantobjs,
        (tagId) => {
            return {
                "iro_id": +pid,
                "clwrldcntr_id": tagId
            }
        },
        (value) => {
            return {
                cnt_id: 0,
                cnt_rusname: value,
                iro_id: +pid,
                clwrldcntr_id: 0
            }
    })
    const handleDeleteCntrTag = useDeleteTags(idTab, 'rqcntquarantobjs', formsName.mainForm, tagsName.rqcntquarantobjs)


    useEffect(() => {
        if (error) {
            alert(error)
            store.addNotification(ERROR_NOTIFY)
            dispatch(ResetStatusForm())
        }
        if (status === statuses.DATA_GET) {
            store.addNotification(GET_DATA_SUCCESS_NOTIFY)
            dispatch(ResetStatusForm())
        }
        if (status === statuses.DATA_SAVE) {
            store.addNotification(SUCCESS_NOTIFY)
            dispatch(ResetStatusForm())
        }
    }, [dispatch, error, status])

    return (
        <>
            <div style={{
                display: 'grid',
                gridAutoRows: 'min-content',
                gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
                height: '100%',
                gridGap: '10px 1vw'
            }}>
                <div style={{
                    gridRowStart: 1,
                    gridRowEnd: 2,
                    gridColumnStart: 1,
                    gridColumnEnd: 4
                }}>
                    <AutocompleteWithTags
                        control={control}
                        label={"Страны"}
                        widthInput={'100%'}
                        widthInputBlock={'80%'}
                        widthLabel={'20%'}
                        api={'/v_clwrldcntrs/join'}
                        tags={mainForm.rqcntquarantobjs}
                        tagIdName={'cnt_id'}
                        tagValueName={'cnt_rusname'}
                        idSearch={'icn_id'}
                        nameSearch={'scn_code'}
                        onBlurInput={handleSaveCountryTag}
                        onDeleteTag={handleDeleteCntrTag}
                    />
                </div>
                {/*{mainForm.rqpququarantobjs.map((rqpququarantobj, idx) => {
                    let rowStart = idx === 0 ? 2 : idx + 2
                    let rowEnd = idx === 0 ? 3 : idx + 3
                    return <React.Fragment key={rqpququarantobj.irj_id}> <div style={{
                        gridRowStart: rowStart,
                        gridRowEnd: rowEnd,
                        gridColumnStart: 1,
                        gridColumnEnd: 4
                    }}>
                        <AutocompleteWithLabel
                            control={control}
                            widthInput={'100%'}
                            widthInputBlock={'55%'}
                            widthLabel={'45%'}
                            nameField='suo_code'
                            label={"Карантинные фитосанитарные зоны"}
                            value={rqpququarantobj.suo_code}
                            api={'/v_uquarantobjs/join'}
                            plus={mainForm.rqpququarantobjs.length - 1 === idx}
                            minus={mainForm.rqpququarantobjs.length - 1 !== idx}
                            onPlusClick={handlePlusClick}
                            onMinusClick={handleDeleteArrayField(rqpququarantobj.irj_id)}
                            onChangeInput={handleArrayFieldChangeAutocomplete(idx)}
                            onBlurInput={handleQuQuarantobjSaveAutocomplete(idx, rqpququarantobj)}
                        />
                    </div>

                        <div style={{
                            gridRowStart: rowStart,
                            gridRowEnd: rowEnd,
                            gridColumnStart: 4,
                            gridColumnEnd: 5
                        }}>
                            <CheckBoxWithLabel
                                control={control}
                                label={"свободные от КО"}
                                nameField='srj_pofree'
                                value={(rqpququarantobj.srj_pofree.toString() === 'Нет' || rqpququarantobj.srj_pofree.toString() === 'false') ? false : true}
                                onChangeInput={handleChangeCheckbox(idx)}
                                onBlurInput={handleSavePofreeCheckbox(rqpququarantobj.irj_id)}
                            />
                        </div>

                        <div style={{
                            gridRowStart: rowStart,
                            gridRowEnd: rowEnd,
                            gridColumnStart: 5,
                            gridColumnEnd: 6
                        }}>
                            <CheckBoxWithLabel
                                control={control}
                                label={"заражённые от КО"}
                                nameField='srj_pocharg'
                                value={(rqpququarantobj.srj_pocharg.toString() === 'Нет' || rqpququarantobj.srj_pocharg.toString() === 'false') ? false : true}
                                onChangeInput={handleChangeCheckbox(idx)}
                                onBlurInput={handleSavePochargCheckbox(rqpququarantobj.irj_id)}
                            />
                        </div>


                    </React.Fragment>
                })}*/}
            </div>

        </>
    );
};

export default QuarantFitoZone;