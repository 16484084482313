import React, {FC, useState} from 'react';
import {SortType} from 'rsuite-table';
import {TabPanel, TabTypes} from '../../../interfaces/TabInterface';
import ToolBar from '../../ToolBar/ToolBar';
import CustomTable from '../../CustomTable/CustomTable';
import FormBottomTable from "../../Forms/FormBottomTable/FormBottomTable";
import {faFileArchive} from "@fortawesome/free-solid-svg-icons"

interface CountryRepositoryTabProps extends TabPanel {
}

const CountryRepositoryTab: FC<CountryRepositoryTabProps> = ({idTab, limit, page, sortColumn, sortType, content, filters, form}) => {

    return (
        <div className="tab-wrap">
            <div className="table-container">
                <CustomTable
                    api={{
                        post: '/v_clwrldcntrs/all'
                    }}
                    idName={'icn_id'}
                    headerHeight={80}
                    idTab={idTab}
                    items={content?.items ? content.items : []}
                    limit={limit}
                    page={page}
                    sortType={sortType as SortType}
                    sortColumn={sortColumn}
                    totalItems={content?.totalItems}
                    filters={filters}
                    setting={[
                        {
                            key: 'icn_id',
                            label: '',
                            link: true,
                            width: 50,
                            icon: faFileArchive,
                            titleNameKey: 'scn_name',
                            tabType: TabTypes.DOCUMENTSREPOSITORY_TAB
                        },
                        {
                            key: 'scn_name',
                            label: 'Страна',
                            flex: 1,
                            sortable: true,
                            filtered: true,
                            readonly: true
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export default CountryRepositoryTab;