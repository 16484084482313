import React, {FC, useState} from 'react';
import {SortType} from 'rsuite-table';
import {TabPanel} from '../../../interfaces/TabInterface';
import ToolBar from '../../ToolBar/ToolBar';
import CustomTable from '../../CustomTable/CustomTable';
import FormBottomTable from "../../Forms/FormBottomTable/FormBottomTable";
import {UqpqObjForm} from "../../../shared/form.table.enviroment";

interface UqpqobjTabProps extends TabPanel {
}

const UqpqobjTab: FC<UqpqobjTabProps> = ({idTab, limit, page, sortColumn, sortType, content, filters, form}) => {
    const [toggleForm, setToggleForm] = useState<boolean>(false)

    return (
        <div className="tab-wrap">
            <div className="table-container">
                <CustomTable
                    api={{
                        post: '/v_uqpqobjs/all',
                        put: '/uqpqobjs'
                    }}
                    idName={'iuq_id'}
                    headerHeight={80}
                    idTab={idTab}
                    items={content?.items ? content.items : []}
                    limit={limit}
                    page={page}
                    sortType={sortType as SortType}
                    sortColumn={sortColumn}
                    totalItems={content?.totalItems}
                    filters={filters}
                    setting={[
                        {
                            key: 'sqo_latname',
                            label: 'Lat наименование',
                            flex: 1,
                            sortable: true,
                            filtered: true,
                            readonly: true
                        },
                        {
                            key: 'sqo_rusname',
                            label: 'Rus наименование',
                            flex: 1,
                            sortable: true,
                            filtered: true,
                            readonly: true
                        }
                    ]}
                />
                <FormBottomTable
                    open={toggleForm} onToggleForm={() => setToggleForm(prev => !prev)}
                    idName={'iuq_id'}
                    form={form}
                    fields={UqpqObjForm}
                    postForm={{
                        iup_pid: '',
                        iqo_quarantobj_id: '',
                    }}
                    api="/uqpqobjs"
                    idTab={idTab}
                />
            </div>
            <ToolBar idTab={idTab} toggleForm={() => setToggleForm(state => !state)} api="/uqpqobjs/"
                     idName={'iuq_id'} tableNameExport={'uqpqobj'} />
        </div>
    );
};

export default UqpqobjTab;