import React, {FC, useEffect, useState} from 'react';
import {Cell} from 'rsuite-table';
import Autocomplete from '@mui/material/Autocomplete';
import {host} from '../../environments/environments';

interface AutocompleteDefaultCellProps {
    idTab: number
    onChange?: (idRow: number, dataKey: string, value: string, updateStore?: boolean) => void
    dataKey: string
    rowData?: any
    rowIndex?: number
    readonly?: boolean | ((data: Record<string, any>) => boolean)
    readonlyColor?: string
    idName: string
    api: string
}

const AutocompleteDefaultCell: FC<AutocompleteDefaultCellProps> = ({
                                                                       idTab,
                                                                       rowData,
                                                                       rowIndex,
                                                                       dataKey,
                                                                       onChange,
                                                                       idName,
                                                                       readonly,
                                                                       readonlyColor,
                                                                       api,
                                                                       ...props
                                                                   }) => {

    const [value, setValue] = useState(rowData[dataKey].toString() ? rowData[dataKey] as string : '')
    const [options, setOptions] = useState<{ [key: string]: any }[]>([])

    useEffect(() => {
        setValue(rowData[dataKey].toString() ? rowData[dataKey] as string : '')
    }, [rowData, dataKey])

    const hanleAutocompleteChange = async (api: string, key: string, value: string) => {
        const response = await fetch(`${host + api}?` + new URLSearchParams({
            field: key,
            input: value
        }))

        if (response.status === 200) {
            const opt = await response.json()
            if (opt.length !== 0) {
                setOptions(opt)
            }
        }
    }

    const styles = typeof readonly === 'function' ? readonly(rowData) ? {backgroundColor: readonlyColor, height: '30px'} : {height: '30px'} : {height: '30px'}

    return (
        <Cell {...props} className="table-content-editing">
            <Autocomplete
                clearOnBlur={true}
                freeSolo
                sx={{width: '100%', height: '100%'}}
                getOptionLabel={(option: any) => option[dataKey]}
                options={options}
                filterOptions={(x) => x}
                renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                        <input type="text" {...params.inputProps} placeholder="Поиск..."
                               className="my-rs-input" value={value} style={styles} readOnly={typeof readonly === 'function' ? readonly(rowData) : readonly}/>
                    </div>
                )}
                onInputChange={(e, val, reas) => {
                    if (reas === 'input') {
                        setValue(val)
                        //SetFormValue(idTab, key, val)
                        if (val) {
                            hanleAutocompleteChange(api, dataKey, val)
                        } else {
                            setOptions([])
                        }
                    }
                    if (reas === 'reset') {
                        setOptions([])
                        setValue('')
                    }
                }}
                onChange={(e, val: any, reas) => {
                    if (reas === 'selectOption') {
                        setValue(val[dataKey])
                        setOptions([])
                        for (let _key in val) {
                            onChange && onChange(rowIndex!, _key, val[_key])
                        }
                    }
                }}
                noOptionsText="Ничего не найдено"
            />
        </Cell>
    );
};

export default AutocompleteDefaultCell;