import React, {FC} from 'react';
import { useActions } from '../../hooks/useAction';
import { TabTypes } from '../../interfaces/TabInterface';

import './RequirementsBlock.scss'

interface RequirementsBlockProps {
    idTab: number
    typeTab: TabTypes
    title: string
    checked?: boolean
    id: string
}

const RequirementsBlock: FC<RequirementsBlockProps> = ({ idTab, title, typeTab, id, checked }) => {

    const { AddTab, ToggleCheckbox } = useActions()

    return (
        <div className="requirements-block" >
            <input
                type="checkbox"
                className="checkbox-input"
                onChange={event => {
                    ToggleCheckbox(idTab, +id, id);
                }}
                checked={checked}
            />
            <p className="requirements-block_text" onClick={() =>  AddTab(title, typeTab, id) } > {title} </p>
        </div>
    );
};

export default RequirementsBlock;