import React, { FC } from 'react';
import { useArrayFieldChangeAutocomplete, useArrayFieldChangeDatePicker, usePlusClick } from '../../../../hooks/useChange';
import { useDeleteArrayFields, useSaveArrayFieldPlaceSimp, useSaveFieldArrayDatePicker } from '../../../../hooks/useSave';
import { IMainForm } from '../../../../interfaces/TabInterface';
import { formsName, tagsName } from '../../../../shared/shared';
import AutocompleteWithLabel from '../../Inputs/AutocompleteWithLabel/AutocompleteWithLabel';
import DataPickersWithLabel from '../../Inputs/DataPickersWithLabel/DataPickersWithLabel';

interface PlaceSimpPointProps {
    control?: any
    mainForm: IMainForm
    idTab: number
}

const PlaceSimpPoint: FC<PlaceSimpPointProps> = ({ control, mainForm, idTab }) => {

    const handleArrayFieldChangeAutocomplete = useArrayFieldChangeAutocomplete(idTab, formsName.mainForm, tagsName.placeSimp)
    const handleArrayFieldSavePlaceSimpl = useSaveArrayFieldPlaceSimp(idTab, mainForm.requirements__irq_id, formsName.mainForm, tagsName.placeSimp, 'rqplacesimps', 'placesimp_id', 'irl_id')
    const handleDeleteArrayField = useDeleteArrayFields(idTab, 'rqplacesimps', formsName.mainForm, tagsName.placeSimp)

    // При клике на плюс
    const onPlusChange = usePlusClick(idTab, formsName.mainForm, tagsName.placeSimp, {
        irl_id: '',
        ips_id: '',
        sps_code: '',
        drl_begdate: '',
        drl_enddate: ''
    })

    //Для изменения даты
    const handleArraFieldChangeDatePicker = useArrayFieldChangeDatePicker(idTab, formsName.mainForm, tagsName.placeSimp)
    
    //Для сохранения даты
    const handleArrayFieldSaveBegDate = useSaveFieldArrayDatePicker('rqplacesimp', 'begdate')
    const handleArrayFieldSaveEndDate = useSaveFieldArrayDatePicker('rqplacesimp', 'enddate')


    return (
        <div style={{
            display: 'grid',
            gridTemplateRows: '2fr 10fr',
            gridTemplateColumns: '1.4fr 1.4fr 1fr 1fr 1fr 1fr 1fr',
            height: '100vh',
            gridGap: '0 1vw'
        }}>

            <div style={{
                gridRowStart: 1,
                gridRowEnd: 2,
                gridColumnStart: 1,
                gridColumnEnd: 4
            }}>
                {mainForm.placeSimp.map((place, idx) => <AutocompleteWithLabel
                    key={idx}
                    control={control}
                    widthInput={'100%'}
                    widthInputBlock={'75%'}
                    widthLabel={'25%'}
                    label={"Места ввоза"}
                    value={place.sps_code}
                    plus={idx === mainForm.placeSimp.length - 1}
                    minus={idx !== mainForm.placeSimp.length - 1}
                    onChangeInput={handleArrayFieldChangeAutocomplete(idx)}
                    onBlurInput={handleArrayFieldSavePlaceSimpl(idx, place)}
                    nameField='sps_code'
                    api={'/v_placesimps/join'}
                    onPlusClick={onPlusChange}
                    onMinusClick={handleDeleteArrayField(place.irl_id)}
                />)}
            </div>

            <div style={{
                gridRowStart: 1,
                gridRowEnd: 2,
                gridColumnStart: 4,
                gridColumnEnd: 6
            }}>
                {mainForm.placeSimp.map((place, idx) => <DataPickersWithLabel
                    key={idx}
                    control={control}
                    widthInput={'100%'}
                    widthInputBlock={'80%'}
                    widthLabel={'20%'}
                    label={"Дата с"}
                    nameField="drl_begdate"
                    onChangeInput={handleArraFieldChangeDatePicker(idx)}
                    onBlurInput={handleArrayFieldSaveBegDate(place.irl_id)}
                    value={place.drl_begdate}
                    readonly={!place.sps_code}
                />)}

            </div>

            <div style={{
                gridRowStart: 1,
                gridRowEnd: 2,
                gridColumnStart: 6,
                gridColumnEnd: 8
            }}>
                {mainForm.placeSimp.map((place, idx) => <DataPickersWithLabel
                    key={idx}
                    control={control}
                    widthInput={'100%'}
                    widthInputBlock={'80%'}
                    widthLabel={'20%'}
                    label={"по"}
                    nameField="drl_enddate"
                    onChangeInput={handleArraFieldChangeDatePicker(idx)}
                    onBlurInput={handleArrayFieldSaveEndDate(place.irl_id)}
                    value={place.drl_enddate}
                    readonly={!place.sps_code}
                />)}

            </div>

        </div>
    );
};

export default PlaceSimpPoint;