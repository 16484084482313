import React, {FC, PropsWithChildren} from 'react';
import ToolBarItem from '../ToolBarItem/ToolBarItem'
import {faTrashAlt, faPlusSquare } from '@fortawesome/free-regular-svg-icons'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useActions } from '../../hooks/useAction'

import './ToolBar.scss'
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import {store} from "react-notifications-component";
import {host} from "../../environments/environments";
import {EXPORT_ERROR_NOTIFY, EXPORT_SUCCESS_NOTIFY} from "../../shared/notification";

interface ToolBarProps {
    idTab: number
    toggleForm: () => void,
    api: string
    idName: string
    tableNameExport?:string;
    schema_name?: string
}

const ToolBar: FC<PropsWithChildren<ToolBarProps>> = ({idTab, toggleForm, api, idName, tableNameExport = "", schema_name, children }) => {

    const { tabs } = useTypedSelector(state => state.tab)
    const { DeleteRows } = useActions()

    const handleDeleteRows = () => {
        const idsRows: number[] = []
        tabs.forEach((tab, idx) => {
            if (idx === idTab) {
                (tab.content as any)?.items.forEach((item: any) => {
                    if (item.checked === true)
                    {
                        idsRows.push(item[idName])
                    }
                })
            }
        })
        DeleteRows(idTab, idsRows, api, idName)
    }
    const excelExportHandler =  async ()=>{
        const response = await fetch(`${host}/export/excel`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: schema_name ? JSON.stringify({
                "table_name": tableNameExport,
                schema_name
            }) : JSON.stringify({
                "table_name": tableNameExport
            })
        })
        if(response.ok){
            const data =  await response.blob()
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(data)
            link.download = tableNameExport +".xlsx"
            link.click()

            store.addNotification(EXPORT_SUCCESS_NOTIFY)
            return;
        }
        store.addNotification(EXPORT_ERROR_NOTIFY)
    }

    return (
        <div className="toolbar" >
            <ToolBarItem icon={faTrashAlt} onClick={handleDeleteRows}/>
            <ToolBarItem icon={faPlusSquare} onClick={toggleForm}/>
            {tableNameExport && <ToolBarItem icon={faFileExcel} onClick={excelExportHandler}/>}
            {children}
        </div>
    );
};

export default ToolBar;