import React, { FC } from 'react';
import { useInputChange } from '../../../../hooks/useChange';
import { useSaveDefaultField } from '../../../../hooks/useSave';
import { IMainForm } from '../../../../interfaces/TabInterface';
import { formsName } from '../../../../shared/shared';
import TextAreaWithLabel from '../../../TextAreaWithLabel/TextAreaWithLabel';

interface PackPointProps {
    idTab: number
    mainForm: IMainForm
    control: any
}

const PackPoint: FC<PackPointProps>  = ({idTab, mainForm, control}) => {

    const handleChange = useInputChange(idTab, formsName.mainForm)
    const handeBlurSaveString = useSaveDefaultField(mainForm.requirements__irq_id, '0')

    return (
        <div style={{
            display: 'grid',
            gridTemplateRows: '1fr 1fr 1fr 16fr',
            gridTemplateColumns: '1fr',
            height: '100vh',
            gridGap: '0 1vw'
        }}>
            <div style={{
                gridRowStart: 1,
                gridRowEnd: 2,
                gridColumnStart: 1,
                gridColumnEnd: 2
            }}>
                <TextAreaWithLabel height={200} label="Упаковка" widthInput={'100%'} widthInputBlock={'85%'} widthLabel={'15%'}
                    control={control}
                    nameField="requirements__pack"
                    onChangeInput={handleChange}
                    value={mainForm.requirements__pack}
                    onBlurInput={handeBlurSaveString}
                />
            </div>

            <div style={{
                gridRowStart: 2,
                gridRowEnd: 3,
                gridColumnStart: 1,
                gridColumnEnd: 2
            }}>
                <TextAreaWithLabel height={200} label="Маркировка упаковки" widthInput={'100%'} widthInputBlock={'85%'} widthLabel={'15%'}
                    control={control}
                    nameField="requirements__packmark"
                    onChangeInput={handleChange}
                    value={mainForm.requirements__packmark}
                    onBlurInput={handeBlurSaveString}
                />
            </div>

            <div style={{
                gridRowStart: 3,
                gridRowEnd: 4,
                gridColumnStart: 1,
                gridColumnEnd: 2
            }}>
                <TextAreaWithLabel height={200} label="Обработка упаковки" widthInput={'100%'} widthInputBlock={'85%'} widthLabel={'15%'}
                    control={control}
                    nameField="requirements__packproc"
                    onChangeInput={handleChange}
                    value={mainForm.requirements__packproc}
                    onBlurInput={handeBlurSaveString}
                />
            </div>

        </div>
    );
};

export default PackPoint;