import React, {FC, useEffect} from 'react';
import {SubmitHandler, useForm, UseFormSetError} from "react-hook-form";
import {useActions} from "../../../hooks/useAction";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import DefaultInput from './FormBottomTableInputTypes/DefaultInput/DefaultInput';
import {FieldsFormButtomTable, fieldType} from "../../../interfaces/FormButtomTableInterface";

import './FormBottomTable.scss';
import AutocompleteInput from "./FormBottomTableInputTypes/AutocompleteInput/AutocompleteInput";
import CheckboxInput from "./FormBottomTableInputTypes/CheckboxInput/CheckboxInput";
import AutocompleteInputTypedData
    from "./FormBottomTableInputTypes/AutocompleteInputTypedData/AutocompleteInputTypedData";
import {ERROR_NOTIFY, SUCCESS_NOTIFY} from "../../../shared/notification";
import {store} from "react-notifications-component";
import AutocompleteInputWithOptions
    from "./FormBottomTableInputTypes/AutocompleteInputWithOptions/AutocompleteInputWithOptions";
import moment from "moment";

interface FormBottomTableProps {
    fields: FieldsFormButtomTable[],
    api: string
    idTab: number
    form: { [key: string]: any; }
    postForm?: { [key: string]: any; }
    idName: string
    validateFunc?: (data: { [key: string]: string }, setError: UseFormSetError<{ [key: string]: any; }>) => boolean
    open: boolean
    onToggleForm: () => void
    immutableFields?: string[]
    additionalValues?: (data: { [key: string]: any }) => { [key: string]: any; }
}

const YEAR_DELTA = 10

const FormBottomTable: FC<FormBottomTableProps> = ({
                                                       idTab,
                                                       form,
                                                       postForm,
                                                       idName,
                                                       api,
                                                       fields,
                                                       validateFunc,
                                                       open,
                                                       onToggleForm,
                                                       immutableFields,
                                                       additionalValues
                                                   }) => {

    const {handleSubmit, control, reset, setError, setValue} = useForm<typeof form>({
        defaultValues: {...form}
    });
    const {PostRow, ClearForm, ClearStatusForm} = useActions()
    const {error, status} = useTypedSelector(state => state.form)

    useEffect(() => {
        //TODO: Исправить баг, если открыто несколько вкладок с таблицами и формами для добавления, то при успешном добавлении
        //TODO: всплывающих уведомлений будет столько же, сколько вкладок открыто
        if (status === 'success') {
            store.addNotification(SUCCESS_NOTIFY)
            ClearStatusForm()
            reset()
        }
        if (status === 'error' && error) {
            store.addNotification(ERROR_NOTIFY)
            ClearStatusForm()
            reset()
        }
    }, [error, status])

    const onSubmit: SubmitHandler<typeof form> = data => {
        const newForm = additionalValues ? {
            ...form,
            ...additionalValues(data)
        } : form
        console.log(newForm)
        if (validateFunc) {
            validateFunc(data, setError) && PostRow(newForm, api, idTab, idName, postForm)
        } else {
            PostRow(newForm, api, idTab, idName, postForm)
        }
    }

    const ResetForm = (e: React.MouseEvent<HTMLButtonElement>) => {
        ClearForm(idTab)
        reset()
    }

    const getDefaultInput = (field: fieldType) => {
        if (!field.autocomplete && !field.checkbox) {
            return <DefaultInput key={field.key} idTab={idTab} control={control} field={field}/>
        }
        if (field.autocomplete === 'default') {
            return <AutocompleteInput key={field.key} field={field} control={control} idTab={idTab} form={form}
                                      setValue={setValue}/>
        }
        if (field.autocomplete === 'type') {
            return <AutocompleteInputTypedData key={field.key} field={field} control={control} idTab={idTab}
                                               setValue={setValue}/>
        }
        if (field.autocomplete === 'infection_type') {
            return <AutocompleteInputWithOptions key={field.key} field={field} control={control} idTab={idTab}
                                                 options={['Сорные растения', 'Грибы', 'Нематоды', 'Вредители', 'Бактерии', 'Вирусы']}/>
        }
        if (field.autocomplete === 'year') {
            const currentYear = moment().year()
            const options: string[] = [currentYear.toString()]
            for (let i = 1; i <= YEAR_DELTA; i++) {
                options.push((currentYear + i).toString())
                /*options.unshift((currentYear - i).toString())*/
            }
            return <AutocompleteInputWithOptions key={field.key} field={field} control={control} idTab={idTab}
                                                 options={options}/>
        }
        if (field.checkbox) {
            return <CheckboxInput key={field.key} field={field} control={control} idTab={idTab}/>
        }
    }

    const getWrapInputs = ({title, fields}: { title: string, fields: fieldType[] }) => {
        return <div key={title}>
            <p className="form-bottom-table_label">{title}</p>
            <div className="form-bottom-table_wrap">
                {fields.map((field, idx) => getDefaultInput(field))}
            </div>
        </div>
    }

    return (
        <>
            <div className={`wrap-form ${open ? 'open' : ''}`}>
                <span className="toggle-form" onClick={() => onToggleForm()}> </span>
                <form className={`form-bottom-table ${open ? 'open' : ''}`} onSubmit={handleSubmit(onSubmit)}>
                    {fields.map((field, idx) => {
                        if (field.default) {
                            return getDefaultInput(field.default)
                        }
                        if (field.wrap) {
                            return getWrapInputs(field.wrap)
                        }
                        return null
                    })}
                    <div className="form-bottom-table-btn-wrap">
                        <input type="submit" className="form-bottom-table_button submit" value="Добавить"/>
                        <button type="button" className="form-bottom-table_button clear" onClick={ResetForm}>Отмена
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default FormBottomTable;