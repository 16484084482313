import React, { FC, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Controller } from 'react-hook-form';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './AutocompleteWithLabel.scss'
import { host } from '../../../../environments/environments';


interface AutocompleteWithLabelProps {
    widthInputBlock: number | string
    widthLabel: number | string
    widthInput: number | string
    label: string
    plus?: boolean
    minus?: boolean
    control?: any
    nameField?: string
    api?: string
    value?: string
    onChangeInput?: ((value: string, nameField: string, dopField?: string ) => void) 
    rules?: any
    type?: string
    onBlurInput?: () => void
    readonly?: boolean
    dopField?: string
    onPlusClick?: () => void
    filters?: {
        nameField: string,
        value: string
    }
    onMinusClick?: () => void
}

const AutocompleteWithLabel: FC<AutocompleteWithLabelProps> = ({ label,
    widthInput,
    widthLabel,
    widthInputBlock,
    plus,
    control,
    nameField,
    api,
    value,
    onChangeInput,
    rules,
    type,
    onBlurInput,
    readonly,
    dopField,
    onPlusClick,
    minus,
    filters,
    onMinusClick }) => {

    const [options, setOptions] = useState<{ [key: string]: any }[]>([])

    const hanleAutocompleteChange = async (api: string, key: string, value: string) => {

        let searchParams = new URLSearchParams({}) 

        if (filters) {
            searchParams = new URLSearchParams({
                field: key,
                input: value,
                'Filters[0].field': filters.nameField,
                'Filters[0].value': filters.value 
            }) 
        } else {
            searchParams = new URLSearchParams({
                field: key,
                input: value
            }) 
        }

        const response = await fetch(`${host + api}?` + searchParams)

        if (response.status === 200) {
            const opt = await response.json()
            if (opt.length !== 0) {
                setOptions(opt)
            }
        }
    }

    return (
        <div className="input-with-label-block">
            <p className='input-with-label-block__text' style={{ width: widthLabel }}>{label}</p>
            {type && type === 'permission' ?
                <div style={{ width: widthInputBlock }}>
                    <Controller
                        name={nameField as string}
                        control={control}
                        rules={rules}
                        render={({ field: { onChange, name }, fieldState: { error } }) => <Autocomplete
                            disablePortal
                            clearOnBlur={true}
                            sx={{ width: '100%' }}
                            getOptionLabel={(option: any) => option}
                            options={['Разрешить', 'Запретить']}
                            renderInput={(params) => (
                                <div ref={params.InputProps.ref}>
                                    <input type="text" {...params.inputProps} placeholder="Поиск..."
                                        className="input-with-label-block_input"
                                        style={{ width: widthInput }}
                                        value={value}
                                        name={name}
                                    />
                                    <p style={{ color: 'red' }}>{error && error.message}</p>
                                </div>
                            )}
                            onInputChange={(e, val, reas) => {
                                if (reas === 'input') {
                                    onChange(e)
                                    onChangeInput && onChangeInput(val, nameField as string)
                                }
                                if (reas === 'reset') {
                                    setOptions([])
                                }
                            }}
                            onChange={(e, val: any, reas) => {
                                if (reas === 'selectOption') {
                                    onChange(e)
                                    onChangeInput && onChangeInput(val, nameField as string)
                                }
                            }}
                            onBlur={(e) => {onBlurInput && onBlurInput()}}
                            noOptionsText="Ничего не найдено"
                        />}
                    />
                </div>
                : <div style={{ width: widthInputBlock }}>
                    <Controller
                        name={nameField as string}
                        control={control}
                        rules={rules}
                        render={({ field: { onChange, name }, fieldState: { error } }) => <Autocomplete
                            disablePortal
                            sx={{ width: '100%' }}
                            clearOnBlur={true}
                            getOptionLabel={(option: any) => option[nameField?.split('__')[nameField?.split('__').length - 1] as string]}
                            options={options}
                            renderInput={(params) => (
                                <div ref={params.InputProps.ref}>
                                    <input type="text" {...params.inputProps} placeholder="Поиск..."
                                        className={`input-with-label-block_input ${readonly ? 'grey' : ''}`}
                                        style={{ width: widthInput }}
                                        value={value}
                                        name={name}
                                        readOnly={readonly}
                                    />
                                    <p style={{ color: 'red' }}>{error && error.message}</p>
                                </div>
                            )}
                            onInputChange={(e, val, reas) => {
                                if (reas === 'input') {
                                    onChange(e)
                                    onChangeInput && onChangeInput(val, nameField as string)
                                    if (val) {
                                        hanleAutocompleteChange(api as string, nameField?.split('__')[nameField?.split('__').length - 1] as string, val)
                                    } else {
                                        setOptions([])
                                    }
                                }
                                if (reas === 'reset') {
                                    setOptions([])
                                }

                            }}
                            onChange={(e, val: any, reas) => {
                                if (reas === 'selectOption') {
                                    onChange(e)
                                    onChangeInput && onChangeInput(val[nameField?.split('__')[nameField?.split('__').length - 1] as string], nameField as string)
                                    setOptions([])
                                    if (dopField) {
                                        let count = 0
                                        for (let _key in val) {
                                            if (count < 1)
                                            {
                                                onChangeInput && onChangeInput(val[_key], _key, dopField as string)
                                            }
                                            count += 1
                                        }
                                    } else {
                                        for (let _key in val) {
                                            onChangeInput && onChangeInput(val[_key], _key, dopField as string)
                                        }
                                    } 
                                }
                            }}
                            onBlur={(e) => {onBlurInput && onBlurInput()}}
                            noOptionsText="Ничего не найдено"
                        />}
                    />
                    {plus && <FontAwesomeIcon className='plus-icon' size='lg' icon={faPlus} onClick={onPlusClick}/>}
                    {minus && <FontAwesomeIcon className='plus-icon' size='lg' icon={faMinus} onClick={onMinusClick}/>}
                </div>}
        </div>
    );
};

export default AutocompleteWithLabel;