import React, { FC } from 'react';
import InputWithLabel from '../../Inputs/InputWithLabel/InputWithLabel';
import AutocompleteWithLabel from '../../Inputs/AutocompleteWithLabel/AutocompleteWithLabel';
import { useForm } from 'react-hook-form';
import { IDopQualsUquarantObj } from '../../../../interfaces/TabInterface';
import { useArrayFieldChangeAutocomplete, usePlusClick } from '../../../../hooks/useChange';
import { formsName, tagsName } from '../../../../shared/shared';
import {useDeleteArrayFields, useSaveArrayFieldDopQual, useSaveArrayFieldDopQualValue} from '../../../../hooks/useSave';
import {SetArrayFieldValue} from "../../../../store/action-creators/requirementsFormActionCreator";

interface DopQualParamsPointProps {
    idTab: number
    dopQuals: IDopQualsUquarantObj[]
    pid: string
}

const DopQualParamsPoint: FC<DopQualParamsPointProps> = ({ idTab, dopQuals, pid }) => {

    //для добавления и сохранения параметра
    const handleArrayFieldChangeAutocomplete = useArrayFieldChangeAutocomplete(idTab, formsName.mainForm, tagsName.dopQuals)
    const handleArrayFieldSaveParam = useSaveArrayFieldDopQual(idTab, pid, formsName.mainForm, tagsName.dopQuals, 'rqaddqualprms', 'othersobj_id', 'irm_id')

    //Для добавления и сохранения значения
    const handleArrayFieldChangeInput = useArrayFieldChangeAutocomplete(idTab, formsName.mainForm, tagsName.dopQuals)
    const handleArrayFieldSaveParamInput = useSaveArrayFieldDopQualValue(idTab, pid, formsName.mainForm, tagsName.dopQuals, 'rqaddqualprms', 's_value', 'irm_id')

    //По клику на плюс
    const onPlusChange = usePlusClick(idTab, formsName.mainForm, tagsName.dopQuals, {
        irm_id: '',
        irp_pid: '',
        ioo_id: '',
        srm_s_value: '',
        nrm_n_value: '',
        drm_d_value: '',
        ioo_othersobj_id: 0,
        soo_latname: '',
        soo_rusname: '',
        ito_tpothersobj_id: 0,
        sto_code: '',
        sto_name: '',
        imu_munits_id: 0,
        smu_name: '',
        nmu_okei: 0,
        smu_condnatdes: '',
        smu_condintdes: '',
        smu_clnatdes: '',
        smu_clintdes: '',
        soo_type: '',
        soo_main: '',
        soo_label_name: ''
    })

    //По клику на минус
    const handleDeleteArrayField = useDeleteArrayFields(idTab, 'rqaddqualprms', formsName.mainForm, tagsName.dopQuals)

    const { control } = useForm()

    return (<div style={{
        display: 'grid',
        gridTemplateRows: '1fr',
        gridTemplateColumns: '1fr 1fr 1fr',
        height: '100%',
        gridGap: '10px 1vw'
    }}>
        <div style={{
            gridRowStart: 1,
            gridRowEnd: 2,
            gridColumnStart: 1,
            gridColumnEnd: 2
        }}>
            {dopQuals.map((dopQual, idx) => <AutocompleteWithLabel
                control={control}
                api={'/v_othersobjs/join'}
                widthInput={'100%'}
                widthInputBlock={'80%'}
                widthLabel={'20%'}
                label={"Параметр"}
                nameField='soo_rusname'
                value={dopQual.soo_rusname}
                plus={dopQuals.length - 1 === idx}
                minus={dopQuals.length - 1 !== idx}
                onChangeInput={handleArrayFieldChangeAutocomplete(idx)}
                onBlurInput={handleArrayFieldSaveParam(idx, dopQual)}
                onPlusClick={onPlusChange}
                onMinusClick={handleDeleteArrayField(dopQual.irm_id)}
            />)}
        </div>

        <div style={{
            gridRowStart: 1,
            gridRowEnd: 2,
            gridColumnStart: 2,
            gridColumnEnd: 3
        }}>
            {dopQuals.map((dopQual, idx) => <InputWithLabel
                control={control}
                widthInput={'100%'}
                widthInputBlock={'80%'}
                widthLabel={'20%'}
                label={"Значение"}
                onChangeInput={(e) => {handleArrayFieldChangeInput(idx)(e.target.value, 'srm_s_value')}}
                onBlurInput={handleArrayFieldSaveParamInput(idx, dopQual)}
                value={dopQual.srm_s_value || dopQual.nrm_n_value || dopQual.drm_d_value || ''}
            />)}

        </div>

        <div style={{
            gridRowStart: 1,
            gridRowEnd: 2,
            gridColumnStart: 3,
            gridColumnEnd: 4
        }}>
            {dopQuals.map((dopQual, idx) => <InputWithLabel
                control={control}
                widthInput={'100%'}
                widthInputBlock={'60%'}
                widthLabel={'40%'}
                label={"Единица измерения"}
                value={dopQual.smu_name}
                readonly
            />)}

        </div>
    </div>);
};

export default DopQualParamsPoint;