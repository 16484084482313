import { Dispatch } from "redux"
import { statuses } from '../../interfaces/requirementsFormActions';
import { host } from "../../environments/environments";
import { normatdocFormActionsType, normotdocFormAction } from "../../interfaces/normatdocFormActions";

export const AddTagND = (idTab: number, nameForm: string, tag: { [key: string]: any }, nameIdTag: string, nameTags: string, nameValueTag: string) => {
    return (dispatch: Dispatch<normotdocFormAction>) => {
        dispatch({
            type: normatdocFormActionsType.ADD_TAGS_ND,
            payload: {
                idTab,
                tag,
                nameIdTag,
                nameTags,
                nameValueTag,
                nameForm
            }
        })
    }
}

export const SetNormatdocFormValue = (idTab: number, nameForm: string, nameField: string, value: string, dopField?: string) => {
    return (dispatch: Dispatch<normotdocFormAction>) => {
        dispatch({
            type: normatdocFormActionsType.SET_ND_FORM_VALUE,
            payload: {
                idTab,
                nameForm,
                nameField,
                value,
                dopField
            }
        })
    }
}

export const SetNormatdocFormToggleCheker = (idTab: number, nameForm: string, nameField: string) => {
    return (dispatch: Dispatch<normotdocFormAction>) => {
        dispatch({
            type: normatdocFormActionsType.SET_ND_FORM_TOGGLE_CHEKER,
            payload: {
                idTab,
                nameForm,
                nameField,
            }
        })
    }
}

export const GetNormatdocsList = (idTab: number) => {
    return async (dispatch: Dispatch<normotdocFormAction>) => {
        try {

            dispatch({
                type: normatdocFormActionsType.START_FETCH_NORMATDOC
            })
            const response = await fetch(`${host}/v_normatdoc_lists/all`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "range": 10,
                    "batch": 1,
                    "orderSort": {
                        "property": "ind_id",
                        "asc": false
                    }
                })
            })

            if (response.status === 200) {
                const obj = await response.json()
                const normatdocs = obj.items

                dispatch({
                    type: normatdocFormActionsType.GET_NORMATDOCS_LIST,
                    payload: {
                        idTab,
                        NORMATDOCs: normatdocs
                    }
                })
                dispatch({
                    type: normatdocFormActionsType.SUCCES_FETCH_NORMATDOC,
                    payload: {
                        message: statuses.DATA_GET
                    }
                })
            }

            if (response.status !== 200) {
                const obj = await response.json()
                dispatch({
                    type: normatdocFormActionsType.ERROR_FETCH_NORMATDOC,
                    payload: {
                        message: JSON.stringify(obj)
                    }
                })
            }

        } catch (e) {
            dispatch({
                type: normatdocFormActionsType.ERROR_FETCH_NORMATDOC,
                payload: {
                    message: e as string
                }
            })
        }



    }
}

export const UpdFieldNormatdocForm = (field_id: string, name_field: string, field_value: string | Date | boolean, field_type: string,) => {
    return async (dispatch: Dispatch<normotdocFormAction>) => {

        try {
            const name_fieldSplit = name_field.split('__')
            if (typeof field_value === typeof true) {
                if (field_value) {
                    field_value = '1'
                } else {
                    field_value = '0'
                }
            }

            if (name_fieldSplit[1] === 'permission') {
                if (field_value === 'Разрешить') {
                    field_value = '1'
                } else {
                    field_value = '0'
                }
            }

            let body = {
                field_id: `${field_id}`,
                table_name: name_fieldSplit[0],
                field_name: name_fieldSplit[1],
                field_value: `${field_value}`,
                field_type
            }

            if (name_fieldSplit.length > 2) {
                body = {
                    field_id: `${field_id}`,
                    table_name: name_fieldSplit[2],
                    field_name: name_fieldSplit[3],
                    field_value: `${field_value}`,
                    field_type
                }
            }    

            dispatch({
                type: normatdocFormActionsType.START_FETCH_NORMATDOC
            })

            const response = await fetch(`${host}/procedure`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })

            if (response.status === 200) {
                dispatch({
                    type: normatdocFormActionsType.START_FETCH_NORMATDOC,
                    payload: {
                        message: statuses.DATA_SAVE
                    }
                })
            }


            if (response.status !== 200) {
                const json = await response.json()
                dispatch({
                    type: normatdocFormActionsType.ERROR_FETCH_NORMATDOC,
                    payload: {
                        message: JSON.stringify(json)
                    }
                })
            }
        } catch (e) {
            dispatch({
                type: normatdocFormActionsType.ERROR_FETCH_NORMATDOC,
                payload: {
                    message: e as string
                }
            })
        }

    }
}

export const DeleteTagNormatdocForm = (idTab: number,
    nameForm: string,
    nameTags: string,
    nameTable: string,
    idTag: string) => {
    return async (dispatch: Dispatch<normotdocFormAction>) => {
        try {
            dispatch({
                type: normatdocFormActionsType.START_FETCH_NORMATDOC
            })

            const response = await fetch(`${host}/${nameTable}/${idTag}`, {
                method: 'DELETE'
            })

            if (response.status === 200) {
                dispatch({
                    type: normatdocFormActionsType.DELETE_TAG_ND,
                    payload: {
                        idTab,
                        nameForm,
                        nameTags,
                        idTag
                    }
                })
                dispatch({
                    type: normatdocFormActionsType.SUCCES_FETCH_NORMATDOC,
                    payload: {
                        message: statuses.DATA_SAVE
                    }
                })
            }

            if (response.status !== 200) {
                const json = await response.json()
                dispatch({
                    type: normatdocFormActionsType.ERROR_FETCH_NORMATDOC,
                    payload: {
                        message: JSON.stringify(json)
                    }
                })
            }
        } catch(e) {
            dispatch({
                type: normatdocFormActionsType.ERROR_FETCH_NORMATDOC,
                payload: {
                    message: e as string
                }
            })
        }
    }
}

export const AddFieldsND = (idTab: number, nameForm: string, nameTags: string, tag: { [key: string]: any }) => {
    return (dispatch: Dispatch<normotdocFormAction>) => {
        dispatch({
            type: normatdocFormActionsType.ADD_TAGS_ND,
            payload: {
                idTab,
                nameForm,
                nameTags,
                tag
            }
        })
    }    
}

export const DeleteNormatdocBlockReqForm = (idTab: number, idNormatdoc: string) => {
    return async (dispatch: Dispatch<normotdocFormAction>) => {
        try {
            dispatch({
                type: normatdocFormActionsType.START_FETCH_NORMATDOC
            })

            const response = await fetch(`${host}/normatdocs/${idNormatdoc}`, {
                method: 'DELETE'
            })

            if (response.status === 200) {
                dispatch({
                    type: normatdocFormActionsType.DELETE_NORMATDOC_BLOCK,
                    payload: {
                        idTab,
                        idNormatdoc
                    }
                })
                dispatch({
                    type: normatdocFormActionsType.SUCCES_FETCH_NORMATDOC,
                    payload: {
                        message: statuses.DATA_DELETE
                    }
                })
            }

            if (response.status !== 200) {
                const json = await response.json()
                dispatch({
                    type: normatdocFormActionsType.ERROR_FETCH_NORMATDOC,
                    payload: {
                        message: JSON.stringify(json)
                    }
                })
            }
           
            
        } catch(e) {
            dispatch({
                type: normatdocFormActionsType.ERROR_FETCH_NORMATDOC,
                payload: {
                    message: e as string
                }
            })
        }
    }
}

export const PostTagNormatdocForm = (idTab: number,
    nameForm: string,
    nameTags: string,
    nameTable: string,
    body: { [key: string]: any },
    tag: { [key: string]: any }) => {
    return async (dispatch: Dispatch<normotdocFormAction>) => {
        try {
            dispatch({
                type: normatdocFormActionsType.START_FETCH_NORMATDOC
            })

            const response = await fetch(`${host}/${nameTable}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })

            if (response.status === 200) {
                const json = await response.json()
                Object.keys(tag).forEach(key => {
                    if (key.endsWith('id')) {
                        tag[key] = json.value.id
                    }
                })
                dispatch({
                    type: normatdocFormActionsType.SUCCES_FETCH_NORMATDOC,
                    payload: {
                        message: statuses.DATA_SAVE
                    }
                })
                dispatch({
                    type: normatdocFormActionsType.ADD_TAGS_ND,
                    payload: {
                        idTab,
                        nameForm,
                        nameTags,
                        tag
                    }
                })
            }

            if (response.status !== 200) {
                const json = await response.json()
                dispatch({
                    type: normatdocFormActionsType.ERROR_FETCH_NORMATDOC,
                    payload: {
                        message: JSON.stringify(json)
                    }
                })
            }

        } catch (e) {
            dispatch({
                type: normatdocFormActionsType.ERROR_FETCH_NORMATDOC,
                payload: {
                    message: e as string
                }
            })
        }
    }
}

export const ResetNormatdocForm = (idTab: number) => {
    return (dispatch: Dispatch<normotdocFormAction>) => {
        dispatch({
            type: normatdocFormActionsType.RESET_ND_FORM,
            payload: {
                idTab
            }
        })
    }
}

export const ResetStatusFormND = () => {
    return (dispatch: Dispatch<normotdocFormAction>) => {
        dispatch({
            type: normatdocFormActionsType.RESET_STATUS_FORM_ND
        })
    }
}