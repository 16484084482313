import React, {ChangeEvent, FC, useEffect, useState} from 'react';

import './FormSelectCountry.scss'
import {ICountry} from "../../../interfaces/country";
import {host} from "../../../environments/environments";
import {IContentTable} from "../../../interfaces/TabInterface";
import {store} from "react-notifications-component";
import {ERROR_NOTIFY, getErrorNotification} from "../../../shared/notification";
import {useDebounce} from "../../../hooks/useDebounce";

interface FormSelectCountryProps {
    onSubmit: (countryId: string) => void
}

const FormSelectCountry: FC<FormSelectCountryProps> = ({onSubmit}) => {

    const [countries, setCountries] = useState<ICountry[]>([])
    const [loading, setLoading] = useState(true)
    const [searchStr, setSearchStr] = useState('')

    const debouncedSearch = useDebounce(searchStr, 300)

    useEffect(() => {
        getCountry(debouncedSearch)
    }, [debouncedSearch]);

    const getCountry = async (countryName?: string) => {
        try {
            const response = await fetch(host + '/v_clwrldcntrs/all', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    batch: 1,
                    orderSort: {
                        property: 'icn_id',
                        asc: false
                    },
                    filters: countryName ? [{field: 'scn_code', value: countryName}] : [],
                    range: 300
                })
            })
            if (response.ok) {
                const batchModel: IContentTable<ICountry> = await response.json()
                setCountries(batchModel.items)
            } else {
                store.addNotification(getErrorNotification('Невозможно получить страны', 'При получении стран произошла ошибка'))
            }
        } catch(error) {
            store.addNotification(ERROR_NOTIFY)
        } finally {
            setLoading(false)
        }
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchStr(event.target.value)
    }

    const handleClickCountry = (name: string) => async () => {
        try {
            const response = await fetch(host + '/export/ReqForCountryByName?countryName=' + name)
            if (response.ok) {
                const blob = await response.blob()
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", name + ".xls");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                store.addNotification(getErrorNotification('Невозможно скачать файл', 'При скачивании произошла ошибка'))
            }
        } catch(error) {
            store.addNotification(ERROR_NOTIFY)
        } finally {

        }
    }

    return (
        <div className={'form-select-country'}>
            <p className={'form-select-country_title'}>Выберите страну</p>
            <input type='text' placeholder={'Поиск страны'} onChange={handleInputChange}/>
            <ul className={'form-select-country_list'}>
                {loading ? <p>Загрузка...</p> :
                countries.map(country => <li key={country.icn_id} onClick={handleClickCountry(country.scn_code)}>{country.scn_code}</li>)}
            </ul>
        </div>
    );
};

export default FormSelectCountry;