import React, {ChangeEvent, memo} from 'react';
import CustomHeader from "../CustomHeader/CustomHeader";
import CheckedCell from "../CheckedCell/CheckedCell";
import SearchInput from "../CustomTable/SearchInput/SearchInput";
import AutocompleteMainCell from "../AutocompleteMainCell/AutocompleteMainCell";
import AutocompleteDefaultCell from "../AutocompleteDefaultCell/AutocompleteDefaultCell";
import LinkCell from "../LinkCell/LinkCell";
import {TabTypes} from "../../interfaces/TabInterface";
import EditCell from "../EditCell/EditCell";
import {Table} from "rsuite";
import {TableInstance} from "rsuite/Table";
import {SortType} from "rsuite-table";

import 'rsuite-table/dist/css/rsuite-table.min.css';
import {AutocompleteCellTypes} from "../CustomTable/CustomTable";

const {Column} = Table

interface MyTableProps {
    idTab: number
    idName: string
    items: any[]
    status: string
    sortType: SortType | undefined
    sortColumn: string
    onRowChange: (idRow: number, dataKey: string, value: string) => void
    onSortColumn: (sortColumn: string, sortType: SortType | undefined) => void
    onChangeCheckbox: (event: ChangeEvent<HTMLInputElement>) => void
    onToggleCheckbox: (id: number, idName: string) => void
    onInputFilterChange: (event: ChangeEvent<HTMLInputElement>) => void
    checkedAll: boolean
    columns: any[]
    filters: { field: string, value: string }[]
    fieldNameNestedExist?: string
}

const MyTable = React.forwardRef<TableInstance, MyTableProps>(({
                                                                   idTab,
                                                                   idName,
                                                                   items,
                                                                   status,
                                                                   sortType,
                                                                   sortColumn,
                                                                   onRowChange,
                                                                   onSortColumn,
                                                                   onChangeCheckbox,
                                                                   onInputFilterChange,
                                                                   onToggleCheckbox,
                                                                   checkedAll,
                                                                   columns,
                                                                   filters,
                                                                   fieldNameNestedExist
                                                               }, ref) => {

    return (
        <Table
            ref={ref}
            data={items}
            autoHeight={true}
            headerHeight={70}
            loading={status === 'pending'}
            locale={{
                emptyMessage: 'Нет данных',
                loading: 'Загрузка...'
            }}
            onSortColumn={onSortColumn}
            sortType={sortType}
            sortColumn={sortColumn}
        >
            <Column width={40}>
                <CustomHeader>
                    <input
                        type="checkbox"
                        className="input-cheked"
                        onChange={onChangeCheckbox}
                        checked={checkedAll}
                    />
                </CustomHeader>
                <CheckedCell
                    dataKey='checked'
                    onChange={onToggleCheckbox}
                    idTab={idTab}
                    idName={idName}
                />
            </Column>
            {columns.map((item, idx) => {
               /* if (!item.key.startsWith('i') || item.key === 'irp_id' || item.key === 'iro_id' || item.key === 'iup_id') {*/

                    return <Column key={item.key} width={item.width} flexGrow={item.flex} align="center"
                                   sortable={!!item.sortable} resizable>
                        <CustomHeader>
                            {item.label}
                            {item.filtered && <SearchInput name={item.key} filters={filters}
                                                           handleInputChange={onInputFilterChange}/>}
                        </CustomHeader>
                        {item.autocomplete === AutocompleteCellTypes.MAIN ?
                            <AutocompleteMainCell
                                options={['Нет', 'Да']}
                                dataKey={item.key}
                                idTab={idTab}
                                idName={idName}
                                readonly={item.readonly}
                                readonlyColor={item.readonlyColor}
                                onChange={onRowChange}
                            /> : item.autocomplete === AutocompleteCellTypes.PERMISSION ?
                            <AutocompleteMainCell
                                options={['Запретить', 'Разрешить']}
                                dataKey={item.key}
                                idTab={idTab}
                                idName={idName}
                                readonly={item.readonly}
                                readonlyColor={item.readonlyColor}
                                onChange={onRowChange}
                            />  : item.autocomplete === AutocompleteCellTypes.DEFAULT ? <AutocompleteDefaultCell
                                dataKey={item.key}
                                idTab={idTab}
                                idName={idName}
                                readonly={item.readonly}
                                readonlyColor={item.readonlyColor}
                                api={item.api as string}
                                onChange={onRowChange}
                            /> : item.autocomplete === AutocompleteCellTypes.TYPE ? <AutocompleteMainCell
                                options={['Строка', 'Число', 'Дата']}
                                dataKey={item.key}
                                idTab={idTab}
                                idName={idName}
                                readonly={item.readonly}
                                readonlyColor={item.readonlyColor}
                            /> : item.autocomplete === AutocompleteCellTypes.INFECTION_TYPE ? <AutocompleteMainCell
                                options={['Сорные растения', 'Грибы', 'Нематоды', 'Вредители', 'Бактерии', 'Вирусы']}
                                dataKey={item.key}
                                idTab={idTab}
                                idName={idName}
                                readonly={item.readonly}
                                readonlyColor={item.readonlyColor}
                                onChange={onRowChange}
                            /> : item.link === true ? <LinkCell
                                tabType={item.tabType as TabTypes}
                                titleNameKey={item.titleNameKey}
                                dataKey={item.key}
                                idTab={idTab}
                                idName={idName}
                                readonly={item.readonly}
                                fieldNameNestedExist={item.fieldNameNestedExist}
                                icon={item.icon}
                            /> : <EditCell
                                dataKey={item.key}
                                idTab={idTab}
                                idName={idName}
                                readonly={item.readonly}
                                readonlyColor={item.readonlyColor}
                                onChange={onRowChange}
                                validateFunc={item.validateFunc}
                                computedValue={item.computedValue}
                            />}
                    </Column>
/*                } else {
                    return null
                }*/
            })}
        </Table>
    );
})

export default memo(MyTable);