import React, {FC} from 'react';
import {fieldType} from "../../../../../interfaces/FormButtomTableInterface";
import {Control, UseFormSetValue} from "react-hook-form/dist/types/form";
import {useActions} from "../../../../../hooks/useAction";
import Autocomplete from "@mui/material/Autocomplete";
import {Controller} from "react-hook-form";

interface AutocompleteInputTypedDataProps {
    field: fieldType,
    control: Control<{ [p: string]: any }, object>,
    idTab: number,
    setValue: UseFormSetValue<any>
}

const AutocompleteInputTypedData: FC<AutocompleteInputTypedDataProps> = ({
                                                                             field,
                                                                             control,
                                                                             idTab,
                                                                             setValue
                                                                         }) => {

    const {SetFormValue} = useActions()

    return (
        <Controller
            name={field.key}
            control={control}
            rules={field.rules}
            render={({field: {onChange, value, name}, formState: {errors}}) =>
                <div className="form-bottom-table_field"
                     style={{width: field.widthField, marginLeft: field.marginLeft}}>
                    <label className="form-bottom-table_label">{field.label}</label>
                    <div className="form-bottom-table_input_error_wrap">
                        <Autocomplete
                            disablePortal
                            freeSolo
                            clearOnBlur={true}
                            sx={{width: '100%'}}
                            options={['Строка', 'Число', 'Дата']}
                            inputValue={value}
                            renderInput={(params) => (
                                <div ref={params.InputProps.ref}>
                                    <input type="text" {...params.inputProps} placeholder="Поиск..."
                                           style={{width: field.widthInput}}
                                           className={`form-bottom-table_input ${errors[field.key] ? 'error' : ''}`}
                                           name={name}
                                    />
                                </div>
                            )}
                            onInputChange={(e, val, reas) => {
                                onChange(val)
                            }}
                            onChange={(e, val, reas) => {
                                if (reas === 'selectOption') {
                                    onChange(val)
                                    SetFormValue(idTab, field.key, val as string)
                                }
                            }}
                            noOptionsText="Ничего не найдено"
                        />
                        {errors[field.key]?.type === 'required' &&
                            <span className='error-message'>{errors[field.key].message}</span>}
                        {errors[field.key]?.type === 'pattern' &&
                            <span className='error-message'>{errors[field.key].message}</span>}
                    </div>
                </div>
            }
        />
    );
};

export default AutocompleteInputTypedData;