import React, {FC, useState} from 'react';
import {SortType} from 'rsuite-table';
import {TabPanel} from '../../../interfaces/TabInterface';
import ToolBar from '../../ToolBar/ToolBar';
import CustomTable from '../../CustomTable/CustomTable';
import FormBottomTable from "../../Forms/FormBottomTable/FormBottomTable";
import moment from "moment";

interface RequirementsHistoryTabProps extends TabPanel {
}

const RequirementsHistoryTab: FC<RequirementsHistoryTabProps> = ({idTab, limit, page, sortColumn, sortType, content, filters, form}) => {

    return (
        <div className="tab-wrap">
            <div className="table-container">
                <CustomTable
                    api={{
                        post: '/requirement/history',
                        put: ''
                    }}
                    idName={'id'}
                    headerHeight={80}
                    idTab={idTab}
                    items={content?.items ? content.items : []}
                    limit={limit}
                    page={page}
                    sortType={sortType as SortType}
                    sortColumn={sortColumn}
                    totalItems={content?.totalItems}
                    filters={filters}
                    setting={[
                        {
                            key: 'name',
                            label: 'Требование',
                            flex: 1,
                            sortable: true,
                            filtered: true,
                            readonly: true
                        },
                        {
                            key: 'author',
                            label: 'Автор',
                            flex: 1,
                            sortable: true,
                            filtered: true,
                            readonly: true
                        },
                        {
                            key: 'date',
                            label: 'Время изменения',
                            flex: 1,
                            readonly: true,
                            computedValue: (data) => {
                                return moment(data.date).format('DD.MM.YYYY HH:mm')
                            }
                        },
                        {
                            key: 'change',
                            label: 'Изменение',
                            flex: 2,
                            readonly: true
                        }
                    ]}
                />
            </div>
        </div>
    );
};

export default RequirementsHistoryTab;