import { StyledEngineProvider } from '@mui/material';
import React, { FC, useEffect } from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useSpecificUquarantObjContext } from '../../../contexts/TabsContext';
import TnvedPoint from '../../RequiredForm/Points/TnvedPoint/TnvedPoint';
import QuarantObjPoint from '../../RequiredForm/Points/QuarantObjPoint/QuarantObjPoint';
import DopQualParamsPoint from '../../RequiredForm/Points/DopQualParamsPoint/DopQualParamsPoint';
import { store } from 'react-notifications-component';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { ResetStatusForm } from '../../../store/action-creators/requirementsFormActionCreator';
import { statuses } from '../../../interfaces/requirementsFormActions';
import { useDispatch } from 'react-redux';
import {ERROR_NOTIFY, GET_DATA_SUCCESS_NOTIFY, SUCCESS_NOTIFY} from "../../../shared/notification";

interface SpecificUquarantTabProps {
    idTab: number
}

const SpecificUquarantTab: FC<SpecificUquarantTabProps> = ({idTab}) => {

    const [tabValue, setTabValue] = React.useState<string>('ТНВЭД');

    const { error, status } = useTypedSelector(state => state.reqForm)
    const dispatch = useDispatch()

    const {mainForm, pid} = useSpecificUquarantObjContext()

    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        event.preventDefault()
        event.stopPropagation()
        setTabValue(newValue)
    }

    useEffect(() => {
        if (error) {
            alert(error)
            store.addNotification(ERROR_NOTIFY)
            dispatch(ResetStatusForm())
        }
        if (status === statuses.DATA_GET) {
            store.addNotification(GET_DATA_SUCCESS_NOTIFY)
            dispatch(ResetStatusForm())
        }
        if (status === statuses.DATA_SAVE) {
            store.addNotification(SUCCESS_NOTIFY)
            dispatch(ResetStatusForm())
        }
    }, [dispatch, error, status])
    
    return (
        <StyledEngineProvider injectFirst>

            <TabContext value={tabValue}>
                <TabList onChange={handleChangeTab}>
                    <Tab label="ТНВЭД" value="ТНВЭД" />
                    <Tab label="Карантинные объекты" value="Карантинные объекты" />
                    <Tab label="Дополнительные параметры качества" value="Дополнительные параметры качества" />
                </TabList>
                <TabPanel value="ТНВЭД">
                    <TnvedPoint
                        pid={pid}
                        idTab={idTab}
                        tnveds={mainForm.tnveds}
                    /></TabPanel>
                <TabPanel value="Карантинные объекты">
                    <QuarantObjPoint
                        idTab={idTab}
                        pid={pid}
                    />
                </TabPanel>
                <TabPanel value="Дополнительные параметры качества">
                    <DopQualParamsPoint
                        pid={pid}
                        idTab={idTab}
                        dopQuals={mainForm.dopQuals}
                    /></TabPanel>
            </TabContext>

        </StyledEngineProvider >
    );
};

export default SpecificUquarantTab;
