import React, {FC} from 'react';
import {IFile} from "../../interfaces/TabInterface";
import WordIcon from '../../img/icons/word-icon.png';
import PDFIcon from '../../img/icons/pdf-icon.png'
import DeleteIcon from '@mui/icons-material/Delete';

import './Document.scss';
import {IconButton} from "@mui/material";

interface DocumentProps {
    file: IFile
    onDelete: (file: IFile) => void
}

const Document: FC<DocumentProps> = ({file, onDelete}) => {

    const splitName = file.name.split('.')
    const extension = splitName[splitName.length - 1]

    return (
        <div className='document-wrapper'>
            <IconButton aria-label='delete' className='document-delete-button' onClick={() => onDelete(file)}>
                <DeleteIcon />
            </IconButton>
            {(extension === 'doc' || extension === 'docx') && <img src={WordIcon} alt="doc/docx"/>}
            {extension === 'pdf' && <img src={PDFIcon} alt="pdf"/>}
            <a href={file.file} download={file.name}><p>{file.name}</p></a>
        </div>
    );
};

export default Document;