import { TabState } from '../interfaces/TabActions';
import {
    GetContent,
    UpdContent,
    ToggleCheckbox,
    ToggleAllCheckbox,
    DeleteRow,
    SetLimit,
    SetPage,
    SetSortColumn,
    SetSortType,
    SetFilters
} from '../interfaces/tableActions';
import { AddItemToContent } from '../interfaces/formActions';
import { ITab,IRequirementsTab, INormatDocTab } from '../interfaces/TabInterface';

export const setContent = (state: TabState, action: GetContent): ITab[] => {
    const newTabs: ITab[] = state.tabs.map((tab, idx) => {
        if (idx === action.payload.idTab) {
            return Object.assign({},
                tab,
                {
                    content: action.payload.content
                })
        }
        return tab
    })
    return newTabs
}

export const updContent = (state: TabState, action: UpdContent): ITab[] => {
    const newTabs: ITab[] = state.tabs.map((tab, idx) => {
        const {idTab, idRow, data} = action.payload;
        if (idx === idTab) {
            const tabAnyContent = (tab.content) as any
            const newItems = [...tabAnyContent.items.slice(0, idRow), {
                ...tabAnyContent.items[idRow],
                ...data
            }, ...tabAnyContent.items.slice(idRow + 1, tabAnyContent.length)]
            /*((tab.content) as any).items?.map((item: any) => {

                /!*if (item[action.payload.idName] === action.payload.idContent) {

                    if(action.payload.dataKey.startsWith('i')) {
                        const threeLetters = action.payload.dataKey.slice(0, 3)
                        Object.keys(item).forEach(key => {
                            if (key.startsWith(threeLetters)) {
                                item[key] = action.payload.value
                            }
                        })
                    } else {
                        item[action.payload.dataKey] = action.payload.value
                    }       
                }*!/
                if (item[idName] === data[idName]) {
                    return {
                        ...item,
                        ...data
                    }
                }
                return item
            })*/
            const newContent = Object.assign({},
                tab.content,
                {
                    items: newItems
                })
            return Object.assign({},
                tab,
                {
                    content: newContent
                })
        }
        return tab
    })
    return newTabs
}

export const toggleCheckbox = (state: TabState, action: ToggleCheckbox): ITab[] => {
    const newTabs: ITab[] = state.tabs.map((tab: ITab, idx: number): ITab => {
        if (idx === action.payload.idTab) {
            if(Object.prototype.hasOwnProperty.call(tab.content,"requirements")){
                const newRequirements = ((tab.content) as IRequirementsTab).requirements.map((item) => {
                    if (item.irq_id.toString() === action.payload.idItem.toString()) {
                        return Object.assign({},
                            item,
                            {
                                checked: !item.checked
                            })
                    }
                    return item
                })
                const newContent = Object.assign({},
                    tab.content,
                    {
                        requirements: newRequirements
                    })
                return Object.assign({},
                    tab,
                    {
                        content: newContent
                    })
            }
            else if (Object.prototype.hasOwnProperty.call(tab.content,"normatdocs")){
                const newNormatdocs = ((tab.content) as INormatDocTab).normatdocs.map((item) => {
                    if (item.ind_id === action.payload.idName) {
                        return Object.assign({},
                            item,
                            {
                                checked: !item.checked
                            })
                    }
                    return item
                })
                const newContent = Object.assign({},
                    tab.content,
                    {
                        normatdocs: newNormatdocs
                    })
                return Object.assign({},
                    tab,
                    {
                        content: newContent
                    })
            }
            else{
                const newItems = ((tab.content) as any).items.map((item: any) => {
                    console.log(item[action.payload.idName])
                    if (item[action.payload.idName] === action.payload.idItem) {
                        return Object.assign({},
                            item,
                            {
                                checked: !item.checked
                            })
                    }
                    return item
                })
                const newContent = Object.assign({},
                    tab.content,
                    {
                        items: newItems
                    })
                return Object.assign({},
                    tab,
                    {
                        content: newContent
                    })
            }
            
        }
        return tab
    })
    return newTabs
}

export const toggleAllCheckbox = (state: TabState, action: ToggleAllCheckbox): ITab[] => {
    const newTabs: ITab[] = state.tabs.map((tab: ITab, idx: number): ITab => {
        if (idx === action.payload.idTab) {
            const newItems = ((tab.content) as any).items.map((item: any) => {
                return Object.assign({},
                    item,
                    {
                        checked: !action.payload.checked
                    })
            })
            const newContent = Object.assign({},
                tab.content,
                {
                    items: newItems
                })
            return Object.assign({},
                tab,
                {
                    content: newContent
                })
        }
        return tab
    })
    return newTabs
}

export const deleteRow = (state: TabState, action: DeleteRow): ITab[] => {
    const newTabs: ITab[] = state.tabs.map((tab: ITab, idx: number): ITab => {
        if (idx === action.payload.idTab) {
            const newItems = ((tab.content) as any).items.filter((item: any) => !action.payload.idsRow.includes(item[action.payload.idName]))
            const newContent = Object.assign({},
                tab.content,
                {
                    items: newItems
                })
            return Object.assign({},
                tab,
                {
                    content: newContent
                })
        }
        return tab
    })
    return newTabs
}

export const setLimit = (state: TabState, action: SetLimit): ITab[] => {
    const newTabs: ITab[] = state.tabs.map((tab: ITab, idx: number): ITab => {
        if (idx === action.payload.idTab) {
            return Object.assign({},
                tab,
                {
                    limit: action.payload.limit
                })
        }
        return tab
    })
    return newTabs
}

export const setPage = (state: TabState, action: SetPage): ITab[] => {
    const newTabs: ITab[] = state.tabs.map((tab: ITab, idx: number): ITab => {
        if (idx === action.payload.idTab) {
            return Object.assign({},
                tab,
                {
                    page: action.payload.page
                })
        }
        return tab
    })
    return newTabs
}

export const setSortColumn = (state: TabState, action: SetSortColumn): ITab[] => {
    const newTabs: ITab[] = state.tabs.map((tab: ITab, idx: number): ITab => {
        if (idx === action.payload.idTab) {
            return Object.assign({},
                tab,
                {
                    sortColumn: action.payload.column
                })
        }
        return tab
    })
    return newTabs
}

export const setSortType = (state: TabState, action: SetSortType): ITab[] => {
    const newTabs: ITab[] = state.tabs.map((tab: ITab, idx: number): ITab => {
        if (idx === action.payload.idTab) {
            return Object.assign({},
                tab,
                {
                    sortType: action.payload.type
                })
        }
        return tab
    })
    return newTabs
}

export const setFilters = (state: TabState, action: SetFilters): ITab[] => {
    const newTabs: ITab[] = state.tabs.map((tab: ITab, idx: number): ITab => { 
        if (idx === action.payload.idTab) {
            let newFilter = true
            let newFilters: {field: string, value: string}[] | undefined = tab.filters

            newFilters = newFilters?.map((filter, idx) => {
                if (filter.field === action.payload.filter.field) {
                    newFilter = false
                    return Object.assign({},
                        filter,
                        {
                            value: action.payload.filter.value
                        })
                }
                return filter
            })

            if (newFilter) {
                newFilters?.push(action.payload.filter)
            }

            if (action.payload.filter.value === '') {
                newFilters = newFilters?.filter(filter => filter.field !== action.payload.filter.field)
            }

            return Object.assign({},
                tab,
                {
                    filters: newFilters
                })
        }
        return tab
    })
    return newTabs
}

export const addItemToContent = (state: TabState, action: AddItemToContent): ITab[] => {
    const newTabs: ITab[] = state.tabs.map((tab: ITab, idx: number): ITab => {
        if (idx === action.payload.idTab) {
            console.log(action.payload.item)
            const newItems = [action.payload.item, ...(tab.content as any).items]
            const newContent = Object.assign({},
                tab.content,
                {
                    items: newItems
                })
            return Object.assign({},
                tab,
                {
                    content: newContent
                })
        }
        return tab
    })
    return newTabs
}