import React, {FC, useState} from 'react';
import {SortType} from 'rsuite-table';
import ToolBar from '../../ToolBar/ToolBar';
import CustomTable from '../../CustomTable/CustomTable'
import {IContentTable, TabPanel} from '../../../interfaces/TabInterface';
import FormBottomTable from "../../Forms/FormBottomTable/FormBottomTable";
import {CodeTnvedForm} from "../../../shared/form.table.enviroment";

interface CodeTnvedTabProps extends TabPanel {

}

const CodeTnvedTab: FC<CodeTnvedTabProps> = ({idTab, limit, page, sortColumn, sortType, content, filters, form}) => {

    const [toggleForm, setToggleForm] = useState<boolean>(false)

    return (
        <div className="tab-wrap">
            <div className="table-container">
                <CustomTable
                    idName={"ict_id"}
                    api={{
                        post: '/v_codetnveds/all',
                        put: '/codetnveds'
                    }}
                    headerHeight={80}
                    idTab={idTab}
                    items={content?.items ? content.items : []}
                    limit={limit}
                    page={page}
                    sortType={sortType as SortType}
                    sortColumn={sortColumn}
                    totalItems={content?.totalItems}
                    filters={filters}
                    setting={[{
                        key: 'sct_code',
                        label: 'Товарный код',
                        width: 150,
                        filtered:true
                    },
                        {
                            key: 'sct_name',
                            label: 'Наименование',
                            width: 480,
                            filtered:true
                        },
                        {
                            key: 'sct_note',
                            label: 'Расшифровка',
                            width: 1140,
                            filtered:true
                        },
                        {
                            key: 'nct_ratecustomsduty',
                            label: 'Базовая ставка таможенной пошлины',
                            width: 150
                        },
                        {
                            key: 'sct_dutynote',
                            label: 'Примечание к пошлине',
                            width: 150
                        },
                        {
                            key: 'nct_excise',
                            label: 'Акциз',
                            width: 150
                        },
                        {
                            key: 'sct_excisenote',
                            label: 'Примечание к акцизу',
                            width: 150
                        },
                        {
                            key: 'nct_nds',
                            label: 'НДС',
                            width: 150
                        },
                        {
                            key: 'sct_ndsnote',
                            label: 'Примечание к НДС',
                            width: 150
                        }]}
                />
                    <FormBottomTable
                        open={toggleForm} onToggleForm={() => setToggleForm(prev => !prev)}
                        idName='ict_id'
                        form={form}
                        fields={CodeTnvedForm}
                        idTab={idTab}
                        postForm={{
                            sct_code: '',
                            sct_name: '',
                            sct_note: '',
                            nct_ratecustomsduty: '',
                            sct_dutynote: '',
                            nct_excise: '',
                            sct_excisenote: '',
                            nct_nds: '',
                            sct_ndsnote: '',
                            pid: ''
                        }}
                        api='/codetnveds'
                    />
            </div>
            <ToolBar idTab={idTab} toggleForm={() => setToggleForm(state => !state)} api="/codetnveds/"
                     idName={"ict_id"} tableNameExport={"v_codetnved"} />
        </div>
    );
};

export default CodeTnvedTab;
