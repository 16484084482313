import { Dispatch } from "redux";
import { TableActionsType, TableAction } from '../../interfaces/tableActions';
import { host } from '../../environments/environments';
import {FieldTypes, mappedFieldName} from "../../shared/form.helpers";

export const GetTableContent = (idx: number, page: number, limit: number, api: string, props?: {sortColumn?: string, sortType?: string, filters?: {field: string, value: string}[]}) => {
    return async (dispatch: Dispatch<TableAction>) => {

        dispatch({
            type: TableActionsType.START_TABLE_FETCH
        })

        let body = {
            range: limit,
            batch: page
        }
        if (props?.sortColumn && props?.sortType) {
            body = Object.assign({},
                body,
                {
                    orderSort: {
                        property: props?.sortColumn,
                        asc: props?.sortType === 'asc' ? true : false
                    }
                })
        }
        if (props?.filters?.length !== 0) {
            body = Object.assign({},
                body,
                {
                    filters: props?.filters
                })
        }
        try {
            const response = await fetch(`${host + api}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })

            if (response.status === 200) {
                const json = await response.json()
                const items = json.items?.map((item: any) => {
                    let newItem = {}
                    Object.keys(item).forEach(key => {
                        if (item[key] === null) {
                            newItem = {
                                ...newItem,
                                [key]: ''
                            }
                        } else {
                            newItem = {
                                ...newItem,
                                [key]: item[key]
                            }
                        }
                    })
                    return Object.assign({}, newItem, {
                        checked: false
                    })
                })
                const content = Object.assign({},
                    json,
                    {
                        items: items
                    })
                dispatch({
                    type: TableActionsType.GET_CONTENT,
                    payload: {
                        idTab: idx,
                        content: content
                    }
                })
                dispatch({
                    type: TableActionsType.SUCCESS_TABLE_FETCH
                })
            }          

            if (response.status !== 200 && response.status !== 204) {
                const json = await response.json()
                dispatch({
                    type: TableActionsType.ERROR_TABLE_FETCH,
                    payload: {
                        message: JSON.stringify(json)
                    }
                })
            }

        } catch (e) {
            dispatch({
                type: TableActionsType.ERROR_TABLE_FETCH,
                payload: {
                    message: e
                }
            })
        }
    }
}

export function UpdTableContent (idTab: number, data: {[key: string]: any}, idRow: number)  {
    return (dispatch: Dispatch<TableAction>) => {
        dispatch({
            type: TableActionsType.UPD_CONTENT,
            payload: {
                idTab,
                data,
                idRow
            }
        })
    }
}


/*export const UpdTableContent = (idTab: number, idContent: number, dataKey: string, value: string | number, idName: string) => {
    return (dispatch: Dispatch<TableAction>) => {
        dispatch({
            type: TableActionsType.UPD_CONTENT,
            payload: {
                idTab,
                idContent,
                dataKey,
                value,
                idName
            }
        })
    }
}*/

export const PutTableRow = (idTab: number, object: {[key: string]: any}, api: string, postForm?: {[key: string]: any}) => {
    return async (dispatch: Dispatch<TableAction>) => {
        try {

            const myObject: {[key: string]: any} = {}
            const myBody: {[key: string]: any} = {}

            // Object.keys(item).map(key => {
            //     if (item[key] === 'Нет') {
            //         newItem[key] = 0
            //     } else if (item[key] === 'Да') {
            //         newItem[key] = 0
            //     } else {
            //         newItem[key] = item[key]
            //     }
            // })

            if(postForm) {
                Object.keys(object).forEach(key => {

                    if (postForm.hasOwnProperty(key))
                    {
                       myObject[key] = object[key]
                    }
                    
                })


                Object.keys(myObject).forEach(key => {
                    const thirdLetter = key.slice(3, 4)
                    if (thirdLetter === '_')
                    {   
                        const newKey = key.slice(4)
                        if (key !== 'sin_type' && mappedFieldName.hasOwnProperty(newKey)) {
                            myBody[newKey] = mappedFieldName[newKey as FieldTypes](myObject[key])
                        } else {
                            myBody[newKey] = myObject[key]
                        }        
                    } else {
                        myBody[key] = myObject[key]
                    }
                })
                
            } else {

                Object.keys(object).forEach(key => {
                    const thirdLetter = key.slice(3, 4)
                    if (thirdLetter === '_')
                    {
                        const newKey = key.slice(4)
                        if (key !== 'sin_type' && mappedFieldName.hasOwnProperty(newKey)) {
                            myBody[newKey] = mappedFieldName[newKey as FieldTypes](object[key])
                        } else {
                            myBody[newKey] = object[key]
                        }        
                    } else {
                        if (key === 'pid') {
                            if (object[key] !== '') {
                                myBody[key] = object[key]
                            }
                        } else {
                            myBody[key] = object[key]
                        }
                        
                    }
                })

            }

            dispatch({
                type: TableActionsType.START_TABLE_FETCH
            })

            const response = await fetch(`${host + api}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(myBody)
            })

           /* if (response.ok) {
                dispatch({
                    type: TableActionsType.SUCCESS_TABLE_FETCH,
                    payload: {
                        idTab
                    }
                })
            }*/

            if (response.ok) {
                dispatch({
                    type: TableActionsType.SUCCESS_TABLE_SAVE
                })
            }

            if (response.status !== 200) {
                const error = await response.json()
                dispatch({
                    type: TableActionsType.ERROR_TABLE_FETCH,
                    payload: {
                        idTab,
                        message: error.message
                    }
                })
            }
        } catch (err: any) {
            dispatch({
                type: TableActionsType.ERROR_TABLE_FETCH,
                payload: {
                    idTab,
                    message: err.message
                }
            })
        }
    }
}

export const ToggleCheckbox = (idTab: number, idItem: number, idName: string) => {
    return (dispatch: Dispatch<TableAction>) => {
        dispatch({
            type: TableActionsType.TOGGLE_CHECKBOX,
            payload: {
                idTab,
                idItem,
                idName
            }
        })
    }
}

export const ToggleAllCheckbox = (idTab: number, checked: boolean) => {
    return (dispatch: Dispatch<TableAction>) => {
        dispatch({
            type: TableActionsType.TOGGLE_ALL_CHECKBOX,
            payload: {
                idTab,
                checked
            }
        })
    }
}

export const DeleteRows = (idTab: number, idsRow: number[], api: string, idName: string) => {
    return async (dispatch: Dispatch<TableAction>) => {
        if(api)
        {
            try {
                const response = await fetch(`${host + api + 'delete'}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        ids: idsRow
                    })
                })
    
                if (response.status !== 200) {
                    const json = await response.json()
                    dispatch({
                        type: TableActionsType.ERROR_TABLE_FETCH,
                        payload: {
                            idTab,
                            message: JSON.stringify(json)
                        }
                    })
                }
    
                if (response.status === 200) {
                    dispatch({
                        type: TableActionsType.DELETE_ROW,
                        payload: {
                            idTab,
                            idsRow,
                            idName
                        }
                    })
                }
            } catch (e) {
                dispatch({
                    type: TableActionsType.ERROR_TABLE_FETCH,
                    payload: {
                        idTab,
                        message: e
                    }
                })
            }
        }
        else 
        { // Значит удаляем  строку в разделе требования
            dispatch({
                type: TableActionsType.DELETE_ROW,
                payload: {
                    idTab,
                    idsRow,
                    idName
                }
            })
        }
        
    }
}

export const SetLimit = (idTab: number, limit: number) => {
    return (dispatch: Dispatch<TableAction>) => {
        dispatch({
            type: TableActionsType.SET_LIMIT,
            payload: {
                idTab,
                limit
            }
        })
    } 
}

export const SetPage = (idTab: number, page: number) => {
    return (dispatch: Dispatch<TableAction>) => {
        dispatch({
            type: TableActionsType.SET_PAGE,
            payload: {
                idTab,
                page
            }
        })
    } 
}

export const SetSortColumn = (idTab: number, column: string) => {
    return (dispatch: Dispatch<TableAction>) => {
        dispatch({
            type: TableActionsType.SET_SORT_COLUMN,
            payload: {
                idTab,
                column
            }
        })
    } 
}

export const SetSortType = (idTab: number, type: string) => {
    return (dispatch: Dispatch<TableAction>) => {
        dispatch({
            type: TableActionsType.SET_SORT_TYPE,
            payload: {
                idTab,
                type
            }
        })
    } 
}

export const SetFilters = (idTab: number, filter: {field: string, value: string}) => {
    return (dispatch: Dispatch<TableAction>) => {
        dispatch({
            type: TableActionsType.SET_FILTERS,
            payload: {
                idTab,
                filter
            }
        })
    }
}
