import {TabQuarantObjByRequirement} from "../contexts/TabsContext";

export enum TabTypes {
    REQUIREMENTSHISTORY_TAB = 'REQUIREMENTSHISTORY_TAB',
    DOCUMENTSREPOSITORY_TAB = 'DOCUMENTSREPOSITORY_TAB',
    COUNTRYREPOSITORY_TAB = 'COUNTRYREPOSITORY_TAB',
    OBSERVATIONS_TAB = 'OBSERVATIONS_TAB',
    CROPSTAGES_TAB = 'CROPSTAGES_TAB',
    CROPS_TAB = 'CROPS_TAB',
    DISTRICTS_TAB = 'DISTRICTS_TAB',
    PHENOPHASES_TAB = 'PHENOPHASES_TAB',
    PHENOCLASSES_TAB = 'PHENOCLASSES_TAB',
    INFECTIONS_TAB = 'INFECTIONS_TAB',
    CULTTYPES_TAB = 'CULTTYPES_TAB',
    CULTS_TAB = 'CULTS_TAB',
    CNTRQOBJ_TAB = 'CNTRQOBJ_TAB',
    UQPQOBJ_TAB = 'UQPQOBJ_TAB',
    REQUIREMENT_TAB = 'REQUIREMENT_TAB',
    DOCS_TAB = 'DOCS_TAB',
    COUNTRY_TAB = 'COUNTRY_TAB',
    COUNTERPARTIES_TAB = 'COUNTERPARTIES_TAB',
    REQUIREMENT_SPECIFIC_TAB = 'REQUIREMENT_SPECIFIC_TAB',
    MUNITS_TAB = 'MUNITS_TAB',
    OTHERSOBJS_TAB = 'OTHERSOBJS_TAB',
    REGIONS_TAB = 'REGIONS_TAB',
    CODETNVEDS_TAB = 'CODETNVEDS_TAB',
    UQUARANTOBJ_TAB = 'UQUARANTOBJ_TAB',
    CLWRLDCNTR_TAB = 'CLWRLDCNTR_TAB',
    EXPORTERS_TAB = 'EXPORTERS_TAB',
    QUARANTOBJ_TAB = 'QUARANTOBJ_TAB',
    TPQUARANTOBJ_TAB = 'TPQUARANTOBJ_TAB',
    SAMPLREGPR_TAB = 'SAMPLREGPR_TAB',
    PLACESIMP_TAB = 'PLACESIMP_TAB',
    DOCTYPES_TAB = 'DOCTYPES_TAB',
    UQUARANTPROD_TAB = 'UQUARANTPROD_TAB',
    KINDUQPROD_TAB = 'KINDUQPROD_TAB',
    GRUQPROD_TAB = 'GRUQPROD_TAB',
    TPOTHERSOBJ_TAB = 'TPOTHERSOBJ_TAB',
    NORMAT_DOC_TAB = 'NORMAT_DOC_TAB',
    NORMAT_DOC_SPECIFIC_TAB = "NORMAT_DOC_SPECIFIC_TAB",
    SPECIFIC_UQUARANTPROD_TAB = 'SPECIFIC_UQUARANTPROD_TAB',
    QUARANT_FITO_ZONE_TAB = 'QUARANT_FITO_ZONE_TAB',
    QUARANT_OBJ_BY_REQUIREMENT = 'QUARANT_OBJ_BY_REQUIREMENT',
}

export interface TabPanel {
    limit: number,
    page: number,
    sortColumn: string,
    sortType: string,
    content: IContentTable,
    idTab: number,
    filters: { field: string, value: string }[],
    form: { [key: string]: any }
}

export interface ITab {
    id: string
    t_pid?: string
    title: string
    type: TabTypes
    limit?: number
    page?: number
    sortColumn?: string
    sortType?: string
    form?: { [key: string]: any }
    filters?: {
        field: string,
        value: string
    }[],
    content?: IContentTable | IRequirementsTab | INormatDocTab
    [key: string]: any
}

export interface IDocumentRepositoryTab extends Pick<TabPanel, 'idTab' > {
    pid: string
    documents: Array<IFile>
}

export interface IFile {
    id: string | null
    name: string
    mimeType?: string
    file: File | null
}

export interface Requirement {
    irq_id: string
    srq_str: string
    country_name: string
    permission: number
    checked?: boolean
}

export interface IRequirementsTab {
    addReqForm: IAddReqForm
    requirements: Requirement[]
    // [key: string]: any
}

export interface INormatDocTab {
    addNormatdocForm: IAddNormatDocForm
    normatdocs: {
        ind_id: string
        snd_str: string
        checked?: boolean

    }[]
    // [key: string]: any
}

export interface IAddNormatDocForm {
    // Данные для записи/добавления  в notmatdoc
    name: string,     // Текстовое поле
    sdt_code: string, //  "Документ"
    docnumb: string,  // Поле номера документа "№"
    docdate: string,  // Поле "Дата"
    begdate: string,  // Поле "Начало действия"
    relevance: boolean// Чекер "Актуальность"
    idt_id: string
}


export interface IAddReqForm {
    pp: string,
    relevance: boolean,
    permission: string,
    sdt_code: string,
    reqnumb: string,
    reqdate: string,
    period_beg: string,
    period_end: string,
    permonths: string,
    scn_code: string,
    idt_id: number,
    icn_id: number,
}

export interface IQuarantFitoZoneTab extends ITab {
    pid: string
    mainForm: {
        rqpququarantobjs: IFSK[]
        rqcntquarantobjs: CntrByQuarantObj[]
    }
}

export interface IFSK {
    irj_id: string,
    iro_pid: string,
    srj_pofree: boolean,
    srj_pocharg: boolean,
    iuo_uquarantobj_id: string,
    suo_code: string,
    suo_name: string,
    nuo_area: string,
    nuo_number: string,
    icn_clwrldcntr_id: string,
    scn_numcode: string,
    scn_code: string,
    scn_name: string,
    scn_letcode2: string,
    scn_letcode3: string,
    irg_region_id: string,
    srg_name: string,
    iuo_id: string
}

export interface CntrByQuarantObj {
    "cnt_id": number
    "cnt_rusname": string
}

export interface IDocsTab {
    text: string
}

export interface ICountriesTab {
    text: string
}

export interface IContrTab {
    text: string
}

export interface ITerritoriesCountryRegionsForm {
    exporters: [],
    reexporters: []
    [key: string]: any
}

export interface ISpecificRequirementsTab extends ITab {
    id: string
    territoriesCountryForm: ITerritoriesCountryRegionsForm
    territoriesRegionsForm: ITerritoriesCountryRegionsForm
    mainForm: IMainForm
    [key: string]: any
}

export interface IQuarantObjByRequirementTab extends ITab {
    [key: string]: any
}

export interface ISpecificUquarantObjTab extends ITab {
    pid: string
    mainForm: {
        tnveds: ITnvedsUquarantObj[]
        dopQuals: IDopQualsUquarantObj[]
    }
    [key: string]: any
}

export interface ITnvedsUquarantObj {
    irt_id: string,
    irp_pid: string,
    ict_id: string,
    ict_codetnved_id: string,
    sct_code: string,
    sct_name: string,
    sct_note: string,
    nct_ratecustomsduty: number,
    sct_dutynote: string,
    nct_excise: number,
    sct_excisenote: string,
    nct_nds: number,
    sct_ndsnote: string,
    sct_fullname: string
}

export interface IDopQualsUquarantObj {
    irm_id: string,
    irp_pid: string,
    ioo_id: string,
    srm_s_value: string,
    nrm_n_value: string,
    drm_d_value: string,
    ioo_othersobj_id: number,
    soo_latname: string,
    soo_rusname: string,
    ito_tpothersobj_id: number,
    sto_code: string,
    sto_name: string,
    imu_munits_id: number,
    smu_name: string,
    nmu_okei: number,
    smu_condnatdes: string,
    smu_condintdes: string,
    smu_clnatdes: string,
    smu_clintdes: string,
    soo_type: string,
    soo_main: string,
    soo_label_name: string
}

export interface ISpecificNormatDocFormTab {
    title: string
    type: TabTypes
    id: string
    normatDocForm: INormatDocForm
    [key: string]: any
}


export interface INormatDocForm {
    normatdoc__ind_id: string
    normatdoc__sdt_code: string

    // Основное
    normatdoc__name: string
    normatdoc__docnumb: string
    normatdoc__docdate: string
    normatdoc__begdate: string
    normatdoc__relevance: boolean
    normatdoc__rfrequir: string
    normatdoc__reqcntrimp: string
    normatdoc__necresearch: string
    [key: string]: any
}

export interface IMainForm {
    requirements__irq_id: string,
    requirements__relevance: boolean,
    requirements__permission: string,
    requirements__notefsks: string,
    requirements__condtransit: string,
    requirements__transportcond: string,
    requirements__reqdate: string,
    requirements__period_end: string,
    requirements__period_beg: string,
    requirements__expallfsks: boolean,
    requirements__permonths: string,
    requirements__reqnumb: string,
    requirements__pp: string,
    requirements__sdt_code: string,
    requirements__idt_id: string,
    requirements__snd_str: string,
    requirements__ind_id: string,
    requirements__freezonecntr: boolean,
    requirements__icn_importer_id: string,
    requirements__scn_code: string,
    requirements__freezonereg: boolean,

    // Территории/Регионы
    requirements__freezoneagn: boolean,
    regionsExp: {
        irg_id: string,
        srg_name: string
    }[]
    regionsReexp: {
        irg_id: string,
        srg_name: string
    }[]
    cntrExp: {
        irc_id: string
        scn_name: string
    }[]
    cntrReexp: {
        irc_id: string
        scn_name: string
    }[]
    dopDoc: {
        ird_id: string,
        idt_id: string,
        sdt_code: string,
        sdt_name: string,
        srd_note: string
    }[]
    agnReexp: {
        ire_id: string,
        icn_clwrldcntr_id: string,
        sea_code: string
    }[]
    agnExp: {
        ira_id: string,
        icn_clwrldcntr_id: string,
        iea_id: string,
        sea_code: string,
        sea_addrprod: string
    }[]
    placeSimp: {
        irl_id: string,
        ips_id: string,
        sps_code: string,
        drl_begdate: string,
        drl_enddate: string
    }[]
    //Вкладка "Упаковка"
    requirements__pack: string,
    requirements__packmark: string,
    requirements__packproc: string,

    //Подкарантинная продукция
    rquqprodsById: {
        [key: number]: {
            irp_id: number,
            sgr_code: string,
            skp_rusname: string,
            srp_note: string,
            srp_permission: number,
            sup_rusname: string
            v_rqaddqualprm: number[],
            v_rqpquarantobj: number[],
            v_rquqprodtnved: number[]
        }
    }
    rquqprodsAll: number[]

    //Доп параметры качества
    v_rqaddqualprm: {
        [key: number]: {
            irm_id: number,
            smu_name: string,
            soo_rusname: string,
            type: number
        }
    }

    //Коды ТНВЕД
    v_rquqprodtnved: {
        [key: number]: {
            irt_id: number,
            sct_code: string,
            sct_fullname: string
        }
    }

    //Карантинные объекты
    v_rqpquarantobj: {
        [key: number]: {
            iro_id: number,
            sqo_rusname: string,
            v_rqpququarantobj: number[]
        }
    }

    //Подкарантинные объекты
    v_rqpququarantobj: {
        [key: number]: {
            irj_id: number,
            srj_pocharg: number,
            srj_pofree: number,
            suo_code: string
        }
    }

    //#region Вкладка документы
    //Первая строка
    doc__mainCode__requirements__idt_id: string,
    doc__mainCode__requirements__sdt_code: string,
    doc__mainCode__requirements__ids__id: string,// Основной
    doc__mainSample__requirements__sds_code: string, // образец
    doc__mainPeriod__requirements__notemainaccomp: string // Период в днях
    doc__mainNote__requirements__permainaccomp: string // Примечание

    //Вторая строка
    doc__concomitantCode__requirements__sdt_code: string, // Основной
    doc__concomitantSample__requirements__sds_code: string, // образец
    doc__concomitantPeriod__requirements__noteescaccomp: string // Период в днях
    doc__concomitantNote__requirements__perescaccomp: string // Примечание

    //Третья строка
    doc__otherCode__requirements__sdt_code: string, // Основной
    doc__otherSample__requirements__sds_code: string, // образец
    doc__otherPeriod__requirements__noteothaccomp: string // Период в днях
    doc__otherNote__requirements__perothaccomp: string // Примечание

    //#endregion

    [key: string]: any
}

export interface IContentTable<T extends object = Record<string, string | number | boolean | null | undefined>> {
    batchSize: number
    currentBatch: number
    totalItems: number
    totalBatches: number
    items: T[]
}


export type ITabContent = IContentTable
    | IRequirementsTab
    | INormatDocTab