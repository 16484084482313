export const host = process.env.NODE_ENV === 'production' ? '/s' : 'https://vniikr.dev.asbc.lo/s'
export const hostIdentity = ''

export const environmentAuth = {
    stsAuthority: `${hostIdentity}`,
    clientId: 'vniikr_client',
    responseType: 'code',
    clientScope: 'openid profile offline_access VniikrAPI',
    postLogoutRedirectUri: `${hostIdentity}`,
    redirectUri: `${hostIdentity}/callback`,
    login: `${hostIdentity}/Account/Login`
}

export const environmentAPI = {
    getDb: `${host}/snapshot`,
    getHash: `${host}/snapshot/hash`
}


export const METADATA_OIDC = {
    issuer: `${hostIdentity}`,
    jwks_uri: `${hostIdentity}/.well-known/openid-configuration/jwks`,
    authorization_endpoint: `${hostIdentity}/connect/authorize`,
    token_endpoint: `${hostIdentity}/connect/token`,
    userinfo_endpoint: `${hostIdentity}/connect/userinfo`,
    end_session_endpoint: `${hostIdentity}/connect/endsession`,
    check_session_iframe: `${hostIdentity}/connect/checksession`,
    revocation_endpoint: `${hostIdentity}/connect/revocation`,
    introspection_endpoint: `${hostIdentity}/connect/introspect`
};