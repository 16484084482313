import React, {FC, useEffect, useState} from 'react';
import { Cell } from 'rsuite-table';

import Autocomplete from '@mui/material/Autocomplete';
import './AutocompleteMainCell.scss';

interface AutocompleteMainCellProps {
    idTab: number
    onChange?: (idRow: number, dataKey: string, val: string) => void
    dataKey: string
    rowData?: any
    rowIndex?: number
    readonly?: boolean | ((data: Record<string, any>) => boolean)
    readonlyColor?: string
    idName: string
    options: string[]
}

const AutocompleteMainCell: FC<AutocompleteMainCellProps> = ({ idTab, rowData, rowIndex, dataKey, onChange, idName, readonly, readonlyColor, options, ...props }) => {

    const [value, setValue] = useState(rowData[dataKey] ? rowData[dataKey] as string : '')

    useEffect(() => {
            setValue(rowData[dataKey]?.toString() ? rowData[dataKey] as string : '')
    }, [rowData, dataKey])

    const styles = typeof readonly === 'function' ? readonly(rowData) ? {backgroundColor: readonlyColor, height: '30px'} : {height: '30px'} : {height: '30px'}

    return (
        <Cell {...props} className="table-content-editing">
             <Autocomplete
                    clearOnBlur={true}
                    sx={{ width: '100%', height: '100%'}}
                    getOptionLabel={(option: any) => option}
                    options={options}
                    renderInput={(params) => (
                        <div ref={params.InputProps.ref}>
                            <input type="text" {...params.inputProps} placeholder="Поиск..."
                                className="my-rs-input" value={value} style={styles} readOnly={typeof readonly === 'function' ? readonly(rowData) : readonly}
                            />
                        </div>
                    )}
                    onInputChange={(e, val, reas) => {
                        setValue(val)
                    }}
                    onChange={(e, val: any, reas) => {
                        if (reas === 'selectOption') {
                            setValue(val)
                            onChange && onChange(rowIndex!, dataKey, val)
                        }
                    }}
                    noOptionsText="Ничего не найдено"
                />
        </Cell>
    );
};

export default AutocompleteMainCell;