import React, {FC, useState} from 'react';
import {SortType} from 'rsuite-table';
import {TabPanel} from '../../../interfaces/TabInterface';
import ToolBar from '../../ToolBar/ToolBar';
import CustomTable, {AutocompleteCellTypes} from '../../CustomTable/CustomTable';
import FormBottomTable from "../../Forms/FormBottomTable/FormBottomTable";
import {DisctrictsForm} from "../../../shared/form.table.enviroment";

interface DistrictsTabProps extends TabPanel {
}

const DistrictsTab: FC<DistrictsTabProps> = ({idTab, limit, page, sortColumn, sortType, content, filters, form}) => {
    const [toggleForm, setToggleForm] = useState<boolean>(false)

    return (
        <div className="tab-wrap">
            <div className="table-container">
                <CustomTable
                    api={{
                        post: '/v_districts/all',
                        put: '/districts'
                    }}
                    idName={'idt_id'}
                    headerHeight={80}
                    idTab={idTab}
                    items={content?.items ? content.items : []}
                    limit={limit}
                    page={page}
                    sortType={sortType as SortType}
                    sortColumn={sortColumn}
                    totalItems={content?.totalItems}
                    filters={filters}
                    setting={[
                        {
                            key: 'sdt_name',
                            label: 'Район',
                            flex: 1,
                            sortable: true,
                            filtered: true
                        },
                        {
                            key: 'srg_name',
                            label: 'Регион',
                            flex: 1,
                            filtered: true,
                            autocomplete: AutocompleteCellTypes.DEFAULT,
                            api: '/v_regions/join'
                        }
                    ]}
                />
                <FormBottomTable
                    open={toggleForm} onToggleForm={() => setToggleForm(prev => !prev)}
                    idName={'idt_id'}
                    form={form}
                    fields={DisctrictsForm}
                    postForm={{
                        sdt_name: '',
                        irg_region_id: ''
                    }}
                    api="/districts"
                    idTab={idTab}
                />
            </div>
            <ToolBar idTab={idTab} toggleForm={() => setToggleForm(state => !state)} api="/districts/"
                     idName={'idt_id'} tableNameExport={'districts'} schema_name={'ais_weeds'}/>
        </div>
    );
};

export default DistrictsTab;