import React, {FC} from 'react';
import InputWithLabel from '../../Inputs/InputWithLabel/InputWithLabel';
import DataPickersWithLabel from '../../Inputs/DataPickersWithLabel/DataPickersWithLabel';
import CheckBoxWithLabel from '../../Inputs/CheckBoxWithLabel/CheckBoxWithLabel';
import AutocompleteWithLabel from '../../Inputs/AutocompleteWithLabel/AutocompleteWithLabel';
import TextAreaWithLabel from '../../../TextAreaWithLabel/TextAreaWithLabel';
import {formsName} from '../../../../shared/shared';
import moment from 'moment';


import './MainPoint.scss'
import {IMainForm} from '../../../../interfaces/TabInterface';

import {useActions} from '../../../../hooks/useAction';
import {useCheckboxChange, useInputChange, useOtherChange} from '../../../../hooks/useChange';
import {useSaveDefaultField, useSaveNonStandartField, useSaveOtherField} from '../../../../hooks/useSave';

interface MainPointProps {
    idTab: number
    mainForm: IMainForm
    control: any
}

const MainPoint: FC<MainPointProps> = ({idTab, mainForm, control}) => {

    const {SetReqFormValue} = useActions()

    const handleChange = useInputChange(idTab, formsName.mainForm)
    const handleCheckerChange = useCheckboxChange(idTab, formsName.mainForm)
    const handleOtherChange = useOtherChange(idTab, formsName.mainForm)
    const handeBlurSaveString = useSaveDefaultField(mainForm.requirements__irq_id, '0')
    const handleBlurSaveCheker = useSaveOtherField(mainForm.requirements__irq_id, '1')
    const handleBlurSaveDate = useSaveOtherField(mainForm.requirements__irq_id, '2')
    const handleAutocompleteDoc = useSaveNonStandartField(mainForm.requirements__irq_id, 'requirements', 'reqtype_id', mainForm.requirements__idt_id)
    const handleAutocompleteNormatdoc = useSaveNonStandartField(mainForm.requirements__irq_id, 'requirements', 'normatdoc_id', mainForm.requirements__ind_id)
    const handleAutocompletePermission = useSaveNonStandartField(mainForm.requirements__irq_id, 'requirements', 'permission', mainForm.requirements__permission)

    const handleDiffDate = (date: string, field: string) => {
        switch (field) {
            case 'requirements__period_beg':
                if (mainForm.requirements__period_end) {
                    const permonth = Math.abs(Math.floor(moment(mainForm.requirements__period_end).diff(moment(date), 'months')))
                    SetReqFormValue(idTab, formsName.mainForm, 'requirements__permonths', permonth.toString())
                }
                break
            case 'requirements__period_end':
                if (mainForm.requirements__period_beg) {
                    const permonth = Math.abs(Math.floor(moment(date).diff(moment(mainForm.requirements__period_beg), 'months')))
                    SetReqFormValue(idTab, formsName.mainForm, 'requirements__permonths', permonth.toString())
                }
                break
            case 'requirements__permonths':
                if (mainForm.requirements__period_beg) {
                    const period_end = moment(mainForm.requirements__period_beg, 'YYYY-MM-DDTHH:mm:ss').add(date, 'month')
                    SetReqFormValue(idTab, formsName.mainForm, 'requirements__period_end', period_end.format('YYYY-MM-DDTHH:mm:ss'))
                }
                break
            default:
                break
        }
    }

    return (

        <div style={{
            display: 'grid',
            gridTemplateRows: '0.8fr 0.2fr 0.2fr 2.8fr',
            gridTemplateColumns: '1.2fr 1.2fr 1fr 1fr 1fr 1fr',
            height: '100%',
            gridGap: '0 1vw'
        }}>

            <div style={{
                gridRowStart: 1,
                gridRowEnd: 2,
                gridColumnStart: 1,
                gridColumnEnd: 3
            }}>
                <InputWithLabel widthInput={80} widthInputBlock={'60%'} widthLabel={'40%'} label={"№ п.п"}
                                control={control}
                                nameField="requirements__pp"
                                onChangeInput={handleChange}
                                value={mainForm.requirements__pp}
                                onBlurInput={handeBlurSaveString}
                />
                <AutocompleteWithLabel widthInput={'100%'} widthInputBlock={'60%'} widthLabel={'40%'} label={"Документ"}
                                       control={control}
                                       nameField="sdt_code"
                                       onChangeInput={handleOtherChange}
                                       value={mainForm.requirements__sdt_code}
                                       api={'/v_doctypess/join'}
                                       onBlurInput={handleAutocompleteDoc}
                />
                <DataPickersWithLabel label="Период действия с" widthInput={'100%'} widthInputBlock={'60%'}
                                      widthLabel={'40%'}
                                      control={control}
                                      readonly={false}
                                      nameField="requirements__period_beg"
                                      onChangeInput={handleOtherChange}
                                      value={mainForm.requirements__period_beg}
                                      onBlurInput={handleBlurSaveDate}
                                      onDiffDate={handleDiffDate}
                />
            </div>

            <div style={{
                gridRowStart: 1,
                gridRowEnd: 2,
                gridColumnStart: 3,
                gridColumnEnd: 5
            }}>
                <CheckBoxWithLabel label={"Актуальность"}
                                   nameField="requirements__relevance"
                                   onChangeInput={handleCheckerChange}
                                   value={mainForm.requirements__relevance}
                                   control={control}
                                   onBlurInput={handleBlurSaveCheker}
                />
                <InputWithLabel widthInput={'100%'} widthLabel={'10%'} widthInputBlock={'90%'} label={"№"}
                                control={control}
                                onChangeInput={handleChange}
                                nameField="requirements__reqnumb"
                                value={mainForm.requirements__reqnumb}
                                onBlurInput={handeBlurSaveString}
                />
                <DataPickersWithLabel label="по" widthInput={'100%'} widthLabel={'10%'} widthInputBlock={'90%'}
                                      control={control}
                                      nameField="requirements__period_end"
                                      onChangeInput={handleOtherChange}
                                      value={mainForm.requirements__period_end}
                                      onBlurInput={handleBlurSaveDate}
                                      onDiffDate={handleDiffDate}
                />
            </div>

            <div style={{
                gridRowStart: 1,
                gridRowEnd: 2,
                gridColumnStart: 5,
                gridColumnEnd: 7
            }}>
                <AutocompleteWithLabel widthInput={'100%'} widthLabel={'30%'} widthInputBlock={'70%'} label={""}
                                       control={control}
                                       nameField="requirements__permission"
                                       onChangeInput={handleOtherChange}
                                       value={mainForm.requirements__permission}
                                       type={'permission'}
                                       onBlurInput={handleAutocompletePermission}
                />
                <DataPickersWithLabel label="Дата" widthLabel={'30%'} widthInputBlock={'70%'} widthInput={'100%'}
                                      control={control}
                                      nameField="requirements__reqdate"
                                      onChangeInput={handleOtherChange}
                                      value={mainForm.requirements__reqdate}
                                      onBlurInput={handleBlurSaveDate}
                />
                <InputWithLabel label="Месяцев" widthLabel={'30%'} widthInputBlock={'70%'} widthInput={'100%'}
                                control={control}
                                nameField="requirements__permonths"
                                onChangeInput={handleChange}
                                value={mainForm.requirements__permonths}
                                onBlurInput={handeBlurSaveString}
                                onDiffDate={handleDiffDate}
                />
            </div>


            {/*   <div style={{
                gridRowStart: 2,
                gridRowEnd: 3,
                gridColumnStart: 1,
                gridColumnEnd: 7
            }}>
                <AutocompleteWithLabel label="Нормативный документ" widthInput={'100%'} widthInputBlock={'80%'} widthLabel={'20%'}
                    control={control}
                    nameField="snd_str"
                    onChangeInput={handleOtherChange}
                    value={mainForm.requirements__snd_str} 
                    api={'/v_normatdoc_lists/join'}
                    onBlurInput={handleAutocompleteNormatdoc}
                />
            </div>*/}

            <div style={{
                gridRowStart: 3,
                gridRowEnd: 4,
                gridColumnStart: 1,
                gridColumnEnd: 7
            }}>
                <CheckBoxWithLabel label={"Допускается экспорт при наличии обработки в ФС/КС"}
                                   nameField="requirements__expallfsks"
                                   onChangeInput={handleCheckerChange}
                                   control={control}
                                   value={mainForm.requirements__expallfsks}
                                   onBlurInput={handleBlurSaveCheker}
                />
            </div>

            <div style={{
                gridRowStart: 4,
                gridRowEnd: 5,
                gridColumnStart: 1,
                gridColumnEnd: 7
            }}>
                <TextAreaWithLabel height={200} label="Условия обработки в ФС/КС" widthInput={'100%'}
                                   widthInputBlock={'80%'} widthLabel={'20%'}
                                   control={control}
                                   nameField="requirements__notefsks"
                                   onChangeInput={handleChange}
                                   value={mainForm.requirements__notefsks}
                                   onBlurInput={handeBlurSaveString}
                />
                <TextAreaWithLabel height={200} label="Условия транзита" widthInput={'100%'} widthInputBlock={'80%'}
                                   widthLabel={'20%'}
                                   control={control}
                                   nameField="requirements__condtransit"
                                   onChangeInput={handleChange}
                                   value={mainForm.requirements__condtransit}
                                   onBlurInput={handeBlurSaveString}
                />
                <TextAreaWithLabel height={200} label="Условия транспортировки" widthInput={'100%'}
                                   widthInputBlock={'80%'} widthLabel={'20%'}
                                   control={control}
                                   nameField="requirements__transportcond"
                                   onChangeInput={handleChange}
                                   value={mainForm.requirements__transportcond}
                                   onBlurInput={handeBlurSaveString}
                />
            </div>

        </div>

    );
};

export default MainPoint;