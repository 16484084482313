import React, { FC, useState } from 'react';
import { SortType } from 'rsuite-table';
import ToolBar from '../../ToolBar/ToolBar';
import CustomTable, {AutocompleteCellTypes} from '../../CustomTable/CustomTable';
import {IContentTable, TabPanel} from '../../../interfaces/TabInterface';
import FormBottomTable from "../../Forms/FormBottomTable/FormBottomTable";
import {QuarantObjForm} from "../../../shared/form.table.enviroment";

interface QuarantobjTabProps extends TabPanel {

}

const QuarantobjTab: FC<QuarantobjTabProps> = ({ idTab, limit, page, sortColumn, sortType, content, filters, form }) => {
    const [toggleForm, setToggleForm] = useState<boolean>(false)

    return (
        <div className="tab-wrap">
            <div className="table-container">
                <CustomTable 
                    api={{
                        post: '/v_quarantobjs/all',
                        put: '/quarantobjs'
                    }}
                    idName={'iqo_id'}
                    headerHeight={80}
                    idTab={idTab}
                    items={content?.items ? content.items : []}
                    limit={limit}
                    page={page}
                    sortType={sortType as SortType}
                    sortColumn={sortColumn}
                    totalItems={content?.totalItems}
                    filters={filters}
                    setting={[
                        {   
                            key: 'sqo_latname',
                            label: 'Lat наименование',
                            width: 200,
                            sortable: true,
                            filtered: true
                        },
                        {
                            key: 'sqo_rusname',
                            label: 'Rus наименование',
                            width: 250,
                            sortable: true,
                            filtered: true
                        },
                        {
                            key: 'sqo_eppo',
                            label: 'EPPO',
                            width: 250,
                            sortable: true
                        },
                        {
                            key: 'sqo_notation',
                            label: 'Примечание',
                            width: 250,
                            sortable: true
                        },
                        {   
                            key: 'stq_code',
                            label: 'Вид',
                            width: 250,
                            autocomplete: AutocompleteCellTypes.DEFAULT,
                            api: '/v_tpquarantobjs/join'
                        },
                        {   
                            key: 'stq_name',
                            label: 'Полное наименование вида',
                            width: 250,
                            readonly: true,
                        }
                    ]}
                />
                <FormBottomTable open={toggleForm} onToggleForm={() => setToggleForm(prev => !prev)} fields={QuarantObjForm} api='/quarantobjs' idTab={idTab} form={form} idName='iqo_id' />
            </div>
            <ToolBar idTab={idTab} toggleForm={() => setToggleForm(state => !state)} api="/quarantobjs/"
                     idName={'iqo_id'} tableNameExport={"v_quarantobj"}/>
        </div>
    );
};

export default QuarantobjTab;