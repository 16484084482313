import React, {FC, memo} from 'react';
import { Cell } from 'rsuite-table';
import './CheckedCell.scss'

interface CheckedCellProps {
    idTab: number
    onChange?: (id: number, idName: string) => void
    dataKey: string
    rowData?: any  
    idName: string
}

const CheckedCell: FC<CheckedCellProps> = ({ idTab, rowData, dataKey, onChange, idName, ...props }) => {
    return (
        <Cell {...props}  className="table-content-editing">
            <input
                type="checkbox"
                className="input-cheked"
                onChange={event => {
                    if (rowData)
                    {
                        onChange && onChange(rowData[idName] as number, idName);
                    }
                    else{
                        // event.preventDefault()
                        // event.stopPropagation()
                        onChange && onChange(0, idName);
                    }
                }}
                checked={rowData?.checked}
            />
        </Cell>
    );
};

export default memo(CheckedCell);