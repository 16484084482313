import {Dispatch} from "redux"
import {TabAction, TabActionsType} from "../../interfaces/TabActions"
import {reqFormAction, reqFormActionsType, statuses} from '../../interfaces/requirementsFormActions'
import {normatdocFormActionsType, normotdocFormAction} from '../../interfaces/normatdocFormActions'
import {ITab, TabTypes} from "../../interfaces/TabInterface"
import {host} from "../../environments/environments"
import {v4 as uuid} from "uuid";

export const AddTab = (title: string, type: TabTypes, id?: string) => {
    return async (dispatch: Dispatch<TabAction | reqFormAction | normotdocFormAction>) => {
        switch (type) {
            case TabTypes.REQUIREMENT_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type: type,
                        addReqForm: {
                            pp: '',
                            period_beg: '',
                            period_end: '',
                            permission: 'Разрешить',
                            permonths: '',
                            relevance: true,
                            reqdate: '',
                            reqnumb: '',
                            scn_code: '',
                            sdt_code: '',
                            icn_id: '',
                            idt_id: ''
                        },
                        content: {
                            requirements: []
                        }
                    }
                } as any)
                break
            case TabTypes.REQUIREMENT_SPECIFIC_TAB:

                try {

                    dispatch({
                        type: reqFormActionsType.START_FETCH_REQUIREMENT
                    })

                    const response = await fetch(`${host}/requirement/${id}`)

                    // const response_rquqprod_get_alls = await fetch(`${host}/v_rquqprod_get_alls/${id}`)

                    if (response.status === 200) {

                        const obj = await response.json()
                        const parentId = uuid()
                        dispatch({
                            type: TabActionsType.ADD_TAB, payload: {
                                id: parentId,
                                title,
                                type,
                                filters: [{
                                    field: 'irq_pid',
                                    value: `${id}`
                                }],
                                limit: 10,
                                page: 1,
                                sortType: 'desc',
                                sortColumn: 'irp_id',
                                form: {
                                    sup_rusname: '',
                                    sgr_code: '',
                                    skp_rusname: '',
                                    srp_permission: 'Да',
                                    srp_note: '',
                                    irq_pid: `${id}`,
                                    iup_uquarantprod_id: ''
                                },
                                mainForm: {
                                    ...obj,
                                    dopDoc: [...obj.dopDoc, {
                                        ird_id: '',
                                        idt_id: '',
                                        sdt_code: '',
                                        sdt_name: '',
                                        srd_note: ''
                                    }],

                                    //Территории/Конрагенты
                                    agnExp: [...obj.agnExp,
                                        {
                                            ira_id: '',
                                            icn_clwrldcntr_id: '',
                                            iea_id: '',
                                            sea_code: '',
                                            sea_addrprod: ''
                                        }],

                                    //Территории/места ввоза
                                    placeSimp: [...obj.placeSimp,
                                        {
                                            irl_id: '',
                                            ips_id: '',
                                            sps_code: '',
                                            drl_begdate: '',
                                            drl_enddate: ''
                                        }],
                                }
                            }
                        })
                        dispatch({
                            type: TabActionsType.ADD_TAB, payload: {
                                id: uuid(),
                                t_pid: parentId,
                                title: 'Карантинные объекты',
                                type: TabTypes.QUARANT_OBJ_BY_REQUIREMENT,
                                filters: [{
                                    field: 'irq_pid',
                                    value: `${id}`
                                }],
                                limit: 10,
                                page: 1,
                                sortType: 'desc',
                                sortColumn: 'iqq_id',
                                form: {
                                    iqo_quarantobj_id: "",
                                    iro_id: "",
                                    irp_pid: `${id}`,
                                    sqo_rusname: "",
                                    sqo_latname: ""
                                }
                            }
                        })
                        dispatch({
                            type: reqFormActionsType.SUCCES_FETCH_REQUIREMENT,
                            payload: {
                                message: statuses.DATA_GET
                            }
                        })
                    }

                    if (response.status !== 200) {
                        const obj = await response.json()
                        dispatch({
                            type: reqFormActionsType.ERROR_FETCH_REQUIREMENT,
                            payload: {
                                message: JSON.stringify(obj)
                            }
                        })
                    }
                } catch (e) {
                    console.log(e)
                    dispatch({
                        type: reqFormActionsType.ERROR_FETCH_REQUIREMENT,
                        payload: {
                            message: e as string
                        }
                    })
                }
                break

            case TabTypes.NORMAT_DOC_TAB:
                try {
                    dispatch({
                        type: normatdocFormActionsType.START_FETCH_NORMATDOC
                    })
                    const response = await fetch(`${host}/v_normatdoc_lists/all`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            //FIXME: уверен что range =10 этого достаточно? а если записей более 10
                            "range": 10,
                            "batch": 1,
                            "orderSort": {
                                "property": "ind_id",
                                "asc": false
                            }
                        })
                    })

                    if (response.status === 200) {
                        const obj = await response.json()
                        let normatdocs = (obj.items as any[]).map(item => Object.assign({}, item, {
                            checked: false
                        }))

                        dispatch({
                            type: TabActionsType.ADD_TAB, payload: {
                                title,
                                type: type,
                                addNormatdocForm: {
                                    name: '',
                                    sdt_code: '',
                                    docnumb: '',
                                    docdate: '',
                                    begdate: '',
                                    relevance: true,
                                    idt_id: ''
                                },
                                content: {
                                    normatdocs: normatdocs
                                }
                            }
                        } as any)
                        dispatch({
                            type: normatdocFormActionsType.SUCCES_FETCH_NORMATDOC,
                            payload: {
                                message: statuses.DATA_GET
                            }
                        })
                    }

                    if (response.status !== 200) {
                        const obj = await response.json()
                        dispatch({
                            type: normatdocFormActionsType.ERROR_FETCH_NORMATDOC,
                            payload: {
                                message: JSON.stringify(obj)
                            }
                        })
                    }
                } catch (e) {
                    dispatch({
                        type: normatdocFormActionsType.ERROR_FETCH_NORMATDOC,
                        payload: {
                            message: e as string
                        }
                    })
                }
                break
            case TabTypes.NORMAT_DOC_SPECIFIC_TAB:

                try {

                    dispatch({
                        type: normatdocFormActionsType.START_FETCH_NORMATDOC
                    })

                    const response = await fetch(`${host}/v_normatdocs/${id}`)


                    // Получение данных для вкладки "Основное"
                    // const response_doctypes = await fetch(`${host}/v_doctypess/all`, {
                    //     method: 'POST',
                    //     headers: {
                    //         'Content-Type': 'application/json'
                    //     },
                    //     body: JSON.stringify({
                    //         "range": 100,
                    //         "batch": 1,
                    //         "filters": [
                    //             {
                    //                 "field": "idt_id",
                    //                 "value": `${id}`
                    //             }
                    //         ],
                    //         "orderSort": {
                    //             "property": "idt_id",
                    //             "asc": true
                    //         }
                    //     })
                    // })


                    if (response.status === 200
                        // && response_exportersReg.status === 200
                        // && response_reexportersReg.status === 200
                        // && response_exportersCntr.status === 200
                        // && response_reexportersCntr.status === 200
                        // && response_rqadddoc.status === 200
                    ) {

                        const obj = await response.json()
                        // const exportersReg = await response_exportersReg.json()
                        // const reexportersReg = await response_reexportersReg.json()
                        // const exportersCntr = await response_exportersCntr.json()
                        // const reexportersCntr = await response_reexportersCntr.json()
                        // const addDoc = await response_rqadddoc.json()
                        dispatch({
                            type: TabActionsType.ADD_TAB, payload: {
                                id: uuid(),
                                title,
                                type,
                                normatDocForm: {
                                    normatdoc__ind_id: obj.ind_id ? obj.ind_id : null,
                                    normatdoc__sdt_code: obj.sdt_code ? obj.sdt_code : null,

                                    // Основное
                                    normatdoc__name: obj.sdt_name ? obj.sdt_name : null,
                                    normatdoc__docnumb: obj.snd_docnumb ? obj.snd_docnumb : null,
                                    normatdoc__docdate: obj.dnd_docdate ? obj.dnd_docdate : null,
                                    normatdoc__begdate: obj.dnd_begdate ? obj.dnd_begdate : null,
                                    normatdoc__relevance: obj.snd_relevance === 'Да' ? true : false,
                                    normatdoc__rfrequir: obj.snd_rfrequir ? obj.snd_rfrequir : null,
                                    normatdoc__reqcntrimp: obj.snd_reqcntrimp ? obj.snd_reqcntrimp : null,
                                    normatdoc__necresearch: obj.snd_necresearch ? obj.snd_necresearch : null,
                                }
                            }
                        })
                        dispatch({
                            type: reqFormActionsType.SUCCES_FETCH_REQUIREMENT,
                            payload: {
                                message: statuses.DATA_GET
                            }
                        })
                    }

                    if (response.status !== 200) {
                        const obj = await response.json()
                        dispatch({
                            type: reqFormActionsType.ERROR_FETCH_REQUIREMENT,
                            payload: {
                                message: JSON.stringify(obj)
                            }
                        })
                    }
                } catch (e) {
                    dispatch({
                        type: reqFormActionsType.ERROR_FETCH_REQUIREMENT,
                        payload: {
                            message: e as string
                        }
                    })
                }
                break
            case TabTypes.MUNITS_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        limit: 10,
                        page: 1,
                        sortColumn: 'id',
                        sortType: 'desc',
                        filters: [],
                        form: {
                            name: '',
                            okei: '',
                            condnatdes: '',
                            condintdes: '',
                            clnatdes: '',
                            clintdes: ''
                        }
                    }
                })
                break
            case TabTypes.DOCS_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type
                    }
                })
                break
            case TabTypes.OTHERSOBJS_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        limit: 10,
                        page: 1,
                        sortColumn: 'ioo_id',
                        sortType: 'desc',
                        filters: [],
                        form: {
                            sto_code: '',
                            sto_name: '',
                            soo_latname: '',
                            soo_rusname: '',
                            smu_name: '',
                            nmu_okei: '',
                            smu_condnatdes: '',
                            smu_condintdes: '',
                            smu_clnatdes: '',
                            smu_clintdes: '',
                            soo_type: '',
                            soo_main: 'Нет',
                            soo_label_name: '',
                            ito_tpothersobj_id: '',
                            imu_munits_id: ''
                        }
                    }
                })
                break
            case TabTypes.REGIONS_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        limit: 10,
                        page: 1,
                        sortColumn: 'irg_id',
                        sortType: 'desc',
                        filters: [],
                        form: {
                            srg_name: '',
                            ncn_numcode: '',
                            scn_code: '',
                            scn_name: '',
                            scn_letcode2: '',
                            scn_letcode3: '',
                            scn_base: '',
                            icn_clwrldcntr_id: '',
                            irg_id: ''
                        }
                    }
                })
                break
            case TabTypes.CODETNVEDS_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        limit: 10,
                        page: 1,
                        sortColumn: 'ict_id',
                        sortType: 'desc',
                        filters: [],
                        form: {
                            sct_code: '',
                            sct_name: '',
                            sct_note: '',
                            nct_ratecustomsduty: '',
                            sct_dutynote: '',
                            nct_excise: '',
                            sct_excisenote: '',
                            nct_nds: '',
                            sct_ndsnote: '',
                            name: '',
                            pid: '',
                        }
                    }
                })
                break
            case TabTypes.UQUARANTOBJ_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        limit: 10,
                        page: 1,
                        sortColumn: 'ikf_id',
                        sortType: 'desc',
                        filters: [],
                        form: {
                            nkf_quant: '',
                            nkf_area: '',
                            skf_district: '',
                            sqo_rusname: '',
                            sqo_latname: '',
                            srg_name: '',
                            iqo_quarantobj_id: '',
                            irg_region_id: '',
                            skf_munits: '',
                            ikf_id: ''
                        }
                    }
                })
                break
            case TabTypes.CLWRLDCNTR_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        limit: 10,
                        page: 1,
                        sortColumn: 'icn_id',
                        sortType: 'desc',
                        filters: [],
                        form: {
                            ncn_numcode: '',
                            scn_code: '',
                            scn_name: '',
                            scn_letcode2: '',
                            scn_letcode3: '',
                            scn_base: 'Нет',
                        }
                    }
                })
                break
            case TabTypes.EXPORTERS_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        limit: 10,
                        page: 1,
                        sortColumn: 'iea_id',
                        sortType: 'desc',
                        filters: [],
                        form: {
                            sea_code: '',
                            sea_name: '',
                            nea_uncode: '',
                            nea_inn: '',
                            sea_addrprod: '',
                            sea_addrjur: '',
                            srg_name: '',
                            ncn_numcode: '',
                            scn_code: '',
                            scn_name: '',
                            scn_letcode2: '',
                            scn_letcode3: '',
                            icn_clwrldcntr_id: '',
                            irg_regions_id: '',
                            iea_id: ''
                        }
                    }
                })
                break
            case TabTypes.QUARANTOBJ_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        limit: 10,
                        page: 1,
                        sortColumn: 'iqo_id',
                        sortType: 'desc',
                        filters: [],
                        form: {
                            sqo_latname: '',
                            sqo_rusname: '',
                            sqo_eppo: '',
                            sqo_notation: '',
                            stq_code: '',
                            stq_name: '',
                            itq_tpquarantobj_id: ''
                        }
                    }
                })
                break
            case TabTypes.TPQUARANTOBJ_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        limit: 10,
                        page: 1,
                        sortColumn: 'itq_id',
                        sortType: 'desc',
                        filters: [],
                        form: {
                            stq_code: '',
                            stq_name: '',
                            stq_absent: 'Нет',
                            stq_limited: 'Нет'
                        }
                    }
                })
                break
            case TabTypes.SAMPLREGPR_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        limit: 10,
                        page: 1,
                        sortColumn: 'isr_id',
                        sortType: 'desc',
                        form: {
                            ssr_name: '',
                            ssr_samprule: '',
                        }
                    }
                })
                break
            case TabTypes.PLACESIMP_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        limit: 10,
                        page: 1,
                        sortColumn: 'ips_id',
                        sortType: 'desc',
                        filters: [],
                        form: {
                            sps_code: '',
                            sps_name: '',
                            scn_code: '',
                            ncn_numcode: '',
                            scn_name: '',
                            scn_letcode2: '',
                            scn_letcode3: '',
                            srg_name: '',
                            icn_clwrldcntr_id: '',
                            irg_regions_id: '',
                        }
                    }
                })
                break
            case TabTypes.DOCTYPES_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        limit: 10,
                        page: 1,
                        sortColumn: 'id',
                        sortType: 'desc',
                        filters: [],
                        form: {
                            code: '',
                            name: ''
                        }
                    }
                })
                break
            case TabTypes.UQUARANTPROD_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        limit: 10,
                        page: 1,
                        sortColumn: 'iup_id',
                        sortType: 'desc',
                        filters: [],
                        form: {
                            sup_latname: '',
                            sup_rusname: '',
                            sup_fsksavail: '',
                            sup_ikravail: '',
                            sup_kfkavail: '',
                            nup_wgsmplavg: '',
                            skp_latname: '',
                            skp_rusname: '',
                            sgr_code: '',
                            sgr_name: '',
                            igr_gruqprod_id: '',
                            ikp_kinduqprod_id: '',
                            sup_notquar: ''
                        }
                    }
                })
                break
            case TabTypes.KINDUQPROD_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        limit: 10,
                        page: 1,
                        sortColumn: 'ikp_id',
                        sortType: 'desc',
                        filters: [],
                        form: {
                            skp_latname: '',
                            skp_rusname: '',
                        }
                    }
                })
                break
            case TabTypes.GRUQPROD_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        limit: 10,
                        page: 1,
                        sortColumn: 'igr_id',
                        sortType: 'desc',
                        filters: [],
                        form: {
                            sgr_code: '',
                            sgr_name: '',
                        }
                    }
                })
                break
            case TabTypes.TPOTHERSOBJ_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        limit: 10,
                        page: 1,
                        sortColumn: 'ito_id',
                        sortType: 'desc',
                        filters: [],
                        form: {
                            sto_code: '',
                            sto_name: '',
                        }
                    }
                })
                break
            case TabTypes.SPECIFIC_UQUARANTPROD_TAB:

                const resposne_tnveds = await fetch(`${host}/v_rquqprodtnveds/all`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "range": 100,
                        "batch": 1,
                        "filters": [
                            {
                                "field": "irp_pid",
                                "value": `${id}`
                            }
                        ],
                        "orderSort": {
                            "property": "irt_id",
                            "asc": true
                        }
                    })
                })

                const response_dopQuals = await fetch(`${host}/v_rqaddqualprms/all`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "range": 100,
                        "batch": 1,
                        "filters": [
                            {
                                "field": "irp_pid",
                                "value": `${id}`
                            }
                        ],
                        "orderSort": {
                            "property": "irm_id",
                            "asc": true
                        }
                    })
                })

                if (resposne_tnveds.status === 200
                    && response_dopQuals.status === 200) {

                    const tnveds = await resposne_tnveds.json()
                    const dopQuals = await response_dopQuals.json()

                    dispatch({
                        type: TabActionsType.ADD_TAB, payload: {
                            id: uuid(),
                            title,
                            type,
                            limit: 10,
                            page: 1,
                            sortColumn: 'iro_id',
                            sortType: 'desc',
                            filters: [{
                                field: 'irp_pid',
                                value: `${id}`
                            }],
                            pid: `${id}`,
                            form: {
                                sqo_rusname: '',
                                iqo_quarantobj_id: '',
                                irp_pid: `${id}`,
                                iro_id: ''
                            },
                            mainForm: {
                                tnveds: [...tnveds.items, {
                                    irt_id: '0',
                                    irp_pid: 0,
                                    ict_codetnved_id: 0,
                                    sct_code: '',
                                    sct_name: '',
                                    sct_note: '',
                                    nct_ratecustomsduty: 0,
                                    sct_dutynote: '',
                                    nct_excise: 0,
                                    sct_excisenote: '',
                                    nct_nds: 0,
                                    sct_ndsnote: '',
                                    sct_fullname: ''
                                }],
                                dopQuals: [...dopQuals.items, {
                                    irm_id: '',
                                    irp_pid: '',
                                    ioo_id: '',
                                    srm_s_value: '',
                                    nrm_n_value: '',
                                    drm_d_value: '',
                                    ioo_othersobj_id: 0,
                                    soo_latname: '',
                                    soo_rusname: '',
                                    ito_tpothersobj_id: 0,
                                    sto_code: '',
                                    sto_name: '',
                                    imu_munits_id: 0,
                                    smu_name: '',
                                    nmu_okei: 0,
                                    smu_condnatdes: '',
                                    smu_condintdes: '',
                                    smu_clnatdes: '',
                                    smu_clintdes: '',
                                    soo_type: '',
                                    soo_main: '',
                                    soo_label_name: ''
                                }]
                            }
                        }
                    })
                }
                break
            case TabTypes.QUARANT_FITO_ZONE_TAB:

                const response_rqcntquarantobjs = await fetch(`${host}/v_rqcntquarantobjs/all`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "range": 300,
                        "batch": 1,
                        "filters": [
                            {
                                "field": "iro_id",
                                "value": `${id}`
                            }
                        ],
                        "orderSort": {
                            "property": "cnt_rusname",
                            "asc": true
                        }
                    })
                })

                const response_rqpququarantobjs = await fetch(`${host}/v_rqpququarantobjs/all`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        "range": 100,
                        "batch": 1,
                        "filters": [
                            {
                                "field": "iro_pid",
                                "value": `${id}`
                            }
                        ],
                        "orderSort": {
                            "property": "irj_id",
                            "asc": true
                        }
                    })
                })

                if (response_rqpququarantobjs.status === 200 && response_rqcntquarantobjs.status === 200) {
                    const rqpququarantobjs = await response_rqpququarantobjs.json()
                    const rqcntquarantobjs = await response_rqcntquarantobjs.json()
                    dispatch({
                        type: TabActionsType.ADD_TAB, payload: {
                            id: uuid(),
                            title,
                            type,
                            limit: 10,
                            page: 1,
                            sortColumn: '',
                            sortType: '',
                            filters: [],
                            pid: `${id}`,
                            mainForm: {
                                rqpququarantobjs: [...rqpququarantobjs.items, {
                                    irj_id: '',
                                    iro_pid: '',
                                    srj_pofree: false,
                                    srj_pocharg: false,
                                    iuo_uquarantobj_id: '',
                                    suo_code: '',
                                    suo_name: '',
                                    nuo_area: '',
                                    nuo_number: '',
                                    icn_clwrldcntr_id: '',
                                    scn_numcode: '',
                                    scn_code: '',
                                    scn_name: '',
                                    scn_letcode2: '',
                                    scn_letcode3: '',
                                    irg_region_id: '',
                                    srg_name: '',
                                    iuo_id: ''
                                }],
                                rqcntquarantobjs: [...rqcntquarantobjs.items]
                            }
                        }
                    })
                }
                break
            case TabTypes.UQPQOBJ_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        limit: 10,
                        page: 1,
                        sortColumn: 'iuq_id',
                        sortType: 'desc',
                        filters: [{
                            field: 'iup_pid',
                            value: `${id}`
                        }],
                        form: {
                            iup_pid: `${id}`,
                            iqo_quarantobj_id: '',
                            sqo_latname: '',
                            sqo_rusname: '',
                            iuq_id: ''
                        }
                    }
                })
                break
            case TabTypes.CNTRQOBJ_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        limit: 10,
                        page: 1,
                        sortColumn: 'icq_id',
                        sortType: 'desc',
                        filters: [{
                            field: 'icn_pid',
                            value: `${id}`
                        }],
                        form: {
                            icn_pid: `${id}`,
                            iqo_quarantobj_id: '',
                            sqo_latname: '',
                            sqo_rusname: '',
                            icq_id: ''
                        }
                    }
                })
                break
            case TabTypes.CULTS_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        limit: 10,
                        page: 1,
                        sortColumn: 'icu_id',
                        sortType: 'desc',
                        filters: [],
                        form: {
                            ipc_phenoclass_id: '',
                            spc_phenoclass: '',
                            scu_rusname: '',
                            scu_latname: '',
                            icu_id: ''
                        }
                    }
                })
                break
            case TabTypes.CULTTYPES_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        limit: 10,
                        page: 1,
                        sortColumn: 'ict_id',
                        sortType: 'desc',
                        filters: [{
                            field: 'icu_cult_id',
                            value: `${id}`
                        }],
                        form: {
                            icu_cult_id: `${id}`,
                            sct_name: '',
                        }
                    }
                })
                break
            case TabTypes.INFECTIONS_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        limit: 10,
                        page: 1,
                        sortColumn: 'iin_id',
                        sortType: 'desc',
                        filters: [],
                        form: {
                            sin_rusname: '',
                            sin_latname: '',
                            sin_type: '',
                            ipc_phenoclass_id: '',
                            spc_phenoclass: '',
                            iqo_quarantobj_id: '',
                            sqo_rusname: '',
                            sqo_latname: '',
                        }
                    }
                })
                break
            case TabTypes.PHENOCLASSES_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        limit: 10,
                        page: 1,
                        sortColumn: 'ipc_id',
                        sortType: 'desc',
                        filters: [],
                        form: {
                            spc_phenoclass: ''
                        }
                    }
                })
                break
            case TabTypes.PHENOPHASES_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        limit: 10,
                        page: 1,
                        sortColumn: 'ipp_id',
                        sortType: 'desc',
                        filters: [{
                            field: 'ipc_phenoclass_id',
                            value: `${id}`
                        }],
                        form: {
                            spp_name: '',
                            ipc_phenoclass_id: `${id}`
                        }
                    }
                })
                break
            case TabTypes.DISTRICTS_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        limit: 10,
                        page: 1,
                        sortColumn: 'idt_id',
                        sortType: 'desc',
                        filters: [],
                        form: {
                            sdt_name: '',
                            srg_name: '',
                            irg_region_id: '',
                        }
                    }
                })
                break
            case TabTypes.CROPS_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        limit: 10,
                        page: 1,
                        sortColumn: 'icr_id',
                        sortType: 'desc',
                        filters: [],
                        form: {
                            scr_name: '',
                            icu_cult_id: '',
                            scu_rusname: '',
                            scu_latname: '',
                            scr_reseeding: 'Нет',
                            ict_cult_type_id: '',
                            sct_name: '',
                            irg_region_id: '',
                            srg_name: '',
                            idt_district_id: '',
                            sdt_name: '',
                            scr_year: '',
                            scr_field: '',
                        },
                        content: {
                            batchSize: 10,
                            currentBatch: 1,
                            totalItems: 10,
                            totalBatches: 1,
                            items: [
                                {
                                    scr_name: 'Наименование',
                                    icu_cult_id: 1,
                                    scu_rusname: 'Кульутра',
                                    scu_latname: 'Culture 1',
                                    ncr_reseeding: 'Да',
                                    ict_cult_type_id: 1,
                                    sct_name: 'Тип культуры',
                                    irg_region_id: 1,
                                    srg_name: 'Район',
                                    idt_district_id: 1,
                                    sdt_name: 'аывавыа',
                                    scr_year: '2023',
                                    scr_field: '123'
                                }
                            ]
                        }
                    }
                })
                break
            case TabTypes.CROPSTAGES_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        limit: 10,
                        page: 1,
                        sortColumn: 'ics_id',
                        sortType: 'desc',
                        filters: [{
                            field: 'icr_crop_id',
                            value: `${id}`
                        }],
                        form: {},
                        content: {
                            batchSize: 10,
                            currentBatch: 1,
                            totalItems: 10,
                            totalBatches: 1,
                            items: [
                                {
                                    ics_id: 1,
                                    scr_name: 'Наименование',
                                    scr_field: 'Поле',
                                    scs_date: '20.10.2023',
                                    spp_name: 'Фенофаза'
                                }
                            ]
                        }
                    }
                })
                break
            case TabTypes.OBSERVATIONS_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        limit: 10,
                        page: 1,
                        sortColumn: 'iob_id',
                        sortType: 'desc',
                        filters: [{
                            field: 'ics_crop_stage_id',
                            value: `${id}`
                        }],
                        form: {}
                    }
                })
                break
            case TabTypes.COUNTRYREPOSITORY_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        limit: 30,
                        page: 1,
                        sortColumn: 'icn_id',
                        sortType: 'desc',
                        filters: []
                    }
                })
                break
            case TabTypes.DOCUMENTSREPOSITORY_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        pid: id,
                        documents: []
                    }
                })
                break
            case TabTypes.REQUIREMENTSHISTORY_TAB:
                dispatch({
                    type: TabActionsType.ADD_TAB, payload: {
                        id: uuid(),
                        title,
                        type,
                        limit: 10,
                        page: 1,
                        sortColumn: 'CreatedAt',
                        sortType: 'desc',
                        form: {}
                    }
                })
                break
            default:
                break
        }
    }
}

export const DeleteTab = (id: string) => {
    return (dispatch: Dispatch<TabAction>) => {
        dispatch({type: TabActionsType.DELETE_TAB, payload: id})
    }
}

export const MovesTabs = (tabDrop: ITab, tabCurrent: ITab) => {
    return (dispatch: Dispatch<TabAction>) => {
        dispatch({
            type: TabActionsType.MOVES_TABS, payload: {
                tabDrop,
                tabCurrent
            }
        })
    }
}