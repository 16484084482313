import React, {FC, useState} from 'react';
import {SortType} from 'rsuite-table';
import {TabPanel} from '../../../interfaces/TabInterface';
import ToolBar from '../../ToolBar/ToolBar';
import CustomTable from '../../CustomTable/CustomTable';
import FormBottomTable from "../../Forms/FormBottomTable/FormBottomTable";

interface ObservationsTabProps extends TabPanel {
}

const ObservationsTab: FC<ObservationsTabProps> = ({idTab, limit, page, sortColumn, sortType, content, filters, form}) => {
    const [toggleForm, setToggleForm] = useState<boolean>(false)

    return (
        <div className="tab-wrap">
            <div className="table-container">
                <CustomTable
                    api={{
                        post: '/v_observations/all',
                        put: ''
                    }}
                    idName={'id'}
                    headerHeight={80}
                    idTab={idTab}
                    items={content?.items ? content.items : []}
                    limit={limit}
                    page={page}
                    sortType={sortType as SortType}
                    sortColumn={sortColumn}
                    totalItems={content?.totalItems}
                    filters={filters}
                    setting={[
                        {
                            key: 'sin_rusname',
                            label: 'Агент заражения',
                            width: 250,
                            sortable: true,
                            filtered: true,
                            readonly: true
                        },
                        {
                            key: 'spp_name',
                            label: 'Фенофаза',
                            width: 250,
                            sortable: true,
                            filtered: true,
                            readonly: true
                        },
                        {
                            key: 'sob_place',
                            label: 'Место в посеве',
                            width: 250,
                            sortable: true,
                            filtered: true,
                            readonly: true
                        },
                        {
                            key: 'sob_fullness',
                            label: 'Обилие',
                            width: 250,
                            sortable: true,
                            filtered: true,
                            readonly: true
                        },
                        {
                            key: 'sob_user',
                            label: 'Определил',
                            width: 250,
                            sortable: true,
                            filtered: true,
                            readonly: true
                        },
                        {
                            key: 'sob_note',
                            label: 'Примечание',
                            width: 250,
                            sortable: true,
                            filtered: true,
                            readonly: true
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export default ObservationsTab;