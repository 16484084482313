import React, {FC, useState} from 'react';
import {SortType} from 'rsuite-table';
import {TabPanel, TabTypes} from '../../../interfaces/TabInterface';
import ToolBar from '../../ToolBar/ToolBar';
import CustomTable from '../../CustomTable/CustomTable';
import FormBottomTable from "../../Forms/FormBottomTable/FormBottomTable";
import moment from "moment";

interface CropStagesTabProps extends TabPanel {
}

const CropStagesTab: FC<CropStagesTabProps> = ({idTab, limit, page, sortColumn, sortType, content, filters, form}) => {
    const [toggleForm, setToggleForm] = useState<boolean>(false)

    return (
        <div className="tab-wrap">
            <div className="table-container">
                <CustomTable
                    api={{
                        post: '/v_crop_stages/all',
                        put: ''
                    }}
                    idName={'ics_id'}
                    headerHeight={80}
                    idTab={idTab}
                    items={content?.items ? content.items : []}
                    limit={limit}
                    page={page}
                    sortType={sortType as SortType}
                    sortColumn={sortColumn}
                    totalItems={content?.totalItems}
                    filters={filters}
                    setting={[
                        {
                            key: 'ics_id',
                            label: '',
                            link: true,
                            width: 50,
                            titleNameKey: 'spp_name',
                            tabType: TabTypes.OBSERVATIONS_TAB
                        },
                        {
                            key: 'spp_name',
                            label: 'Стадия посева',
                            flex: 1,
                            sortable: true,
                            filtered: true,
                            readonly: true
                        },
                        {
                            key: 'dcs_date',
                            label: 'Дата обследования',
                            flex: 1,
                            sortable: true,
                            filtered: true,
                            readonly: true,
                            computedValue: (stage) => moment(stage.dcs_date).format('DD.MM.YYYY')
                        }
                    ]}
                />
            </div>
        </div>
    );
};

export default CropStagesTab;