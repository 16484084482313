import {Dispatch} from "redux";
import {FileRepositoryAction, FileRepositoryActionType} from "../../interfaces/fileRepositoryActions";
import {IFile} from "../../interfaces/TabInterface";

export const setFilesInRepository = (idTab: number, files: Array<IFile>) => {
    return (dispatch: Dispatch<FileRepositoryAction>) => {
       dispatch({
           type: FileRepositoryActionType.SET_FILES,
           payload: {
               idTab,
               files
           }
       })
    }
}
export const addFileInRepository = (idTab: number, file: IFile) => {
    return (dispatch: Dispatch<FileRepositoryAction>)=> {
        dispatch({
            type: FileRepositoryActionType.ADD_FILE,
            payload: {
                idTab,
                file
            }
        })
    }
}

export const removeFileFromRepository = (idTab: number, idFile: string) => {
    return (dispatch: Dispatch<FileRepositoryAction>)=> {
        dispatch({
            type: FileRepositoryActionType.REMOVE_FILE,
            payload: {
                idTab,
                idFile: idFile
            }
        })
    }
}