import React, { ChangeEvent, FC } from 'react';
import { Controller } from "react-hook-form";

interface TextAreaWithLabelProps {
    widthInputBlock: number | string
    widthLabel: number | string
    widthInput: number | string
    label: string
    nameForm?: string
    onChangeInput?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    nameField?: string
    value?: string
    control?: any
    height: number
    onBlurInput?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const TextAreaWithLabel: FC<TextAreaWithLabelProps> = ({ label,
    widthInput,
    widthLabel,
    widthInputBlock,
    nameField,
    onChangeInput,
    value,
    control,
    height,
    onBlurInput }) => {

    return (
        <div>
            <div className='input-with-label-block' style={{ height, marginBottom: 10 }}>
                <p className='input-with-label-block__text' style={{ width: widthLabel }}>{label}</p>
                <div style={{ width: widthInputBlock }}>
                    <Controller
                        control={control}
                        name={nameField as string}
                        defaultValue={value}
                        render={({ field: { onChange, onBlur, value, ref, name } }) => (
                            <textarea
                                className="input-with-label-block_input"
                                style={{
                                    width: widthInput,
                                    height,
                                    resize: 'none',
                                    padding: '10px 5px'
                                }}
                                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                                    onChange(e);
                                    onChangeInput && onChangeInput(e);
                                }}
                                value={value}
                                name={name}
                                onBlur={(e) => {
                                    onBlur()
                                    onBlurInput && onBlurInput(e)
                                }}
                            />
                        )}
                    />
                </div>
            </div>
        </div>
    );
};

export default TextAreaWithLabel;