import { Dispatch } from "redux"
import { reqFormActionsType, reqFormAction, statuses } from '../../interfaces/requirementsFormActions';
import { host } from "../../environments/environments";

export const AddTag = (idTab: number, nameForm: string, tag: { [key: string]: any }, nameIdTag: string, nameTags: string, nameValueTag: string) => {
    return (dispatch: Dispatch<reqFormAction>) => {
        dispatch({
            type: reqFormActionsType.ADD_TAGS,
            payload: {
                idTab,
                tag,
                nameIdTag,
                nameTags,
                nameValueTag,
                nameForm
            }
        })
    }
}

export const SetReqFormValue = (idTab: number, nameForm: string, nameField: string, value: string, dopField?: string) => {
    return (dispatch: Dispatch<reqFormAction>) => {
        dispatch({
            type: reqFormActionsType.SET_REQ_FORM_VALUE,
            payload: {
                idTab,
                nameForm,
                nameField,
                value,
                dopField
            }
        })
    }
}

export const SetReqFormToggleCheker = (idTab: number, nameForm: string, nameField: string) => {
    return (dispatch: Dispatch<reqFormAction>) => {
        dispatch({
            type: reqFormActionsType.SET_REQ_FORM_TOGGLE_CHEKER,
            payload: {
                idTab,
                nameForm,
                nameField,
            }
        })
    }
}

export const GetRequirementsList = (idTab: number, search?: string) => {
    return async (dispatch: Dispatch<reqFormAction>) => {
        try {

            let body = {
                "range": 10000,
                "batch": 1,
                "orderSort": {
                    "property": "irq_id",
                    "asc": false
                }
            }

            if (search) {
                body = Object.assign({}, body, {
                    "filters": [{
                        "field": "country_name",
                        "value": search
                    }]
                })
            }

            const response = await fetch(`${host}/v_requirements_lists/all`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })

            if (response.status === 200) {
                const obj = await response.json()
                const requirements = obj.items

                dispatch({
                    type: reqFormActionsType.GET_REQUIREMENTS_LIST,
                    payload: {
                        idTab,
                        requirements: requirements
                    }
                })

            }

            if (response.status !== 200) {
                const obj = await response.json()
                dispatch({
                    type: reqFormActionsType.ERROR_FETCH_REQUIREMENT,
                    payload: {
                        message: JSON.stringify(obj)
                    }
                })
            }

        } catch (e) {
            dispatch({
                type: reqFormActionsType.ERROR_FETCH_REQUIREMENT,
                payload: {
                    message: e as string
                }
            })
        }



    }
}

export const UpdFieldReqForm = (field_id: string, name_field: string, field_value: string | Date | boolean, field_type: string,) => {
    return async (dispatch: Dispatch<reqFormAction>) => {

        try {
            const name_fieldSplit = name_field.split('__')
            if (typeof field_value === typeof true) {
                if (field_value) {
                    field_value = '1'
                } else {
                    field_value = '0'
                }
            }

            if (name_fieldSplit[1] === 'permission') {
                if (field_value === 'Разрешить') {
                    field_value = '0'
                } else {
                    field_value = '1'
                }
            }

            let body = {
                field_id: `${field_id}`,
                table_name: name_fieldSplit[0],
                field_name: name_fieldSplit[1],
                field_value: field_value !== null ? `${field_value}` : null,
                field_type
            }

            if (name_fieldSplit.length > 2) {
                body = {
                    field_id: `${field_id}`,
                    table_name: name_fieldSplit[2],
                    field_name: name_fieldSplit[3],
                    field_value: field_value !== null ? `${field_value}` : null,
                    field_type
                }
            }

            dispatch({
                type: reqFormActionsType.START_FETCH_REQUIREMENT
            })

            const response = await fetch(`${host}/procedure`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })

            if (response.status === 200) {
                dispatch({
                    type: reqFormActionsType.SUCCES_FETCH_REQUIREMENT,
                    payload: {
                        message: statuses.DATA_SAVE
                    }
                })
            }


            if (response.status !== 200) {
                const json = await response.json()
                dispatch({
                    type: reqFormActionsType.ERROR_FETCH_REQUIREMENT,
                    payload: {
                        message: JSON.stringify(json)
                    }
                })
            }
        } catch (e) {
            dispatch({
                type: reqFormActionsType.ERROR_FETCH_REQUIREMENT,
                payload: {
                    message: e as string
                }
            })
        }

    }
}

export const DeleteTagReqForm = (idTab: number,
    nameForm: string,
    nameTags: string,
    nameTable: string,
    idTag: string) => {
    return async (dispatch: Dispatch<reqFormAction>) => {

        if (idTag === '0') {
            dispatch({
                type: reqFormActionsType.DELETE_TAG,
                payload: {
                    idTab,
                    nameForm,
                    nameTags,
                    idTag
                }
            })
        } else {
            try {
                dispatch({
                    type: reqFormActionsType.START_FETCH_REQUIREMENT
                })

                const response = await fetch(`${host}/${nameTable}/delete`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        ids: [+idTag]
                    })
                })

                if (response.status === 200) {
                    dispatch({
                        type: reqFormActionsType.DELETE_TAG,
                        payload: {
                            idTab,
                            nameForm,
                            nameTags,
                            idTag
                        }
                    })
                    dispatch({
                        type: reqFormActionsType.SUCCES_FETCH_REQUIREMENT,
                        payload: {
                            message: statuses.DATA_SAVE
                        }
                    })
                }

                if (response.status !== 200) {
                    const json = await response.json()
                    dispatch({
                        type: reqFormActionsType.ERROR_FETCH_REQUIREMENT,
                        payload: {
                            message: JSON.stringify(json)
                        }
                    })
                }
            } catch (e) {
                dispatch({
                    type: reqFormActionsType.ERROR_FETCH_REQUIREMENT,
                    payload: {
                        message: e as string
                    }
                })
            }
        }
    }
}

export const AddFields = (idTab: number, nameForm: string, nameTags: string, tag: { [key: string]: any }) => {
    return (dispatch: Dispatch<reqFormAction>) => {
        dispatch({
            type: reqFormActionsType.ADD_TAGS,
            payload: {
                idTab,
                nameForm,
                nameTags,
                tag
            }
        })
    }
}

export const AddQuarantProd = (idTab: number) => {
    return (dispatch: Dispatch<reqFormAction>) => {
        dispatch({
            type: reqFormActionsType.ADD_QUARANT_PROD,
            payload: {
                idTab
            }
        })
    }
}

export const AddTnvedArrayField = (idTab: number, idQuarantProd: number) => {
    return (dispatch: Dispatch<reqFormAction>) => {
        dispatch({
            type: reqFormActionsType.ADD_TNVED_ARRAY_FIELD,
            payload: {
                idTab,
                idQuarantProd
            }
        })
    }
}

export const AddtQuarantObjArrayField = (idTab: number, idQuarantProd: number) => {
    return (dispatch: Dispatch<reqFormAction>) => {
        dispatch({
            type: reqFormActionsType.ADD_QUARANT_OBJ_ARRAY_FIELD,
            payload: {
                idTab,
                idQuarantProd
            }
        })
     }
}

export const AddQuquarantObjArrayField = (idTab: number, idQuarantObj: number) => {
    return (dispatch: Dispatch<reqFormAction>) => {
        dispatch({
            type: reqFormActionsType.ADD_QUQUARANT_OBJ_ARRAY_FIELD,
            payload: {
                idTab,
                idQuarantObj
            }
        })
     }
}

export const DeleteRequirementBlockReqForm = (idTab: number, idRequirements: number[]) => {
    return async (dispatch: Dispatch<reqFormAction>) => {
        try {
            dispatch({
                type: reqFormActionsType.START_FETCH_REQUIREMENT
            })

            const response = await fetch(`${host}/requirementss/delete`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ids: idRequirements
                })
            })

            if (response.status === 200) {
                dispatch({
                    type: reqFormActionsType.DELETE_REQUIREMENT_BLOCK,
                    payload: {
                        idTab,
                        idRequirements
                    }
                })
                dispatch({
                    type: reqFormActionsType.SUCCES_FETCH_REQUIREMENT,
                    payload: {
                        message: statuses.DATA_DELETE
                    }
                })
            }

            if (response.status !== 200) {
                const json = await response.json()
                dispatch({
                    type: reqFormActionsType.ERROR_FETCH_REQUIREMENT,
                    payload: {
                        message: JSON.stringify(json)
                    }
                })
            }


        } catch (e) {
            dispatch({
                type: reqFormActionsType.ERROR_FETCH_REQUIREMENT,
                payload: {
                    message: e as string
                }
            })
        }
    }
}

export const PostArrayField = (idTab: number,
    nameForm: string,
    nameTags: string,
    nameTable: string,
    body: { [key: string]: any },
    idTag: number,
    tagField: string) => {
    return async (dispatch: Dispatch<reqFormAction>) => {
        try {
            dispatch({
                type: reqFormActionsType.START_FETCH_REQUIREMENT
            })

            const response = await fetch(`${host}/${nameTable}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })

            if (response.status === 200) {
                const json = await response.json()
                dispatch({
                    type: reqFormActionsType.SET_ARRAY_FIELD_VALUE,
                    payload: {
                        idTab,
                        nameForm,
                        nameTag: nameTags,
                        idTag,
                        nameField: tagField,
                        value: json.value.id
                    }
                })
                dispatch({
                    type: reqFormActionsType.SUCCES_FETCH_REQUIREMENT,
                    payload: {
                        message: statuses.DATA_SAVE
                    }
                })
            }

            if (response.status !== 200) {
                const json = await response.json()
                dispatch({
                    type: reqFormActionsType.ERROR_FETCH_REQUIREMENT,
                    payload: {
                        message: JSON.stringify(json)
                    }
                })
            }
        } catch (e) {
            dispatch({
                type: reqFormActionsType.ERROR_FETCH_REQUIREMENT,
                payload: {
                    message: e as string
                }
            })
        }
    }
}

export const PostTagReqForm = (idTab: number,
    nameForm: string,
    nameTags: string,
    nameTable: string,
    body: { [key: string]: any },
    tag: { [key: string]: any }) => {
    return async (dispatch: Dispatch<reqFormAction>) => {
        try {
            dispatch({
                type: reqFormActionsType.START_FETCH_REQUIREMENT
            })

            const response = await fetch(`${host}/${nameTable}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })

            if (response.status === 200) {
                const json = await response.json()

                Object.keys(tag).forEach((key, idx) => {
                    if (idx === 0) {
                        tag[key] = json.value.id
                    }
                })


                dispatch({
                    type: reqFormActionsType.SUCCES_FETCH_REQUIREMENT,
                    payload: {
                        message: statuses.DATA_SAVE
                    }
                })

                dispatch({
                    type: reqFormActionsType.ADD_TAGS,
                    payload: {
                        idTab,
                        nameForm,
                        nameTags,
                        tag
                    }
                })
            }

            if (response.status !== 200) {
                const json = await response.json()
                dispatch({
                    type: reqFormActionsType.ERROR_FETCH_REQUIREMENT,
                    payload: {
                        message: JSON.stringify(json)
                    }
                })
            }

        } catch (e) {
            dispatch({
                type: reqFormActionsType.ERROR_FETCH_REQUIREMENT,
                payload: {
                    message: e as string
                }
            })
        }
    }
}

export const ResetReqForm = (idTab: number) => {
    return (dispatch: Dispatch<reqFormAction>) => {
        dispatch({
            type: reqFormActionsType.RESET_REQ_FORM,
            payload: {
                idTab
            }
        })
    }
}

export const ResetStatusForm = () => {
    return (dispatch: Dispatch<reqFormAction>) => {
        dispatch({
            type: reqFormActionsType.RESET_STATUS_FORM
        })
    }
}

export const SetArrayFieldValue = (idTab: number, nameForm: string, nameTag: string, nameField: string, value: string | boolean, idTag: number) => {
    return (dispatch: Dispatch<reqFormAction>) => {
        dispatch({
            type: reqFormActionsType.SET_ARRAY_FIELD_VALUE,
            payload: {
                idTab,
                nameForm,
                nameTag,
                idTag,
                nameField,
                value
            }
        })
    }
}