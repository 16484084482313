import {Dispatch} from "redux"
import {TableAction} from '../../interfaces/tableActions';
import {formAction, formActionsType} from '../../interfaces/formActions';
import {host} from "../../environments/environments";
import {FieldTypes, mappedFieldName} from "../../shared/form.helpers";

export const PostRow = (object: { [key: string]: any }, api: string, idTab: number, idName: string, postForm?: {[key: string]: any}) => {
    return async (dispatch: Dispatch<TableAction | formAction>) => {
        try {

            dispatch({
                type: formActionsType.POST_REQUEST_FORM_START
            })

            const myBody: {[key: string]: any} = {}
            const myObject: {[key: string]: any} = {}
            let response: Response = new Response()

            if(postForm) {
                Object.keys(object).forEach(key => {
                    if (postForm.hasOwnProperty(key))
                    {
                       myObject[key] = object[key]
                    }
                })

                Object.keys(myObject).forEach(key => {
                    const thirdLetter = key.slice(3, 4)
                    if (thirdLetter === '_')
                    {   
                        const newKey = key.slice(4)
                        if (key !== 'sin_type' && mappedFieldName.hasOwnProperty(newKey)) {
                            myBody[newKey] = mappedFieldName[newKey as FieldTypes](myObject[key])
                        } else {
                            myBody[newKey] = myObject[key]
                        }
                    } else {
                        myBody[key] = myObject[key]
                    }
                })
                
            }
            else {
                Object.keys(object).forEach(key => {
                    const thirdLetter = key.slice(3, 4)
                    if (thirdLetter === '_')
                    {
                        const newKey = key.slice(4)
                        if (key !== 'sin_type' && mappedFieldName.hasOwnProperty(newKey)) {
                            myBody[newKey] = mappedFieldName[newKey as FieldTypes](myObject[newKey])
                        } else {
                            myBody[newKey] = object[key]
                        }
                    } else {
                        if (key === 'pid') {
                            if (object[key] !== '') {
                                myBody[key] = object[key]
                            }
                        } else {
                            myBody[key] = object[key]
                        }
                    }
                })
            }

            Object.keys(myBody).forEach(key => {
                if (myBody[key] === '') {
                    myBody[key] = null
                } 
            })

            
            response = await fetch(`${host + api}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(myBody)
            })


            if (response.status === 200) {
                
                const res = await response.json()

                const newObj = Object.assign({}, object, {
                    checked: false
                })

                newObj[idName] = res.value.id

                dispatch({
                    type: formActionsType.POST_REQUEST_FORM_SUCCESS
                })

                dispatch({
                    type: formActionsType.ADD_ITEMS_TO_CONTENT,
                    payload: {
                        idTab,
                        item: newObj
                    }
                })
                dispatch({
                    type: formActionsType.RESET_FORM,
                    payload: {
                        idTab
                    }
                })
            }

            if (response.status !== 200) {
                const json = await response.json()
                dispatch({
                    type: formActionsType.POST_REQUEST_FORM_ERROR,
                    payload: {
                        idTab,
                        message: JSON.stringify(json)
                    }
                })
            }
        } catch (e: any) {
            dispatch({
                type: formActionsType.POST_REQUEST_FORM_ERROR,
                payload: {
                    idTab,
                    message: e
                }
            })
        }
    }
}

export const CreateForm = (idTab: number, object: {[key: string]: any}) => {
    return (dispatch: Dispatch<formAction>) => {
        dispatch({
            type: formActionsType.CREATE_FORM_ON_TAB,
            payload: {
                idTab,
                form: object
            }
        })
    }
}

export const SetFormValue = (idTab: number, key: string, value: string | boolean) => {
    return (dispatch: Dispatch<formAction>) => {
        dispatch({
            type: formActionsType.SET_FORM_VALUE,
            payload: {
                idTab,
                key,
                value
            }
        })
    }
}

export const ClearForm = (idTab: number) => {
    return (dispatch: Dispatch<formAction>) => {
        dispatch({
            type: formActionsType.RESET_FORM,
            payload: {
                idTab
            }
        })
    }
}

export const ClearStatusForm = () => {
    return (dispatch: Dispatch<formAction>) => {
        dispatch({
            type: formActionsType.SET_EMPTY_STATUS_FORM
        })
    }
}