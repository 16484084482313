import React, {FC, useState} from 'react';
import {SortType} from 'rsuite-table';
import {TabPanel, TabTypes} from '../../../interfaces/TabInterface';
import ToolBar from '../../ToolBar/ToolBar';
import CustomTable from '../../CustomTable/CustomTable';
import FormBottomTable from "../../Forms/FormBottomTable/FormBottomTable";
import {PhenoclassesForm} from "../../../shared/form.table.enviroment";

interface PhenoclassesTabProps extends TabPanel {
}

const PhenoclassesTab: FC<PhenoclassesTabProps> = ({idTab, limit, page, sortColumn, sortType, content, filters, form}) => {
    const [toggleForm, setToggleForm] = useState<boolean>(false)

    return (
        <div className="tab-wrap">
            <div className="table-container">
                <CustomTable
                    api={{
                        post: '/v_phenoclasses/all',
                        put: '/phenoclasss'
                    }}
                    idName={'ipc_id'}
                    headerHeight={80}
                    idTab={idTab}
                    items={content?.items ? content.items : []}
                    limit={limit}
                    page={page}
                    sortType={sortType as SortType}
                    sortColumn={sortColumn}
                    totalItems={content?.totalItems}
                    filters={filters}
                    setting={[
                        {
                            key: 'ipc_id',
                            label: '',
                            link: true,
                            width: 50,
                            titleNameKey: 'spc_phenoclass',
                            tabType: TabTypes.PHENOPHASES_TAB
                        },
                        {
                            key: 'spc_phenoclass',
                            label: 'Класс фенофазы',
                            flex: 1,
                            sortable: true,
                            filtered: true
                        },
                    ]}
                />
                <FormBottomTable
                    open={toggleForm} onToggleForm={() => setToggleForm(prev => !prev)}
                    idName={'ipc_id'}
                    form={form}
                    fields={PhenoclassesForm}
                    api="/phenoclasss"
                    idTab={idTab}
                />
            </div>
            <ToolBar idTab={idTab} toggleForm={() => setToggleForm(state => !state)} api="/phenoclasss/"
                     idName={'ipc_id'} tableNameExport={'phenoclasss'} schema_name={'ais_weeds'}/>
        </div>
    );
};

export default PhenoclassesTab;