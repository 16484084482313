export interface formState {
    error: string,
    status: string
}

export enum formActionsType {
    POST_REQUEST_FORM_ERROR = 'POST_REQUEST_FORM_ERROR',
    POST_REQUEST_FORM_SUCCESS = 'POST_REQUEST_FORM_SUCCESS',
    POST_REQUEST_FORM_START = 'POST_REQUEST_FORM_START',
    ADD_ITEMS_TO_CONTENT = 'ADD_ITEMS_TO_CONTENT',
    CREATE_FORM_ON_TAB = 'CREATE_FORM_ON_TAB',
    SET_FORM_VALUE = 'SET_FORM_VALUE',
    RESET_FORM = 'RESET_FORM',
    SET_POST_FORM_VALUE_MODEL = 'SET_POST_FORM_VALUE_MODEL',
    SET_EMPTY_STATUS_FORM = 'SET_EMPTY_STATUS_FORM'
}

export interface startFetch {
    type: formActionsType.POST_REQUEST_FORM_START
}

export interface getSuccess {
    type: formActionsType.POST_REQUEST_FORM_SUCCESS
}

export interface getError {
    type: formActionsType.POST_REQUEST_FORM_ERROR,
    payload: {
        message: string
    }
}

export interface SetEmptyStatus {
    type: formActionsType.SET_EMPTY_STATUS_FORM
}

export interface AddItemToContent {
    type: formActionsType.ADD_ITEMS_TO_CONTENT,
    payload: {
        idTab: number,
        item: { [key: string]: any }
    }
}

export interface CreateFormOnTab {
    type: formActionsType.CREATE_FORM_ON_TAB,
    payload: {
        idTab: number,
        form: { [key: string]: any }
    }
}

export interface SetFormValue {
    type: formActionsType.SET_FORM_VALUE,
    payload: {
        idTab: number,
        key: string,
        value: string | boolean
    }
}

export interface ResetForm {
    type: formActionsType.RESET_FORM,
    payload: {
        idTab: number
    }
}

export interface SetPostFormValueModel {
    type: formActionsType.SET_POST_FORM_VALUE_MODEL,
    payload: {
        idTab: number,
        key: string,
        value: string,
        postFormModel: { [key: string]: any }
    }
}

export type formAction = startFetch
    | getSuccess
    | getError
    | AddItemToContent
    | CreateFormOnTab
    | SetFormValue
    | ResetForm
    | SetPostFormValueModel
    | SetEmptyStatus