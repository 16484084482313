import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import {IFile} from "../../../interfaces/TabInterface";
import {host} from "../../../environments/environments";
import {store} from "react-notifications-component";
import {ERROR_NOTIFY, getErrorNotification} from "../../../shared/notification";

import './FormAddFileToRequirement.scss'
import {CircularProgress} from "@mui/material";

interface FormAddFileToRequirementProps {
    country_id: string
    onSubmit: (files: IFile) => void
}

const FormAddFileToRequirement: FC<FormAddFileToRequirementProps> = ({country_id, onSubmit}) => {

    const [files, setFiles] = useState<IFile[]>([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        (async () => {
            try {
                const response = await fetch(host + `/file/country/${country_id}`)
                if (!response.ok) {
                    store.addNotification(getErrorNotification('Невозможно получить файлы', 'При получении файлов произошла ошибка'))
                    return;
                }
                const files: Array<IFile> = await response.json()
                setFiles(files.map(file => ({...file, selected: false})))
                setLoading(false)
            } catch (error) {
                store.addNotification(ERROR_NOTIFY)
                setLoading(false)
            }
        })()
    }, [country_id]);

    const handleSubmit = (file: IFile) => () => {
        onSubmit(file)
    }

    return (
        <div className={'form-add-files_wrapper'}>
            <p className={'form-add-files_title'}>Выберите файлы</p>
            <div className={'form-add-files_file-list'}>
                {loading ?
                    <div className={'form-add-files_loader_wrapper'}>
                        <CircularProgress sx={{color: '#5CDB94'}}/>
                    </div> :
                    files.length === 0 ? <p>Файлы отсутствуют</p> :
                    files.map(file => <div key={file.id} className={'form-add-files_file-list_item'} onClick={handleSubmit(file)}>
                        <p>{file.name}</p>
                    </div>)}
            </div>
        </div>
    );
};

export default FormAddFileToRequirement;