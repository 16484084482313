import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { Cell } from 'rsuite-table';
import { useActions } from '../../hooks/useAction';
import { TabTypes } from '../../interfaces/TabInterface';

import './LinkCell.scss'
import {IconDefinition} from "@fortawesome/free-solid-svg-icons";

interface LinkCellProps {
    idTab: number
    dataKey: string
    titleNameKey?: string | string[]
    fieldNameNestedExist?: string
    rowData?: any
    readonly?: boolean
    idName: string
    tabType: TabTypes
    icon?: IconDefinition
}

const LinkCell: FC<LinkCellProps> = ({ idTab, rowData, dataKey, idName, readonly, icon, titleNameKey, tabType, fieldNameNestedExist, ...props }) => {

    const {AddTab} = useActions()

    const handleClick = (event: React.MouseEvent<SVGSVGElement>) => {
        let title = ''
        if (!titleNameKey) return
        if (typeof titleNameKey === 'string') {
            title = rowData[titleNameKey]
        } else {
            title = titleNameKey.map(key => rowData[key]).join(' ')
        }
        AddTab(title, tabType, rowData[dataKey as string])
    }

    return (
        <Cell {...props} className="table-content-editing">
            <FontAwesomeIcon icon={icon || faEdit} size='lg' className={`edit-icon ${fieldNameNestedExist && rowData[fieldNameNestedExist] === 'Да' ? 'highlight' : ''}`} onClick={handleClick}/>
        </Cell>
    );
};

export default LinkCell;