import React, {FC, useState} from 'react';
import {IContentTable, TabTypes} from '../../../../interfaces/TabInterface';
import {useSpecificUquarantObjContext} from '../../../../contexts/TabsContext';
import CustomTable, {AutocompleteCellTypes} from '../../../CustomTable/CustomTable';
import {SortType} from 'rsuite-table';
import ToolBar from '../../../ToolBar/ToolBar';
import FormBottomTable from "../../../Forms/FormBottomTable/FormBottomTable";
import {QuarantObjPointForm} from "../../../../shared/form.table.enviroment";
import {faArrowAltCircleDown} from "@fortawesome/free-regular-svg-icons";
import ToolBarItem from "../../../ToolBarItem/ToolBarItem";
import ConfirmModal from "../../../ConfirmModal/ConfirmModal";
import {host} from "../../../../environments/environments";
import {store} from "react-notifications-component";
import {v4 as uuid} from "uuid";
import {ERROR_NOTIFY, SUCCESS_NOTIFY} from "../../../../shared/notification";
import {GetTableContent} from "../../../../store/action-creators/tableActionCreator";
import {useDispatch} from "react-redux";


interface QuarantObjPointProps {
    idTab: number
    pid: string
}

const QuarantObjPoint: FC<QuarantObjPointProps> = ({idTab, pid}) => {

    const [toggleForm, setToggleForm] = useState<boolean>(false)
    const [isOpenConfirmationForm, setIsOpenConfirmationForm] = useState(false)
    const {content, limit, page, sortType, sortColumn, filters, form} = useSpecificUquarantObjContext()
    const dispatch = useDispatch()

    const updateTableContent = () => {
        dispatch(GetTableContent(idTab, page as number, limit as number, '/v_rqpquarantobjs/all', {sortColumn, sortType, filters}))
    }
    const handleCopyKO = async () => {
        const response = await fetch(host + '/requirement/cp_rqpquarantobj/' + pid, {
            method: 'POST'
        })
        if (response.ok) {
            store.addNotification({
                id: uuid(),
                ...SUCCESS_NOTIFY
            })
            setIsOpenConfirmationForm(false)
            updateTableContent()
        } else {
            store.addNotification({
                id: uuid(),
                ...ERROR_NOTIFY
            })
        }
    }

    return (<div className="tab-wrap">
        <div className="table-container">
            <CustomTable
                api={{
                    post: '/v_rqpquarantobjs/all',
                    put: '/rqpquarantobjs'
                }}
                idName={'iro_id'}
                headerHeight={80}
                idTab={idTab}
                items={(content as IContentTable)?.items ? (content as IContentTable)?.items : []}
                limit={limit as number}
                page={page as number}
                sortType={sortType as SortType}
                sortColumn={sortColumn as string}
                totalItems={(content as IContentTable)?.totalItems}
                filters={filters as { field: string; value: string; }[]}
                postForm={
                    {
                        irp_pid: '',
                        iqo_quarantobj_id: '',
                        iro_id: ''
                    }
                }
                setting={[
                    {
                        key: 'iro_id',
                        label: '',
                        link: true,
                        width: 50,
                        titleNameKey: 'sqo_rusname',
                        tabType: TabTypes.QUARANT_FITO_ZONE_TAB
                    },
                    {
                        key: 'sqo_rusname',
                        label: 'Карантинный объект',
                        flex: 1,
                        sortable: true,
                        filtered: true,
                        autocomplete: AutocompleteCellTypes.DEFAULT,
                        api: '/v_quarantobjs/join'
                    }
                ]}
            />
            <FormBottomTable
                open={toggleForm} onToggleForm={() => setToggleForm(prev => !prev)}
                fields={QuarantObjPointForm}
                api='/rqpquarantobjs'
                idTab={idTab}
                immutableFields={['irp_pid']}
                form={form as { [key: string]: any; }}
                idName='iro_id'
                postForm={
                    {
                        irp_pid: '',
                        iqo_quarantobj_id: ''
                    }
            }/>
            <ConfirmModal
                open={isOpenConfirmationForm}
                onClose={() => setIsOpenConfirmationForm(false)}
                onConfirm={handleCopyKO}
                onNotConfirm={() => setIsOpenConfirmationForm(false)}
                header={''}
            >
                <p>Выполнение данной функции приведет к очистке заполненного ранее списка карантинных объектов. Продолжить?</p>
            </ConfirmModal>
        </div>
        <ToolBar idTab={idTab} toggleForm={() => setToggleForm(state => !state)} api="/rqpquarantobjs/"
                 idName={'iro_id'}>
            <ToolBarItem icon={faArrowAltCircleDown} onClick={() => setIsOpenConfirmationForm(true)} />
        </ToolBar>
    </div>);
};

export default QuarantObjPoint;