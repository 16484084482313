export enum SpecificRequirementsTabActionsType {
    ADD_COUNTRY = 'ADD_COUNTRY',
    ADD_COUNTERPARTIES = 'ADD_COUNTERPARTIES',
    DELETE_COUNTRY = 'DELETE_COUNTRY',
    DELETE_COUNTERPARTIES = 'DELETE_COUNTERPARTIES',
    SET_NUMBER = 'SET_NUMBER',
    SET_TIP = 'SET_TIP',
    SET_START_DATE = 'SET_START_DATE',
    SET_END_DATE = 'SET_END_DATE',
    GET_STATE_FORM = 'GET_STATE_FORM'
}

export interface AddCountryAction {
    type: SpecificRequirementsTabActionsType.ADD_COUNTRY
    payload: {
        idTab: number
        country: string
    }
}

export interface AddCounterpartiesAction {
    type: SpecificRequirementsTabActionsType.ADD_COUNTERPARTIES
    payload: {
        idTab: number
        counterparty: string
    }
}

export interface DeleteCountryAction {
    type: SpecificRequirementsTabActionsType.DELETE_COUNTRY
    payload: {
        idTab: number,
        idTag: number
    }
}

export interface DeleteCouterpartiesAction {
    type: SpecificRequirementsTabActionsType.DELETE_COUNTERPARTIES
    payload: {
        idTab: number,
        idTag: number
    }
}

export interface SetNumberAction {
    type: SpecificRequirementsTabActionsType.SET_NUMBER
    payload: {
        idTab: number,
        value: string
    }
}

export interface SetTipAction {
    type: SpecificRequirementsTabActionsType.SET_TIP
    payload: {
        idTab: number,
        value: string
    }
}

export interface SetStartDateAction {
    type: SpecificRequirementsTabActionsType.SET_START_DATE
    payload: {
        idTab: number,
        date: string | null
    }
}

export interface SetEndDateAction {
    type: SpecificRequirementsTabActionsType.SET_END_DATE
    payload: {
        idTab: number,
        date: string | null
    }
}

export type SpecificRequirementsTabAction =  AddCountryAction
    | AddCounterpartiesAction
    | DeleteCountryAction
    | DeleteCouterpartiesAction
    | SetNumberAction
    | SetTipAction
    | SetStartDateAction
    | SetEndDateAction

