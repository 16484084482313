import {TabState} from "../interfaces/TabActions";
import {AddFileAction, RemoveFileAction, SetFilesAction} from "../interfaces/fileRepositoryActions";
import {IFile, ITab} from "../interfaces/TabInterface";

export const setFilesInRepository = (state: TabState, action: SetFilesAction) => {
    const newTabs: ITab[] = state.tabs.map((tab, idx) => {
        if (idx === action.payload.idTab) {
            return Object.assign({},
                tab,
                {
                    documents: action.payload.files
                })
        }
        return tab
    })
    return newTabs
}

export const addFileInRepository = (state: TabState, action: AddFileAction) => {
    const newTabs: ITab[] = state.tabs.map((tab, idx) => {
        if (idx === action.payload.idTab) {
            return Object.assign({},
                tab,
                {
                    documents: [...tab.documents, action.payload.file]
                })
        }
        return tab
    })
    return newTabs
}

export const removeFileFromRepository = (state: TabState, action: RemoveFileAction) => {
    const newTabs: ITab[] = state.tabs.map((tab, idx) => {
        if (idx === action.payload.idTab) {
            return Object.assign({},
                tab,
                {
                    documents: tab.documents.filter((document: IFile)=> document.id !== action.payload.idFile)
                })
        }
        return tab
    })
    return newTabs
}