/* eslint-disable import/no-anonymous-default-export */
import * as TabsActionCreator from './tabActionCreator'
import * as SpecificRequirementsTabActionCreator from './specificRequirementsTabActionCreator'
import * as MunitsTabActionCreator from './munitsTabActionCreator'
import * as TableActionCreator from './tableActionCreator'
import * as FormActionCreator from './formActionCreator'
import * as ReqFormActionCreator from './requirementsFormActionCreator'
import * as NormatdocFormActionCreator from './notmatdocsFormActionCreator'
import * as FileRepositoryActionCreator from './fileRepositoryActionCreator'

export default {
    ...TabsActionCreator,
    ...SpecificRequirementsTabActionCreator,
    ...MunitsTabActionCreator,
    ...TableActionCreator,
    ...FormActionCreator,
    ...ReqFormActionCreator,
    ...NormatdocFormActionCreator,
    ...FileRepositoryActionCreator
}