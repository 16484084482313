import React, {FC, useState} from 'react';
import {SortType} from 'rsuite-table';
import ToolBar from '../../ToolBar/ToolBar';
import CustomTable, {AutocompleteCellTypes} from '../../CustomTable/CustomTable'
import {IContentTable, TabPanel} from '../../../interfaces/TabInterface';
import FormBottomTable from "../../Forms/FormBottomTable/FormBottomTable";
import {RegionsForm} from '../../../shared/form.table.enviroment';

interface RegionsTabProps extends TabPanel {

}

const RegionsTab: FC<RegionsTabProps> = ({content, idTab, limit, page, sortType, sortColumn, filters, form}) => {

    const [toggleForm, setToggleForm] = useState<boolean>(false)

    return (
        <div className="tab-wrap">
            <div className="table-container">
                <CustomTable
                    api={{
                        post: '/v_regionss/all',
                        put: '/regionss'
                    }}
                    idName={'irg_id'}
                    headerHeight={70}
                    idTab={idTab}
                    items={content?.items ? content.items : []}
                    limit={limit}
                    page={page}
                    sortType={sortType as SortType}
                    sortColumn={sortColumn}
                    totalItems={content?.totalItems}
                    filters={filters}
                    postForm={{
                        irg_id: '',
                        srg_name: '',
                        icn_clwrldcntr_id: ''
                    }}
                    setting={[{
                        key: 'srg_name',
                        label: 'Наименование',
                        width: 150,
                        sortable: true,
                        filtered: true
                    },
                        {
                            key: 'ncn_numcode',
                            label: 'Цифровой код страны',
                            width: 100

                        },
                        {
                            key: 'scn_code',
                            label: 'Страна',
                            width: 300,
                            api: '/v_clwrldcntrs/join',
                            autocomplete: AutocompleteCellTypes.DEFAULT
                        },
                        {
                            key: 'scn_name',
                            label: 'Полное наименование страны',
                            width: 300,
                            readonly: true
                        },
                        {
                            key: 'scn_letcode2',
                            label: 'Буквенный код 2 страны',
                            width: 309,
                            readonly: true
                        },
                        {
                            key: 'scn_letcode3',
                            label: 'Буквенный код 3 страны',
                            width: 309,
                            readonly: true
                        }]}
                />
                <FormBottomTable
                    open={toggleForm} onToggleForm={() => setToggleForm(prev => !prev)}
                    fields={RegionsForm}
                    api='/regionss'
                    idTab={idTab}
                    form={form}
                    idName='irg_id'
                    postForm={{
                        srg_name: '',
                        icn_clwrldcntr_id: ''
                    }}/>
            </div>
            <ToolBar idTab={idTab} toggleForm={() => setToggleForm(state => !state)} api="/regionss/"
                     idName={"irg_id"} tableNameExport={'v_regions'}/>
        </div>
    );
};

export default RegionsTab;