import React, {FC, useEffect, useRef, useState} from 'react';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ExpandLink.scss'
import {useExpandedDiv} from "../../hooks/useExpandedDiv";
import AnimatedPlus from "../AnimatedPlus/AnimatedPlus";

interface ExpandLinkProps {
    title: string
}

const ExpandLink: FC<ExpandLinkProps> = ({ title, children }) => {

    const [expand, setExpand] = useState<boolean>(false)
    const ref = useRef<HTMLDivElement>(null)
    const [expandSection, collapseSection] = useExpandedDiv(ref)

    useEffect(() => {
        if (expand) {
            expandSection()
        } else {
            collapseSection()
        }
    }, [expand, expandSection, collapseSection])



    return (
        <div className="menu-nav-item" >
            <div className="menu-nav-item_link" onClick={() => setExpand(expand => !expand)}>
                <AnimatedPlus expanded={expand} />
                <p className="menu-nav-item_text">{title}</p>
            </div>
            <div className="menu-nav-item_children" ref={ref}>
                    {children}
            </div>
        </div>
    );
};

export default ExpandLink;