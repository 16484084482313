import React, {FC, useState} from 'react';
import {SortType} from 'rsuite-table';
import {TabPanel} from '../../../interfaces/TabInterface';
import ToolBar from '../../ToolBar/ToolBar';
import CustomTable, {AutocompleteCellTypes} from '../../CustomTable/CustomTable';
import FormBottomTable from "../../Forms/FormBottomTable/FormBottomTable";
import {InfectionsForm} from "../../../shared/form.table.enviroment";

interface InfectionsTabProps extends TabPanel {
}

const InfectionsTab: FC<InfectionsTabProps> = ({idTab, limit, page, sortColumn, sortType, content, filters, form}) => {
    const [toggleForm, setToggleForm] = useState<boolean>(false)

    return (
        <div className="tab-wrap">
            <div className="table-container">
                <CustomTable
                    api={{
                        post: '/v_infections/all',
                        put: '/infections'
                    }}
                    idName={'iin_id'}
                    headerHeight={80}
                    idTab={idTab}
                    items={content?.items ? content.items : []}
                    limit={limit}
                    page={page}
                    sortType={sortType as SortType}
                    sortColumn={sortColumn}
                    totalItems={content?.totalItems}
                    filters={filters}
                    setting={[
                        {
                            key: 'sin_rusname',
                            label: 'Rus наименование',
                            width: 250,
                            sortable: true,
                            filtered: true
                        },
                        {
                            key: 'sin_latname',
                            label: 'Lat наименование',
                            width: 250,
                            sortable: true,
                            filtered: true
                        },
                        {
                            key: 'sin_type',
                            label: 'Тип',
                            width: 250,
                            sortable: true,
                            filtered: true,
                            autocomplete: AutocompleteCellTypes.INFECTION_TYPE
                        },
                        {
                            key: 'spc_phenoclass',
                            label: 'Фенофаза',
                            width: 300,
                            filtered: true,
                            autocomplete: AutocompleteCellTypes.DEFAULT,
                            api: '/v_phenoclasses/join'
                        },
                        {
                            key: 'sqo_rusname',
                            label: 'Карантинный объект',
                            width: 450,
                            filtered: true,
                            autocomplete: AutocompleteCellTypes.DEFAULT,
                            api: '/v_quarantobjs/join'
                        }
                    ]}
                    postForm={{
                        sin_rusname: '',
                        sin_latname: '',
                        sin_type: '',
                        ipc_phenoclass_id: '',
                        iqo_quarantobj_id: '',
                        iin_id: ''
                    }}
                />
                <FormBottomTable
                    open={toggleForm} onToggleForm={() => setToggleForm(prev => !prev)}
                    idName={'iin_id'}
                    form={form}
                    fields={InfectionsForm}
                    postForm={{
                        sin_rusname: '',
                        sin_latname: '',
                        sin_type: '',
                        ipc_phenoclass_id: '',
                        iqo_quarantobj_id: ''
                    }}
                    api="/infections"
                    idTab={idTab}
                />
            </div>
            <ToolBar idTab={idTab} toggleForm={() => setToggleForm(state => !state)} api="/infections/"
                     idName={'iin_id'} tableNameExport={'infections'} schema_name={'ais_weeds'}/>
        </div>
    );
};

export default InfectionsTab;