import React, { ChangeEvent, FC } from 'react';
import CheckBoxWithLabel from '../../Inputs/CheckBoxWithLabel/CheckBoxWithLabel';
import AutocompleteWithTags from '../../Inputs/AutocompleteWithTags/AutocompleteWithTags';

interface CountriesRegionsPointProps {
    control?: any
    exporters?: {[key: string]: any}[]
    reexporters?: {[key: string]: any}[]
    handleCheckerChange: (event: ChangeEvent<HTMLInputElement>) => void
    handleBlurSaveCheker: (nameField: string, value: string | Date | boolean) => void
    handleSaveTagTypeOne: (idTag: number, value: string) => void
    handleDeleteTagTypeOne: (idTag: string) => void
    handleSaveTagTypeTwo: (idTag: number, value: string) => void
    handleDeleteTagTypeTwo: (idTag: string) => void
    api: string
    tagIdName: string
    tagValueName: string
    idSearch: string
    nameSearch: string,
    nameChekerField: string,
    valueCheker: boolean
}

const CountriesRegionsPoint: FC<CountriesRegionsPointProps> = ({ 
    control, 
    exporters,
    reexporters, 
    handleCheckerChange, 
    handleBlurSaveCheker,
    handleSaveTagTypeOne,
    handleDeleteTagTypeOne,
    handleSaveTagTypeTwo,
    handleDeleteTagTypeTwo,
    api,
    tagIdName,
    tagValueName,
    idSearch,
    nameSearch,
    nameChekerField,
    valueCheker}) => {

    return (
        <div style={{
            display: 'grid',
            gridAutoRows: '1fr',
            gridTemplateColumns: '4fr 1fr',
            height: '100%',
            gridGap: '0 1vw'
        }}>
            <div style={{
                gridRowStart: 1,
                gridRowEnd: 2,
                gridColumnStart: 1,
                gridColumnEnd: 2
            }}>
                <CheckBoxWithLabel 
                    label={"Свободная зона от КО (экспортеры)"} 
                    control={control}
                    value={valueCheker}
                    onChangeInput={handleCheckerChange}
                    onBlurInput={handleBlurSaveCheker}
                    nameField={nameChekerField}
                />
                <AutocompleteWithTags
                        control={control}
                        label={"Экспортеры"}
                        widthInput={'100%'}
                        widthInputBlock={'80%'}
                        widthLabel={'20%'} 
                        api={api}
                        tags={exporters}
                        tagIdName={tagIdName}
                        tagValueName={tagValueName}
                        idSearch={idSearch}
                        nameSearch={nameSearch}
                        onBlurInput={handleSaveTagTypeOne}
                        onDeleteTag={handleDeleteTagTypeOne}
                /> 
                <AutocompleteWithTags 
                        control={control}
                        label={"Реэкспортёры"} 
                        widthInput={'100%'} 
                        widthInputBlock={'80%'} 
                        widthLabel={'20%'}
                        api={api}
                        tags={reexporters}
                        tagIdName={tagIdName}
                        tagValueName={tagValueName}
                        idSearch={idSearch}
                        nameSearch={nameSearch}
                        onBlurInput={handleSaveTagTypeTwo}
                        onDeleteTag={handleDeleteTagTypeTwo}
                />
            </div>
        </div>
    );
};

export default CountriesRegionsPoint;