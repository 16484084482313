import React, {FC, memo, useEffect, useState} from 'react';
import {IAddReqForm, IRequirementsTab, Requirement, TabTypes} from '../../../interfaces/TabInterface';
import RequirementsBlock from '../../RequirementsBlock/RequirementsBlock';
import ToolBarItem from '../../ToolBarItem/ToolBarItem';
import FormAddReq from '../../RequiredForm/FormAddReq/FormAddReq';
import {faPlusSquare, faTrashAlt, faFileExcel, faClock} from '@fortawesome/free-regular-svg-icons';
import './RequirementsTab.scss';
import {useActions} from '../../../hooks/useAction';
import {useTypedSelector} from '../../../hooks/useTypedSelector';
import searchIcon from '../../../img/icons/search.png';
import Modal from "../../Modal/Modal";
import FormSelectCountry from "../../Forms/FormSelectCountry/FormSelectCountry";

interface RequirementsTabProps {
    idTab: number
    content: IRequirementsTab
    form: IAddReqForm
}

const RequirementsTab: FC<RequirementsTabProps> = ({idTab, content, form}) => {

    const [searchReq, setSearchReq] = useState('')

    const {tabs} = useTypedSelector(state => state.tab)
    const {DeleteRequirementBlockReqForm, GetRequirementsList, AddTab} = useActions()
    const [toggleForm, setToggleForm] = useState<boolean>(false)
    const [isOpenModalSelectCountry, setIsOpenModalSelectCountry] = useState(false)

    useEffect(() => {
        GetRequirementsList(idTab, searchReq)
    }, [searchReq])

    const handleDeleteRows = () => {
        const idsRequirements: number[] = []
        tabs.forEach((tab: any, idx: number) => {
            if (idx === idTab) {
                (tab.content as IRequirementsTab)?.requirements.forEach((item) => {
                    if (item.checked === true) {
                        idsRequirements.push(+item.irq_id)
                    }
                })
            }
        })
        DeleteRequirementBlockReqForm(idTab, idsRequirements)
    }

    const downloadExcelByCountry = (id: string) => {

    }

    return (
        <>
            <div style={{position: 'relative', display: 'inline-block'}}>
                <img className="reqq-search_icon" src={searchIcon} alt="Поиск"/>
                <input
                    className="reqq-search"
                    placeholder="Введите страну требования..."
                    value={searchReq}
                    onChange={(event) => setSearchReq(event.currentTarget.value)}
                />
                {
                    searchReq && <div className="reqq-search_close" onClick={() => {setSearchReq('')}}>
                        ✖
                    </div>
                }
            </div>



            <div className="reqq-wrap">
                <div style={{width: '75vw', height: '100%'}}>
                    <FormAddReq open={toggleForm} onToggleForm={() => {setToggleForm(prev => !prev)}} idTab={idTab} form={form}/>
                    {content.requirements.map((requirement) =>
                        <div key={requirement.irq_id}>
                            <RequirementsBlock
                                id={requirement.irq_id}
                                checked={requirement.checked}
                                title={requirement.srq_str}
                                typeTab={TabTypes.REQUIREMENT_SPECIFIC_TAB} idTab={idTab}/>
                        </div>)}
                </div>
                <div className="toolbar" style={{height: 200}}>
                    <ToolBarItem icon={faTrashAlt} onClick={handleDeleteRows}/>
                    <ToolBarItem icon={faPlusSquare} onClick={() => {
                        setToggleForm(prev => !prev)
                    }}/>
                    <ToolBarItem icon={faFileExcel} onClick={() => {
                        setIsOpenModalSelectCountry(true)
                    }}/>
                    <ToolBarItem icon={faClock} onClick={() => {AddTab('История изменений', TabTypes.REQUIREMENTSHISTORY_TAB)}} />
                </div>
            </div>
            <Modal isOpen={isOpenModalSelectCountry} onClose={() => setIsOpenModalSelectCountry(false)}>
                <FormSelectCountry onSubmit={downloadExcelByCountry}/>
            </Modal>
        </>
    );
};

export default RequirementsTab;

