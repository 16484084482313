import { combineReducers } from "redux";
import { tabReducer } from "./tabReducer";
import { formReducer } from './formReducer';
import { tableReducer } from './tableReducer';
import { reqFormReducer } from './requirementsFormReducer';
import { normatdocFormReducer } from "./normatdocsFormReducer";

export const rootReducer = combineReducers({
    tab: tabReducer,
    form: formReducer,
    table: tableReducer,
    reqForm: reqFormReducer,
    normatdocForm: normatdocFormReducer
})

export type RootState = ReturnType<typeof rootReducer>