export enum TableActionsType {
    GET_CONTENT = 'GET_CONTENT',
    UPD_CONTENT = 'UPD_CONTENT',
    START_TABLE_FETCH = 'START_TABLE_FETCH',
    SUCCESS_TABLE_FETCH = 'SUCCESS_TABLE_FETCH',
    SUCCESS_TABLE_SAVE = 'SUCCESS_TABLE_SAVE',
    ERROR_TABLE_FETCH = 'ERROR_TABLE_FETCH',
    TOGGLE_CHECKBOX = 'TOOGLE_CHECKBOX',
    TOGGLE_ALL_CHECKBOX = 'TOGGLE_ALL_CHECKBOX',
    DELETE_ROW = 'DELETE_ROW',
    POST_ROW = 'POST_ROW',
    SET_LIMIT = 'SET_LIMIT',
    SET_PAGE = 'SET_PAGE',
    SET_SORT_COLUMN = 'SET_SORT_COLUMN',
    SET_SORT_TYPE = 'SET_SORT_TYPE',
    SET_FILTERS = 'SET_FILTERS'
}

export interface tableState {
    error: string,
    status: string
}

export interface GetContent {
    type: TableActionsType.GET_CONTENT,
    payload: {
        idTab: number,
        content: {[key: string]: any}
    }
}

export interface UpdContent {
    type: TableActionsType.UPD_CONTENT,
    payload: {
        idTab: number,
        data: {[key: string]: any},
        idRow: number
    }
}

/*export interface UpdContent {
    type: TableActionsType.UPD_CONTENT,
    payload: {
        idTab: number,
        idContent: number,
        dataKey: string,
        value: string | number,
        idName: string
    }
}*/

export interface StartFetch {
    type: TableActionsType.START_TABLE_FETCH,
}

export interface EndFetch {
    type: TableActionsType.SUCCESS_TABLE_FETCH,
}

export interface SuccessSave {
    type: TableActionsType.SUCCESS_TABLE_SAVE
}

export interface ErrorFetch {
    type: TableActionsType.ERROR_TABLE_FETCH,
    payload: {
        message: any
    }
}

export interface ToggleCheckbox {
    type: TableActionsType.TOGGLE_CHECKBOX,
    payload: {
        idTab: number,
        idItem: number,
        idName: string
    }
}

export interface ToggleAllCheckbox {
    type: TableActionsType.TOGGLE_ALL_CHECKBOX,
    payload: {
        idTab: number,
        checked: boolean
    }
}


export interface DeleteRow {
    type: TableActionsType.DELETE_ROW,
    payload: {
        idTab: number
        idsRow: number[]
        idName: string
    }
}


export interface PostRow {
    type: TableActionsType.POST_ROW,
    payload: {
        object: { [key: string]: string | number | boolean | null | undefined },
        api: string,
        idTab: number
    }
}

export interface SetLimit {
    type: TableActionsType.SET_LIMIT,
    payload: {
        idTab: number,
        limit: number
    }
}

export interface SetPage {
    type: TableActionsType.SET_PAGE,
    payload: {
        idTab: number,
        page: number
    }
}

export interface SetSortColumn {
    type: TableActionsType.SET_SORT_COLUMN,
    payload: {
        idTab: number,
        column: string
    }
}

export interface SetSortType {
    type: TableActionsType.SET_SORT_TYPE,
    payload: {
        idTab: number,
        type: string
    }
}

export interface SetFilters {
    type: TableActionsType.SET_FILTERS,
    payload: {
        idTab: number,
        filter: {
            field: string,
            value: string
        }
    }
}

export type TableAction = GetContent
    | UpdContent
    | StartFetch
    | EndFetch
    | ErrorFetch
    | ToggleCheckbox
    | ToggleAllCheckbox
    | DeleteRow
    | PostRow
    | SetLimit
    | SetPage
    | SetSortColumn
    | SetSortType
    | SetFilters
    | SuccessSave