import React, { FC } from 'react';
import { useActions } from '../../hooks/useAction';
import { TabTypes } from '../../interfaces/TabInterface';

import './NormatdocBlock.scss'

interface NormatdocBlockProps {
    idTab: number
    typeTab: TabTypes
    title: string
    checked?: boolean
    id: string
}

const NormatdocBlock: FC<NormatdocBlockProps> = ({ idTab, title, typeTab, id, checked }) => {

    const { AddTab, ToggleCheckbox } = useActions()

    return (

        <div className="normatdoc-block" >
            <input
                type="checkbox"
                className="checkbox-input"
                onChange={event => {
                    ToggleCheckbox(idTab, 0, id);
                }}
                checked={checked}
                
            />
            {/* { &&  AddTab(title, typeTab, id)} */}
            <p className="normatdoc-block_text" onClick={() =>  AddTab(title, typeTab, id) } > {title} </p>
        </div>
        // {/* </div> */}

    );
};

export default NormatdocBlock;